import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import axios from 'axios'
import Locale from 'date-fns/locale/en-GB'
import { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createTask } from '../../API/endpoints'
import { checkedIcon, enum_assignment_type, enum_task_type, unCheckedIcon, week_days } from '../../utils'
import { toast } from 'react-toastify'

const New_Task_Modal = ({ onCloseModal }) => {
  const [users, setUsers] = useState([])
  const queryClient = useQueryClient()

  const createTaskMutation = useMutation({
    mutationFn: createTask,
    onSuccess: (data) => {
      queryClient.setQueryData(['activeTasks'], data)
      queryClient.invalidateQueries(['activeTasks'], data, { exact: true })
      onCloseModal()
    },
    onError: (error) => {
      toast.error(error.message)
    }
  })

  const [newTaskData, setNewTaskData] = useState({
    name: '',
    description: '',
    task_type: null,
    repetition: null,
    assignment_type: null,
    assigned_to: null,
    is_for_all_users: true,
    due_time: null,
    due_date: null
  })

  const [validationErrors, setValidationErrors] = useState(null)

  const checkValidations = () => {
    const errors = {}
    if (!newTaskData.name) {
      errors.name = 'Name is required'
    }
    if (!newTaskData.task_type) {
      errors.task_type = 'Task Type is required'
    }
    if (!newTaskData.assignment_type) {
      errors.assignment_type = 'Assignment Type is required'
    }
    if (!newTaskData.due_time) {
      errors.due_time = 'Due Time is required'
    }
    if (newTaskData.task_type === enum_task_type.single && !newTaskData.due_date) {
      errors.due_date = 'Due Date is required'
    }
    if (newTaskData.task_type === enum_task_type.repetitive && (!newTaskData.repetition || newTaskData.repetition.length === 0)) {
      errors.repetition = 'You should select at least one day'
    }
    if (
      newTaskData.assignment_type === enum_assignment_type.per_user &&
      (!newTaskData.assigned_to || newTaskData.assigned_to.length === 0)
    ) {
      errors.assigned_to = 'You should select at least one user'
    }
    setValidationErrors(errors)
    return Object.keys(errors).length === 0
  }

  const onSubmit = async () => {
    for (const key in newTaskData) {
      if (newTaskData[key] === null || newTaskData[key] === undefined) {
        delete newTaskData[key]
      }
    }
    if (checkValidations()) {
      createTaskMutation.mutate({ newTaskData })
    }
  }

  useEffect(() => {
    axios.get(`taskUsers/under_management`).then((response) => {
      if (response.data.success) {
        setUsers(response.data.data)
      }
    })
  }, [])

  useEffect(() => {
    if (validationErrors && Object.keys(validationErrors).length > 0) {
      checkValidations()
    }
  }, [newTaskData])

  const today = new Date()
  today.setDate(today.getDate())

  return (
    <Dialog open={true} fullWidth sx={{ fieldset: { border: '1px solid' } }}>
      <DialogTitle>Shto Taskë</DialogTitle>
      <DialogContent>
        <TextField
          value={newTaskData?.name}
          onChange={(e) => setNewTaskData((prev) => ({ ...prev, name: e.target.value }))}
          margin='dense'
          label='Emri i Taskës'
          id='name'
          task_type='text'
          variant='outlined'
          size='small'
          fullWidth
          helperText={validationErrors?.name}
          error={Boolean(validationErrors?.name)}
        />
        <TextField
          value={newTaskData?.description}
          onChange={(e) => setNewTaskData((prev) => ({ ...prev, description: e.target.value }))}
          margin='dense'
          label='Përshkrimi i Taskës'
          id='description'
          task_type='text'
          variant='outlined'
          size='small'
          fullWidth
        />
        <RadioGroup
          row
          aria-labelledby='task-task_type'
          onChange={(e) =>
            setNewTaskData((prev) => ({
              ...prev,
              task_type: e.target.value,
              repetition: null,
              due_date: null
            }))
          }
        >
          <FormControlLabel
            value={enum_task_type.single}
            label='E Njëherëshme'
            control={<Radio sx={{ color: validationErrors?.task_type && 'red' }} />}
            sx={{ marginBottom: 0, color: validationErrors?.task_type && 'red' }}
            onClick={() =>
              setNewTaskData((prev) => ({ ...prev, due_time: newTaskData.repetition === 'single' ? null : newTaskData.due_date }))
            }
          />
          <FormControlLabel
            value={enum_task_type.repetitive}
            label='E Përsëritshme'
            control={<Radio sx={{ color: validationErrors?.task_type && 'red' }} />}
            sx={{ marginBottom: 0, color: validationErrors?.task_type && 'red' }}
          />
        </RadioGroup>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
          <TimePicker
            ampm={false}
            disabled={newTaskData.task_type == null ? true : false}
            value={newTaskData?.due_time}
            minTime={newTaskData.task_type === 'single' ? today : false}
            onChange={(e) => setNewTaskData((prev) => ({ ...prev, due_time: e }))}
            renderInput={(params) => (
              <TextField
                {...params}
                margin='dense'
                label='Afati Kohor'
                placeholder='Hours:Minutes'
                variant='outlined'
                size='small'
                helperText={validationErrors?.due_time}
                error={Boolean(validationErrors?.due_time)}
              />
            )}
          />
        </LocalizationProvider>

        <p style={{ color: 'red' }}>{validationErrors?.task_type}</p>
        {newTaskData?.task_type === enum_task_type.single && (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
            <DatePicker
              label='Data e Afatit'
              inputFormat='dd/MM/yyyy'
              minDate={today}
              value={newTaskData.due_date}
              onChange={(e) => setNewTaskData((prev) => ({ ...prev, due_date: e.setHours(12, 0, 0) }))}
              renderInput={(params) => (
                <TextField {...params} size='small' helperText={validationErrors?.due_date} error={Boolean(validationErrors?.due_date)} />
              )}
            />
          </LocalizationProvider>
        )}
        {newTaskData?.task_type === enum_task_type.repetitive && (
          <FormControl>
            <FormGroup>
              {week_days?.map((item) => (
                <FormControlLabel
                  key={item.value}
                  control={
                    <Checkbox
                      checked={(newTaskData?.repetition ?? []).includes(item.value)}
                      value={item.value}
                      onChange={(e) =>
                        setNewTaskData((prev) => ({
                          ...prev,
                          repetition: e.target.checked
                            ? [...(prev.repetition ?? []), item.value]
                            : prev.repetition.filter((day) => day !== item.value)
                        }))
                      }
                    />
                  }
                  label={item.name}
                />
              ))}
              <p style={{ color: 'red' }}>{validationErrors?.repetition}</p>
            </FormGroup>
          </FormControl>
        )}
        <RadioGroup
          row
          aria-labelledby='assignment-task_type'
          onChange={(e) =>
            setNewTaskData((prev) => ({
              ...prev,
              assignment_type: e.target.value,
              is_for_all_users: e.target.value === enum_assignment_type.all_users,
              assigned_to: e.target.value === enum_assignment_type.all_users ? null : []
            }))
          }
        >
          <FormControlLabel
            value={enum_assignment_type.all_users}
            label='Te gjith përdoruesit'
            control={<Radio sx={{ color: validationErrors?.assignment_type && 'red' }} />}
            sx={{ marginBottom: 0, color: validationErrors?.assignment_type && 'red' }}
          />
          <FormControlLabel
            value={enum_assignment_type.per_user}
            label='Zgjedh përdoruesit'
            control={<Radio sx={{ color: validationErrors?.assignment_type && 'red' }} />}
            sx={{ marginBottom: 0, color: validationErrors?.assignment_type && 'red' }}
          />
        </RadioGroup>
        <p style={{ color: 'red' }}>{validationErrors?.assignment_type}</p>
        {newTaskData.assignment_type === enum_assignment_type.per_user && (
          <Autocomplete
            multiple
            fullWidth
            disableCloseOnSelect
            options={users.map((user) => user.user_id)}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
            onChange={(_e, selectedOptions) => {
              const selectedIds = selectedOptions.map((option) => option._id)
              setNewTaskData((prev) => ({ ...prev, assigned_to: selectedIds }))
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option._id}>
                <Checkbox icon={unCheckedIcon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {`${option.first_name} ${option.last_name}`}
              </li>
            )}
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Users'
                placeholder='Users'
                size='small'
                fullWidth
                helperText={validationErrors?.assigned_to}
                error={Boolean(validationErrors?.assigned_to)}
              />
            )}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal} size='small' variant='contained' color='error'>
          Anulo
        </Button>
        <Button disabled={createTaskMutation.isLoading} onClick={onSubmit} size='small' variant='contained'>
          {createTaskMutation.isLoading ? 'Loading...' : 'Krijo'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default New_Task_Modal
