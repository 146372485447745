import { Grid } from '@mui/material'
import Trophy from './Cards/Trophy'
import SalaryCard from './Cards/SalaryCard'
import HoursTable from './DataTable/My_Hours_table'
import MonthPicker from './Month_Picker/Month_picker'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import LeavesCalendar from './My_Leaves_Calendar/LeavesCalendar'

export default function MyWorkingHourse({ userId }) {
  const [value, setValue] = useState(new Date())

  const [salaryInfo, setSalaryInfo] = useState([])
  const [paidLeavesHours, setPaidLeavesHours] = useState([0])
  const [paidLeavesMoney, setPaidLeavesMoney] = useState([0])

  const [worktimeMoney, setWorktimeMoney] = useState([0])
  const [worktimeHours, setWorktimeHours] = useState([0])

  const [overtimeMoney, setOvetimeMoney] = useState([0])
  const [overtimeHourse, setOvertimeHourse] = useState([0])

  const [weekendMoney, setWeekendMoney] = useState([0])
  const [weekendtimeHourse, setWeekendtimeHourse] = useState([0])
  const [myHours, setMyHours] = useState([])

  let query = `year=${moment(value).format('YYYY')}&month=${moment(value).format('MM')}&day=${moment(value).format('DD')}`
  useEffect(() => {
    let endpont = `dailyWorkingHours/myHours/me?${query}`
    if (userId) {
      endpont = `dailyWorkingHours/colagueHours/${userId}?${query}`
    }
    axios
      .get(endpont)
      .then((res) => {
        if (res.data.success) {
          setWorktimeMoney(res.data.data.myWorkHours?.[0]?.workTimeMoney || 0)
          setWorktimeHours(res.data.data.myWorkHours?.[0]?.totalWorkTime || 0)

          setOvetimeMoney(res.data.data.myWorkHours[0]?.overtimeMoney || 0)
          setOvertimeHourse(res.data.data.myWorkHours[0]?.totalOvertime || 0)

          setWeekendMoney(res.data.data.myWorkHours[0]?.weekendTimeMoney || 0)
          setWeekendtimeHourse(res.data.data.myWorkHours[0]?.totalWeekendTime || 0)

          setSalaryInfo(res.data.data.myWorkHours)
          setPaidLeavesHours(res.data.data.paidLeaves?.totalHours || 0)
          setPaidLeavesMoney(res.data.data.paidLeaves?.totalValue || 0)
          setMyHours(res.data.data.myStats)
        } else {
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        console.log('error', err)
      })
  }, [value])

  let brutoSalary = 0
  brutoSalary = worktimeMoney + overtimeMoney + +weekendMoney + paidLeavesMoney

  return (
    <Grid container spacing={2} mt={3} pb={5} pl={2} pr={2}>
      <Grid item xs={12} md={12} lg={12} xl={4}>
        <Trophy brutoSalary={brutoSalary} userId={userId} />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={8}>
        <SalaryCard
          paidLeavesHours={paidLeavesHours}
          paidLeavesMoney={paidLeavesMoney}
          worktimeMoney={worktimeMoney}
          worktimeHours={worktimeHours}
          overtimeHourse={overtimeHourse}
          overtimeMoney={overtimeMoney}
          weekendMoney={weekendMoney}
          weekendtimeHourse={weekendtimeHourse}
        />
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
        <MonthPicker value={value} setValue={setValue} />
      </Grid>
      <Grid item xs={12}>
        <HoursTable myHours={myHours} />
      </Grid>
      {userId ? null : (
        <Grid item xs={12}>
          <LeavesCalendar userId={userId} />
        </Grid>
      )}
    </Grid>
  )
}
