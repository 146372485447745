import { Paper, Table, TableRow, TableHead, TableBody, TableCell, TableContainer } from '@mui/material'
import moment from 'moment'
import React from 'react'

const HoursTable = ({ myHours }) => {
  const allWorkingHourTypes = Array.from(
    new Set(
      myHours.flatMap((entry) =>
        Object.keys({ Worktime: entry?.work_time, Weekend: entry?.weekend, Overtime: entry?.overtime, ...entry?.workingHours } || {})
      )
    )
  )

  const filteredRows = allWorkingHourTypes.filter((type) =>
    myHours.some((entry) => {
      const valueToCheck =
        type === 'Overtime'
          ? entry?.overtime
          : type === 'Weekend'
            ? entry?.weekend
            : type === 'Worktime'
              ? entry?.work_time
              : entry?.workingHours?.[type]

      // Check if the value is not empty or null and not equal to 0
      return valueToCheck !== null && valueToCheck !== '' && valueToCheck !== 0 && valueToCheck !== '0' && valueToCheck !== undefined
    })
  )

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
        <TableHead sx={{ backgroundColor: '#F3F3F3' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Statuset për ditët</TableCell>
            {myHours.map((entry) => (
              <TableCell sx={{ fontWeight: 'bold' }} key={entry._id} align='right'>
                {moment(entry.date).format('DD')}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredRows.map((type) => (
            <TableRow key={type} sx={{ '&:last-of-type  td, &:last-of-type  th': { border: 0 } }}>
              <TableCell>{type}</TableCell>
              {myHours.map((entry) => (
                <TableCell key={entry._id} align='right'>
                  {type === 'Overtime'
                    ? (Number(entry?.overtime) || 0).toFixed(2)
                    : type === 'Weekend'
                      ? (Number(entry?.weekend) || 0).toFixed(2)
                      : type === 'Worktime'
                        ? (Number(entry?.work_time) || 0).toFixed(2)
                        : (Number(entry.workingHours[type]) || 0).toFixed(2)}
                  h
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default HoursTable
