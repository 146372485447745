import { AccountTreeOutlined } from '@material-ui/icons'
import { useEffect, useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import OrganogramSpeeex from '../../assets/images/organogram.svg'
import OrganogramSpeeexMobile from '../../assets/images/organogram_mobile.svg'

export default function Organogram() {
  const [mobile, setMobile] = useState(OrganogramSpeeex)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        setMobile(OrganogramSpeeex)
      } else {
        setMobile(OrganogramSpeeexMobile)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div>
      <em onClick={() => setIsOpen(true)} style={{ color: '#464c62' }} className='organoopen'>
        <AccountTreeOutlined color='disabled' fontSize='small' />
      </em>
      {isOpen && <Lightbox mainSrc={mobile} imageTitle={'SPEEEX Organogram'} onCloseRequest={() => setIsOpen(false)} />}
    </div>
  )
}
