import { Box, Button, CardContent, Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import axios from 'axios'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Card } from 'reactstrap'
import ContentWrapper from '../Layout/ContentWrapper'
import Header from '../Layout/Header'

const TaxesSurvey = ({ user_id }) => {
  const [showTextF, setShowTextF] = useState(false)
  const [trueData, setTrueData] = useState(false)

  const [citizenship, setCitizenship] = useState('')
  const [activity, setActivity] = useState()
  const [workType, setWorkType] = useState()
  const [residencePermit, setResidencePermit] = useState(null)
  const [pensionContributions, setPensionContributions] = useState(null)
  const [trustAccount, setTrustAccount] = useState(null)
  const [personalEmail, setPersonalEmail] = useState()

  const partTimeID = '5d9b2907d489d437f295059f'
  const fullTimeID = '5d9b290fd489d437f29505a0'

  const submitHandler = (e) => {
    e.preventDefault()
    if (citizenship === 'Kosovar' && activity && workType && trueData && personalEmail) {
      postRequest()
    } else if (citizenship !== 'Kosovar' && citizenship.length > 3 && residencePermit === false && trueData && personalEmail) {
      postRequest()
    } else if (
      citizenship !== 'Kosovar' &&
      citizenship.length > 3 &&
      residencePermit === true &&
      trustAccount === true &&
      pensionContributions !== null &&
      trueData &&
      personalEmail
    ) {
      postRequest()
    } else if (
      citizenship !== 'Kosovar' &&
      citizenship.length > 3 &&
      residencePermit === true &&
      trustAccount === false &&
      trueData &&
      personalEmail
    ) {
      postRequest()
    } else if (citizenship !== 'Kosovar' && citizenship !== '' && citizenship.length <= 3) {
      toast.error('Shtetësia duhet të jetë më e gjatë!')
    } else {
      toast.error('Të gjitha fushat duhet të jenë të plotësuara!')
    }
  }

  const postRequest = async () => {
    const inputsData = {
      activity,
      citizenship: citizenship,
      work_type: workType,
      residence_permit: residencePermit,
      pension_contributions: pensionContributions,
      trust_account: trustAccount,
      personal_email: personalEmail
    }
    await axios
      .post(`/users/taxessurvey/${user_id}`, inputsData)
      .then((response) => {
        if (response.data.status) {
          toast.success('Formularin e plotësuat me sukses!')
          window.location.pathname = '/dashboard'
          localStorage.setItem('token', response.data.token)
        } else {
          toast.error(response.data.message)
        }
      })
      .catch((error) => {
        toast.error(error.message)
      })
  }

  return (
    <div>
      <Header />
      <ContentWrapper>
        <Box width={{ xs: '100%', lg: '80%' }} m='auto' component='form' onSubmit={submitHandler}>
          <Card>
            <CardContent style={{ padding: { xs: '15px', lg: '40px' } }}>
              <Typography align='center' variant='h5' style={{ fontWeight: '600', letterSpacing: '1.5px' }}>
                Plotësimi i këtij formularit është i detyrueshëm!
              </Typography>
              <Box width={{ xs: '100%', lg: '65%' }} m='auto' mt={10}>
                <Box sx={{ mb: 3 }}>
                  <Typography variant='h6' style={{ color: '#000', marginBottom: 10 }}>
                    ➡ Unë punoj në Speeex me orar:
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <RadioGroup style={{}}>
                      <FormControlLabel
                        control={<Radio />}
                        value={fullTimeID}
                        label='Full-Time'
                        onChange={(e) => {
                          setActivity(e.target.value)
                        }}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        value={partTimeID}
                        label='Part-Time'
                        onChange={(e) => {
                          setActivity(e.target.value)
                        }}
                      />
                    </RadioGroup>
                  </Box>
                </Box>
                <Box sx={{ mb: 3 }}>
                  <Typography variant='h6' style={{ color: '#000' }}>
                    ➡ Ju lutem konfirmoni një nga dy opsionet poshtë:
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <Typography style={{ color: '#000', marginBottom: 10 }}>Puna ime në kompaninë SPEEEX është...</Typography>
                    <RadioGroup style={{}}>
                      <FormControlLabel
                        control={<Radio />}
                        value='Primare'
                        label='Punë Primare'
                        onChange={(e) => {
                          setWorkType(e.target.value)
                        }}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        value='Sekondare'
                        label='Punë Sekondare'
                        onChange={(e) => {
                          setWorkType(e.target.value)
                        }}
                      />
                    </RadioGroup>
                  </Box>
                </Box>
                <Box sx={{ mb: 3 }}>
                  <Typography variant='h6' style={{ color: '#000', marginBottom: 10 }}>
                    ➡ Ju lutem selektoni shtetësinë tuaj:
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <RadioGroup>
                      <FormControlLabel
                        control={<Radio />}
                        value='Kosovar'
                        label='Shtetas i Kosovës'
                        onChange={(e) => {
                          setCitizenship(e.target.value)
                          setShowTextF(false)
                        }}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        value='Tjeter'
                        label='Jo-shtetas i Kosovës'
                        onChange={() => {
                          setCitizenship('')
                          setShowTextF(true)
                        }}
                      />
                    </RadioGroup>
                    {showTextF && (
                      <input
                        type='text'
                        placeholder='Shënoni shtetësinë tuaj'
                        style={{ padding: '5px 10px', fontSize: '16px', minWidth: '200px' }}
                        onChange={(e) => {
                          setCitizenship(e.target.value)
                        }}
                      />
                    )}
                  </Box>
                </Box>

                {showTextF && (
                  <Box sx={{ mb: 3 }}>
                    <Typography variant='h6' style={{ color: '#000', marginBottom: 10 }}>
                      ➡ A posedoni lejeqëndrim valid në Kosovë?
                    </Typography>
                    <Box sx={{ pl: 3 }}>
                      <RadioGroup style={{}}>
                        <FormControlLabel
                          control={<Radio />}
                          value='po'
                          label='Po'
                          onChange={(e) => {
                            setResidencePermit(true)
                            setPensionContributions(null)
                          }}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value='jo'
                          label='Jo'
                          onChange={(e) => {
                            setResidencePermit(false)
                            setPensionContributions(null)
                          }}
                        />
                      </RadioGroup>
                      {/* {residencePermit === true && (
                        <>
                          <Typography>
                            Bashkangjitni dokumentin për lejeqëndrim!
                          </Typography>
                          <Button
                            variant="contained"
                            startIcon={<CloudUpload />}
                            component="label"
                          >
                            Ngarko
                            <input type="file" hidden />
                          </Button>
                        </>
                      )} */}
                      {residencePermit === false && (
                        <>
                          <Typography>
                            Meqenëse nuk keni dokument lejeqëndrimi valid në Republikën e Kosovës, kontributet tuaja nuk mund deklarohen në
                            Trust. Ju lutemi të aplikoni për lejeqëndrim dhe të hapni llogari në Trust në mënyrë që të bëheni pjesë e skemës
                            pensionale të Kosoves. Përgjatë kësaj kohe Speeex nuk do ua ndalë kontributet nga paga juaj. Për më shumë
                            informacion klikoni linkun poshtë:
                          </Typography>
                          <a target='_blank' href='https://mpb.rks-gov.net/f/45/Leje-qendrim-i-perkohshem-per-te-huajt'>
                            https://mpb.rks-gov.net/f/45/Leje-qendrim-i-perkohshem-per-te-huajt
                          </a>
                        </>
                      )}
                    </Box>
                    {residencePermit && (
                      <Box sx={{ my: 3 }}>
                        <Typography variant='h6' style={{ color: '#000' }}>
                          ➡ A posedoni llogari të hapur në Trust?
                        </Typography>
                        <Box sx={{ pl: 3 }}>
                          <RadioGroup style={{}}>
                            <FormControlLabel
                              control={<Radio />}
                              value='po'
                              label='Po'
                              onChange={(e) => {
                                setTrustAccount(true)
                              }}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              value='jo'
                              label='Jo'
                              onChange={(e) => {
                                setTrustAccount(false)
                                setPensionContributions(false)
                              }}
                            />
                          </RadioGroup>
                          {trustAccount === false && (
                            <>
                              <Typography>
                                Ju lutemi të hapni llogari në Trust në mënyrë që të bëheni pjesë e skemës pensionale të Kosoves. Për më
                                shumë informacion klikoni linkun poshtë:
                              </Typography>
                              <a target='_blank' href='https://mpb.rks-gov.net/f/45/Leje-qendrim-i-perkohshem-per-te-huajt'>
                                https://mpb.rks-gov.net/f/45/Leje-qendrim-i-perkohshem-per-te-huajt
                              </a>
                            </>
                          )}
                        </Box>
                      </Box>
                    )}

                    {residencePermit && trustAccount && (
                      <Box sx={{ my: 3 }}>
                        <Typography variant='h6' style={{ color: '#000' }}>
                          ➡ Unë si punonjës i kompanisë Speeex jap konfirmim që kontributet pensionale të punonjësit:
                        </Typography>
                        <Box sx={{ pl: 3 }}>
                          <RadioGroup style={{}}>
                            <FormControlLabel
                              control={<Radio />}
                              value='po'
                              label='Të më deklarohen në Trust nga paga ime mujore.'
                              onChange={(e) => {
                                setPensionContributions(true)
                              }}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              value='jo'
                              label='Të mos më deklarohen në Trust nga paga ime mujore.'
                              onChange={(e) => {
                                setPensionContributions(false)
                              }}
                            />
                          </RadioGroup>
                          <Typography style={{ color: '#000' }}>
                            Në ditët në vijim do ftoheni të nënshkruani konfirmimin mbi informacionin që keni ofruar lartë.
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
                <Box sx={{ my: 3 }}>
                  <Typography variant='h6' style={{ color: '#000' }}>
                    ➡ Ju lutem vendosni emailin tuaj personal poshtë (jo email me domain: Sunrise ose UPC):
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <input
                      type='email'
                      placeholder='Email juaj personal'
                      style={{ padding: '5px 10px', fontSize: '16px', minWidth: '300px', marginTop: 8 }}
                      onChange={(e) => setPersonalEmail(e.target.value)}
                    />
                  </Box>
                </Box>
                <FormControlLabel
                  control={<Checkbox value={trueData} onChange={() => setTrueData(!trueData)} />}
                  label='Unë pranoj se të gjitha të dhënat e mësipërme janë të sakta!'
                />
                <Typography style={{ color: 'red' }}>Të gjitha fushat duhet të plotësohen*</Typography>
                <Box style={{ marginTop: '10px' }}>
                  <Button type='submit' variant='contained'>
                    Ruaj
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </ContentWrapper>
    </div>
  )
}

export default TaxesSurvey
