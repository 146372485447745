import React from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import pubsub from 'pubsub-js'
import moment from 'moment'

class NotificationItem extends React.Component {
  constructor() {
    super()

    this.state = {
      isHovering: false,
      isRead: false,
      canClick: true
    }

    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.goToPath = this.goToPath.bind(this)
    this.markAsRead = this.markAsRead.bind(this)
  }

  componentDidMount() {
    this.setState({
      isRead: this.props.isRead
    })

    pubsub.subscribe('haltClicks', () => {
      this.setState({
        canClick: false
      })
      setTimeout(() => {
        this.setState({
          canClick: true
        })
      }, 10000)
    })
  }

  onMouseLeave() {
    this.setState({
      isHovering: false
    })
  }

  onMouseEnter() {
    this.setState({
      isHovering: true
    })
  }

  goToPath(e) {
    e.preventDefault()
    switch (this.props.type) {
      case 'SALERY_PROCESSED_NOTIFICATION': {
        this.props.history.push('/wages')
        break
      }
      case 'END_OF_CONTRACT': {
        this.props.history.push('/editUsers/' + this.props.refId)
        break
      }
      case 'END_OF_PROBATION': {
        this.props.history.push('/editUsers/' + this.props.refId)
        break
      }
      case 'LAST_DAY_ON_WORK': {
        this.props.history.push('/editUsers/' + this.props.refId)
        break
      }
      case 'LEAVE_APPROVED_NOTIFICATION': {
        this.props.history.push('/leaves/' + this.props.refId)
        break
      }
      case 'LEAVE_REJECTED_NOTIFICATION': {
        this.props.history.push('/leaves/' + this.props.refId)
        break
      }
      case 'NEW_LEAVE_NOTIFICATION': {
        this.props.history.push('/manageLeaves/' + this.props.createdBy + '/' + this.props.refId)
        break
      }
      case 'CAR_USAGE_APPROVED_NOTIFICATION': {
        this.props.history.push('/bookings/' + this.props.refId)
        break
      }
      case 'CAR_USAGE_REJECTED_NOTIFICATION': {
        this.props.history.push('/bookings/' + this.props.refId)
        break
      }
      case 'NEW_CAR_USAGE_NOTIFICATION': {
        this.props.history.push('/manageBookings/' + this.props.createdBy + '/' + this.props.refId)
        break
      }
      case 'NEW_BUSINESSTRIP_NOTIFICATION': {
        this.props.history.push('/businesstrip', { data: this.props.refId })
        break
      }
      case 'APPROVED_BUSINESSTRIP_NOTIFICATION': {
        this.props.history.push('/businesstrip', { data: this.props.refId })
        break
      }
      case 'REJECTED_BUSINESSTRIP_NOTIFICATION': {
        this.props.history.push('/businesstrip', { data: this.props.refId })
        break
      }
      default: {
        return
      }
    }
  }

  async markAsRead(e) {
    e.preventDefault()
    if (this.props.id && this.state.isRead === false) {
      await this.setState({ isRead: true })

      axios
        .post('notification/markread', { notification_id: this.props.id })
        .then((response) => {
          if (response.data.success) {
          } else {
            pubsub.publish('showNotification', {
              message: response.data.message,
              type: 'error'
            })
          }
        })
        .catch((e) => {
          console.error('Notification could not be marked as read', e)
        })
    }
  }

  _getIconFromType = (type) => {
    switch (type) {
      case 'SALERY_PROCESSED_NOTIFICATION': {
        return (
          <span className='fa-stack'>
            <em className='fa fa-circle fa-stack-2x text-success'></em>
            <em className='fa fa-money-bill fa-stack-1x fa-inverse text-white'></em>
          </span>
        )
      }
      case 'LEAVE_APPROVED_NOTIFICATION': {
        return (
          <span className='fa-stack'>
            <em className='fa fa-circle fa-stack-2x text-success'></em>
            <em className='far fa-clock fa-stack-1x fa-inverse text-white'></em>
          </span>
        )
      }
      case 'LEAVE_REJECTED_NOTIFICATION': {
        return (
          <span className='fa-stack'>
            <em className='fa fa-circle fa-stack-2x text-danger'></em>
            <em className='far fa-clock fa-stack-1x fa-inverse text-white'></em>
          </span>
        )
      }
      case 'NEW_LEAVE_NOTIFICATION': {
        return (
          <span className='fa-stack'>
            <em className='fa fa-circle fa-stack-2x text-warning'></em>
            <em className='far fa-clock fa-stack-1x fa-inverse text-white'></em>
          </span>
        )
      }

      case 'CAR_USAGE_APPROVED_NOTIFICATION': {
        return (
          <span className='fa-stack'>
            <em className='fa fa-circle fa-stack-2x text-success'></em>
            <em className='far fa-clock fa-stack-1x fa-inverse text-white'></em>
          </span>
        )
      }
      case 'CAR_USAGE_REJECTED_NOTIFICATION': {
        return (
          <span className='fa-stack'>
            <em className='fa fa-circle fa-stack-2x text-danger'></em>
            <em className='far fa-clock fa-stack-1x fa-inverse text-white'></em>
          </span>
        )
      }
      case 'NEW_CAR_USAGE_NOTIFICATION': {
        return (
          <span className='fa-stack'>
            <em className='fa fa-circle fa-stack-2x text-warning'></em>
            <em className='far fa-clock fa-stack-1x fa-inverse text-white'></em>
          </span>
        )
      }

      case 'NEW_BUSINESSTRIP_NOTIFICATION': {
        return (
          <span className='fa-stack'>
            <em className='fa fa-circle fa-stack-2x text-warning'></em>
            <em className='fa fa-plane fa-stack-1x fa-inverse text-white'></em>
          </span>
        )
      }
      case 'APPROVED_BUSINESSTRIP_NOTIFICATION': {
        return (
          <span className='fa-stack'>
            <em className='fa fa-circle fa-stack-2x text-success'></em>
            <em className='fa fa-plane fa-stack-1x fa-inverse text-white'></em>
          </span>
        )
      }
      case 'REJECTED_BUSINESSTRIP_NOTIFICATION': {
        return (
          <span className='fa-stack'>
            <em className='fa fa-circle fa-stack-2x text-danger'></em>
            <em className='fa fa-plane fa-stack-1x fa-inverse text-white'></em>
          </span>
        )
      }
      case 'LAST_DAY_ON_WORK': {
        return (
          <span className='fa-stack'>
            <em className='fa fa-circle fa-stack-2x text-dark'></em>
            <em className='fa fa-user-times fa-stack-1x fa-inverse text-white'></em>
          </span>
        )
      }
      case 'END_OF_CONTRACT': {
        return (
          <span className='fa-stack'>
            <em className='fa fa-circle fa-stack-2x text-muted'></em>
            <em className='fa fa-sticky-note fa-stack-1x fa-inverse text-white'></em>
          </span>
        )
      }
      case 'END_OF_PROBATION': {
        return (
          <span className='fa-stack'>
            <em className='fa fa-circle fa-stack-2x text-muted'></em>
            <em className='fa fa-briefcase fa-stack-1x fa-inverse text-white'></em>
          </span>
        )
      }
      default: {
        return (
          <span className='fa-stack'>
            <em className='fa fa-circle fa-stack-2x text-warning'></em>
            <em className='fa fa-tasks fa-stack-1x fa-inverse text-white'></em>
          </span>
        )
      }
    }
  }

  _getTextColorFromType = (type) => {
    switch (type) {
      case 'SALERY_PROCESSED_NOTIFICATION': {
        return 'text-success'
      }
      case 'LEAVE_APPROVED_NOTIFICATION': {
        return 'text-success'
      }
      case 'LEAVE_REJECTED_NOTIFICATION': {
        return 'text-danger'
      }
      case 'NEW_LEAVE_NOTIFICATION': {
        return 'text-warning'
      }
      case 'CAR_USAGE_APPROVED_NOTIFICATION': {
        return 'text-success'
      }
      case 'CAR_USAGE_REJECTED_NOTIFICATION': {
        return 'text-danger'
      }
      case 'NEW_CAR_USAGE_NOTIFICATION': {
        return 'text-warning'
      }
      case 'NEW_BUSINESSTRIP_NOTIFICATION': {
        return 'text-warning'
      }
      case 'APPROVED_BUSINESSTRIP_NOTIFICATION': {
        return 'text-success'
      }
      case 'REJECTED_BUSINESSTRIP_NOTIFICATION': {
        return 'text-danger'
      }
      case 'LAST_DAY_ON_WORK': {
        return 'text-dark'
      }
      case 'END_OF_CONTRACT': {
        return 'text-muted'
      }
      case 'END_OF_PROBATION': {
        return 'text-success'
      }
      default: {
        return 'text-warning'
      }
    }
  }

  render() {
    const { type, title, message, path } = this.props
    const isRead = this.state.isRead ? null : styles.unRead
    const isHovering = this.state.isHovering ? styles.hover : null
    return (
      <div
        onClick={this.markAsRead}
        className={`list-group-item cursor-pointer`}
        style={{ ...isRead, ...isHovering, padding: '3px 10px 3px 10px' }}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className='media'>
          <div className='align-self-start mr-2' onClick={this.goToPath}>
            {this._getIconFromType(type)}
          </div>
          <div className='media-body text-truncate' onClick={this.goToPath}>
            <p className='mb-1'>
              <span className={`${this._getTextColorFromType(this.props.type)} m-0 ${path && 'cursor-pointer'}`}>{title}</span>
            </p>
            <p className={`m-0 ${path && 'cursor-pointer'}`}>
              <small>{message}</small>
            </p>
          </div>
          {!this.state.isRead && this.state.isHovering && (
            <div className='align-self-end mr-2' style={{ position: 'relative', top: -5 }}>
              <span className='fa-stack'>
                <em className={`fas fa-check-square fa-stack-2x fa-inverse text-green`}></em>
              </span>
            </div>
          )}
          <div className='ml-auto' onClick={this.goToPath}>
            <small className='text-muted ml-2'>{this.props.createdDate && moment(this.props.createdDate).fromNow()}</small>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  hover: {
    backgroundColor: 'rgb(217, 222, 245)'
  },
  unRead: {
    fontWeight: '900',
    backgroundColor: '#e4e7f5'
  }
}

NotificationItem.defaultProps = {
  type: 'INFO',
  title: 'Njoftim',
  message: 'Njoftim i ri është postuar',
  path: null,
  isRead: false,
  id: null,
  createdDate: null,
  refId: null
}

export default withRouter(NotificationItem)
