import axios from 'axios'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'
import ContentWrapper from '../../Layout/ContentWrapper'

class Activity extends React.Component {
  constructor() {
    super()
    this.state = {
      data: []
    }
  }

  componentDidMount() {
    this.axiosCancelSource = axios.CancelToken.source()
    axios
      .get('activities', { cancelToken: this.axiosCancelSource.token })
      .then((res) => {
        this.setState({ data: res.data.data })
      })
      .catch((error) => {})
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel('Component unmounted')
  }

  render() {
    const columns = [
      {
        name: 'Emri i Aktivitetit',
        label: 'Emri i Aktivitetit',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'Krijuar nga',
        label: 'Krijuar nga',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'Modifikuar nga',
        label: 'Modifikuar nga',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'Data e modifikimit',
        label: 'Data e Modifikimit',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'Pamje',
        label: 'Pamje',
        options: {
          filter: true,
          sort: true
        }
      }
    ]
    const options = {
      responsive: 'scroll',
      filterType: 'dropdown',
      selectableRows: 'none',
      textLabels: {
        body: {
          noMatch: 'Nuk ka të dhëna'
        }
      }
    }

    const data = this.state.data.map((elem, index) => {
      return [
        elem.name,
        elem.created_by.email,
        elem.edited_by && elem.edited_by.email,
        elem.last_edit_date && moment(elem.last_edit_date).format('DD/MM/YYYY'),
        <Link key={index} to={`/editActivity/${elem._id}`}>
          <Button outline className='mb-1' color='warning' type='button'>
            <span>Modifiko</span>
          </Button>
        </Link>
      ]
    })
    return (
      <ContentWrapper>
        <div className='content-heading'>
          <div>Aktivitetet</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-end'
            }}
            className='ml-auto'
          >
            <Link to='/editActivity/'>
              <Button color='success' className='btn-oval'>
                Shto Aktivitet
              </Button>
            </Link>
          </div>
        </div>
        <Row>
          <Col>
            <MUIDataTable title={'Aktivitetet'} columns={columns} options={options} data={data} />
          </Col>
        </Row>
      </ContentWrapper>
    )
  }
}

export default Activity
