import { Component } from 'react'
import { Link } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import pubsub from 'pubsub-js'
import ToggleState from '../Common/ToggleState'
import TriggerResize from '../Common/TriggerResize'
import Organogram from '../Dashboard/Organogram'
import HeaderRun from './Header.run'

@inject('userStore')
@observer
class Header extends Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {
    HeaderRun()
  }

  handleLogOut() {
    localStorage.clear()
    pubsub.publish('goToLogin')
    this.props.userStore.setAuthenticated(false)
    return
  }

  render() {
    return (
      <>
        <header className='topnavbar-wrapper height-55'>
          {/* START Top Navbar */}
          <nav className='navbar topnavbar navbar-sticky'>
            {/* START navbar header */}
            <div className='navbar-header'>
              <Link className='navbar-brand' to='/dashboard'>
                <div className='brand-logo'>
                  <img className='img-fluid' style={{ height: 30 }} src='/img/logo.png' alt='App Logo' />
                </div>
                <div className='brand-logo-collapsed'>
                  <div className='animation-content'>
                    <div className='brand-logo-collapsed logo-small-animation'></div>
                  </div>
                </div>
              </Link>
            </div>
            {/* END navbar header */}

            {/* START Left navbar */}
            <ul className='navbar-nav mr-auto flex-row'>
              <li className='nav-item'>
                {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
                <TriggerResize>
                  <ToggleState state='aside-collapsed'>
                    <a href='' className='nav-link d-none d-md-block d-lg-block d-xl-block'>
                      <em className='fas fa-bars' style={{ color: '#464c62' }}></em>
                    </a>
                  </ToggleState>
                </TriggerResize>
                {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                <ToggleState state='aside-toggled' nopersist={true}>
                  <a href='' className='nav-link sidebar-toggle d-md-none'>
                    <em className='fas fa-bars' style={{ color: '#464c62' }}></em>
                  </a>
                </ToggleState>
              </li>
              {/* START User avatar toggle */}
              <li className='nav-item d-none d-md-block'>
                <Link className='nav-link' title='Profili' to={'/profile'}>
                  <em className='icon-user' style={{ color: '#464c62' }}></em>
                </Link>
              </li>
              {/* END User avatar toggle */}
            </ul>
            {/* END Left navbar */}
            {/* START Right Navbar */}
            <ul className='navbar-nav flex-row'>
              {/* Search icon */}
              <li className='dropdown-list dropdown nav-item'>
                <span className='dropdown-toggle-nocaret nav-link dropdown_organogram' title='SPEEEX Organogram'>
                  <Organogram />
                </span>
              </li>
              <li className='dropdown-list dropdown nav-item'>
                <a
                  onClick={(e) => {
                    e.preventDefault()
                    this.handleLogOut()
                  }}
                  title="Ç'kyçu"
                  aria-haspopup='true'
                  href='#'
                  className='dropdown-toggle-nocaret nav-link no-scrollbar'
                  aria-expanded='false'
                >
                  <em className='icon-lock' style={{ color: '#464c62' }}></em>
                </a>
              </li>
            </ul>
            {/* END Right Navbar */}
          </nav>
          {/* END Top Navbar */}
        </header>
      </>
    )
  }
}

export default Header
