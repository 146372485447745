import React, { useEffect, useState, useMemo } from 'react'
import { Button, FormGroup, Label, Input } from 'reactstrap'
import Select from 'react-select'
import { useHistory } from 'react-router'
import axios from 'axios'
import pubsub from 'pubsub-js'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import DateTimeBoostrap from '../../Forms/DateTimeBootstrap'
import { qualityDepartment } from './EnumOrder'

const OrderForm = ({ user, type, setOrders }) => {
  const history = useHistory()
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [ordertypes, setOrdertypes] = useState([])
  const [orderType, setOrderType] = useState('')
  const [order, setOrder] = useState('')
  const [orderOptions, setOrderOptions] = useState([])
  const [orderId, setOrderId] = useState('')
  const [orderActivationDate, setOrderActivationDate] = useState('')
  const [editMode, setEditMode] = useState(false)
  const [orderUserId, setOrderUserId] = useState()
  const [error, setError] = useState(false)
  const [orderNotFound, setOrderNotFound] = useState(false)
  // const [orderSell, setOrderSell] = useState([])
  // const [orderSellOptions, setOrderSellOptions] = useState([])

  const orderTypeOptions = [
    { label: 'Primare', value: true },
    { label: 'Sekondare', value: false }
  ]

  useEffect(() => {
    if (type == 'edit') {
      setEditMode(true)
    }
  }, [type])

  useEffect(() => {
    const getOrdersType = async () => {
      try {
        const { data } = await axios.get('/ordertype')
        setOrdertypes(data)
      } catch (error) {
        console.log('error', error)
      }
    }

    getOrdersType()
  }, [])

  useEffect(async () => {
    if (orderType) {
      if (ordertypes.success) {
        const filteredData = await ordertypes.data.filter((d) => d.is_primary == orderType?.value)
        setOrderOptions(filteredData)
      }
    }
  }, [orderType])

  useEffect(async () => {
    if (editMode && ordertypes?.data?.length) {
      let orderId = params.orderId

      try {
        setLoading(true)
        const { data } = await axios.get(`/orders/getOne`, {
          params: { id: orderId }
        })
        if (data.success) {
          let isPrimary = orderTypeOptions.find((orderTypeOption) => orderTypeOption.value == data.order.order_type.is_primary)
          setOrderType(isPrimary)

          let orderOption = ordertypes.data.find((orderOption) => orderOption.name == data.order.order_type.name)
          setOrder(orderOption)
          // let orderSellOption = orderSellOptions.find((crossalesOptions) => crossalesOptions.label == data.order.order_sell.name)
          // setOrderSell(orderSellOption)
          setOrderId(data.order.order_id)
          setOrderActivationDate(moment(data.order.activation_date).format('DD/MM/YYYY'))
          setOrderUserId(data.order.user_id._id)
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        setOrderNotFound(true)
      }
    }
  }, [editMode, ordertypes])

  if (orderNotFound) {
    return 'ORDER NOT FOUND !'
  }

  const submit = (e) => {
    e.preventDefault()
    setError(false)
    // if (orderId && orderType && orderActivationDate && order && orderSell) {
    if (orderId && orderType && orderActivationDate && order) {
      let data = {
        id: orderId,
        type: order._id,
        // order_sell: orderSell._id,
        activationDate: new Date(orderActivationDate)
      }
      if (editMode) {
        data = {
          ...data,
          user_id: orderUserId,
          _id: params.orderId
        }
        axios
          .put('/orders', data)
          .then((data) => {
            if (data.data.success) {
              pubsub.publish('showNotification', {
                message: 'Order updated',
                type: 'success'
              })
              history.goBack()
            }
          })
          .catch((err) => {
            pubsub.publish('showNotification', {
              message: 'Error while updating the order',
              type: 'error'
            })
          })
      } else {
        axios
          .post('/orders', data)
          .then((data) => {
            if (data.data.success) {
              setOrderType('')
              setOrder('')
              setOrderOptions([])
              setOrderId('')
              setOrderActivationDate('')
              // setOrderSell([])
              let order = data.data.order
              if (order) {
                let newOrder = [
                  `${order.user_id.first_name} ${order.user_id.last_name} ${
                    order.user_id.department[0].includes(qualityDepartment) ? '(From Quality)' : ''
                  }`,
                  order.order_type.name,
                  order.order_type.category.name,
                  order.order_id,
                  order.user_id.department[0].includes(qualityDepartment) ? 'Yes' : 'No',
                  // order.order_sell.name,
                  moment(order.activation_date).format('DD/MM/YYYY'),
                  <Link to={`/editOrder/${order._id}`}>
                    <Button outline className='mb-1' color='warning' type='button'>
                      <span>Modifiko</span>
                    </Button>
                  </Link>
                ]

                setOrders((prevState) => [newOrder, ...prevState])

                pubsub.publish('showNotification', {
                  message: 'Order Submited',
                  type: 'success'
                })
              }
            }
          })
          .catch((err) => {
            console.log('error', err)
            pubsub.publish('showNotification', {
              message: err,
              type: 'error'
            })
          })
      }
    } else {
      setError(true)
    }
  }

  const deleteOrder = async (id) => {
    try {
      if (user.userLevel == 1 || user.userLevel == 2 || user.userLevel == 1) {
        const { data } = await axios.delete('/orders', {
          params: { id }
        })
        if (data.success) {
          pubsub.publish('showNotification', {
            message: data.message,
            type: 'success'
          })
          history.goBack()
        }
      }
    } catch (e) {
      pubsub.publish('showNotification', {
        message: 'Error while deleting the order',
        type: 'error'
      })
    }
  }

  return (
    <div>
      {editMode && (
        <div className='content-heading d-flex justify-content-between'>
          <div>Edit Order</div>
          <div>
            <Button onClick={() => history.goBack()} color='dark'>
              Kthehu prapa
            </Button>
          </div>
        </div>
      )}

      {!loading ? (
        <form onSubmit={(e) => submit(e)}>
          <FormGroup>
            <Label for='order_type'>Order Type:</Label>
            <Select
              placeholder='Selekto Order Type'
              name='order_type'
              getOptionValue={(option) => `${option['value']}`}
              value={orderType}
              options={orderTypeOptions}
              onChange={setOrderType}
            />
          </FormGroup>

          <FormGroup>
            <Label for='order'>Order:</Label>
            <Select
              placeholder='Selekto Order'
              name='order'
              getOptionLabel={(option) => option['name']}
              getOptionValue={(option) => option['_id']}
              value={order}
              options={orderOptions}
              onChange={setOrder}
            />
          </FormGroup>

          <FormGroup>
            <Label for='order_id'>Order ID:</Label>
            <Input
              type='text'
              name='order_id'
              id='order_id'
              placeholder='Shkruaj ID e Orderit'
              onChange={(e) => setOrderId(e.target.value)}
              value={orderId}
              data-validate='["required"]'
            />
          </FormGroup>
          {/* <FormGroup>
            <Label for='order'>Shitje Fillestare ose CrossSell</Label>
            <Select
              placeholder='Selekto Shitjen'
              name='order_sell'
              value={orderSell}
              options={orderSellOptions}
              getOptionLabel={(option) => option['name']}
              getOptionValue={(option) => option['_id']}
              data-validate='["required"]'
              onChange={(selectedOption) => setOrderSell(selectedOption ? selectedOption : '')}
            />
          </FormGroup> */}
          <FormGroup>
            <Label for='orderActivationDate'>Order activation date:</Label>
            <DateTimeBoostrap
              value={orderActivationDate}
              utc={true}
              dateFormat={'DD/MM/YYYY'}
              timeFormat=''
              onChange={(e) => setOrderActivationDate(e._d)}
            />
          </FormGroup>

          {error && (
            <div className='mb-2'>
              <span className='text-danger'>Ju lutem plotesoni te gjitha fushat !</span>
            </div>
          )}

          <Button type={'submit'} color='success' className='btn-oval'>
            {editMode ? 'Edit' : 'Submit'}
          </Button>

          {(user?.userLevel == 1 || user?.userLevel == 2 || user?.userLevel == 3) && editMode ? (
            <Button onClick={() => deleteOrder(params.orderId)} color='danger' className='btn-oval ml-2'>
              Delete Order
            </Button>
          ) : (
            ''
          )}
        </form>
      ) : (
        'Loading...'
      )}
    </div>
  )
}

export default OrderForm
