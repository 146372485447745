import axios from 'axios'
import pubsub from 'pubsub-js'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Badge, Button, Col, Row } from 'reactstrap'
import ContentWrapper from '../../../components/Layout/ContentWrapper'
import Loader from '../../../components/Layout/Loader'
import { APPLICANTS_API } from '../../../utils/consts'
import ApplicantDataTable from '../Components/ApplicantDataTable'
import ApplicantModal from '../Components/ApplicantModal'
import { buttonsApplicants, requiredApplicantFields } from '../enums/applicantsInput'
import { statusEnumSQ } from '../enums/statusEnumSQ'

const Applicant = ({ user, id }) => {
  const [loading, setLoader] = useState(true)
  const [applicant, setApplicant] = useState(null)
  const [applicantStatus, setApplicantStatus] = useState('')
  const [exEmployee, setExEmployee] = useState(false)
  const [show, setShow] = useState(false)
  const [description, setDescription] = useState('')
  const [testResult, setTestResult] = useState()
  const [status, setStatus] = useState('new')
  const [newStatus, setNewStatus] = useState('')
  const [languageLevel, setLanguageLevel] = useState()
  const [testPassability, setTestPassability] = useState(undefined)
  const [error, setError] = useState(false)
  const [applicantData, setApplicantData] = useState()
  const history = useHistory()

  useEffect(() => {
    if (id) {
      getApplicant(id)
    }
  }, [id])

  const getApplicant = (id) => {
    setLoader(true)
    axios
      .get(`${APPLICANTS_API}/applicants/getOne`, {
        params: { id }
      })
      .then((data) => {
        if (data.data.success) {
          setApplicant(data.data.applicant)
          setApplicantData(data.data.applicant)
          setApplicantStatus(data.data.applicant.status)
          setExEmployee(data.data.applicant.ex_employee)
        }
      })
      .catch((e) => console.log('error', error))
      .finally(() => setLoader(false))
  }

  const rollbackApplicantStatus = async () => {
    try {
      const response = await axios.patch(`${APPLICANTS_API}/applicants/rollbackToNew/${id}`, {})
      if (response.data.success) {
        pubsub.publish('showNotification', {
          message: `Statusi kaloj ne aplikues`,
          type: 'success'
        })
        getApplicant(id)
      } else {
        pubsub.publish('showNotification', {
          message: res.data.message,
          type: 'error'
        })
      }
    } catch (error) {
      pubsub.publish('showNotification', {
        message: error.message,
        type: 'error'
      })
    }
  }

  // const changeApplicantStatus = async (item = false) => {
  //   try {
  //     const requestBody = item
  //       ? { ...applicantData }
  //       : {
  //           ...applicantData,
  //           status: newStatus || status,
  //           description: description,
  //           ...(status === 'waiting_list' && {
  //             test_level: languageLevel,
  //             test_result: testPassability,
  //             test_score: testResult
  //           })
  //         }

  //     if ((applicant.status !== status || applicant.status !== newStatus) && requestBody.status_changes) {
  //       requestBody.status_changes = [
  //         ...(applicant.status_changes || []),
  //         {
  //           from_status: applicant.status,
  //           to_status: newStatus || status,
  //           changed_at: new Date(Date.now()),
  //           changed_by: user.user_id
  //         }
  //       ]
  //     }

  //     if ((status === 'recruited' || newStatus === 'recruited') && requiredApplicantFields.length) {
  //       const missingFields = requiredApplicantFields.filter(
  //         (oneReq) =>
  //           applicantData[oneReq.value] === '' ||
  //           applicantData[oneReq.value] === undefined ||
  //           applicantData[oneReq.value] === null ||
  //           (oneReq.value === 'bank_account_no' && applicantData[oneReq.value]?.length !== 16) ||
  //           (oneReq.value === 'password' && applicantData[oneReq.value]?.length < 8)
  //       )

  //       if (missingFields.length) {
  //         pubsub.publish('showNotification', {
  //           message: `Fushat ${missingFields.map((req) => req.text)} jane te nevojshme`,
  //           type: 'error'
  //         })
  //         return
  //       }
  //     }

  //     const endpoint =
  //       status === 'recruited' || newStatus === 'recruited'
  //         ? `${APPLICANTS_API}/applicants/createUserFromApplicant?id=${id}`
  //         : `${APPLICANTS_API}/applicants/updateStatus?id=${id}`

  //     const response = await (status === 'recruited' || newStatus === 'recruited'
  //       ? axios.post(endpoint, requestBody)
  //       : axios.patch(endpoint, requestBody))

  //     if (response.data.success) {
  //       const notificationText = item
  //         ? 'Të dhënat u përditësuan!'
  //         : `Statusi u ndryshua në ${statusEnumSQ[status].charAt(0).toLowerCase() + statusEnumSQ[status].slice(1)}`
  //       pubsub.publish('showNotification', { message: notificationText, type: 'success' })
  //       if (!item) setApplicantStatus(response.data.applicant.status)
  //       getApplicant(id)
  //     } else {
  //       throw new Error(response.data.message)
  //     }
  //   } catch (error) {
  //     pubsub.publish('showNotification', { message: error.message, type: 'error' })
  //   }
  // }

  const changeApplicantStatus = async (item = false) => {
    let body
    if (item) {
      body = { ...applicantData }
    } else {
      body = {
        ...applicantData,
        status: newStatus ? newStatus : status,
        description: description
      }
    }
    if (applicant.status != status || applicant.status != newStatus) {
      if (body?.status_changes) {
        body.status_changes = [
          ...applicant?.status_changes,
          {
            from_status: applicant?.status,
            to_status: newStatus ? newStatus : status,
            changed_at: new Date(Date.now()),
            changed_by: user.user_id
          }
        ]
      } else {
        body.status_changes = [
          {
            from_status: applicant?.status,
            to_status: newStatus ? newStatus : status,
            changed_at: new Date(Date.now()),
            changed_by: user.user_id
          }
        ]
      }
    }

    if (status === 'waiting_list') {
      body.test_level = languageLevel
      body.test_result = testPassability
      body.test_score = testResult
    }
    try {
      if (status === 'recruited' || newStatus === 'recruited') {
        const reqFields = requiredApplicantFields.filter(
          (oneReq) =>
            applicantData[oneReq.value] === '' ||
            applicantData[oneReq.value] === undefined ||
            applicantData[oneReq.value] === null ||
            (oneReq.value === 'bank_account_no' && applicantData[oneReq.value]?.length !== 16) ||
            (oneReq.value === 'password' && applicantData[oneReq.value]?.length < 8)
        )
        if (reqFields.length) {
          pubsub.publish('showNotification', {
            message: `Fushat
            ${reqFields.map((req) => req.text)} jane te nevojshme`,
            type: 'error'
          })

          return
        } else {
          const res = await axios.post(`${APPLICANTS_API}/applicants/createUserFromApplicant?id=${id}`, body)
          if (res.data.success) {
            pubsub.publish('showNotification', {
              message: `Statusi u ndryshua në ${statusEnumSQ[status].charAt(0).toLowerCase() + statusEnumSQ[status].slice(1)}`,
              type: 'success'
            })
            pubsub.publish('showNotification', {
              message: 'Profili eshte aktivizuar',
              type: 'success'
            })
            getApplicant(id)
          } else {
            pubsub.publish('showNotification', {
              message: res.data.message,
              type: 'error'
            })
          }
        }
      } else {
        const { data } = await axios.patch(`${APPLICANTS_API}/applicants/updateStatus?id=${id}`, body)
        if (data.success) {
          let notificationText = `Statusi u ndryshua në ${statusEnumSQ[status].charAt(0).toLowerCase() + statusEnumSQ[status].slice(1)}`

          if (item) {
            notificationText = 'Të dhënat u përditësuan!'
          }
          pubsub.publish('showNotification', {
            message: notificationText,
            type: 'success'
          })
          setApplicantStatus(data.applicant.status)
          getApplicant(id)
        } else {
          throw Error(data.message)
        }
      }
    } catch (error) {
      pubsub.publish('showNotification', {
        message: error.message,
        type: 'error'
      })
    }
  }

  const handleClose = () => {
    setDescription('')
    setTestResult()
    setStatus('new')
    setLanguageLevel('')
    setTestPassability('')
    setNewStatus('')
    setShow(false)
  }

  const handleOpen = () => {
    setShow(true)
  }

  return (
    user && (
      <ContentWrapper>
        <ApplicantModal
          show={show}
          setShow={setShow}
          handleClose={handleClose}
          status={status}
          testResult={testResult}
          setTestResult={setTestResult}
          languageLevel={languageLevel}
          setLanguageLevel={setLanguageLevel}
          testPassability={testPassability}
          setTestPassability={setTestPassability}
          newStatus={newStatus}
          setNewStatus={setNewStatus}
          description={description}
          setDescription={setDescription}
          error={error}
          setError={setError}
          changeApplicantStatus={changeApplicantStatus}
          buttonsApplicants={buttonsApplicants}
        />

        {!loading ? (
          <div>
            <div className='content-heading d-flex justify-content-between'>
              <div className='d-flex align-items-center'>
                {applicant && applicant.firstName + ' ' + applicant.lastName + ' - '}
                <Badge
                  color='info'
                  style={{
                    padding: '10px 20px',
                    borderRadius: '4px',
                    fontSize: '18px',
                    fontWeight: '500'
                  }}
                  className='ml-2'
                >
                  {statusEnumSQ[applicantStatus]}
                </Badge>
                {applicantStatus === 'recruited' && (
                  <Badge
                    color='warning'
                    style={{
                      padding: '10px 20px',
                      borderRadius: '4px',
                      fontSize: '18px'
                    }}
                    className='ml-2'
                  >
                    Përdorues
                  </Badge>
                )}
                {exEmployee && (
                  <Badge
                    color='warning'
                    style={{
                      padding: '10px 20px',
                      borderRadius: '4px',
                      fontSize: '18px'
                    }}
                    className='ml-2'
                  >
                    Ish punëtor në SPEEEX L.L.C
                  </Badge>
                )}
              </div>
              <div>
                <Button style={{ marginLeft: 10 }} type={'button'} onClick={() => history.push('/applicants')} color='primary'>
                  Prapa
                </Button>
              </div>
            </div>

            <Row>
              <Col>
                <div className='card card-default'>
                  <div className='card-body'>
                    <div className='py-2'>
                      <div>
                        {applicant && (
                          <ApplicantDataTable
                            applicant={applicant}
                            applicantData={applicantData}
                            setApplicantData={setApplicantData}
                            applicantStatus={applicantStatus}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {applicantStatus !== 'recruited' && (
                    <div
                      className='card-footer'
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between'
                      }}
                    >
                      {buttonsApplicants.length > 0 && (
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap'
                          }}
                        >
                          {applicantStatus !== 'blacklist' ? (
                            buttonsApplicants.map((btn, index) => {
                              if (btn.showOnStatus.includes(applicantStatus)) {
                                return (
                                  <Button
                                    key={index}
                                    color='info'
                                    className='mr-2 mb-1 mt-1'
                                    onClick={() => {
                                      handleOpen()
                                      setStatus(btn.type)
                                    }}
                                  >
                                    {btn.text}
                                  </Button>
                                )
                              }
                            })
                          ) : (
                            <Badge
                              color='info'
                              style={{
                                padding: '10px 20px',
                                borderRadius: '2px',

                                fontWeight: '400',
                                fontSize: '16px'
                              }}
                            >
                              Ky person gjendet në listën e zezë.
                            </Badge>
                          )}
                        </div>
                      )}

                      <div>
                        <Button
                          color='info'
                          className='mr-2 mb-1 mt-1'
                          onClick={() => {
                            rollbackApplicantStatus(true)
                            setDescription('')
                          }}
                        >
                          Kthe si aplikues
                        </Button>

                        <Button
                          color='success'
                          className='mr-2 mb-1 mt-1'
                          onClick={() => {
                            changeApplicantStatus(true)
                            setDescription('')
                          }}
                        >
                          Ruaj Ndryshimet
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <Loader />
        )}
      </ContentWrapper>
    )
  )
}

export default Applicant
