import React, { Component } from 'react'
import { Col, Button, CardFooter, Input, Alert } from 'reactstrap'
import moment from 'moment'
import 'moment/locale/sq' // without this line it didn't work
import DateTimeBoostrap from '../../components/Forms/DateTimeBootstrap'
import axios from 'axios'
import pubsub from 'pubsub-js'

export default class LeaveForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dateFrom: '',
      dateTo: '',
      otherInfo: '',
      type: ''
    }
  }

  submitForm = async (e) => {
    e.preventDefault()
    if (this.state.dateFrom !== '' && this.state.dateTo !== '' && this.state.otherInfo !== '' && this.state.type !== '') {
      if (this.state.dateTo.isSameOrAfter(this.state.dateFrom)) {
        const body = {
          date_from: moment(this.state.dateFrom).format('YYYY-MM-DD'),
          date_to: moment(this.state.dateTo).format('YYYY-MM-DD'),
          category: this.state.type,
          other_info: this.state.otherInfo,
          message: this.state.messageChanged,
          user_id: this.props.user_id
        }
        try {
          const postLeaveRes = await axios.post('leaves', body)
          if (postLeaveRes.data.success) {
            pubsub.publish('showNotification', {
              message: 'Pushimi u shtua me sukses.',
              type: 'success'
            })
            this.props.onSuccess()
          } else {
            pubsub.publish('showNotification', {
              message: 'Gabim gjatë shtimit të pushimit!',
              type: 'error'
            })
          }
        } catch (e) {
          pubsub.publish('showNotification', {
            message: 'Gabim gjatë shtimit të pushimit!',
            type: 'error'
          })
        }
      } else {
        this.setState({
          errorMessage: 'Data deri duhet të jetë më vonë se data prej'
        })
      }
    } else {
      this.setState({
        errorMessage: 'Ju lutem plotesoni të gjitha dhënat'
      })
    }
  }

  renderLeaveType() {
    const items = this.props.leaveTypes.map((element, index) => {
      return (
        <option key={index} value={element._id}>
          {element.name}
        </option>
      )
    })

    return (
      <fieldset>
        <div className='form-group row align-items-center'>
          <label className='col-md-2 col-form-label'>Kategoria:</label>
          <div className='col-md-8'>
            <Input
              type='select'
              name='type'
              data-validate='["required"]'
              value={this.state.type}
              className='custom-select custom-select'
              onChange={(e) => {
                this.setState({ type: e.target.value })
              }}
            >
              <option value=''>Zgjidhni Kategorine...</option>
              {items}
            </Input>
            <span className='invalid-feedback'>Fusha është e nevojshme</span>
          </div>
        </div>
      </fieldset>
    )
  }

  render() {
    return (
      <form onSubmit={this.props.onSubmit} name='formNewLeave'>
        <div className='card b w-100'>
          <div className='card-header d-flex align-items-center'>
            <div className='d-flex justify-content-center col'>
              <div className='h4 m-0 text-center'>{this.props.title}</div>
            </div>
          </div>
          {this.state.errorMessage != '' && <p className='text-center text-danger'>{this.state.errorMessage}</p>}
          <div className='card-body text-left'>
            <fieldset>
              <div className='form-group row align-items-center'>
                <label className='col-md-2 col-form-label'>Prej</label>
                <Col md={8}>
                  <DateTimeBoostrap
                    utc={true}
                    value={this.state.dateFrom}
                    dateFormat={'DD/MM/YYYY'}
                    timeFormat=''
                    onChange={(date) => {
                      this.setState({ dateFrom: date })
                    }}
                    inputProps={{
                      id: 'dateFrom',
                      'data-validate': '["validDate"]',
                      className: 'form-control',
                      name: 'dateFrom'
                    }}
                  />
                </Col>
              </div>
            </fieldset>
            <fieldset>
              <div className='form-group row align-items-center'>
                <label className='col-md-2 col-form-label'>Deri</label>
                <Col md={8}>
                  <DateTimeBoostrap
                    value={this.state.dateTo}
                    utc={true}
                    dateFormat={'DD/MM/YYYY'}
                    timeFormat=''
                    onChange={(date) => {
                      this.setState({ dateTo: date })
                    }}
                    inputProps={{
                      disabled: this.props.viewOnly && 'disabled',
                      'data-param': 'dateFrom',
                      'data-validate': '["dateIsAfter"]',
                      className: 'form-control',
                      name: 'dateTo'
                    }}
                  />
                </Col>
              </div>
            </fieldset>
            {this.renderLeaveType()}
            <fieldset>
              <div className='form-group row align-items-center'>
                <label className='col-md-2 col-form-label'>Informata Shtesë:</label>
                <Col md={8}>
                  <Input
                    type='text'
                    name='otherInfo'
                    disabled={this.props.viewOnly && 'disabled'}
                    onChange={(e) => this.setState({ otherInfo: e.target.value })}
                    value={this.state.otherInfo}
                  />
                  <span className='invalid-feedback'>Fusha është e nevojshme</span>
                </Col>
              </div>
            </fieldset>
          </div>
          <CardFooter className='text-right'>
            <button style={{ marginLeft: 10 }} onClick={this.submitForm} className='btn btn-info'>
              Ruaj
            </button>
          </CardFooter>
        </div>
      </form>
    )
  }
}
