import { Button } from 'reactstrap'
import NotificationItem from './NotificationItem'

function NotificationBox({ contracts = false, data, setPage }) {
  return (
    <div className='card card-default'>
      <div className='card-header'>
        <div className='card-title'>{contracts ? 'Njoftimet e kontratave' : 'Njoftimet e fundit'}</div>
      </div>
      <div className='list-group' style={{ overflowY: 'scroll' }}>
        {data?.data.length > 0 &&
          data?.data.map((notification) => {
            const isRead = notification.read_by && notification.read_by.length !== 0
            if (notification.type !== 'FEED_NOTIFICATION')
              return (
                <NotificationItem
                  key={notification._id}
                  refId={notification.ref_id}
                  createdBy={notification.created_by}
                  id={notification._id}
                  createdDate={notification.created_date}
                  type={notification.type}
                  title={notification.name}
                  message={notification.message}
                  path='/dashboard'
                  isRead={isRead}
                />
              )
          })}
      </div>
      {data.page && (
        <div className='list-group-item text-center'>
          <Button onClick={setPage} className='btn btn-secondary btn-oval btn-sm'>
            Më shumë
          </Button>
        </div>
      )}
    </div>
  )
}

export default NotificationBox
