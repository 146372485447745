import { useCallback } from 'react'

const useTwoFaCodeInput = (twoFaToken, setTwoFaToken, twoFaInputs) => {
  const handleTwoFaChange = useCallback(
    (e, index) => {
      const { value } = e.target
      if (/^\d$/.test(value)) {
        const newTwoFaToken = [...twoFaToken]
        newTwoFaToken[index] = value
        setTwoFaToken(newTwoFaToken)
        if (index < 5) {
          twoFaInputs[index + 1].current.focus()
        }
      } else if (value === '') {
        const newTwoFaToken = [...twoFaToken]
        newTwoFaToken[index] = ''
        setTwoFaToken(newTwoFaToken)
      }
    },
    [twoFaToken, setTwoFaToken, twoFaInputs]
  )

  const handleTwoFaKeyDown = useCallback(
    (e, index) => {
      if (e.key === 'Backspace') {
        if (twoFaToken[index] === '') {
          if (index > 0) {
            const newTwoFaToken = [...twoFaToken]
            newTwoFaToken[index - 1] = ''
            setTwoFaToken(newTwoFaToken)
            twoFaInputs[index - 1].current.focus()
          }
        } else {
          const newTwoFaToken = [...twoFaToken]
          newTwoFaToken[index] = ''
          setTwoFaToken(newTwoFaToken)
        }
      } else if (e.key === 'ArrowLeft' && index > 0) {
        twoFaInputs[index - 1].current.focus()
      } else if (e.key === 'ArrowRight' && index < 5) {
        twoFaInputs[index + 1].current.focus()
      }
    },
    [twoFaToken, setTwoFaToken, twoFaInputs]
  )

  const handleTwoFaPaste = useCallback(
    (e) => {
      e.preventDefault()
      const pasteData = e.clipboardData.getData('text').trim()
      if (/^\d{6}$/.test(pasteData)) {
        const pastedDigits = pasteData.split('')
        setTwoFaToken(pastedDigits)
        twoFaInputs[5].current.focus()
      }
    },
    [setTwoFaToken, twoFaInputs]
  )

  const disabledBtn = twoFaToken.some((item) => item === '')

  return {
    handleTwoFaChange,
    handleTwoFaKeyDown,
    handleTwoFaPaste,
    disabledBtn
  }
}

export default useTwoFaCodeInput
