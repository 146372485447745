import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import axios from 'axios'
import { saveAs } from 'file-saver'
import 'loaders.css/loaders.css'
import { inject } from 'mobx-react'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import Datetime from 'react-datetime'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, Col, Input, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import ContentWrapper from '../../Layout/ContentWrapper'
import ResignIntervieModal from './resignInterviewModal'
import DateTimeBoostrap from '../../Forms/DateTimeBootstrap'

var disable = Datetime.moment().subtract(1, 'day')
var valid = function (current) {
  return current.isBefore(disable)
}

var timeout = null
@inject('userStore')
class ExitList extends React.Component {
  constructor() {
    super()
    this.state = {
      getData: [],
      count: 10,
      total: 0,
      page: 0,
      searchText: '',
      projects: [],
      funksionet: [],
      departaments: [],
      projectSelected: '',
      departmentSelected: '',
      functionSelected: '',
      activities: [],
      activitySelected: '',
      activityRender: '',
      projectRender: '',
      departmentRender: '',
      functionRender: '',
      loader: false,
      emailType: 1,
      firstNameType: 1,
      lastNameType: 1,
      positionType: 1,
      projectType: 1,
      activityType: 1,
      exitReasonType: 1,
      exitDateType: 1,
      showDepartment: false,
      showActivity: false,
      languages: [],
      languageSelected: '',
      languageRender: '',
      showLanguage: false,
      fromDate: true,
      toDate: true,
      fromDateValue: '',
      toDateValue: ''
    }

    this._getExport = this._getExport.bind(this)
    this._getData = this._getData.bind(this)
    this.onFromDateChange = this.onFromDateChange.bind(this)
    this.onToDateChange = this.onToDateChange.bind(this)
    this.onCheckToDate = this.onCheckToDate.bind(this)
    this.onCheckFromDate = this.onCheckFromDate.bind(this)
  }

  _getData = () => {
    this.axiosCancelSource = axios.CancelToken.source()
    this.setState({ loader: true })

    axios
      .get(`projects`, { cancelToken: this.axiosCancelSource.token })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            projects: response.data.data
          })
        }
      })
      .catch((error) => {})

    axios
      .get(`roles`, { cancelToken: this.axiosCancelSource.token })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            funksionet: response.data.data
          })
        }
      })
      .catch((error) => {})

    axios
      .get(`departments`, { cancelToken: this.axiosCancelSource.token })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            departaments: response.data.data
          })
        }
      })
      .catch((error) => {})

    axios
      .get(`activities`, { cancelToken: this.axiosCancelSource.token })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            activities: response.data.data
          })
        }
      })
      .catch((error) => {})

    axios
      .get(`languages`, { cancelToken: this.axiosCancelSource.token })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            languages: response.data.data
          })
        }
      })
      .catch((error) => {})

    const { page, count } = this.state
    axios
      .get(`exitlist/${count}/${page + 1}`, { cancelToken: this.axiosCancelSource.token })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            loader: false,
            getData: response.data.data.docs,
            total: response.data.data.totalDocs
          })
        }
      })
      .catch((error) => {})
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel('Component unmounted')
  }

  componentDidMount() {
    this.axiosCancelSource = axios.CancelToken.source()
    this._getData()
  }

  titleCrop(text) {
    var textCrop = text
    var limit = 80
    var trimmedString = textCrop.length > limit ? textCrop.substring(0, limit - 3) + '...' : textCrop
    return trimmedString
  }

  _getExport() {
    const {
      functionSelected,
      projectSelected,
      departmentSelected,
      activitySelected,
      searchText,
      languageSelected,
      fromDateValue,
      toDateValue
    } = this.state
    var params = {}
    if (functionSelected) {
      params.function = functionSelected
    }
    if (projectSelected) {
      params.project = projectSelected
    }
    if (departmentSelected) {
      params.department = departmentSelected
    }
    if (activitySelected) {
      params.activity = activitySelected
    }
    if (searchText) {
      params.search = searchText
    }
    if (languageSelected) {
      params.language = languageSelected
    }
    params.fromDate = fromDateValue.length !== 0 && !this.state.fromDate ? fromDateValue : undefined
    params.toDate = toDateValue.length !== 0 && !this.state.toDate ? toDateValue : undefined

    axios
      .get('exitList/export', { params, responseType: 'blob' })
      .then((response) => {
        if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          saveAs(response.data, 'exitList.csv')
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  _getUsers = async (e) => {
    this.setState({
      loader: true
    })
    const {
      count,
      page,
      functionSelected,
      projectSelected,
      departmentSelected,
      activitySelected,
      searchText,
      languageSelected,
      fromDateValue,
      toDateValue
    } = this.state
    var params = {}
    params['function'] = functionSelected ? functionSelected : undefined
    params['project'] = projectSelected ? projectSelected : undefined
    params['department[]'] = departmentSelected ? departmentSelected : undefined
    params['languages[]'] = languageSelected ? languageSelected : undefined
    params['activity'] = activitySelected ? activitySelected : undefined
    params.search = searchText ? searchText : undefined

    if (fromDateValue.length === 0 && toDateValue.length !== 0) {
      if (toDateValue.length !== 0) {
        params.fromDate = fromDateValue.length !== 0 && !this.state.fromDate ? fromDateValue : undefined
        params.toDate = toDateValue.length !== 0 && !this.state.toDate ? toDateValue : undefined
      } else {
        this.setState({
          toDateValue: ''
        })
        Swal.fire('', 'Gabim në zgjedhjen e datave', 'error')
      }
    } else if (toDateValue.length !== 0) {
      if (fromDateValue.length !== 0 && toDateValue.length !== 0 && fromDateValue < toDateValue) {
        params.fromDate = fromDateValue.length !== 0 && !this.state.fromDate ? fromDateValue : undefined
        params.toDate = toDateValue.length !== 0 && !this.state.toDate ? toDateValue : undefined
      } else if (fromDateValue.length !== 0 && fromDateValue < toDateValue) {
        params.fromDate = fromDateValue.length !== 0 && !this.state.fromDate ? fromDateValue : undefined
        params.toDate = toDateValue.length !== 0 && !this.state.toDate ? toDateValue : undefined
      } else {
        this.setState({
          toDateValue: ''
        })
        Swal.fire('', 'Gabim në zgjedhjen e datave', 'error')
      }
    }

    if (fromDateValue.length !== 0 && toDateValue.length !== 0) {
      if (fromDateValue < toDateValue) {
        params.fromDate = fromDateValue.length !== 0 && !this.state.fromDate ? fromDateValue : undefined
        params.toDate = toDateValue.length !== 0 && !this.state.toDate ? toDateValue : undefined
      } else {
        this.setState({
          toDateValue: ''
        })
        Swal.fire('', 'Gabim në zgjedhjen e datave', 'error')
      }
    } else {
      if (fromDateValue.length !== 0) {
        params.fromDate = fromDateValue.length !== 0 && !this.state.fromDate ? fromDateValue : undefined
      }
    }
    params.fromDate = fromDateValue.length !== 0 && !this.state.fromDate ? fromDateValue : undefined

    if (e) {
      await this.setState({
        searchText: e.target.value
      })
      params.search = this.state.searchText

      window.onkeyup = (e) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          axios
            .get(`exitList/${count}/${page + 1}`, { params })
            .then((response) => {
              if (response.data.success) {
                this.setState({
                  loader: false,
                  getData: response.data.data.docs,
                  total: response.data.data.totalDocs
                })
              }
            })
            .catch((error) => {})
        }, 500)
      }
    } else {
      axios
        .get(`exitList/${count}/${page + 1}`, { params })
        .then((response) => {
          if (response.data.success) {
            this.setState({
              loader: false,
              getData: response.data.data.docs,
              total: response.data.data.totalDocs
            })
          }
        })
        .catch((error) => {})
    }
  }

  async onCheckFromDate(e) {
    var d = new Date()
    d.setMonth(d.getMonth() - 1)
    await this.setState((prevState) => ({
      fromDate: !prevState.fromDate,
      fromDateValue: moment(d).format('YYYY-MM-DD')
    }))
    this._getUsers()
    if (this.state.fromDate) this._onDateBlur()
  }

  _onDateBlurTo = (date) => {
    this.setState({ toDateValue: '' })
  }

  _onDateBlur = (date) => {
    this.setState({ fromDateValue: '' })
  }

  async onFromDateChange(date) {
    if (typeof date == 'object') {
      await this.setState({ fromDateValue: moment(date).format('YYYY-MM-DD') })
      await this._getUsers()
    }
  }

  async onCheckToDate() {
    await this.setState((prevState) => ({
      toDate: !prevState.toDate,
      toDateValue: moment(new Date()).format('YYYY-MM-DD')
    }))
    this._getUsers()
    if (this.state.toDate) this._onDateBlurTo()
  }

  async onToDateChange(date) {
    if (typeof date == 'object') {
      await this.setState({ toDateValue: moment(date).format('YYYY-MM-DD') })
      this._getUsers()
    }
  }

  render() {
    const level = this.props.userStore.userLevel
    const { count, page, searchText, getData, total } = this.state
    const columns = [
      {
        name: 'Email',
        label: 'Email',
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'Emri',
        label: 'Emri',
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'Mbiemri',
        label: 'Mbiemri',
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'Gjuhet',
        label: 'Gjuhet',
        options: {
          filter: true,
          sort: false,
          display: this.state.showLanguage,
          filterList: this.state.languageRender, //Language selected
          filterOptions: {
            names: this.state.languages.map((element) => element.code)
          } //
        }
      },
      {
        name: 'Pozita',
        label: 'Pozita',
        options: {
          filter: true,
          sort: true,
          filterList: this.state.functionRender, //Function selected
          // filterOptions: this.state.funksionet.map(element => element.name) //List of all functions
          filterOptions: {
            names: this.state.funksionet.map((element) => element.name)
          } //
        }
      },
      {
        name: 'Projekti',
        label: 'Projekti',
        options: {
          filter: true,
          sort: true,
          filterList: this.state.projectRender, //Project selected
          // filterOptions: this.state.projects.map(element => element.name) //List of all Projects
          filterOptions: {
            names: this.state.projects.map((element) => element.name)
          } //
        }
      },
      {
        name: 'Departamenti',
        label: 'Departamenti',
        options: {
          filterType: 'checkbox',
          filter: true,
          sort: false,
          display: this.state.showDepartment,
          filterList: this.state.departmentRender, //Department selected
          // filterOptions: this.state.departaments.map(element => element.name) //List of all Departments
          filterOptions: {
            names: this.state.departaments.map((element) => element.name)
          } //
        }
      },
      {
        name: 'Aktiviteti',
        label: 'Aktiviteti',
        options: {
          filter: true,
          sort: true,
          display: this.state.showActivity,
          filterList: this.state.activityRender, //Activity selected
          // filterOptions: this.state.activities.map(element => element.name) //Lisof all activities
          filterOptions: {
            names: this.state.activities.map((element) => element.name)
          } //
        }
      },
      {
        name: 'Arsyeja e daljes',
        label: 'Arsyeja e daljes',
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'Data e daljes',
        label: 'Data e daljes',
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'Pamje',
        label: 'Pamje',
        options: {
          filter: false,
          sort: false
        }
      }
    ]
    const options = {
      download: false,
      elevation: 0,
      sort: false,
      pagination: true,
      filter: true,
      filterType: 'dropdown',
      responsive: 'scroll',
      viewColumns: true,
      selectableRows: false,
      serverSide: true,
      count: total,
      page: this.state.page,
      search: false,
      rowsPerPage: count,
      rowsPerPageOptions: [10, 50, 100, 200],
      textLabels: {
        body: {
          noMatch: 'Nuk ka të dhëna'
        },
        pagination: {
          next: 'Faqja tjeter',
          previous: 'Faqja e meparshme',
          rowsPerPage: 'Rreshta për faqe',
          displayRows: 'të'
        },
        toolbar: {
          search: 'Kërko',
          downloadCsv: 'Shkarko në CSV',
          print: 'Printo',
          viewColumns: 'Shiko kolonat',
          filterTable: 'Tabela e filtrimit'
        },
        filter: {
          all: 'TE GJITHA',
          title: 'FILTRIMET',
          reset: 'RIVENDOS'
        },
        viewColumns: {
          title: 'Shfaq kolonat',
          titleAria: 'Trego/fsheh kolonat e tabelave'
        }
      },
      customToolbar: () => {
        return (
          <Tooltip title={'Shkarko CSV'}>
            <IconButton
              aria-label={'Shkarko CSV'}
              onClick={() => {
                this._getExport()
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        )
      },
      onFilterChange: async (changedColumn, filterList) => {
        switch (changedColumn) {
          case 'Gjuhet':
            await this.setState({
              languageRender: filterList[3]
            })

            this.state.languages.forEach((element) => {
              if (element.code == this.state.languageRender[0]) {
                this.setState({ languageSelected: element._id })
              } else if (this.state.languageRender.length == 0) {
                this.setState({ languageSelected: '' })
              }
            })

            this._getUsers()
            break
          case 'Pozita':
            await this.setState({
              functionRender: filterList[4]
            })

            this.state.funksionet.forEach((element) => {
              if (element.name == this.state.functionRender[0]) {
                this.setState({ functionSelected: element._id })
              } else if (this.state.functionRender.length == 0) {
                this.setState({ functionSelected: '' })
              }
            })

            this._getUsers()
            break
          case 'Projekti':
            await this.setState({
              projectRender: filterList[5]
            })

            this.state.projects.forEach((element) => {
              if (element.name == this.state.projectRender[0]) {
                this.setState({ projectSelected: element._id })
              } else if (this.state.projectRender.length == 0) {
                this.setState({ projectSelected: '' })
              }
            })

            this._getUsers()
            break
          case 'Departamenti':
            var array = []
            await this.setState({ departmentRender: filterList[6] })
            this.state.departaments.map((department) => {
              filterList[6].forEach(async (element) => {
                if (department.name == element) {
                  array.push(department._id)
                  await this.setState({
                    departmentSelected: array
                  })
                }
              })
            })

            if (filterList[6].length == 0) {
              await this.setState({
                departmentSelected: ''
              })
            }

            this._getUsers()
            break
          case 'Aktiviteti':
            await this.setState({
              activityRender: filterList[7]
            })

            this.state.activities.forEach((element) => {
              if (element.name == this.state.activityRender[0]) {
                this.setState({ activitySelected: element._id })
              } else if (this.state.activityRender.length == 0) {
                this.setState({ activitySelected: '' })
              }
            })

            this._getUsers()
            break
        }
      },
      onTableChange: async (action, tableState) => {
        switch (action) {
          case 'changePage':
            await this.setState({ page: tableState.page })
            this._getUsers()
            break
          case 'resetFilters':
            await this.setState({
              functionRender: '',
              projectRender: '',
              activityRender: '',
              departmentRender: '',
              functionSelected: '',
              projectSelected: '',
              activitySelected: '',
              departmentSelected: '',
              languageRender: ''
            })
            this._getUsers()
            break
          case 'changeRowsPerPage':
            await this.setState({ count: tableState.rowsPerPage })
            this._getUsers()
            break
          case 'columnViewChange':
            this.setState({
              showLanguage: tableState.columns[3].display == 'true' ? true : false,
              showDepartment: tableState.columns[6].display == 'true' ? true : false,
              showActivity: tableState.columns[7].display == 'true' ? true : false
            })
            break
        }
      }
    }

    const data = this.state.getData.map((element) => {
      return [
        element.email ? element.email : '',
        element.first_name ? element.first_name : '',
        element.last_name ? element.last_name : '',
        element.languages && element.languages?.map((lang) => lang?._id?.code).join(','),
        element.function && element.function.name,
        element.project && element.project.name,
        element.department &&
          element.department.map((department, index) => {
            return element.department.length - 1 == index ? department.name : department.name + ', '
          }),
        element.activity && element.activity.name,
        element.exit_reason && element.exit_reason != null
          ? element.exit_reason == 'Tjeter...'
            ? element.other_exit_reason != null
              ? this.titleCrop(element.other_exit_reason)
              : this.titleCrop(element.exit_reason)
            : this.titleCrop(element.exit_reason)
          : '',
        element.exit_date && element.exit_date != undefined ? moment(element.exit_date).format('DD/MM/YYYY') : '',
        <Link to={`/editUsers/${element._id}`}>
          <Button outline className='mb-1' color='warning' type='button'>
            <span>Shfaq</span>
          </Button>
        </Link>
      ]
    })
    return (
      <ContentWrapper>
        <div className='content-heading'>
          <div>Lista e daljes</div>
        </div>
        <ResignIntervieModal />
        <Row>
          <Col>
            <Card className={`shadow bg-white ${this.state.loader && 'whirl standard'}`}>
              <CardBody>
                <div className='input-group '>
                  <input
                    value={this.state.searchText}
                    onChange={this._getUsers}
                    className='form-control'
                    type='text'
                    placeholder='Kërko përdoruesit...'
                  />
                  <span className='input-group-btn'>
                    <button disabled className='btn btn-secondary' type='button'>
                      <em className='fa fa-search'></em>
                    </button>
                  </span>
                </div>

                {/* from date */}
                <div className='input-group pt-2'>
                  <Col>
                    <div className='form-group row mb float-left pl-1'>
                      <div className='checkbox c-checkbox pt-2'>
                        <label>
                          <Input onChange={() => this.onCheckFromDate()} name='fromdate' type='checkbox' />
                          <span className='fa fa-check'></span>
                        </label>
                      </div>
                      <label className='col-form-label mb'>Prej datës:</label>
                      <Col md={6} className='from-date'>
                        <DateTimeBoostrap
                          onChange={this.onFromDateChange}
                          value={this.state.fromDateValue}
                          utc={true}
                          locale='de'
                          dateFormat={'YYYY/MM/DD'}
                          timeFormat='YYYY/MM/DD'
                          name='fromdate'
                          type='checkbox'
                          data-validate='["validDate"]'
                          inputProps={{
                            disabled: this.state.fromDate && 'disabled',
                            id: 'dateFrom',
                            'data-validate': '["validDate"]',
                            className: 'form-control input',
                            name: 'dateFrom'
                          }}
                        />
                      </Col>
                    </div>

                    <div className='form-group row mb float-left pl-1'>
                      <div className='checkbox c-checkbox pt-2'>
                        <label>
                          <Input onChange={() => this.onCheckToDate()} name='todate' type='checkbox' />
                          <span className='fa fa-check'></span>
                        </label>
                      </div>
                      <label className='col-form-label mb'>Deri:</label>
                      <Col md={7} className='to-date'>
                        <DateTimeBoostrap
                          value={this.state.toDateValue}
                          type='date'
                          utc={true}
                          dateFormat={'YYYY/MM/DD'}
                          timeFormat=''
                          onChange={this.onToDateChange}
                          placeholder='yy/mm/dd'
                          inputProps={{
                            disabled: this.state.toDate && 'disabled',
                            id: 'dateFrom',
                            'data-validate': '["validDate"]',
                            className: 'form-control',
                            name: 'dateFrom'
                          }}
                        />
                      </Col>
                    </div>
                  </Col>
                </div>
              </CardBody>
              <MUIDataTable key={Math.random()} ref={(ref) => (this.tableRef = ref)} columns={columns} options={options} data={data} />
            </Card>
          </Col>
        </Row>
      </ContentWrapper>
    )
  }
}

export default ExitList
