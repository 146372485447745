import axios from 'axios'
import { inject } from 'mobx-react'
import { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import FormValidator from '../Forms/FormValidator.js'

@inject('userStore')
class Login extends Component {
  state = {
    formReset: {
      email: ''
    },
    passwordSent: false,
    errorMessage: ''
  }

  /**
   * Validate input using onChange event
   * @param  {String} formName The name of the form in the state object
   * @return {Function} a function used for the event
   */
  validateOnChange = (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value
    const result = FormValidator.validate(input)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      },
      errorMessage: ''
    })
    if (!hasError) {
      const postData = {
        email: this.state.formReset.email
      }
      axios
        .post('https://liyakq8s5d.execute-api.eu-central-1.amazonaws.com/prod/api/resetPasswordLink', postData)
        .then((response) => {
          if (response.data.success) {
            this.setState({ passwordSent: true })
          } else {
            this.setState({ errorMessage: response.data.message })
          }
        })
        .catch((e) => {
          this.setState({ errorMessage: 'Server error' })
        })
    }
  }

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  render() {
    return (
      <div className='row h-100 main-container'>
        <div className='text-center col-lg-7 left-container'>
          <Link to='/login' className='text-muted'>
            <img className='block-center rounded' src='img/logo.png' alt='Logo' />
          </Link>
        </div>
        <span className='divider' />
        <div className='col-lg-3 right-container'>
          <p className='text-center py-2'>Resetimi i passwordit</p>
          {this.state.passwordSent ? (
            <Fragment>
              <p className='text-center'>Ju lutem kontrolloni emailin tuaj per te vazhduar.</p>
              <Link to='/login' className='text-muted'>
                Kthehu tek login
              </Link>
            </Fragment>
          ) : (
            <form name='formReset' onSubmit={this.onSubmit}>
              <p className='text-center'>Shkruani emailin e llogarise tuaj.</p>
              {this.state.errorMessage != '' && <p className='text-center text-danger'>{this.state.errorMessage}</p>}
              <div className='form-group'>
                <label className='text-muted' htmlFor='resetInputEmail1'>
                  Email:
                </label>
                <div className='input-group with-focus'>
                  <input
                    name='email'
                    className='form-control border-right-0'
                    onChange={this.validateOnChange}
                    data-validate='["required", "email"]'
                    type='email'
                    placeholder='Shenoni emailin tuaj'
                    autoComplete='off'
                  />
                  {this.hasError('formReset', 'email', 'required') && <span className='invalid-feedback'>Ju lutem plotesoni fushen</span>}
                  {this.hasError('formReset', 'email', 'email') && (
                    <span className='invalid-feedback'>Ju lutem shenoni email adresen tuaj</span>
                  )}
                  <div className='input-group-append'>
                    <span className='input-group-text text-muted bg-transparent border-left-0'>
                      <em className='fa fa-envelope'></em>
                    </span>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='clearfix'>
                  <div>
                    <Link to='/login' className='text-muted'>
                      Kthehu tek login
                    </Link>
                  </div>
                </div>
              </div>
              <button className='btn btn-danger btn-block' type='submit'>
                Dergo linkun per reset
              </button>
            </form>
          )}
        </div>
      </div>
    )
  }
}

export default Login
