const getQuery = (query) => {
  const { page, limit, dateFrom, dateTo, status, location, name } = query
  const queryText =
    `page=${page}&limit=${limit}` +
    (dateFrom ? `&from=${dateFrom.getTime()}` : '') +
    (dateTo ? `&to=${dateTo.getTime()}` : '') +
    (name ? `&name=${name}` : '') +
    (location ? `&location=${location}` : '') +
    (status ? (status !== 'all' ? `&status=${status}` : '') : '')

  return queryText
}

export default getQuery
