import MUIDataTable from 'mui-datatables'
import TableHeader from './TableHeaders'

const UsersDataTable = ({ columns, options, data, setSelectedFilters, selectedFilters, handleDateChange, isLoading, setPage }) => {
  return (
    <div className={isLoading ? 'whirl standard' : null}>
      <MUIDataTable
        columns={columns}
        options={options}
        data={data}
        title={
          <TableHeader
            handleDateChange={handleDateChange}
            setPage={setPage}
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilters}
          />
        }
      />
    </div>
  )
}

export default UsersDataTable
