import React from 'react'
import 'moment/locale/sq' // without this line it didn't work
import { Col, Button, CardFooter, Input, Alert } from 'reactstrap'
import DateTimeBoostrap from '../../components/Forms/DateTimeBootstrap'

import RemovedDays from './components/RemovedDays'

const LeaveForm = (props) => {
  const renderLeaveType = () => {
    const items = props.leaveTypes.map((element, index) => {
      return (
        <option key={index} value={element._id}>
          {element.name}
        </option>
      )
    })

    return (
      <fieldset>
        <div className='form-group row align-items-center'>
          <label className='col-md-2 col-form-label'>Kategoria:</label>
          <div className='col-md-8'>
            <Input
              type='select'
              name='type'
              disabled={props.viewOnly && 'disabled'}
              data-validate='["required"]'
              value={props.type}
              className='custom-select custom-select'
              onChange={props.onSelectChange}
              invalid={props.hasError('formNewLeave', 'type', 'required')}
            >
              <option value=''>Zgjidhni Kategorine...</option>
              {items}
            </Input>
            <span className='invalid-feedback'>Fusha është e nevojshme</span>
          </div>
        </div>
      </fieldset>
    )
  }

  const removedDaysValues = [
    { title: 'Nga viti i kaluar', value: Number(props?.lastYearLeave || 0).toFixed(2) },
    { title: 'Nga ditët shtesë', value: Number(props?.extraLeaves || 0).toFixed(2) },
    { title: 'Nga viti aktual', value: Number(props?.accumulatedLeaves || 0).toFixed(2) }
  ]

  let color
  let badgeText
  switch (props.status) {
    case 'Approved':
      color = 'success'
      badgeText = 'Pushimi juaj eshte Aprovuar!'
      break
    case 'Rejected':
      color = 'danger'
      badgeText = 'Pushimi juaj eshte Refuzuar!'
      break
    case 'Partially approved':
      color = 'warning'
      badgeText = 'Pushimi juaj eshte Aprovuar pjeserisht nga Teamleader'
      break
  }

  return (
    <form onSubmit={props.onSubmit} name='formNewLeave' style={{ opacity: props.formLoading ? 0.5 : 1 }}>
      <div className='card b w-100'>
        <div className='card-header d-flex align-items-center'>
          <div className='d-flex justify-content-center col'>
            <div className='h4 m-0 text-center'>{props.title}</div>
          </div>
        </div>
        <div className='card-body text-left'>
          <fieldset>
            <div className='form-group row align-items-center'>
              <label className='col-md-2 col-form-label'>Prej</label>
              <Col md={8}>
                <DateTimeBoostrap
                  value={props.dateFrom}
                  utc={true}
                  dateFormat={'DD/MM/YYYY'}
                  timeFormat=''
                  onChange={props.onChange('dateFrom')}
                  inputProps={{
                    disabled: props.viewOnly && 'disabled',
                    id: 'dateFrom',
                    'data-validate': '["validDate"]',
                    className: 'form-control',
                    name: 'dateFrom',
                    invalid: props.hasError('formNewLeave', 'dateFrom', 'validDate')
                  }}
                />
              </Col>
            </div>
          </fieldset>
          <fieldset>
            <div className='form-group row align-items-center'>
              <label className='col-md-2 col-form-label'>Deri</label>
              <Col md={8}>
                <DateTimeBoostrap
                  value={props.dateTo}
                  utc={true}
                  dateFormat={'DD/MM/YYYY'}
                  timeFormat=''
                  onChange={props.onChange('dateTo')}
                  inputProps={{
                    disabled: props.viewOnly && 'disabled',
                    'data-param': 'dateFrom',
                    'data-validate': '["dateIsAfter"]',
                    className: 'form-control',
                    name: 'dateTo',
                    invalid: props.hasError('formNewLeave', 'dateTo', 'dateIsAfter')
                  }}
                />
              </Col>
            </div>
          </fieldset>
          {props.viewOnly && (
            <fieldset>
              <div className='form-group row align-items-center'>
                <label className='col-md-2 col-form-label'>Ditë pushimi:</label>
                <Col md={8}>
                  <Input disabled={props.viewOnly && 'disabled'} type='number' name='days' value={props.days} />
                  <span className='invalid-feedback'>Fusha është e nevojshme</span>
                </Col>
              </div>
            </fieldset>
          )}
          {renderLeaveType()}
          <fieldset>
            <div className='form-group row align-items-center'>
              <label className='col-md-2 col-form-label'>Informata Shtesë:</label>
              <Col md={8}>
                <Input
                  type='text'
                  name='otherInfo'
                  disabled={props.viewOnly && 'disabled'}
                  invalid={props.hasError('formNewLeave', 'otherInfo', 'required')}
                  onChange={(e) => props.onChange('otherInfo')(e.target.value)}
                  value={props.otherInfo}
                />
                <span className='invalid-feedback'>Fusha është e nevojshme</span>
              </Col>
            </div>
          </fieldset>
          {props.viewOnly && (
            <fieldset>
              <div className='form-group row align-items-center'>
                <label className='col-md-2 col-form-label'>Shenim:</label>
                <Col md={8}>
                  <Input disabled={props.viewOnly && 'disabled'} type='textarea' name='message' value={props.messageChanged} />
                  <span className='invalid-feedback'>Fusha është e nevojshme</span>
                </Col>
              </div>
            </fieldset>
          )}
          {props.viewOnly && props.status === 'Approved' && (
            <>
              <div className='align-items-center '>
                <h5 className='mb-3 font-weight-bold'>Ditët e pushimit janë zbritur si me poshtë:</h5>
              </div>
              {removedDaysValues.map((item) => (
                <RemovedDays {...item} />
              ))}
            </>
          )}
        </div>
        {props.viewOnly ? (
          <CardFooter className='text-center'>
            <Alert color={color}>{badgeText}</Alert>
          </CardFooter>
        ) : (
          <CardFooter className='text-right'>
            {props.hasDeleteButton && (
              <Button color='danger' onClick={props.delete}>
                Fshij
              </Button>
            )}
            <button style={{ marginLeft: 10 }} type='submit' className='btn btn-info'>
              Apliko
            </button>
          </CardFooter>
        )}
      </div>
    </form>
  )
}

export default LeaveForm
