import { observable } from 'mobx'

class userStore {
  @observable isAuthenticated = false
  @observable userLevel = 0
  @observable user_id = null
  @observable projects = null
  @observable email = null
  @observable first_call = null
  @observable userHasBirthday = false

  setAuthenticated(auth) {
    this.isAuthenticated = auth
  }

  setUserLevel(userLevel) {
    this.userLevel = userLevel
  }

  setUserEmail(userEmail) {
    this.email = userEmail
  }

  setStartDate(startDate) {
    this.first_call = startDate
  }

  setUserID(userId) {
    this.user_id = userId
  }
  setUserHasBirthday(userHasBirthday) {
    this.userHasBirthday = userHasBirthday
  }
}

export default new userStore()
