import jwt_decode from 'jwt-decode'
import { inject } from 'mobx-react'
import pubsub from 'pubsub-js'
import { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Badge, Collapse } from 'reactstrap'
import ToggleState from '../Common/ToggleState'
import { getRoutesData } from './getRoutesData.js'
import SidebarRun from './Sidebar.run'
import SidebarUserBlock from './SidebarUserBlock'

/** Normal items for the sidebar */
const SidebarItem = ({ item, isActive }) => (
  <ToggleState state='aside-toggled' nopersist={true}>
    <li className={isActive ? 'active' : ''}>
      <Link
        to={
          item.type === 'externalLink'
            ? {
              pathname: item.path
            }
            : item.path
        }
        target={item.type === 'externalLink' ? '_blank' : '_self'}
        title={item.name}
      >
        {item.label && (
          <Badge tag='div' className='float-right' color={item.label.color}>
            {item.label.value}
          </Badge>
        )}
        {item.icon && <em className={item.icon} />}
        <span>{item.name}</span>
      </Link>
    </li>
  </ToggleState>
)

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({ item, isActive, handler, children, isOpen }) => (
  <li className={isActive ? 'active' : ''}>
    <div className='nav-item' onClick={handler}>
      {item.label && (
        <Badge tag='div' className='float-right' color={item.label.color}>
          {item.label.value}
        </Badge>
      )}
      {item.icon && <em className={item.icon} />}
      <span>{item.name}</span>
    </div>
    <Collapse isOpen={isOpen}>
      <ul id={item.path} className='sidebar-nav sidebar-subnav'>
        {children}
      </ul>
    </Collapse>
  </li>
)

@inject('userStore')
class Sidebar extends Component {
  state = {
    collapse: {}
  }

  componentDidMount() {
    // pass navigator to access router api
    SidebarRun(this.navigator.bind(this))
    pubsub.subscribe('goToProfile', () => {
      this.props.history.push('/profile')
    })
    pubsub.subscribe('goToLogin', () => {
      this.props.history.push('/login')
    })

    if (!this.props.userStore.isAuthenticated) {
      this.props.history.push('/login')
    }
  }

  navigator(route) {
    this.props.history.push(route)
  }

  routeActive(paths) {
    paths = Array.isArray(paths) ? paths : [paths]
    if (paths.indexOf(this.props.location.pathname.replace('/', '')) > -1) return true
    return false
  }

  toggleItemCollapse(stateName) {
    for (let c in this.state.collapse) {
      if (this.state.collapse[c] === true && c !== stateName)
        this.setState({
          collapse: {
            [c]: false
          }
        })
    }
    this.setState({
      collapse: {
        [stateName]: !this.state.collapse[stateName]
      }
    })
  }

  getSubRoutes = (item) => item.submenu.map(({ path }) => path)

  render() {
    const token = localStorage.getItem('token')
    const decodedToken = jwt_decode(token)
    const { user_level } = decodedToken
    const routes = getRoutesData()

    return (
      <aside className='aside-container sticky-left'>
        {/* START Sidebar (left) */}
        <div className='aside-inner'>
          <nav data-sidebar-anyclick-close='' className='sidebar'>
            {/* START sidebar nav */}
            <ul className='sidebar-nav'>
              {/* START user info */}
              <li className='has-user-block'>
                <SidebarUserBlock />
              </li>
              {/* END user info */}

              {routes.map((item, i) => {
                if (item?.icon) {
                  if (item?.allowed_user_levels.includes(user_level) || item.allowed_condition) {
                    if (item?.submenu) {
                      return [
                        <SidebarSubItem
                          item={item}
                          isOpen={this.state.collapse[item.name]}
                          handler={this.toggleItemCollapse.bind(this, item.name)}
                          isActive={this.routeActive(this.getSubRoutes(item))}
                          key={i}
                        >
                          <li className='sidebar-subnav-header'>{item.name}</li>
                          {item.submenu
                            .filter((subitem) => subitem.allowed_user_levels.includes(user_level))
                            .map((subitem, i) => (
                              <SidebarItem key={i} item={subitem} isActive={this.routeActive(subitem.path)} />
                            ))}
                        </SidebarSubItem>
                      ]
                    } else {
                      return <SidebarItem isActive={this.routeActive(item.path)} item={item} key={i} />
                    }
                  }
                }
              })}
            </ul>
          </nav>
        </div>
      </aside>
    )
  }
}

export default withRouter(Sidebar)
