import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

const BadgesModal = ({ updateUserData, onCloseModal, type, badgeValue }) => {
  const { first_name, last_name, email, identity_card, project, department, speeex_badge, office_location, _id } = updateUserData || {}

  const data = [
    { key: 'Emri & Mbiemri', value: `${first_name} ${last_name}` },
    { key: 'Email', value: email },
    { key: 'Nr Personal', value: identity_card },
    type === 'haveBadge' && { key: 'Bexhi', value: speeex_badge },
    { key: 'Projekti', value: project?.name },
    { key: 'Departamenti', value: department?.[0]?.name },
    { key: 'Lokacioni i punës', value: office_location }
  ].filter(Boolean)

  const [badge, setBadge] = useState(badgeValue)

  const updateBadge = async () => {
    try {
      const data = await axios.post(`users/badges/${updateUserData._id}`, { speeex_badge: badge })
      if (data.data.success) {
        onCloseModal()
        toast.success('Badge u shtua me sukses')
      } else {
        toast.success('Ndodhi një gabim!')
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Ndodhi një gabim!')
      console.error('Error updating badge:', error)
    }
  }

  return (
    <Dialog open={true}>
      <DialogTitle>Shto badge</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
        {data?.map((item) => (
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>{item.key}: </Typography>
            <Typography>{item.value}</Typography>
          </Box>
        ))}
        <Box sx={{ fieldset: { border: '1px solid' } }}>
          <TextField
            label={type === 'noBadge' ? 'Shto bexhin' : 'Ndrysho bexhin'}
            size='small'
            value={badge}
            onChange={(e) => setBadge(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal} size='small' variant='contained' color='error'>
          Mbrapa
        </Button>
        <Button onClick={updateBadge} size='small' variant='contained'>
          {type === 'noBadge' ? 'Shto' : 'Modifiko'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BadgesModal
