import BadgesTable from '../components/BadgesTable/badgesTable'
import React from 'react'

const useTableOptions = (total, params, setParams) => {
  return {
    filter: false,
    selectableRows: 'none',
    search: false,
    print: false,
    download: false,
    pagination: true,
    serverSide: true,
    count: total,
    rowsPerPage: 5,
    viewColumns: false,
    rowsPerPageOptions: [5, 10, 50, 100, 200],
    onTableChange: (action, tableState) => {
      if (action === 'changeRowsPerPage') {
        setParams((prevParams) => ({
          ...prevParams,
          limit: tableState.rowsPerPage,
          page: 0 // Reset page to 0 when changing rows per page
        }))
      } else if (action === 'changePage') {
        setParams((prevParams) => ({
          ...prevParams,
          page: tableState.page
        }))
      }
    }
  }
}

export default useTableOptions
