import { Button } from '@mui/material'

export const useTableData = (item, setUserId, type, setBadge) => {
  return [
    `${item?.first_name} ${item?.last_name}`,
    item?.email,
    type === 'haveBadge' && item?.speeex_badge,
    item?.identity_card,
    item?.project?.name,
    item?.department[0]?.name,
    item?.office_location,
    <Button size='small' variant='contained' onClick={() => {
      setUserId(item);
      setBadge(item?.speeex_badge);
    }}>
      {type === 'noBadge' ? 'Shto bexhin' : 'Ndrysho bexhin'}
    </Button>
  ]
}
