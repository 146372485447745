import jwtDecode from 'jwt-decode'
export const qualityDepartment = ['5d9b27f7d489d437f2950595']

export const columnsTableStats = [
  {
    name: 'Order Type',
    label: 'Order Type',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'Orders',
    label: 'Orders',
    options: {
      sortDirection: 'Orders',
      filter: false,
      sort: true
    }
  },
  {
    name: 'Orders Group',
    label: 'Orders Group',
    options: {
      filter: true,
      sort: true
    }
  }
]

export const optionsTableStats = {
  responsive: 'scroll',
  filterType: 'multiselect',
  selectableRows: 'none',
  print: false,
  textLabels: {
    body: {
      noMatch: 'Nuk ka të dhëna'
    }
  }
}

export const columnsOrderTable = [
    {
      name: 'Emri Mbiemri',
      label: 'Emri Mbiemri',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Order Type',
      label: 'Order Type',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Order Group',
      label: 'Order Group',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Order ID',
      label: 'Order ID',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'From Order Quality',
      label: 'From Order Quality',
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: 'Activation Date',
      label: 'Activation Date',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Modifiko',
      label: 'Modifiko',
      options: {
        filter: false,
        sort: false,
        download: false
      }
    }
  ]

export const optionsOrderTable = {
  responsive: 'scroll',
  filterType: 'dropdown',
  selectableRows: 'none',
  print: false,
  textLabels: {
    body: {
      noMatch: 'Nuk ka të dhëna'
    }
  }
}
