import axios from 'axios'
import { inject } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/sq' // without this line it didn't work
import pubsub from 'pubsub-js'
import React, { Fragment } from 'react'
import { DATE_FORMAT, SchedulerData, ViewTypes } from 'react-big-scheduler'
import 'react-big-scheduler/lib/css/style.css'
import { Link, withRouter } from 'react-router-dom'
import { Button, CardBody, CardHeader, Col, Collapse, Nav, Row, TabContent, TabPane } from 'reactstrap'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import Calendar, { getColor } from '../Calendar/Calendar'
import { DeleteDialogBox } from '../Common/'
import ContentWrapper from '../Layout/ContentWrapper'
import LeavesHeader from '../LeavesHeader/LeavesHeader'
import ApproveLeaveForm from './ApproveLeaveForm'
import LeaveForm from './LeaveForm'
import LeavesDataTable from './LeavesDataTable'
import { Box } from '@mui/material'

moment.updateLocale('sq', {
  week: {
    dow: -7
  }
})

function arraysEqual(a1, a2) {
  /* WARNING: arrays must not contain {objects} or behavior may be undefined */
  return JSON.stringify(a1) == JSON.stringify(a2)
}

var timeout = null // timeout for searchBox

@inject('userStore')
class ManageLeaves extends React.Component {
  _isMounted = false
  scrollToSpecialMoment = true

  state = {
    showLocation: true,
    showLanguage: false,
    showEmail: false,
    showCreatedDate: false,
    showPozita: false,
    showProject: false,
    showDepartment: false,
    showActivity: false,
    isUserID: false,
    schedulerData: new SchedulerData(),
    dropdownOpen: false,
    width: 100,
    activeTab: '1',
    leaveTypes: [],
    currentLeave: null,
    dateFrom: null,
    dateTo: null,
    type: '',
    otherInfo: '',
    days: '',
    message: '',
    returnToWork: '',
    holidays: [],
    leaves: [],
    categoriesLoading: true,
    dataLoading: false,
    formLoading: false,
    dataTableDataLoading: false,
    dataTableRowsPerPage: 10,
    dataTableSearch: '',
    dataTablePage: 0,
    dataTableData: [],
    editLeave: null,
    resources: [],
    selectedUserID: null,
    backCurrentLeave: null,
    statsLoading: false,
    stats: null,
    selectedUser: {
      first_name: 'lorem',
      last_name: 'ipsum'
    },
    filters: {
      project: undefined,
      function: undefined,
      'department[]': undefined,
      activity: undefined,
      category: undefined,
      'languages[]': undefined,
      office_location: undefined
    },
    filterList: {
      projects: [],
      roles: [],
      departments: [],
      activities: [],
      leavecategories: [],
      languages: [],
      office_locations: []
    },
    filterOptions: {
      projects: {},
      roles: {},
      departments: {},
      languages: {},
      activities: {},
      leavecategories: {},
      status: {
        Approved: 'Approved',
        'Partially approved': 'Partially approved',
        Rejected: 'Rejected',
        Pending: 'Pending'
      },
      office_locations: {
        'Home Office': 'Home Office',
        Prishtinë: 'Prishtinë',
        'Prishtinë HQ': 'Prishtinë HQ',
        Vushtrri: 'Vushtrri',
        Ferizaj: 'Ferizaj',
        Prizren: 'Prizren'
      }
    },
    searchQuery: '',
    collapse: false,
    labelStatus: '',
    statusType: 1,
    daysType: 1,
    dateToType: 1,
    dateFromType: 1,
    createdDateType: 1,
    createdByFirstName: 1,
    categoryNameType: 1,
    selectedDate: new Date(),
    fromDate: true,
    toDate: true,
    fromDateValue: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
    toDateValue: moment().add(1, 'months').endOf('month').format('YYYY-MM-DD'),
    showCalendar: false
  }

  constructor(props) {
    super(props)

    this.toggleLegend = this.toggleLegend.bind(this)

    this.deleteRejectedLeaves = this.deleteRejectedLeaves.bind(this)
    this.messageChanged = this.messageChanged.bind(this)
    this.returnToWorkChanged = this.returnToWorkChanged.bind(this)
    this.daysChanged = this.daysChanged.bind(this)

    this.updateDimensions = this.updateDimensions.bind(this)
    this.nextClick = this.nextClick.bind(this)
    this.prevClick = this.prevClick.bind(this)
    this.onViewChange = this.onViewChange.bind(this)
    this.onSelectDate = this.onSelectDate.bind(this)
    this.eventClicked = this.eventClicked.bind(this)
    this.ops1 = this.ops1.bind(this)
    this.ops2 = this.ops2.bind(this)
    this.toggleExpandFunc = this.toggleExpandFunc.bind(this)
    this.loadData = this.loadData.bind(this)
    this.loadUserInfo = this.loadUserInfo.bind(this)
    this.loadStats = this.loadStats.bind(this)
    this.clearFromAndRefresh = this.clearFromAndRefresh.bind(this)
    this.editLeave = this.editLeave.bind(this)
    this.clearEditLeave = this.clearEditLeave.bind(this)
    this.selectedUserID = this.selectedUserID.bind(this)
    this.disApprove = this.disApprove.bind(this)

    this.changePage = this.changePage.bind(this)
    this.changeRow = this.changeRow.bind(this)
    this.changeSearch = this.changeSearch.bind(this)
    this.onTableChange = this.onTableChange.bind(this)

    this.loadFilter = this.loadFilter.bind(this)
    this.loadFilters = this.loadFilters.bind(this)

    this.resetFilters = this.resetFilters.bind(this)
    this.changeFilters = this.changeFilters.bind(this)
    this.getYear = this.getYear.bind(this)
    this.onSelectChange = this.onSelectChange.bind(this)

    let schedulerData = new SchedulerData(new moment().format(DATE_FORMAT), ViewTypes.Month, false, false, undefined, {
      getScrollSpecialMomentFunc: (schedulerData, start) => {
        if (this.state.currentLeave) {
          const view = document.getElementsByClassName('scheduler-view')
          const scrollView = view[0].querySelectorAll(':scope > div')
          const categoryElementOfcurrentEvent = document.querySelectorAll(`[data-resource-id='${this.state.currentLeave.resourceId}']`)

          if (!scrollView[1]) return this.state.dateFrom

          if (categoryElementOfcurrentEvent[0]) scrollView[1].scrollTop = categoryElementOfcurrentEvent[0].offsetTop

          setTimeout(() => {
            if (categoryElementOfcurrentEvent[0]) scrollView[1].scrollTop = categoryElementOfcurrentEvent[0].offsetTop
          }, 100)

          return this.state.dateFrom
        }

        return moment()
      }
    })

    schedulerData.config.schedulerMaxHeight = 350
    schedulerData.setLocaleMoment(moment)
    schedulerData.config.views = schedulerData.config.views
      .filter((v) => {
        return v.viewType == ViewTypes.Week || v.viewType == ViewTypes.Month
      })
      .map((v) => {
        if (v.viewType == ViewTypes.Week) return { ...v, viewName: 'Java' }

        if (v.viewType == ViewTypes.Month) return { ...v, viewName: 'Muaji' }

        return v
      })

    this.state.viewModel = schedulerData
  }

  toggleLegend() {
    this.setState((state) => ({ collapse: !state.collapse }))
  }

  onSelectChange(e) {
    this.setState({ type: e.target.value }, () => {
      this.updateCreateEventFromInput()
    })
  }

  eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth) => {
    let borderWidth = isStart ? '4' : '0'
    let borderColor = 'rgba(0,139,236,1)',
      backgroundColor = '#80C5F6'
    let titleText = schedulerData.behaviors.getEventTextFunc(schedulerData, event)
    if (!!event.type) {
      borderColor = event.type == 1 ? 'rgba(0,139,236,1)' : event.type == 3 ? 'rgba(245,60,43,1)' : '#999'
      backgroundColor = event.type == 1 ? '#80C5F6' : event.type == 3 ? '#FA9E95' : '#D9D9D9'
    }
    let divStyle = {
      borderLeft: borderWidth + 'px solid ' + borderColor,
      backgroundColor: backgroundColor,
      height: mustBeHeight
    }
    if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth }

    return (
      <div key={event.id} className={mustAddCssClass} style={divStyle}>
        <span style={{ marginLeft: '4px', lineHeight: `${mustBeHeight}px` }}>{titleText}</span>
      </div>
    )
  }

  async loadStats() {
    return new Promise(async (resolve, reject) => {
      this.setState({
        statsLoading: true
      })

      try {
        const statsRes = await axios.get('leaves/userstats', {
          params: {
            year: moment(this.state.selectedDate).format('YYYY'),
            userId: this.state.selectedUserID
          }
        })

        if (statsRes.data.success) {
          this.setState(
            {
              stats: statsRes.data.data,
              statsLoading: false
            },
            resolve
          )
        } else {
          reject()
        }
      } catch (e) {
        reject()
        console.log('error', e)
      }
    })
  }

  async loadData() {
    if (this.state.showCalendar) {
      return new Promise(async (resolve, reject) => {
        const schedulerData = this.state.viewModel
        var events = [...this.state.holidays]

        this.state.holidays.map((holiday) => {
          holiday.end._d = holiday.start._d
        })

        if (this._isMounted) {
          this.setState({
            viewModel: schedulerData,
            dataLoading: true
          })
        }

        try {
          const leaveRes = await axios.get('leaves', {
            params: {
              date_from: moment(this.state.fromDateValue).format('YYYY-MM-DD'),
              date_to: moment(this.state.toDateValue).format('YYYY-MM-DD'),
              ...this.state.filters,
              search: this.state.searchQuery || undefined
            }
          })

          if (leaveRes.data.success) {
            let people = {}

            let resources = [
              {
                id: 'r0',
                name: 'Festat',
                groupOnly: true
              }
            ]

            let leaves = leaveRes.data.data.map((h) => {
              if (!people[h.user_id._id]) {
                people[h.user_id._id] = 1
                resources.push({
                  id: h.user_id._id,
                  name: `${h.user_id.first_name} ${h.user_id.last_name}`,
                  groupOnly: true
                })
              }

              return {
                id: h._id,
                showPopover: true,
                start: h.date_from,
                end: h.date_to,
                resourceId: h.user_id._id,
                title: `${h.user_id.first_name} ${h.user_id.last_name} ${h.category && h.category.name}`,
                bgColor: this.state.currentLeave && this.state.currentLeave.id == h._id ? 'purple' : getColor(h),
                movable: false,
                resizable: false,
                type: h.category._id,
                user: h.user_id,
                other_info: h.other_info,
                message: h.message,
                returnToWork: h.return_to_work ? h.return_to_work : '',
                labelStatus: h.status,
                days: h.days
              }
            })

            events.push(...leaves)

            if (!arraysEqual(resources, this.state.resources)) {
              schedulerData.setResources(resources)
            }

            schedulerData.setEvents(events)

            if (this._isMounted) {
              this.setState(
                {
                  resources: resources,
                  viewModel: schedulerData,
                  leaves: leaves,
                  dataLoading: false,
                  categoriesLoading: false
                },
                resolve
              )
            }
          }
          reject()
        } catch (e) {
          reject()
          console.log('error', e)
        }
      })
    }
  }

  prevClick(schedulerData) {
    const view = document.getElementsByClassName('scheduler-view')
    const scrollView = view[0].querySelectorAll(':scope > div')

    if (scrollView[1]) scrollView[1].scrollLeft = 1000000
    setTimeout(() => {
      if (scrollView[1]) scrollView[1].scrollLeft = 1000000
    }, 300)

    schedulerData.prev()

    this.setState(
      {
        viewModel: schedulerData
      },
      this.loadData
    )
  }

  nextClick(schedulerData) {
    const view = document.getElementsByClassName('scheduler-view')
    const scrollView = view[0].querySelectorAll(':scope > div')

    if (scrollView[1]) scrollView[1].scrollLeft = 0
    setTimeout(() => {
      if (scrollView[1]) scrollView[1].scrollLeft = 0
    }, 300)

    schedulerData.next()

    this.setState(
      {
        viewModel: schedulerData
      },
      this.loadData
    )
  }

  onViewChange(schedulerData, view) {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective)

    this.setState(
      {
        viewModel: schedulerData
      },
      this.loadData
    )
  }

  onSelectDate(schedulerData, date) {
    schedulerData.setDate(date)

    this.setState(
      {
        viewModel: schedulerData
      },
      this.loadData
    )
  }

  eventClicked(schedulerData, event) {
    if (!this.editLeave && this.state.currentLeave && event.id == this.state.currentLeave.id) {
      this.setState({
        activeTab: '2'
      })
    }
  }

  ops1(schedulerData, event) {
    this.scrollToSpecialMoment = false
    this.props.history.push(`/manageLeaves/${event.user._id}/${event.id}`)
  }

  ops2(schedulerData, event) {
    alert(`You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`)
  }

  async loadDataTableData() {
    return new Promise(async (resolve, reject) => {
      const { dataTableRowsPerPage, dataTablePage } = this.state
      this.setState({
        dataTableDataLoading: true
      })

      try {
        if (this.state.selectedUserID) {
          const year = moment(this.state.selectedDate).format('YYYY')
          var params = {}
          const url = `leaves/user/${this.state.selectedUserID}`
          params.year = year
          params.date_from = this.state.fromDateValue ? this.state.fromDateValue : ''
          params.date_to = this.state.toDateValue ? this.state.toDateValue : ''
          const dataRes = await axios.get(url, { params })

          if (dataRes.data.success) {
            let dataTableData = dataRes.data.message
            this.setState({
              dataTableTotal: dataTableData.length,
              dataTableData,
              dataTableDataLoading: false
            })

            return resolve(dataTableData)
          }

          reject()
        } else {
          const url = 'leaves/'
          const rows = dataTableRowsPerPage
          const page = dataTablePage + 1

          const dataRes = await axios.get(url + rows + '/' + page, {
            params: {
              ...this.state.filters,
              fromDateValue: this.state.fromDateValue ? this.state.fromDateValue : '',
              toDateValue: this.state.toDateValue ? this.state.toDateValue : '',
              search: this.state.searchQuery || undefined
            }
          })
          if (dataRes.data.success) {
            let dataTableData = dataRes.data.data.docs
            this.setState({
              dataTableTotal: dataRes.data.data.totalDocs,
              dataTableData,
              dataTableDataLoading: false
            })
            return resolve(dataTableData)
          }
          reject()
        }
      } catch (e) {
        console.log('error ', e)
        reject()
      }
    })
  }

  async selectedUserID(id) {
    this.setState(
      {
        selectedUserID: id,
        activeTab: '1'
      },
      () => {
        this.loadStats()
        this.loadDataTableData()
        this.loadUserInfo(id)
        this.loadData()
      }
    )
  }

  async loadUserInfo(selectedUserID) {
    try {
      const userRes = await axios.get(`users/${selectedUserID}`)
      this.setState({ selectedUser: userRes.data.data })
    } catch (e) {}
  }

  async editLeave(id, jumpToSpecialMoment = true) {
    var leaves = this.state.leaves
    const schedulerData = this.state.viewModel
    if (this.state.editLeave) {
      leaves = this.state.leaves.map((l) => {
        if (l.id == this.state.backCurrentLeave.id) {
          Object.assign(l, this.state.backCurrentLeave)
        }
        return l
      })
    }

    await this.setState({
      activeTab: '2',
      backCurrentLeave: { ...leaves.filter((l) => l.id == id)[0] },
      categoriesLoading: true
    })

    const setEditLeave = async (event) => {
      event.bgColor = 'purple'
      event.showPopover = false

      if (jumpToSpecialMoment) {
        schedulerData.setScrollToSpecialMoment(true)
      }

      this.setState(
        {
          currentLeave: event,
          editLeave: id,
          dateFrom: moment(event.start),
          dateTo: moment(event.end),
          viewModel: schedulerData,
          otherInfo: event.other_info,
          days: event.days,
          labelStatus: event.labelStatus,
          message: event.message,
          type: event.type,
          selectedUserID: event.user._id,
          returnToWork: moment(event.returnToWork),
          activeTab: '2',
          leaves,
          categoriesLoading: false
        },
        this.loadStats
      )
    }
    let matchLeaves = leaves.filter((l) => l.id == id)
    if (matchLeaves[0]) {
      setEditLeave(matchLeaves[0])
    } else {
      let matchDataTableData = this.state.leaves.filter((l) => l._id == id)
      const myLeaveRes = await axios.get('leaves/' + id)
      if (myLeaveRes.data.success && this._isMounted) {
        matchDataTableData = myLeaveRes.data.data
        if (matchDataTableData) {
          schedulerData.setDate(matchDataTableData.date_from)

          this.setState(
            {
              viewModel: schedulerData,
              dataTableDataLoading: true
            },
            async () => {
              await this.loadData()

              this.setState({
                dataTableDataLoading: false
              })

              const leaveCategoriesRes = await axios.get('leavecategories')
              if (leaveCategoriesRes.data.success) {
                const leaveTypes = leaveCategoriesRes.data.data
                this.setState({
                  leaveTypes
                })
              }

              if (matchDataTableData) {
                let thisData = {
                  id: matchDataTableData._id,
                  start: matchDataTableData.date_from,
                  end: matchDataTableData.date_to,
                  type: matchDataTableData.category,
                  user: matchDataTableData.user_id,
                  other_info: matchDataTableData.other_info,
                  message: matchDataTableData.message,
                  returnToWork: matchDataTableData.return_to_work,
                  days: matchDataTableData.days
                }
                setEditLeave(thisData)
              } else {
                pubsub.publish('showNotification', {
                  message: 'Pushimi nuk u gjet ose është anuluar',
                  type: 'error'
                })
                this.setState(
                  {
                    categoriesLoading: false,
                    dataTableDataLoading: false
                  },
                  () => {
                    this.props.history.push('/manageLeaves')
                  }
                )
              }
            }
          )
        } else {
          pubsub.publish('showNotification', {
            message: 'Pushimi nuk u gjet ose është anuluar',
            type: 'error'
          })
          this.setState(
            {
              categoriesLoading: false,
              dataTableDataLoading: false
            },
            () => {
              this.props.history.push('/manageLeaves')
            }
          )
        }
      } else {
        pubsub.publish('showNotification', {
          message: 'Pushimi nuk u gjet ose është anuluar',
          type: 'error'
        })
        this.setState(
          {
            categoriesLoading: false,
            dataTableDataLoading: false
          },
          () => {
            this.props.history.push('/manageLeaves')
          }
        )
      }
    }
  }

  clearEditLeave() {
    this.setState(
      {
        currentLeave: null,
        editLeave: null,
        selectedUserID: null,
        activeTab: '1'
      },
      this.clearFromAndRefresh
    )
  }

  moveEvent(schedulerData, event, slotId, slotName, start, end) {
    event.title = this.state.leaveTypes.filter((t) => t._id == slotId)[0].name

    schedulerData.moveEvent(event, slotId, slotName, start, end)

    this.setState({
      dateFrom: moment(start),
      dateTo: moment(end),
      viewModel: schedulerData,
      type: slotId,
      activeTab: '2'
    })
  }

  toggleExpandFunc(schedulerData, slotId) {
    schedulerData.toggleExpandStatus(slotId)
    this.setState({
      viewModel: schedulerData
    })
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.showCalendar !== this.state.showCalendar) {
      this.loadData()
    }

    if (prevProps.location.search !== this.props.location.search) {
      const params = new URLSearchParams(this.props.location.search)
      const filters = {
        function: params.get('function') || undefined,
        project: params.get('project') || undefined,
        'department[]': params.getAll('department[]') || undefined,
        activity: params.get('activity') || undefined,
        category: params.get('category') || undefined,
        status: params.get('status') || undefined,
        'languages[]': params.getAll('languages[]') || undefined,
        office_location: params.get('office_location') || undefined
      }
      this.setState({
        filters
      })
    }

    if (
      this.props.match.params.leaveId !== prevProps.match.params.leaveId ||
      this.props.match.params.userId !== prevProps.match.params.userId
    ) {
      const leaveId = this.props.match.params.leaveId
      if (leaveId) {
        this.editLeave(leaveId, this.scrollToSpecialMoment)
        this.scrollToSpecialMoment = true
        return
      } else if (this.state.currentLeave) {
        this.clearFromAndRefresh()
      }

      const userId = this.props.match.params.userId

      if (userId) {
        return this.selectedUserID(userId)
      }

      this.clearEditLeave()
    }
  }

  loadFilter = async (name) => {
    try {
      const response = await axios.get(name)

      if (!response.data.success) {
        console.error('Failed to load filter data')
        return null
      }

      const byName = response.data.data.reduce((acc, element) => {
        acc[name === 'languages' ? element.code : element.name] = element._id
        return acc
      }, {})

      if (this._isMounted) {
        await this.setState((prevState) => ({
          filterOptions: { ...prevState.filterOptions, [name]: byName }
        }))
      }
      return response.data.data
    } catch (error) {
      console.error(`Error loading ${name}:`, error)
      throw error
    }
  }

  loadFilters = async (callback) => {
    try {
      const results = await Promise.all([
        this.loadFilter('projects'),
        this.loadFilter('roles'),
        this.loadFilter('departments'),
        this.loadFilter('activities'),
        this.loadFilter('languages'),
        this.loadFilter('leavecategories'),
        this.loadFilter('holidays')
      ])

      const leaveCategories = results[5]
      const holidays = results[6]
      if (callback) return callback(this.state.filterOptions, { leaveCategories, holidays })
    } catch (error) {
      console.error('Error loading filters:', error)
    }
  }

  async componentDidMount() {
    this._isMounted = true
    const { userId } = this.props.match.params

    if (userId && this._isMounted) {
      await this.setState({ isUserID: true })
    }

    window.addEventListener('resize', this.updateDimensions)
    this.updateDimensions()
    const schedulerData = this.state.viewModel

    const loadFiltersFromUrl = (filterOptions, { leaveCategories, holidays }) => {
      const params = new URLSearchParams(this.props.location.search)
      const getParamNames = (param, options) => {
        const ids = params.getAll(param)
        return ids.map((id) => Object.keys(options).find((key) => options[key] === id) || id)
      }

      const filters = {
        function: params.get('function'),
        project: params.get('project'),
        'department[]': params.getAll('department[]'),
        activity: params.get('activity'),
        category: params.get('category'),
        status: params.get('status'),
        'languages[]': params.getAll('languages[]'),
        office_location: params.get('office_location')
      }

      const filterList = {
        roles: getParamNames('function', filterOptions.roles),
        projects: getParamNames('project', filterOptions.projects),
        departments: getParamNames('department[]', filterOptions.departments),
        activities: getParamNames('activity', filterOptions.activities),
        leavecategories: getParamNames('category', filterOptions.leavecategories),
        status: getParamNames('status', filterOptions.status),
        languages: getParamNames('languages[]', filterOptions.languages),
        office_locations: getParamNames('office_location', filterOptions.office_locations)
      }

      this.setState({
        filters,
        filterList,
        ...(params.get('fromDateValue') && { fromDateValue: params.get('fromDateValue') }),
        ...(params.get('toDateValue') && { toDateValue: params.get('toDateValue') }),
        ...(params.get('search') && { searchQuery: params.get('search') })
      })

      return { leaveCategories, holidays }
    }

    const { leaveCategories, holidays } = await this.loadFilters(loadFiltersFromUrl)
    await this.loadData()
    await this.loadDataTableData()

    try {
      if (leaveCategories.length) {
        const byName = {}
        leaveCategories.forEach((element) => {
          byName[element.name] = element._id
        })
        this.setState({
          leaveTypes: leaveCategories
        })
        if (holidays.length) {
          const holidaysData = holidays.map((h) => ({
            id: h._id,
            start: moment(h.date),
            end: moment(h.date).add(1, 'days'),
            resourceId: 'r0',
            title: h.name,
            showPopover: false,
            bgColor: 'gray'
          }))

          schedulerData.setEvents(holidaysData)

          if (this._isMounted) {
            this.setState({
              categoriesLoading: false,
              filterOptions: { ...this.state.filterOptions, leavecategories: byName },
              viewModel: schedulerData,
              holidaysData,
              leaveCategories
            })
          }

          const leaveId = this.props.match.params.leaveId
          if (leaveId && this._isMounted) {
            return this.editLeave(leaveId)
          }

          if (userId && this._isMounted) {
            return this.selectedUserID(userId)
          }
        }
      }
    } catch (e) {
      console.error('Error fetching additional data:', e)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions() {
    const schedulerData = this.state.viewModel
    schedulerData.config.schedulerWidth = this.divElement.clientWidth - 80

    if (this._isMounted) {
      this.setState({ width: this.divElement.clientWidth, schedulerData })
    }

    if (this._isMounted) {
      setTimeout(() => {
        const schedulerData = this.state.viewModel
        if (!this.divElement) return
        schedulerData.config.schedulerWidth = this.divElement.clientWidth - 80
        this.setState({ width: this.divElement.clientWidth })
      }, 300)
    }
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  clearFromAndRefresh() {
    this.setState(
      {
        currentLeave: null,
        selectedUserID: null,
        editLeave: null,
        dateFrom: null,
        dateTo: null,
        type: '',
        otherInfo: '',
        days: '',
        message: '',
        returnToWork: '',
        formLoading: false
      },
      () => {
        this.loadData()
        this.loadDataTableData()
      }
    )
  }

  async disApprove() {
    try {
      await Swal.fire({
        title: 'A jeni i sigurt?',
        // text: `Nëse fshini ${name}, të gjitha të dhënat do të fshihen!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Po, refuzoje.',
        cancelButtonText: 'Jo.'
      }).then(async (result) => {
        if (result.value) {
          const res = await axios.put(`leaves/approve/${this.state.currentLeave.id}`, {
            status: 'Rejected',
            message: this.state.message || '',
            days: this.state.days
          })
          if (res.data.success) {
            this.setState({
              formLoading: false,
              activeTab: '1'
            })

            this.clearFromAndRefresh()

            pubsub.publish('showNotification', {
              message: 'Pushimi është REFUZUAR!',
              type: 'success'
            })

            this.props.history.push('/manageLeaves')
          } else {
            pubsub.publish('showNotification', {
              message: res.data.message,
              type: 'error'
            })
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Anuluar', 'Të dhënat nuk janë fshirë', 'error')
        }
      })
    } catch (e) {
      pubsub.publish('showNotification', {
        message: e.toString(),
        type: 'error'
      })
    }
  }

  changePage = (dataTablePage) => {
    this.setState(
      {
        dataTablePage
      },
      this.loadDataTableData
    )
  }

  changeRow = (dataTableRowsPerPage) => {
    this.setState(
      {
        dataTableRowsPerPage
      },
      this.loadDataTableData
    )
  }

  changeSearch = (dataTableSearch) => {
    this.setState(
      {
        dataTableSearch
      },
      this.loadDataTableData
    )
  }

  resetFilters() {
    this.setState(
      {
        filters: {
          function: undefined,
          project: undefined,
          'department[]': undefined,
          activity: undefined,
          category: undefined,
          status: undefined,
          'languages[]': undefined,
          office_location: undefined
        },
        filterList: {
          roles: [],
          projects: [],
          departments: [],
          activities: [],
          leavecategories: [],
          status: [],
          languages: [],
          office_locations: []
        },
        fromDateValue: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        toDateValue: moment().add(1, 'months').endOf('month').format('YYYY-MM-DD'),
        searchQuery: ''
      },
      () => {
        this.props.history.push('/manageLeaves')
        this.loadDataTableData()
      }
    )
  }

  changeFilters(tableState) {
    const { filterOptions } = this.state
    const params = {
      function: tableState.filterList[11][0] ? filterOptions['roles'][tableState.filterList[11][0]] : undefined,
      project: tableState.filterList[12][0] ? filterOptions['projects'][tableState.filterList[12][0]] : undefined,
      activity: tableState.filterList[10][0] ? filterOptions['activities'][tableState.filterList[10][0]] : undefined,
      category: tableState.filterList[3][0] ? filterOptions['leavecategories'][tableState.filterList[3][0]] : undefined,
      status: tableState.filterList[8][0] ? filterOptions['status'][tableState.filterList[8][0]] : undefined,
      office_location: tableState.filterList[9][0] ? filterOptions['office_locations'][tableState.filterList[9][0]] : undefined,
      search: this.state.searchQuery || undefined,
      'languages[]': Array.isArray(tableState.filterList[2])
        ? tableState.filterList[2].map((item) => filterOptions['languages'][item])
        : [],
      'department[]': Array.isArray(tableState.filterList[13])
        ? tableState.filterList[13].map((item) => filterOptions['departments'][item])
        : []
    }

    const searchParams = new URLSearchParams(this.props.location.search)
    Object.entries(params).forEach(([key, value]) => {
      if (value == null || value.length === 0) {
        searchParams.delete(key)
        return
      }

      if (Array.isArray(value)) {
        if (key === 'department[]') {
          searchParams.delete('department[]')
          value.forEach((department) => searchParams.append('department[]', department))
        } else {
          searchParams.set(key, value.join(','))
        }
      } else {
        searchParams.set(key, value)
      }
    })

    this.setState(
      {
        filterList: {
          languages: tableState.filterList[2],
          roles: tableState.filterList[11],
          projects: tableState.filterList[12],
          departments: tableState.filterList[13],
          activities: tableState.filterList[10],
          leavecategories: tableState.filterList[3],
          status: tableState.filterList[8],
          office_locations: tableState.filterList[9]
        },
        filters: params
      },
      () => {
        this.props.history.push({
          pathname: this.props.location.pathname,
          search: searchParams.toString()
        })
        this.loadDataTableData()
      }
    )
  }

  onTableChange = (action, tableState) => {
    switch (action) {
      case 'changePage':
        this.changePage(tableState.page)
        break
      case 'changeRowsPerPage':
        this.changeRow(tableState.rowsPerPage)
        break
      case 'resetFilters':
        this.resetFilters(null)
        break
      case 'filterChange':
        this.changeFilters(tableState)
        break
      case 'columnViewChange':
        this.setState({
          showEmail: tableState.columns[1].display == 'true' ? true : false,
          showCreatedDate: tableState.columns[6].display == 'true' ? true : false,
          showPozita: tableState.columns[11].display == 'true' ? true : false,
          showProject: tableState.columns[12].display == 'true' ? true : false,
          showDepartment: tableState.columns[13].display == 'true' ? true : false,
          showActivity: tableState.columns[10].display == 'true' ? true : false,
          showLanguage: tableState.columns[2].display == 'true' ? true : false,
          showLocation: tableState.columns[9].display == 'true' ? true : false
        })
        break
    }
  }

  addLeaveSuccess = () => {
    this.setState({ activeTab: '1' })
    this.loadData()
    this.loadDataTableData()
  }

  onSubmit = async (e) => {
    e.preventDefault()

    try {
      await Swal.fire({
        title: 'A jeni i sigurt?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Po, aprovoje.',
        cancelButtonText: 'Jo.'
      }).then(async (result) => {
        if (result.value) {
          const res = await axios.put(`leaves/approve/${this.state.currentLeave.id}`, {
            status: 'Approved',
            message: this.state.message || '',
            days: this.state.days,
            return_to_work: moment(this.state.returnToWork).format('YYYY-MM-DD')
          })
          if (res.data.success) {
            this.setState({
              formLoading: false,
              activeTab: '1',
              returnToWork: ''
            })

            this.clearFromAndRefresh()

            pubsub.publish('showNotification', {
              message: 'Pushimi është aprovuar!',
              type: 'success'
            })

            this.props.history.push('/manageLeaves')
          } else {
            pubsub.publish('showNotification', {
              message: res.data.message,
              type: 'error'
            })
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Anuluar', 'Të dhënat nuk janë fshirë', 'error')
        }
      })
    } catch (e) {
      pubsub.publish('showNotification', {
        message: e.toString(),
        type: 'error'
      })
    }
  }

  _getUsers = async (e) => {
    this.setState({ isLoading: true })
    const {
      count,
      page,
      functionSelected,
      projectSelected,
      departmentSelected,
      activitySelected,
      searchText,
      languageSelected,
      fromDateValue,
      toDateValue
    } = this.state
    var params = {}
    params['function._id'] = functionSelected ? functionSelected : undefined
    params['project._id'] = projectSelected ? projectSelected : undefined
    params['department[]'] = departmentSelected ? departmentSelected : undefined
    params['languages[]'] = languageSelected ? languageSelected : undefined
    params['activity._id'] = activitySelected ? activitySelected : undefined
    params.search = searchText ? searchText : undefined
    if (fromDateValue.length === 0 && toDateValue.length !== 0) {
      if (toDateValue.length !== 0) {
        params.fromDate = fromDateValue.length !== 0 && !this.state.fromDate ? fromDateValue : undefined
        params.toDate = toDateValue.length !== 0 ? toDateValue : undefined
      } else {
        this.setState({
          toDateValue: ''
        })
        Swal.fire('', 'Gabim në zgjedhjen e datave', 'error')
      }
    } else if (toDateValue.length !== 0) {
      if (fromDateValue.length !== 0 && toDateValue.length !== 0 && fromDateValue < toDateValue) {
        params.fromDate = fromDateValue.length !== 0 && !this.state.fromDate ? fromDateValue : undefined
        params.toDate = toDateValue.length !== 0 ? toDateValue : undefined
      } else if (fromDateValue.length !== 0 && fromDateValue < toDateValue) {
        params.fromDate = fromDateValue.length !== 0 && !this.state.fromDate ? fromDateValue : undefined
        params.toDate = toDateValue.length !== 0 ? toDateValue : undefined
      } else {
        this.setState({
          toDateValue: ''
        })
        Swal.fire('', 'Gabim në zgjedhjen e datave', 'error')
      }
    }

    if (fromDateValue.length !== 0 && toDateValue.length !== 0) {
      if (fromDateValue < toDateValue) {
        params.fromDate = fromDateValue.length !== 0 && !this.state.fromDate ? fromDateValue : undefined
        params.toDate = toDateValue.length !== 0 ? toDateValue : undefined
      } else {
        this.setState({
          toDateValue: ''
        })
        Swal.fire('', 'Gabim në zgjedhjen e datave', 'error')
      }
    } else {
      if (fromDateValue.length !== 0) {
        params.fromDate = fromDateValue.length !== 0 && !this.state.fromDate ? fromDateValue : undefined
      }
    }
    params.fromDate = fromDateValue.length !== 0 && !this.state.fromDate ? fromDateValue : undefined

    if (e) {
      this.setState({
        searchText: e.target.value
      })
      params.search = this.state.searchText

      window.onkeyup = (e) => {
        clearTimeout(timeout)
        if (e.view && e.view.location.pathname === '/users')
          timeout = setTimeout(async () => {
            const users = await axios.get(`users/${count}/${page + 1}`, {
              params
            })
            this.setState({
              isLoading: false,
              getData: users.data.success && users.data.data.docs,
              total: users.data.success && users.data.data.totalDocs
            })
          }, 500)
      }
    } else {
      const users = await axios.get(`users/${count}/${page + 1}`, { params })
      this.setState({
        isLoading: false,
        getData: users.data.success && users.data.data.docs,
        total: users.data.success && users.data.data.totalDocs
      })
    }
  }

  messageChanged = (e) => {
    this.setState({
      message: e.target.value
    })
  }
  returnToWorkChanged = (date) => {
    this.setState({
      returnToWork: date
    })
  }
  daysChanged = (e) => {
    this.setState({
      days: this.state.days + 1
    })
  }

  deleteRejectedLeaves() {
    const schedulerData = this.state.viewModel

    if (this.state.editLeave) {
      const goBack = () => this.props.history.goBack()
      const path = `leaves/${this.state.currentLeave.id}`
      const name = 'Pushimin'

      DeleteDialogBox({ path, name, goBack })
    } else {
      if (this.state.currentLeave) {
        schedulerData.removeEvent(this.state.currentLeave)
        this.clearFromAndRefresh()
      }
    }
  }

  getYear(year) {
    this.setState({ selectedDate: year })
    this.loadStats()
    this.loadData()
    this.loadDataTableData()
  }

  render() {
    const userName = this.props.location.state != undefined && this.props.location.state.userName ? this.props.location.state.userName : ''
    const lastName = this.props.location.state != undefined && this.props.location.state.lastName ? this.props.location.state.lastName : ''
    return (
      <ContentWrapper>
        <div className='content-heading d-flex justify-content-between'>
          <p>
            {this.state.editLeave ? (
              <p>
                <span>Aprovo Pushimin:</span>
                <span>
                  <Link to={`/editUsers/${this.props.match.params.userId}`}>
                    {this.state.currentLeave && ' ' + this.state.currentLeave.user.first_name}{' '}
                    {this.state.currentLeave && this.state.currentLeave.user.last_name}
                  </Link>
                </span>
                <span />
              </p>
            ) : this.state.selectedUserID ? (
              <p>
                <span>Menaxho Pushimet:</span>
                <span>
                  <Link to={`/editUsers/${this.props.match.params.userId}`}>{' ' + userName + ' ' + lastName}</Link>
                </span>
                <span />
              </p>
            ) : (
              // `Menaxho Pushimet: ${" " + this.state.selectedUser && this.state.selectedUser.first_name} ${this.state.selectedUser && this.state.selectedUser.last_name}`
              'Menaxho Pushimet'
            )}
          </p>
          {(this.state.editLeave || this.state.selectedUserID) && (
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>
                Prapa
              </Button>
            </div>
          )}
        </div>
        <div ref={(ref) => (this.divElement = ref)} style={{ opacity: this.state.categoriesLoading ? 0.5 : 1 }}>
          <div role='tabpanel'>
            <Col>
              <Row>
                {(this.state.editLeave || this.state.selectedUserID) && (
                  <LeavesHeader previous={true} onYear={this.getYear} stats={this.state.stats} />
                )}
                <div className='card b w-100'>
                  <div className='card-body text-center'>
                    <Nav tabs fill />
                    <TabContent activeTab={this.state.activeTab}>
                      {this.state.showCalendar ? (
                        <>
                          <div className={this.state.dataLoading ? 'whirl standard' : null}>
                            <Calendar
                              key='calendar'
                              schedulerData={this.state.viewModel}
                              eventItemClick={this.eventClicked}
                              prevClick={this.prevClick}
                              nextClick={this.nextClick}
                              onSelectDate={this.onSelectDate}
                              onViewChange={this.onViewChange}
                              viewEventClick={this.ops1}
                              viewEventText='Më shumë'
                              viewEvent2Text=''
                              viewEvent2Click={this.ops2}
                              toggleExpandFunc={this.toggleExpandFunc}
                              holidays={this.state.holidays}
                              leaveTypes={this.state.leaveTypes}
                              leaves={this.state.leaves}
                              currentLeave={this.state.currentLeave}
                              dateFrom={this.state.dateFrom}
                              dateTo={this.state.dateTo}
                              type={this.state.type}
                              width={this.state.width}
                            />
                          </div>

                          <div className='card b mb-3'>
                            <CardHeader onClick={this.toggleLegend}>
                              <div className='d-flex justify-content-between'>
                                <span color='info' className='btn-labeled'>
                                  <span>Legjenda</span>
                                </span>
                                <div>
                                  <i className='fas fa-angle-down' />
                                </div>
                              </div>
                            </CardHeader>
                            <Collapse isOpen={this.state.collapse}>
                              <CardBody>
                                <Col>
                                  <span style={{ marginRight: 8 }} className='badge badge-success'>
                                    Pushimi i aprovuar
                                  </span>
                                  <span style={{ marginRight: 8 }} className='badge badge-danger'>
                                    Pushimi i refuzuar
                                  </span>
                                  <span style={{ marginRight: 8 }} className='badge bg-yellow'>
                                    Pushimi eshte ne procesim
                                  </span>
                                  <span style={{ marginRight: 8 }} className='badge badge-warning'>
                                    Pushimi pjeserisht i aprovuar
                                  </span>
                                  <span
                                    style={{
                                      marginRight: 8,
                                      backgroundColor: 'rgb(128,128,128)',
                                      color: 'white'
                                    }}
                                    className='badge'
                                  >
                                    Pushimet e festave
                                  </span>
                                  <span
                                    style={{
                                      marginRight: 8,
                                      backgroundColor: 'rgb(123,2,134)',
                                      color: 'white'
                                    }}
                                    className='badge'
                                  >
                                    Pushimi qe jeni duke aplikuar
                                  </span>
                                </Col>
                              </CardBody>
                            </Collapse>
                          </div>
                        </>
                      ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                          <Button
                            onClick={() =>
                              this.setState({
                                showCalendar: true
                              })
                            }
                          >
                            Shfaq Kalendarin
                          </Button>
                        </Box>
                      )}
                      <TabPane tabId='1'>
                        <LeavesDataTable thisObject={this} />
                      </TabPane>
                      <TabPane tabId='2'>
                        <div className={this.state.formLoading ? 'whirl standard' : null}>
                          <ApproveLeaveForm
                            days={this.state.days}
                            daysChanged={this.daysChanged}
                            userLevel={this.props.userStore.userLevel}
                            delete={this.deleteRejectedLeaves}
                            leaveTypes={this.state.leaveTypes}
                            type={this.state.type}
                            dateFrom={this.state.dateFrom}
                            dateTo={this.state.dateTo}
                            otherInfo={this.state.otherInfo}
                            onSubmit={this.onSubmit}
                            disApprove={this.disApprove}
                            message={this.state.message}
                            messageChanged={this.messageChanged}
                            returnToWork={this.state.returnToWork}
                            returnToWorkChanged={this.returnToWorkChanged}
                            labelStatus={this.state.labelStatus}
                          />
                        </div>
                      </TabPane>
                      <TabPane tabId='3'>
                        <div className={this.state.formLoading ? 'whirl standard' : null}>
                          <LeaveForm
                            status={this.state.status}
                            hasDeleteButton={false}
                            user_id={this.state.selectedUserID}
                            title={`Shto pushimin për ${userName} ${lastName}`}
                            leaveTypes={this.state.leaveTypes}
                            onSuccess={this.addLeaveSuccess}
                          />
                        </div>
                      </TabPane>
                    </TabContent>
                    {(this.props.userStore.userLevel == 1 ||
                      this.props.userStore.userLevel == 5 ||
                      this.props.userStore.userLevel == 6 ||
                      this.props.userStore.userLevel == 8) &&
                      this.state.selectedUserID &&
                      !this.state.editLeave && (
                        <Fragment>
                          <br />
                          {this.state.activeTab === '3' ? (
                            <Button
                              outline
                              className='mb-1'
                              color='warning'
                              type='button'
                              onClick={() => {
                                this.setState({ activeTab: '1' })
                              }}
                            >
                              <span>Kthehu</span>
                            </Button>
                          ) : (
                            <Button
                              outline
                              className='mb-1'
                              color='warning'
                              type='button'
                              onClick={() => {
                                this.setState({ activeTab: '3' })
                              }}
                            >
                              <span>Shto Pushim</span>
                            </Button>
                          )}
                        </Fragment>
                      )}
                  </div>
                </div>
              </Row>
            </Col>
          </div>
        </div>
      </ContentWrapper>
    )
  }
}

export default withRouter(ManageLeaves)
