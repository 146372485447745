import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import axios from 'axios'
import { inject } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/sq'
import pubsub from 'pubsub-js'
import React from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import userStore from '../../stores/user.store'

@inject('userStore')
class UserNotes extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      error: null,
      dropdownOpen: {},
      usernotes: [],
      newNote: '',
      open: false
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    })
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`usernotes/${this.props.userId}`)
      if (response.data.success) {
        this.setState({
          usernotes: response.data.data,
          loading: false
        })
      } else {
        this.setState({
          error: response.data.message,
          loading: false
        })
      }
    } catch (e) {
      this.setState({
        loading: false
      })
    }
  }

  onChangeNote = (e) => {
    this.setState({
      newNote: e.target.value
    })
  }

  dropdownToggle = (index) => {
    this.setState({
      dropdownOpen: { [index]: !this.state.dropdownOpen[index] }
    })
  }

  deleteNote = async (noteId) => {
    try {
      const response = await axios.post(`usernotes/${this.props.userId}/${noteId}`)
      if (response.data.success) {
        this.setState({
          loading: false,
          usernotes: this.state.usernotes.filter((note) => {
            return note._id != noteId
          })
        })
      } else {
        this.setState({
          error: response.data.message,
          loading: false
        })
      }
    } catch (e) {
      this.setState({
        loading: false
      })
    }
  }
  cancelLastTransfer = async () => {
    try {
      const res = await axios.post(`/users/cancel-last-transfer/${this.props.userId}`)

      if (res.data.success) {
        this.props.onAnuloTransferClick()
      } else {
        this.setState({
          error: res.data.message,
          loading: false
        })
      }
    } catch (e) {
      this.setState({
        loading: false
      })
    }
  }

  saveNote = async (e) => {
    e.preventDefault()
    if (this.state.newNote != '') {
      this.setState({
        loading: true
      })
      try {
        const response = await axios.post(`usernotes/${this.props.userId}`, {
          note: this.state.newNote
        })
        if (response.data.success) {
          this.setState({
            loading: false,
            newNote: '',
            usernotes: [...this.state.usernotes, response.data.data]
          })
        } else {
          this.setState({
            loading: false
          })
          pubsub.publish('showNotification', {
            message: response.data.message,
            type: 'error'
          })
        }
      } catch (e) {
        pubsub.publish('showNotification', {
          message: 'Gabim gjatë ruajtjes së të dhënave',
          type: 'error'
        })
      }
    }
  }

  render() {
    return (
      <div className={`${this.state.loading && 'whirl standard'}`}>

        <Dialog open={this.state.open} keepMounted onClose={this.handleClose} aria-describedby='alert-dialog-slide-description'>
          <DialogTitle>{'A jeni i sigurtë që dëshironi ta anuloni transferin e fundit?'}</DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
            <Button onClick={this.handleClose} variant='contained' sx={{ fontSize: '12px' }}>
              Jo, kthehu prapa
            </Button>
            <Button
              onClick={() => {
                this.cancelLastTransfer()
                this.handleClose()
                this.props.onAnuloTransferClick()
              }}
              variant='contained'
              color='error'
              sx={{ fontSize: '12px' }}
            >
              Po anuloje!
            </Button>
          </DialogActions>
        </Dialog>

        {this.state.error && <p className='text-center text-danger'>{this.state.error}</p>}
        {this.state.usernotes.map((note, index) => {
          return (
            <div key={index} className='media mb-2'>
              <img className='mr-2 rounded-circle thumb32' src={'/img/blank_profile.png'} />
              <div className='media-body'>
                <span>
                  <p className='m-0'>
                    <strong>{`${note.created_by?.first_name} ${note.created_by.last_name}`}</strong>
                  </p>
                  <p className='m-0 text-muted'>{note.note}</p>
                </span>
              </div>
              <small className='ml-auto text-muted'>{moment(note.created_date).fromNow()}</small>
              {note.created_by._id == this.props.userStore.user_id && (
                <div className='float-right'>
                  <Dropdown
                    isOpen={this.state.dropdownOpen[index] ? this.state.dropdownOpen[index] : false}
                    toggle={() => {
                      this.dropdownToggle(index)
                    }}
                  >
                    <DropdownToggle className='btn-xs' color='link'>
                      <em className='fa fa-ellipsis-v fa-lg text-muted' />
                    </DropdownToggle>
                    <DropdownMenu className='dropdown-menu-right-forced animated fadeInLeft'>
                      <DropdownItem
                        className='cursor-pointer'
                        onClick={() => {
                          this.deleteNote(note._id)
                        }}
                      >
                        <span>Fshij komentin</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              )}
            </div>
          )
        })}
        <div className='card-body'>
          <form className='mb-2 mt-2' onSubmit={this.saveNote} action=''>
            <input
              type='text'
              value={this.state.newNote}
              onChange={this.onChangeNote}
              className='form-control no-resize'
              placeholder='Shkruaj shënim'
              rows='1'
            />

            <div className='clearfix' style={{ marginTop: 10 }}>
              <div className='float-right'>
                <button className='btn btn-info btn-sm' type='submit'>
                  Ruaj
                </button>
              </div>
            </div>
          </form>
          {this.props.transfers && this.props.transfers?.[0]?.from_department?.length > 0 ? (
            <div>
              <h4 style={{ marginBottom: '20px' }}>Transferet</h4>
              {this.props.transfers.map((oneTransfer) => {
                return (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        border: '1px solid #e4eaec',
                        borderRadius: '5px',
                        padding: '10px 10px 0 10px',
                        marginBottom: '20px'
                      }}
                    >
                      <div style={{ width: '24%', overflowY: 'auto' }}>
                        <strong style={{ fontSize: '16px' }}>Nga: </strong>
                        <p>
                          {oneTransfer.from_department.length
                            ? oneTransfer.from_department?.map((oneDep, i) => {
                                return `${oneDep.name}${
                                  oneTransfer.from_department.length === 1 || i === oneTransfer.from_department.length - 1 ? '' : ', '
                                }`
                              })
                            : oneTransfer.from_department.name}
                        </p>
                      </div>
                      <div style={{ width: '24%', overflowY: 'auto' }}>
                        <strong style={{ fontSize: '16px' }}>Tek: </strong>
                        <p>
                          {oneTransfer?.to_department?.length
                            ? oneTransfer?.to_department?.map((oneDep, i) => {
                                return `${oneDep.name}${
                                  oneTransfer.to_department.length === 1 || i === oneTransfer.to_department.length - 1 ? '' : ', '
                                }`
                              })
                            : oneTransfer?.to_department?.name}
                        </p>
                      </div>
                      <div style={{ width: '20%', overflowY: 'auto' }}>
                        <strong style={{ fontSize: '16px' }}>Data: </strong>
                        <p>{moment(oneTransfer?.start_date).format('LL')}</p>
                      </div>
                      <div style={{ maxWidth: '32%', overflowY: 'auto' }}>
                        <strong style={{ fontSize: '16px' }}>Ndryshuar nga: </strong>
                        <p>
                          {oneTransfer.changed_by?.first_name} {oneTransfer.changed_by?.last_name}{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
              {[1, 5, 6].includes(userStore?.userLevel) && (
                <button
                  onClick={() => {
                    this.handleClickOpen()
                  }}
                  className='btn btn-danger btn-outline btn-small'
                  type=''
                >
                  <p className='m-0 p-0'>Anulo transferin e fundit</p>
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default UserNotes
