export const columns = [
  {
    name: 'Emri dhe Mbiemri',
    label: 'Emri dhe Mbiemri',
    options: { sort: false }
  },
  {
    name: 'Email',
    label: 'Email',
    options: { sort: false }
  },
  {
    name: 'Nr Personal',
    label: 'Nr Personal',
    options: { sort: false }
  },
  {
    name: 'Lloji i doreheqjes',
    label: 'Lloji i doreheqjes',
    options: { sort: false }
  },
  {
    name: 'Data e krijimit',
    label: 'Data e krijimit',
    options: { sort: false }
  },
  {
    name: 'Data e doreheqjes',
    label: 'Data e doreheqjes',
    options: { sort: false }
  },
  {
    name: 'Data e fundit si punetor',
    label: 'Data e fundit si punetor',
    options: { sort: false }
  },
  {
    name: 'Detajet',
    label: 'Detajet',
    options: {
      sort: false
    }
  }
]
