import {
  Button,
  Grid,
  Card,
  CardContent,
  FormControl,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@mui/material'
import TextField from '@mui/material/TextField'
import DatePickerFilter from '../filters/datePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Locale from 'date-fns/locale/sq'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { createMozoOrders, updateMozoOrder, deleteMozoOrder } from '../../API/endpoints'
import { useHistory } from 'react-router'

const MobileZoneForm = ({ editData = [], edit = false, accessToDelete = false }) => {
  const queryClient = useQueryClient()
  const history = useHistory()

  const [orderId, setOrderId] = useState(editData?.order_id || '')
  const [productName, setProductName] = useState(editData?.product_name || '')
  const [additionalProduct, setAdditionalProduct] = useState(editData?.additional_product || '')
  const [createdDate, setCreatedDate] = useState(editData?.created_date || '')
  const [activationDate, setActivationDate] = useState(editData?.activation_date || '')
  const [open, setOpen] = useState(false)

  const [requiredInput, setRequiredInput] = useState({
    orderId: false,
    productName: false,
    createdDate: false,
    activationDate: false
  })

  const createMozoOrderMutation = useMutation({
    mutationFn: createMozoOrders,
    onSuccess: (data) => {
      queryClient.setQueryData(['myMozoOrder, mozoOrder'], data)
      queryClient.invalidateQueries(['myMozoOrder'])

      setOrderId('')
      setProductName('')
      setAdditionalProduct('')
      setActivationDate()
      setCreatedDate()
    }
  })

  const updateMozoOrderMutation = useMutation({
    mutationFn: updateMozoOrder,
    onSuccess: (data) => {
      queryClient.setQueryData(['myMozoOrder', 'mozoOrder', 'oneMozoOrder'], data)
      queryClient.invalidateQueries(['myMozoOrder'])
      queryClient.invalidateQueries(['mozoOrder'])
      queryClient.invalidateQueries(['oneMozoOrder'])
    }
  })

  const deleteOrderMozo = useMutation({
    mutationFn: deleteMozoOrder,
    onSuccess: (data) => {
      queryClient.setQueryData(['myMozoOrder', 'mozoOrder'], data)
      queryClient.invalidateQueries(['myMozoOrder'])
      queryClient.invalidateQueries(['mozoOrder'])
      history.goBack()
    }
  })

  const handleSubmit = () => {
    let isValid = true
    if (!orderId) {
      isValid = false
      setRequiredInput((prev) => ({ ...prev, orderId: true }))
    }
    if (!productName) {
      isValid = false
      setRequiredInput((prev) => ({ ...prev, productName: true }))
    }
    if (!createdDate) {
      isValid = false
      setRequiredInput((prev) => ({ ...prev, createdDate: true }))
    }
    if (!activationDate) {
      isValid = false
      setRequiredInput((prev) => ({ ...prev, activationDate: true }))
    }
    if (isValid) {
      const body = {
        order_id: orderId,
        product_name: productName,
        additional_product: additionalProduct,
        created_date: createdDate,
        activation_date: activationDate
      }
      edit ? updateMozoOrderMutation.mutate({ body, id: editData?._id }) : createMozoOrderMutation.mutate({ body })
    }
  }
  const deleteOrder = () => {
    deleteOrderMozo.mutate({ id: editData?._id })
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
      {createMozoOrderMutation.isError && JSON.stringify(createMozoOrderMutation.error)}
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Confirm to delete this order?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>Are you sure that you want to delete this order?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={deleteOrder} disabled={deleteOrderMozo.isLoading} autoFocus variant='contained' color='error'>
            {deleteOrderMozo.isLoading ? 'Loading...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
      <Card
        variant='outlined'
        sx={{
          pt: 2,
          fieldset: {
            borderBottom: '1px solid rgba(0,0,0,0.23)'
          }
        }}
      >
        <Grid
          container
          sx={{
            fieldset: {
              borderBottom: '1px solid rgba(0,0,0,0.23)'
            }
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <CardContent>
              {/* <Typography>Add new Order</Typography> */}
              <FormControl size='small' sx={{ m: 0, width: '100%' }}>
                <TextField
                  error={requiredInput.orderId}
                  size='small'
                  onChange={(e) => {
                    setOrderId(e.target.value)
                    setRequiredInput((prev) => ({ ...prev, orderId: e.target.value === '' }))
                  }}
                  value={orderId}
                  fullWidth
                  id='outlined-error-helper-text'
                  label='Order Id'
                  sx={{
                    '.MuiInputBase-input': {
                      padding: '11px 14px !important'
                    }
                  }}
                  helperText={requiredInput.orderId ? 'Required field' : ''}
                />
              </FormControl>
            </CardContent>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <CardContent>
              <FormControl size='small' sx={{ m: 0, width: '100%' }}>
                <TextField
                  error={requiredInput.productName}
                  size='small'
                  onChange={(e) => {
                    setProductName(e.target.value)
                    setRequiredInput((prev) => ({ ...prev, productName: e.target.value === '' }))
                  }}
                  sx={{
                    '.MuiInputBase-input': {
                      padding: '11px 14px !important'
                    }
                  }}
                  fullWidth
                  value={productName}
                  id='outlined-error-helper-text'
                  label='Product Name'
                  helperText={requiredInput.productName ? 'Required field' : ''}
                />
              </FormControl>
            </CardContent>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <CardContent>
              <FormControl size='small' sx={{ m: 0, width: '100%' }}>
                <TextField
                  onChange={(e) => setAdditionalProduct(e.target.value)}
                  size='small'
                  fullWidth
                  sx={{
                    '.MuiInputBase-input': {
                      padding: '11px 14px !important'
                    }
                  }}
                  value={additionalProduct}
                  id='outlined-error-helper-text'
                  label='Additional Product'
                />
              </FormControl>
            </CardContent>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <CardContent>
              <DatePickerFilter
                date={createdDate}
                setDate={(date) => {
                  setCreatedDate(date)
                  setRequiredInput((prev) => ({ ...prev, createdDate: !date }))
                }}
                label={'Creation Date'}
                error={requiredInput.createdDate}
                helperText={requiredInput.createdDate ? 'Required field' : ''}
              />
            </CardContent>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <CardContent>
              <DatePickerFilter
                date={activationDate}
                setDate={(date) => {
                  setActivationDate(date)
                  setRequiredInput((prev) => ({ ...prev, activationDate: !date }))
                }}
                label={'Activation Date'}
                error={requiredInput.activationDate}
                helperText={requiredInput.activationDate ? 'Required field' : ''}
              />
            </CardContent>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <CardContent sx={{ display: 'flex', justifyContent: 'start' }}>
              <Button
                onClick={handleSubmit}
                disabled={createMozoOrderMutation.isLoading}
                variant='contained'
                color='success'
                sx={{
                  padding: '6px 16px !important',
                  backgroundColor: '#2e7d32 !important',
                  borderRadius: '4px !important',
                  marginRight: '20px',
                  color: 'white !important',
                  transition: 'all .3s ease',
                  '&:hover': {
                    opacity: '0.80 !important'
                  }
                }}
              >
                {createMozoOrderMutation.isLoading || updateMozoOrderMutation.isLoading ? 'Loading...' : !edit ? 'Submit' : 'Edit'}
              </Button>
              {edit && accessToDelete && (
                <Button
                  onClick={() => setOpen(true)}
                  sx={{
                    transition: 'all .3s ease',
                    '&:hover': {
                      opacity: '0.80 !important'
                    }
                  }}
                  variant='contained'
                  color='error'
                >
                  Delete
                </Button>
              )}
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </LocalizationProvider>
  )
}

export default MobileZoneForm
