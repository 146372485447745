import ContentWrapper from '../../../components/Layout/ContentWrapper'
import BadgesTable from '../components/BadgesTable/badgesTable'

const Badges = () => {
  return (
    <ContentWrapper>
      <div className='content-heading'>
        <div>Përdoruesit e ri</div>
      </div>
      <BadgesTable type='noBadge' />
      <BadgesTable type='haveBadge' />
    </ContentWrapper>
  )
}

export default Badges
