export const statusEnumSQ = {
  all: 'Të gjithë',
  new: 'Aplikues',
  low: 'Dobët',
  spxedu: 'Speeex Education',
  testing: 'Testim',
  waiting_list: 'Lista e pritjes',
  recruited: 'Rekrutim',
  declined: 'Refuzues',
  blacklist: 'Lista e zezë'
}
