import AccountBoxIcon from '@material-ui/icons/AccountBox'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import BallotIcon from '@material-ui/icons/Ballot'
import CancelIcon from '@material-ui/icons/Cancel'
import {
  Avatar,
  AvatarGroup,
  Box,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
  linearProgressClasses,
  styled
} from '@mui/material'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { week_days } from '../../utils'
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#4CAF50' : '#308fe8'
  }
}))

const TaskCard = ({ tasksReportData, progressBar = false, completed = false, date, report = false, dep = false }) => {
  if (tasksReportData.isError) return <pre>{JSON.stringify(tasksReportData.error)}</pre>

  const maxVisible = 4

  return tasksReportData.isLoading ? (
    <Box sx={{ textAlign: 'center' }}>
      <CircularProgress sx={{ marginX: 'auto' }} />
    </Box>
  ) : (
    <Grid container spacing={4}>
      {tasksReportData.data.data.map((taskData1, index) => {
        const taskData = progressBar ? taskData1.taskData : taskData1
        const completedTask = progressBar ? taskData1.completed : []
        const unCompletedTask = progressBar ? taskData1.uncompleted : []
        const users = taskData?.assigned_to
        const visibleUsers = users?.slice(0, maxVisible)
        const overflowCount = users?.length - maxVisible
        const totalTasks = taskData?.assigned_to
        const percentage = (completedTask?.length / totalTasks?.length) * 100

        return (
          <Grid item key={index} xs={12} sm={12} md={6} lg={4} xl={3}>
            <Link
              style={{ color: 'black', textDecoration: 'none' }}
              to={report ? `/tasks-reports/task/${taskData?._id}?date=${date}` : `/manage-tasks/${taskData?._id}`}
            >
              <Card sx={{ border: 0, borderColor: 'fff', borderStyle: 'fff' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                    <Tooltip key={taskData._id} title={taskData?.name} placement='bottom'>
                      <Typography variant='h5' component='h2'>
                        {taskData?.name.slice(0, 20)}
                      </Typography>
                    </Tooltip>
                    <Tooltip title={taskData.assignment_type === 'per_user' ? 'User' : 'Të gjithë përdoruesit'} placement='bottom'>
                      {taskData.assignment_type === 'per_user' ? (
                        <AccountBoxIcon style={{ fill: '#707173' }} />
                      ) : (
                        <BallotIcon style={{ fill: '#707173' }} />
                      )}
                    </Tooltip>
                  </Box>

                  <Tooltip
                    key={Math.random(4)}
                    title={
                      taskData.repetition
                        ? taskData.repetition?.map((item) => week_days.find((i) => i.value === item)?.name).join(', ')
                        : moment(taskData.due_date).format('DD/MM/YYYY')
                    }
                    placement='bottom'
                  >
                    <Chip
                      key={Math.random(4)}
                      variant='outlined'
                      style={{ color: '#707173', fontWeight: 'bold', fontSize: '12px' }}
                      size='small'
                      label={taskData.task_type === 'repetitive' ? 'E përsëritshme' : 'E njëherëshme'}
                    />
                  </Tooltip>
                  {taskData?.due_date && (
                    <Chip
                      key={Math.random(4)}
                      variant='outlined'
                      style={{ color: '#707173', fontWeight: 'bold', fontSize: '12px', marginLeft: '10px' }}
                      size='small'
                      label={moment(taskData?.due_date).format('DD/MM/YYYY')}
                    />
                  )}
                  {taskData?.due_time && (
                    <Chip
                      key={Math.random(4)}
                      variant='outlined'
                      style={{ color: '#707173', fontWeight: 'bold', fontSize: '12px', marginLeft: '10px' }}
                      size='small'
                      label={`${taskData.due_time?.hours} : ${taskData?.due_time?.minutes}`}
                    />
                  )}
                  <Tooltip key={taskData._id} title={taskData.description} placement='bottom'>
                    <Typography variant='body2' component='p' sx={{ marginTop: '15px' }}>
                      {taskData.description.slice(0, 35)}
                    </Typography>
                  </Tooltip>
                </CardContent>
                {progressBar && (
                  <Box sx={{ display: 'flex', alignItems: 'center', paddingX: '16px' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <BorderLinearProgress variant='determinate' value={percentage} />
                    </Box>
                    <Box>
                      <Typography variant='body2' color='text.secondary'>{`${Math.round(percentage)}%`}</Typography>
                    </Box>
                  </Box>
                )}
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <Typography variant='body2' component='p' gutterBottom>
                        Deleguar tek:
                      </Typography>
                      <AvatarGroup>
                        {visibleUsers?.map((user, index) => (
                          <Tooltip key={index} title={user.first_name + ' ' + user.last_name} placement='bottom'>
                            <Avatar sx={{ width: 25, height: 25 }} key={index}>
                              {user.first_name.charAt(0)}
                            </Avatar>
                          </Tooltip>
                        ))}
                        {overflowCount > 0 && (
                          <Tooltip
                            title={users.slice(maxVisible).map((user, index) => (
                              <div key={index}>{`${user.first_name} ${user.last_name}`}</div>
                            ))}
                            placement='bottom'
                          >
                            <Avatar sx={{ width: 25, height: 25 }}>+{overflowCount}</Avatar>
                          </Tooltip>
                        )}
                      </AvatarGroup>
                    </Box>
                  </Box>
                  {completed && (
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                      <div style={{ textAlign: 'center' }}>
                        <Tooltip
                          title={completedTask.map((user, index) => (
                            <div key={index}>{`${user.first_name} ${user.last_name}`}</div>
                          ))}
                          placement='bottom'
                        >
                          <AssignmentTurnedInIcon style={{ fill: '#4CAF50' }} />
                          <Typography variant='body2' component='p' color='textSecondary'>
                            {completedTask.length || 0} Kompletuara
                          </Typography>
                        </Tooltip>
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <Tooltip
                          title={unCompletedTask.map((user, index) => (
                            <div key={index}>{`${user.first_name} ${user.last_name}`}</div>
                          ))}
                          placement='bottom'
                        >
                          <CancelIcon style={{ fill: '#FF010199' }} />
                          <Typography variant='body2' component='p' color='textSecondary'>
                            {unCompletedTask.length || 0} Pa kompletuara
                          </Typography>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </CardContent>
              </Card>
            </Link>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default TaskCard
