import axios from 'axios'
import { inject } from 'mobx-react'
import moment from 'moment'
import { Component } from 'react'
import Datetime from 'react-datetime'
import { Col, Row } from 'reactstrap'
import { Area, AreaChart, Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from 'recharts'
import DateTimeBoostrap from '../../components/Forms/DateTimeBootstrap'
import Now from '../Common/Now'
import ContentWrapper from '../Layout/ContentWrapper'
import '../Panel/panel.css'
import PieCustomized from './ActiveShapePieChart'
import PieCustomizedFill from './FillCustomPieChart'

var disable = Datetime.moment().subtract(1, 'day')

@inject('userStore')
class Panel extends Component {
  constructor() {
    super()
    this.state = {
      firstDose: 0,
      secondDose: 0,
      refuse: 0,
      pending: 0,
      InfectedIn2020: 0,
      InfectedIn2021: 0,
      activeUsers: 0,
      inactiveUsers: 0,
      newUsers: 0,
      exitUsers: 0,
      nrOfFeeds: 0,
      dataDepartment: [],
      dataProject: [],
      dataLang: [],
      dropdownOpen: false,
      dataLogs: [],
      dataLevels: [],
      dataCities: [],
      selectedDate: new Date(),
      selectedYear: new Date(),
      COLORS: [
        '#204051',
        '#3b6978',
        '#84a9ac',
        '#cae8d5',
        '#a4c5c6',
        '#641E16',
        '#76D7C4',
        '#16A085',
        '#0E6655',
        '#FF6633',
        '#FFB399',
        '#E6B333',
        '#3366E6',
        '#999966',
        '#B34D4D',
        '#80B300',
        '#E6B3B3',
        '#6680B3',
        '#66991A',
        '#5B5EA6',
        '#FF99E6',
        '#CCFF1A',
        '#FF1A66',
        '#E6331A',
        '#33FFCC',
        '#66994D',
        '#B366CC',
        '#4D8000',
        '#B33300',
        '#CC80CC',
        '#66664D',
        '#991AFF',
        '#E666FF',
        '#4DB3FF',
        '#1AB399',
        '#E666B3',
        '#33991A',
        '#CC9999',
        '#B3B31A',
        '#00E680',
        '#4D8066',
        '#809980',
        '#E6FF80',
        '#1AFF33',
        '#999933',
        '#FF3380',
        '#CCCC00',
        '#66E64D',
        '#4D80CC',
        '#9900B3',
        '#E64D66',
        '#4DB380',
        '#FF4D4D',
        '#99E6E6',
        '#6666FF'
      ]
    }
    this._getData = this._getData.bind(this)
    this._onDateChange = this._onDateChange.bind(this)
    this._onYearChange = this._onYearChange.bind(this)
    this._dateFunctionFiltered = this._dateFunctionFiltered.bind(this)
    this._yearFunctionFiltered = this._yearFunctionFiltered.bind(this)
  }

  async _getData() {
    var params = {}
    axios
      .get(`/dashboard/boxes`)
      .then(async (boxesData) => {
        if (boxesData.data.success) {
          await this.setState({
            activeUsers: boxesData.data.data.boxData.activeUsers,
            inactiveUsers: boxesData.data.data.boxData.inActiveUsers,
            nrOfFeeds: boxesData.data.data.boxData.numberOfFeeds
          })
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
    axios
      .get(`/dashboard/pies`)
      .then(async (piesData) => {
        if (piesData.data.success) {
          let dataDepartment = this.handlePieData('department', piesData.data.data.pieData)
          this.setState({
            dataDepartment
          })
          let dataProject = this.handlePieData('project', piesData.data.data.pieData)
          await this.setState({
            dataProject
          })
        }
      })
      .catch((error) => {
        console.log('error', error)
      })

    axios
      .get(`/dashboard/languages`)
      .then(async (langData) => {
        if (langData.data.success) {
          let dataLang = []
          let { languageData } = langData.data.data
          for (const lang in languageData) {
            let i = 0
            let tempColor = this.state.COLORS[i]
            this.state.COLORS.splice(i, 1)
            dataLang.push({
              name: lang,
              'Numri i punëtorëve': languageData[lang],
              fill: tempColor
            })
          }

          await this.setState({
            dataLang
          })
        }
      })
      .catch((error) => {
        console.log('error', error)
      })

    axios
      .get(`/dashboard/userlevels`)
      .then(async (usersData) => {
        if (usersData.data.success) {
          await this.setState({
            dataLevels: usersData.data.data.levels
          })
        }
      })
      .catch((error) => {
        console.log('error', error)
      })

    axios
      .get(`/dashboard/usercities`)
      .then(async (usersCities) => {
        if (usersCities.data.success) {
          await this.setState({
            dataCities: usersCities.data.data.cities
          })
        }
      })
      .catch((error) => {
        console.log('error', error)
      })

    this._dateFunctionFiltered()

    if (this.props.userStore.userLevel == 1 || this.props.userStore.userLevel == 5) {
      this._yearFunctionFiltered()
    }
  }

  async _dateFunctionFiltered() {
    const date = moment(this.state.selectedDate).format('YYYY-MM-DD')
    var params = {}
    try {
      params['day'] = date
      axios.get(`dashboard/logs`, { params }).then(async (logsData) => {
        if (logsData.data.success) {
          let data = []
          for (const logs in logsData.data.data.logsData) {
            data.push({
              name: logs,
              zgjedhur: logsData.data.data.logsData[logs],
              sotshme: this.state.todayDataLogs ? this.state.todayDataLogs[logs] : []
            })
          }
          await this.setState({
            dataLogs: data
          })
        }
      })
    } catch (e) {
      console.log('error', e)
    }
  }

  async _onDateChange(date) {
    if (typeof date === 'object') {
      await this.setState({ selectedDate: date })
      await this._dateFunctionFiltered()
    }
  }

  async _onYearChange(year) {
    // if (typeof year === "object") {
    //   await this.setState({ selectedYear: year });
    //   await this._yearFunctionFiltered();
    // }

    await this.setState({ selectedYear: year })
    await this._yearFunctionFiltered()
  }

  async _yearFunctionFiltered() {
    const date = moment(this.state.selectedYear).format('YYYY')

    var params = {}
    params['year'] = date
    try {
      axios.get(`dashboard/userInfo`, { params }).then(async (logsData) => {
        let res = logsData.data.data
        if (logsData.data.success) {
          this.setState({
            newUsers: res.userStats.newUsers,
            exitUsers: res.userStats.goneUsers
          })
        }
      })
    } catch (e) {
      console.log('error', e)
    }
  }

  handlePieData(model, pieData) {
    let data01 = []

    if (model === 'project') {
      let { projectData } = pieData
      for (const project in projectData) {
        data01.push({ name: project, value: projectData[project] })
      }
    } else {
      let { departmentData } = pieData
      for (const department in departmentData) {
        data01.push({ name: department, value: departmentData[department] })
      }
    }
    return data01
  }

  messageCrop(text) {
    var limit = 18
    var trimmedString = text.length > limit ? text.slice(0, limit - 3) + '...' : text
    return trimmedString
  }

  CustomizedAxisTick = (props) => {
    const { x, y, payload } = props
    return (
      <g transform={`translate(${x} ${y})`}>
        <Text angle={-45} dy={5} fontSize={'12px'} textAnchor='end' fill='#666'>
          {this.messageCrop(payload.value)}
        </Text>
      </g>
    )
  }

  onSectorClick = (index, data) => {
    this.setState({
      color: '#768294'
    })
  }

  componentDidMount() {
    const today = moment(new Date()).format('YYYY-MM-DD')
    var params = {}
    try {
      params['startDate'] = today
      axios.get(`dashboard/logs`, { params }).then((logsData) => {
        if (logsData.data.success) {
          let data = []
          data = logsData.data.data.logsData
          this.setState({
            todayDataLogs: data
          })
        }
      })
    } catch (e) {
      console.log('error', e)
    }
    this._getData()
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  render() {
    return (
      <ContentWrapper>
        <div className='content-heading'>
          <div>Paneli</div>
        </div>
        {/* START cards box */}
        <Row>
          <Col xl={3} md={6}>
            {/* START card */}
            <div className='card flex-row align-items-center align-items-stretch border-0'>
              <div
                className='col-4 d-flex align-items-center justify-content-center rounded-left'
                style={{ backgroundColor: '#d27e5acc', color: 'white' }}
              >
                <em className='gg-check-o float-right'></em>
              </div>
              <div className='col-8 py-3 rounded-right' style={{ backgroundColor: '#db987bd1', color: 'white' }}>
                <div className='h2 mt-0'>{this.state.activeUsers}</div>
                <div className='text-uppercase'>Përdorues Aktiv</div>
              </div>
            </div>
          </Col>

          {(this.props.userStore.userLevel == 5 || this.props.userStore.userLevel == 6) && (
            <Col xl={3} md={6}>
              {/* START card */}
              <div className='card flex-row align-items-center align-items-stretch border-0'>
                <div
                  className='col-4 d-flex align-items-center justify-content-center rounded-left'
                  style={{ backgroundColor: '#c19191', color: 'white' }}
                >
                  <em className='gg-unavailable float-right'></em>
                </div>
                <div className='col-8 py-3 rounded-right' style={{ backgroundColor: '#c19191a8', color: 'white' }}>
                  <div className='h2 mt-0'>{this.state.inactiveUsers}</div>
                  <div className='text-uppercase'>Përdorues joaktiv</div>
                </div>
              </div>
            </Col>
          )}

          {(this.props.userStore.userLevel == 5 || this.props.userStore.userLevel == 6) && (
            <Col xl={3} lg={6} md={12}>
              {/* START card */}
              <div className='card flex-row align-items-center align-items-stretch border-0'>
                <div
                  className='col-4 d-flex align-items-center justify-content-center rounded-left'
                  style={{ backgroundColor: '#947c65', color: 'white' }}
                >
                  <em className='icon-bubbles fa-3x'></em>
                </div>
                <div className='col-8 py-3 rounded-right'>
                  <div className='h2 mt-0'>{this.state.nrOfFeeds}</div>
                  <div className='text-uppercase'>Njoftime</div>
                </div>
              </div>
            </Col>
          )}

          <Col xl={3} lg={6} md={12}>
            {/* START date card */}
            <div className='card flex-row align-items-center align-items-stretch border-0'>
              <div
                className='col-4 d-flex align-items-center justify-content-center rounded-left'
                style={{ backgroundColor: '#7A431D', color: 'white' }}
              >
                <div className='text-center'>
                  <Now format='MMMM' className='text-sm' />
                  <br />
                  <Now format='D' className='h2 mt0' />
                </div>
              </div>
              <div className='col-8 py-3 rounded-right'>
                <Now format='dddd' className='text-uppercase' />
                <br />
                <Now format='h:mm' className='h2 mt0 mr-sm' />
                <Now format='a' className='text-muted text-sm' />
              </div>
            </div>
            {/* END date card */}
          </Col>
        </Row>

        <>
          {(this.props.userStore.userLevel == 1 || this.props.userStore.userLevel == 5) && (
            <Row className='pt-4'>
              <Col>
                <div className='form-group row mb float-left'>
                  <label className='col-form-label pl-3 mb'>Zgjedh vitin:</label>
                  <Col md={5}>
                    <DateTimeBoostrap
                      key='selectDate'
                      value={this.state.selectedYear}
                      onChange={this._onYearChange}
                      locale='de'
                      endDate={new Date()}
                      utc={true}
                      dateFormat='YYYY'
                      onBlur={this._onDateBlur}
                      data-validate='["validDate"]'
                      timeFormat={false}
                      inputProps={{
                        'data-validate': '["validDate"]',
                        className: 'form-control',
                        name: 'data',
                        autoComplete: 'off'
                      }}
                    />
                  </Col>
                </div>
              </Col>
            </Row>
          )}

          <Row>
            {(this.props.userStore.userLevel == 1 || this.props.userStore.userLevel == 5) && (
              <Col xl={3} md={6}>
                {/* START card */}
                <div className='card flex-row align-items-center align-items-stretch border-0'>
                  <div
                    className='col-4 d-flex align-items-center justify-content-center rounded-left'
                    style={{ backgroundColor: '#1E983B', color: 'white' }}
                  >
                    <em className='fa-3x fas fa-angle-double-up float-right'></em>
                  </div>
                  <div className='col-8 py-3 rounded-right' style={{ backgroundColor: '#fff', color: '#656565' }}>
                    <div className='h2 mt-0'>{this.state.newUsers}</div>
                    <div className='text-uppercase'>New Users</div>
                  </div>
                </div>
              </Col>
            )}
            {this.props.userStore.userLevel == 5 && (
              <Col xl={3} md={6}>
                {/* START card */}
                <div className='card flex-row align-items-center align-items-stretch border-0'>
                  <div
                    className='col-4 d-flex align-items-center justify-content-center rounded-left'
                    style={{ backgroundColor: '#EC2121', color: 'white' }}
                  >
                    <em className='fa-3x icon-close float-right'></em>
                  </div>
                  <div className='col-8 py-3 rounded-right' style={{ backgroundColor: '#fff', color: '#656565' }}>
                    <div className='h2 mt-0'>{this.state.exitUsers}</div>
                    <div className='text-uppercase'>Exit Users</div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </>

        <Row>
          {/* START dashboard main content */}
          <Col xl={12}>
            {/* START chart */}
            <Row>
              <Col xl={12}>
                {/* START card */}
                <div className='card card-default'>
                  <div className='card-header'>
                    <div className='card-title'>Aktiviteti i përdoruesve:</div>
                    <Row>
                      <Col>
                        <div className='form-group row mb float-right pl-3'>
                          <label className='col-form-label mb'>Zgjedh datën:</label>
                          <Col md={6}>
                            <DateTimeBoostrap
                              key='selectDate'
                              value={this.state.selectedDate}
                              onChange={this._onDateChange}
                              locale='de'
                              utc={true}
                              dateFormat='DD-MM-YYYY'
                              onBlur={this._onDateBlur}
                              data-validate='["validDate"]'
                              timeFormat={false}
                              inputProps={{
                                'data-validate': '["validDate"]',
                                className: 'form-control',
                                name: 'data',
                                autoComplete: 'off'
                              }}
                            />
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    <ResponsiveContainer height={330}>
                      <AreaChart
                        width={730}
                        height={330}
                        data={this.state.dataLogs}
                        syncId='anyId'
                        margin={{
                          top: 10,
                          right: 30,
                          left: 0,
                          bottom: 50
                        }}
                      >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis interval={0} allowDataOverflow={true} dataKey='name' angle={-45} tick={<this.CustomizedAxisTick />} />
                        <YAxis />
                        <Tooltip />
                        <Area type='monotone' dataKey='sotshme' stroke='#947c65' fill='#6f4a39' />
                        <Area type='monotone' dataKey='zgjedhur' stroke='#c19191' fill='#e1ab93' />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                {/* END widget */}
              </Col>
            </Row>
          </Col>
        </Row>
        {/* END chart */}
        <Row>
          <Col xl={12}>
            <div className='card border-0'>
              <div className='row row-flush'>
                <div className='col-lg-2 col-md-3 py-2 py-4 d-flex bg-info align-items-center justify-content-center rounded-left'>
                  <em className='fa fa-users fa-3x'></em>
                </div>
                {this.state.dataLevels.map((data, index) => {
                  return (
                    <div key={index} className='col-lg-2 col-md-3 d-md-block py-2 text-center br bb'>
                      <div className='text-muted text-md'>{data.users}</div>
                      <div className='text-info'>
                        <span className=''> {data.name}</span>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {/* END dashboard main content */}
          {/* START dashboard sidebar */}
          <Col xl={12}>
            <Row>
              {/* START card */}
              <Col xl={4}>
                <div className='card card-default'>
                  <div className='card-header'>
                    <div className='card-title'>Statistika për departamente</div>
                    <ResponsiveContainer height={300}>
                      <PieCustomizedFill projectData={this.state.dataDepartment} />
                    </ResponsiveContainer>
                  </div>
                  <div className='card-body'>
                    {/* <FlotChart data={this.state.flotData} options={this.state.flotOptions} height="250px" /> */}
                  </div>
                </div>
              </Col>
              {/* START card */}
              <Col xl={4}>
                <div className='card card-default'>
                  <div className='card-header'>
                    <div className='card-title'>Statistika për projekte</div>
                    <ResponsiveContainer height={300}>
                      <PieCustomized projectData={this.state.dataProject} />
                    </ResponsiveContainer>
                  </div>
                  <div className='card-body'></div>
                </div>
              </Col>
              {/* END widget */}

              <Col xl={4}>
                {/* START card */}
                <div className='card card-default'>
                  <div className='card-header'>
                    <div className='card-title'>Gjuhët e folura:</div>
                    <ResponsiveContainer height={330}>
                      <ComposedChart
                        layout='vertical'
                        data={this.state.dataLang}
                        margin={{
                          top: 60,
                          right: 20,
                          bottom: 0,
                          left: 0
                        }}
                      >
                        <CartesianGrid stroke='#f5f5f5' />
                        <XAxis type='number' />
                        <YAxis padding={{ bottom: 10 }} dataKey='name' type='category' />
                        <Tooltip />
                        <Bar dataKey='Numri i punëtorëve' barSize={15} fill='#413ea0' />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                {/* END widget */}
              </Col>

              <Col xl={12}>
                {/* START card */}
                <div className='card card-default'>
                  <div className='card-body'>
                    <div className='card-title'>Qytetet e përdoruesve:</div>
                    <ResponsiveContainer height={300}>
                      <AreaChart
                        width={730}
                        height={250}
                        data={this.state.dataCities}
                        syncId='anyId'
                        margin={{
                          top: 10,
                          right: 30,
                          left: 0,
                          bottom: 40
                        }}
                      >
                        <CartesianGrid stroke='#f5f5f5' />
                        <defs>
                          <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                            <stop offset='5%' stopColor='#5d3a3a' stopOpacity={1} />
                            <stop offset='95%' stopColor='#fbc490' stopOpacity={0} />
                          </linearGradient>
                        </defs>
                        <XAxis dataKey='name' interval={0} tick={<this.CustomizedAxisTick />} />
                        <YAxis />
                        <Tooltip />
                        <Area type='monotone' dataKey='users' stroke='#becbd6' fillOpacity={1} fill='url(#colorUv)' />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                {/* END widget */}
              </Col>
            </Row>
          </Col>
          {/* END dashboard sidebar */}
        </Row>
      </ContentWrapper>
    )
  }
}

export default Panel
