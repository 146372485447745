import axios from 'axios'
import moment from 'moment'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

export const createMozoOrders = async ({ body }) => {
  try {
    const res = await axios.post('mobilezoneOrders', { ...body })
    if (res.data.success) {
      toast.success('Order created successfully')
    }
  } catch (error) {
    toast.error(error?.response?.data?.message)
  }
}

export const updateMozoOrder = async ({ id, body }) => {
  try {
    const res = await axios.put(`mobilezoneOrders/updateOne/${id}`, { ...body })
    if (res.data.status) {
      toast.success('Order updated succsesfully')
    }
  } catch (error) {
    toast.error(error?.response?.data?.message)
  }
}

export const deleteMozoOrder = async ({ id }) => {
  try {
    const res = await axios.put(`mobilezoneOrders/delete/${id}`)
    if (res.data.status) {
      toast.success('Order deleted succsesfully')
    }
  } catch (error) {
    toast.error(error.res?.data?.message)
  }
}

export const fetchMozoOrders = async (urlWithParams) => {
  try {
    const res = await axios.get(urlWithParams)
    if (res.data.success) {
      return res.data
    }
  } catch (error) {
    toast.error(error.res?.data?.message)
  }
}

export const fetchOneMozoOrders = async (orderId) => {
  try {
    const res = await axios.get(`mobilezoneOrders/getOne/${orderId}`)
    if (res.data.success) {
      return res.data.data
    }
  } catch (error) {
    console.log('err', error)
    toast.error(error.res?.data?.message)
  }
}

export const fetchMyMozoOrders = async (urlWithParams) => {
  try {
    const res = await axios.get(urlWithParams)
    if (res.data.status) {
      return res.data
    }
  } catch (error) {
    toast.error(error.res?.data?.message)
  }
}

export const fetchMozoOrdersByDepartment = async () => {
  try {
    const res = await axios.get(`mobilezoneOrders/groupByDepartemnt`)
    if (res.data.success) {
      return res.data.data
    }
  } catch (error) {
    toast.error(error.res?.data?.message)
  }
}

export const downloadMozoOrders = async (urlWithParams, startDate, endDate) => {
  Swal.fire({
    title: 'Ju lutem prisni...',
    html: 'Duke shkarkuar...',
    allowEscapeKey: false,
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      Swal.showLoading()
    }
  })
  try {
    const res = await axios.get(urlWithParams, {
      responseType: 'blob'
    })

    if (res.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      saveAs(res.data, `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}_Activites.csv`)
    }
    Swal.close()
  } catch (error) {
    console.error('Error in downloadActivityCsv:', error)
    Swal.close()
    Swal.fire('', 'Ndodhi nje gabim!', 'error')
    throw error
  }
}

export const fetchDepartments = async () => {
  try {
    const res = await axios.get('departments')
    if (res.data.success) {
      return res.data.data
    }
  } catch (error) {
    toast.error(error.res?.data?.message)
  }
}
