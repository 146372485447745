import { Close } from '@material-ui/icons'
import { IconButton } from '@mui/material'
import axios from 'axios'
import pubsub from 'pubsub-js'
import React from 'react'
import { Button, Card, CardBody, CardFooter, Col, Input, Row } from 'reactstrap'
import { DeleteDialogBox } from '../../Common/'
import FormValidator from '../../Forms/FormValidator.js'
import ContentWrapper from '../../Layout/ContentWrapper'
class EditDepartments extends React.Component {
  constructor() {
    super()
    this.state = {
      isEdit: false,
      formDepartment: {
        nameDepartment: '',
        descDepartment: ''
      },
      projects: [],
      calculation_methods: [],
      projectSelected: '',
      current_month_bonuses: [],
      new_bonus: {
        show_error: false,
        show_input: false,
        input_value: ''
      }
    }
  }

  validateOnChange = (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value
    const result = FormValidator.validate(input)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
  }

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  componentDidMount() {
    const idDepartment = this.props.match.params.idDepartment
    if (idDepartment) {
      this.setState({ isEdit: true })
    }
  }

  async componentWillMount() {
    const ID = this.props.match.params.idDepartment
    try {
      const calcMethods = await axios.get('/salarycalculations')
      if (calcMethods.data.success) {
        this.setState({
          calculation_methods: calcMethods.data.data.map((c) => {
            return {
              name: c.name,
              value: c._id
            }
          })
        })
      }
    } catch (e) {}
    if (ID) {
      axios
        .get(`departments/${ID}`)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              formDepartment: {
                nameDepartment: response.data.data.name,
                descDepartment: response.data.data.description,
                calculation_method: response.data.data.calculation_method
              },
              current_month_bonuses: response.data.data.current_month_bonuses ?? [],
              projectSelected: response.data.data.project
            })
          }
        })
        .catch((error) => {})
    }

    axios
      .get(`projects`)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            projects: response.data.data
          })
        }
      })
      .catch((error) => {})
  }

  onSubmit = (e) => {
    const ID = this.props.match.params.idDepartment
    const form = e.target
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })

    if (!hasError) {
      const postData = {
        name: this.state.formDepartment.nameDepartment,
        description: this.state.formDepartment.descDepartment,
        project: this.state.projectSelected,
        calculation_method: this.state.formDepartment.calculation_method,
        current_month_bonuses: this.state.current_month_bonuses ?? null
      }
      if (ID) {
        axios
          .put(`departments/${ID}`, postData)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push('/departments')
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ndryshuar!',
                type: 'success'
              })
            } else {
              pubsub.publish('showNotification', {
                message: 'Gabim gjatë ndryshimit të të dhënave',
                type: 'error'
              })
            }
          })

          .catch((err) => {
            pubsub.publish('showNotification', {
              message: 'Gabim gjatë ndryshimit të të dhënave',
              type: 'error'
            })
          })
      } else {
        axios
          .post('departments', postData)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push('/departments')
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ruajtur!',
                type: 'success'
              })
            } else {
              pubsub.publish('showNotification', {
                message: res.data.message,
                type: 'error'
              })
            }
          })
          .catch((err) => {
            pubsub.publish('showNotification', {
              message: 'Zgjedh projektin!',
              type: 'error'
            })
          })
      }
    }

    e.preventDefault()
  }

  deleteDepartment() {
    const ID = this.props.match.params.idDepartment
    const goBack = () => this.props.history.goBack()
    const path = `departments/${ID}`
    const name = 'Departamentin'
    DeleteDialogBox({ path, name, goBack })
  }

  renderProjects() {
    const items = this.state.projects.map((element, index) => {
      return (
        <option key={element._id} value={element._id}>
          {element.name}
        </option>
      )
    })
    return (
      <fieldset>
        <div className='form-group row align-items-center'>
          <label className='col-md-2 col-form-label'>Projekti :</label>
          <div className='col-md-6'>
            <Input
              type='select'
              name='selectProj'
              data-validate='["required"]'
              onChange={(e) => {
                this.setState({ projectSelected: e.target.value })
              }}
              value={this.state.projectSelected}
              defaultValue=''
              invalid={this.hasError('formDepartment', 'selectProj', 'required')}
              className='custom-select custom-select'
            >
              <option key='1' value=''>
                Zgjedhni projektin...
              </option>
              {items}
            </Input>

            <span className='invalid-feedback'>Fusha është e nevojshme</span>
          </div>
        </div>
      </fieldset>
    )
  }

  renderMethods() {
    const items = this.state.calculation_methods.map((element, index) => {
      return (
        <option key={index} value={element.value}>
          {element.name}
        </option>
      )
    })
    return (
      <fieldset>
        <div className='form-group row align-items-center'>
          <label className='col-md-2 col-form-label'>Metoda e Kalkulimit :</label>
          <div className='col-md-6'>
            <Input
              type='select'
              name='calculation_method'
              onChange={this.validateOnChange}
              value={this.state.formDepartment.calculation_method}
              data-validate='["required"]'
              invalid={this.hasError('formDepartment', 'calculation_method', 'required')}
              className='custom-select custom-select'
            >
              <option key='1' value=''>
                Zgjedhni Metodën e kalkulimit te pagës...
              </option>
              {items}
            </Input>

            <span className='invalid-feedback'>Fusha është e nevojshme</span>
          </div>
        </div>
      </fieldset>
    )
  }

  render() {
    return (
      <ContentWrapper>
        {this.state.isEdit ? (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Modifiko Departamentin</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>
                Prapa
              </Button>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Shto Departamentin</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        )}
        <Row>
          <div className='col-md-12'>
            <form onSubmit={this.onSubmit} name='formDepartment'>
              {/* START card */}
              <Card className='card-default'>
                <CardBody>
                  <legend className='mb-4' />
                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Emri i Departamentit :</label>
                      <Col md={6}>
                        <Input
                          key='nameDepartment'
                          type='text'
                          name='nameDepartment'
                          invalid={this.hasError('formDepartment', 'nameDepartment', 'required')}
                          onChange={this.validateOnChange}
                          value={this.state.formDepartment.nameDepartment}
                          data-validate='["required"]'
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                    </div>
                  </fieldset>

                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Përshkrimi i Departamentit :</label>
                      <Col md={6}>
                        <Input
                          key='descDepartment'
                          type='text'
                          name='descDepartment'
                          onChange={this.validateOnChange}
                          value={this.state.formDepartment.descDepartment}
                        />
                      </Col>
                    </div>
                  </fieldset>

                  {this.renderProjects()}
                  {this.renderMethods()}
                  {/* <fieldset> */}
                  <div className='form-group row '>
                    <label className='col-md-2 col-form-label'>Bonuset e ketij muaji:</label>
                    <Col>
                      <div style={{ width: 'fit-content' }}>
                        {this.state.current_month_bonuses ? (
                          this.state.current_month_bonuses?.map((oneBonus) => (
                            <div
                              key={oneBonus.id}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginBottom: '20px'
                              }}
                            >
                              <label style={{ marginRight: '20px' }}>{oneBonus.bonus_name}</label>
                              <IconButton
                                size='small'
                                onClick={() =>
                                  this.setState({
                                    current_month_bonuses: this.state.current_month_bonuses.filter(
                                      (bonus) => bonus.bonus_name !== oneBonus.bonus_name
                                    )
                                  })
                                }
                              >
                                <Close fontSize='small' />
                              </IconButton>
                            </div>
                          ))
                        ) : (
                          <label>Nuk ka ndonje bonus</label>
                        )}
                      </div>
                      {this.state.new_bonus.show_input ? (
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: '60%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: '25px'
                          }}
                        >
                          <div>
                            <Input
                              type='text'
                              key='new_bonus_text'
                              name='new_bonus_text'
                              placeholder='Emri'
                              onChange={(e) => {
                                this.setState({
                                  new_bonus: {
                                    ...this.state.new_bonus,
                                    show_error: false,
                                    input_value: e.target.value
                                  }
                                })
                              }}
                              value={this.state.new_bonus.input_value}
                              invalid={this.state.new_bonus.show_error}
                            />
                            {this.state.new_bonus.show_error && <label style={{ color: 'red' }}>Bonusi me këtë emër egziston!</label>}
                          </div>
                          <button
                            type='button'
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                              if (this.state?.current_month_bonuses?.find((e) => e.bonus_name === this.state.new_bonus.input_value)) {
                                this.setState({
                                  new_bonus: {
                                    ...this.state.new_bonus,
                                    show_error: true
                                  }
                                })
                              } else {
                                this.setState({
                                  current_month_bonuses: [
                                    ...this.state.current_month_bonuses,
                                    {
                                      bonus_name: this.state.new_bonus.input_value
                                    }
                                  ]
                                })
                                this.setState({
                                  new_bonus: {
                                    show_error: false,
                                    show_input: false,
                                    input_value: ''
                                  }
                                })
                              }
                            }}
                            className='btn btn-success'
                          >
                            Shto bonusin
                          </button>
                        </div>
                      ) : (
                        <button
                          type='button'
                          style={{ marginRight: 10 }}
                          onClick={() =>
                            this.setState({
                              new_bonus: {
                                ...this.state.new_bonus,
                                show_input: true
                              }
                            })
                          }
                          className='btn btn-success'
                        >
                          Shto bonus
                        </button>
                      )}
                    </Col>
                  </div>
                  {/* </fieldset> */}
                </CardBody>
                {this.state.isEdit ? (
                  <CardFooter className='text-right'>
                    <button style={{ marginRight: 10 }} type='submit' className='btn btn-info'>
                      Ruaj / Modifiko
                    </button>
                    <Button color='danger' onClick={this.deleteDepartment.bind(this)}>
                      Fshij
                    </Button>
                  </CardFooter>
                ) : (
                  <CardFooter className='text-right'>
                    <button type='submit' className='btn btn-info'>
                      Ruaj
                    </button>
                  </CardFooter>
                )}
              </Card>
              {/* END card */}
            </form>
          </div>
        </Row>
      </ContentWrapper>
    )
  }
}

export default EditDepartments
