import { Typography, Card, CardContent, styled, Box } from '@mui/material'
import trophy from '../../../../../assets/images/trophy.png'

// Styled component for the trophy image
const TrophyImg = styled('img')({
  right: 36,
  bottom: 20,
  height: 98,
  position: 'absolute'
})

const Trophy = ({ brutoSalary, userId }) => {
  const username = localStorage.getItem('name')
  return (
    <Card sx={{ position: 'relative', paddingBottom: '0px' }}>
      <CardContent>
        {userId ? (
          <>
            <Typography variant='h6'>Urime! 🥳</Typography>
            <Typography variant='body2' sx={{ letterSpacing: '0.25px' }}>
              Paga Bruto deri sot.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant='h6'>Përshëndetje, {username}! 🥳</Typography>
            <Typography variant='body2' sx={{ letterSpacing: '0.25px' }}>
              Paga ime Bruto deri sot.
            </Typography>
          </>
        )}
        <Typography variant='h5' sx={{ margin: '48px 0 !important', color: 'primary.main' }}>
          €{parseFloat(brutoSalary).toFixed(2)}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexWrap: 'wrap' }}>
          <TrophyImg alt='trophy' src={trophy} />
        </Box>
      </CardContent>
    </Card>
  )
}

export default Trophy
