import { Grid } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import { useDebounce } from '../../../utils/searchDebouncer'
import ContentWrapper from '../../../components/Layout/ContentWrapper'
import { downloadCsv, useApplicantHistory, useGetApplicants } from '../applicantsRequests'
import ApplicantChart from '../Components/ApplicantsChart'
import Filters from '../Components/Filters/Filters'
import OpenPosition from '../Components/Positions/OpenPosition'
import Statuses from '../Components/Statuses'
import { statusEnumSQ } from '../enums/statusEnumSQ'
import { applicantTableColumns } from '../utils/tableColumns'
import { applicantTableOptions } from '../utils/tableOptions'

const ApplicantsComponent = () => {
  const [query, setQuery] = useState({
    status: 'all',
    dateFrom: null,
    dateTo: null,
    name: '',
    page: 0,
    limit: 10,
    location: []
  })

  const searchTxt = useDebounce(query.name)
  const { data, isLoading: loading } = useGetApplicants({ ...query, name: searchTxt }, searchTxt)
  const { applicants, statistics: statuses, records } = data || {}
  const { data: history, isLoading: historyLoading } = useApplicantHistory(query)
  const applicantHistory = history?.data || {}

  return (
    <ContentWrapper style={{ width: '100%' }}>
      <div className='content-heading'>
        <div>Lista e aplikantëve</div>
      </div>

      <OpenPosition />
      <ApplicantChart applicantHistory={applicantHistory} />

      <Grid marginY={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        {statuses &&
          statuses.map((btn, i) => (
            <Statuses statusEnumSQ={statusEnumSQ} query={query} setQuery={setQuery} key={i} btn={btn} len={statuses.length} />
          ))}
      </Grid>

      <Card className='shadow'>
        <CardBody>
          <Filters setQuery={setQuery} query={query} />
          <MUIDataTable
            title={statusEnumSQ[query.status] ? statusEnumSQ[query.status] : 'Të gjithë aplikantët'}
            columns={applicantTableColumns}
            options={applicantTableOptions(records, loading, setQuery, () => downloadCsv(query, searchTxt))}
            data={applicants}
          />
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

export default ApplicantsComponent
