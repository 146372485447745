import axios from 'axios'
import { Box, Card, Typography } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { columns } from '../../utils/columns'
import { useTableData } from '../../utils/useTableData'
import useTableOptions from '../../utils/useTableOptions'
import BadgesModal from '../BadgesModal/badgesModal'
import BadgesFilters from '../badgesFilters/badgesFilters'
import { useDebounce } from '../../../../utils/searchDebouncer'

const BadgesTable = ({ type }) => {
  const [users, setUsers] = useState([])
  const [total, setTotal] = useState(null)
  const [updateUserData, setUpdateUserData] = useState(null)
  const [badge, setBadge] = useState('')
  const [loading, setLoading] = useState(true)
  const [params, setParams] = useState({
    page: 0,
    limit: 5,
    search: '',
    project: '',
    departments: [],
    office_locations: []
  })

  const searchTxt = useDebounce(params.search)

  const getUsers = async () => {
    try {
      const res = await axios.get(`users/badges?type=${type}`, { params })
      if (res?.data?.success) {
        const tableData = res?.data?.data?.map((item) => useTableData(item, setUpdateUserData, type, setBadge))
        setUsers(tableData)
        setTotal(res?.data?.total)
        setLoading(false)
      } else {
        toast.success('Ndodhi një gabim!')
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Ndodhi një gabim!')
      console.error('Error updating badge:', error)
    }
  }

  useEffect(() => {
    getUsers()
  }, [params.departments, params.limit, params.office_locations, params.page, params.project, searchTxt])

  const options = useTableOptions(total, params, setParams)
  const onCloseModal = () => {
    setUpdateUserData(null)
    getUsers()
  }

  return (
    <Box className='shadow bg-white'>
      {updateUserData && <BadgesModal badgeValue={badge} updateUserData={updateUserData} onCloseModal={onCloseModal} type={type} />}

      <Card className={` ${loading && 'whirl standard'}`} sx={{ marginBottom: '30px' }}>
        <Typography variant='h6' sx={{ padding: '10px', paddingBottom: '0px' }}>
          {type === 'haveBadge' ? 'Posedojnë bexhin' : 'Nuk kanë bexh'}
        </Typography>
        <BadgesFilters params={params} setParams={setParams} />
        <MUIDataTable columns={columns(type)} options={options} data={users} />
      </Card>
    </Box>
  )
}

export default BadgesTable
