import ContentWrapper from '../../../components/Layout/ContentWrapper'
import ComplianceTable from '../components/DataTable/complianceTable'

const Compliance = () => {
  return (
    <ContentWrapper>
      <div className='content-heading'>
        <p>Compliance</p>
      </div>
      <div className='mb-5'>
        <ComplianceTable showBtn={true} type='uncompleted' />
      </div>
      <ComplianceTable showBtn={false} type='completed' />
    </ContentWrapper>
  )
}

export default Compliance
