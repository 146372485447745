/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { Fragment } from 'react'
import { Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap'
import moment from 'moment'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import DateTimeBoostrap from '../../components/Forms/DateTimeBootstrap'

class DocumentInputModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputState: {},
      missingDetails: false
    }
    this.inputChange = this.inputChange.bind(this)
    this.fileInputChange = this.fileInputChange.bind(this)
  }

  async inputChange(e) {
    await this.setState({
      inputState: { ...this.state.inputState, [e.target.name]: e.target.value }
    })
  }

  fileInputChange(e) {
    if (e.target.files.length > 0) {
      this.setState({
        inputState: {
          ...this.state.inputState,
          [e.target.name]: e.target.files
        }
      })
    }
  }

  _handleArrayFill = (elem_name, value) => {
    var oldValue = this.state.inputState[elem_name] ? this.state.inputState[elem_name] : []
    this.setState({
      inputState: {
        ...this.state.inputState,
        [elem_name]: [...oldValue, { description: value }]
      }
    })
  }

  _handleArrayRemove = (elem_name, indexToRemove) => {
    this.setState({
      inputState: {
        ...this.state.inputState,
        [elem_name]: this.state.inputState[elem_name].filter((elem, index) => {
          return index != indexToRemove
        })
      }
    })
  }

  _onDateBlur = (date, name) => {
    this.setState({
      inputState: {
        ...this.state.inputState,
        [name]: moment(date, 'DD/MM/YYYY')
      }
    })
  }

  _onDateChange = (date, name) => {
    if (typeof date == 'object') {
      this.setState({
        inputState: { ...this.state.inputState, [name]: date }
      })
    }
  }

  _onTimeBlur = (time, name) => {
    this.setState({
      inputState: {
        ...this.state.inputState,
        [name]: moment(time).format('HH:mm')
      }
    })
  }

  _onTimeChange = (time, name) => {
    if (typeof time == 'object') {
      this.setState({
        inputState: {
          ...this.state.inputState,
          [name]: moment(time).format('HH:mm')
        }
      })
    }
  }

  _onComplete = () => {
    var isInvalid = false
    this.props.modalData.fields.forEach((element) => {
      if (!this.state.inputState[element.name]) {
        this.setState({ missingDetails: true })
        isInvalid = true
      }
    })
    if (!isInvalid) {
      this.setState({ inputState: {}, missingDetails: false })
      this.props.onComplete({
        state: this.state.inputState,
        modalType: this.props.modalData.modalType,
        inputFields: this.props.modalData.fields
      })
    }
  }
  _onCancel = () => {
    this.setState({ inputState: {}, missingDetails: false })
    this.props.toggleModal()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps != this.props) {
      var theState =
        this.props.modalData.fields &&
        this.props.modalData.fields
          .filter((e) => {
            return e.default
          })
          .reduce((a, e) => {
            return { ...a, [e.name]: e.default }
          }, {})
      if (theState) {
        this.setState({
          inputState: { ...this.state.inputState, ...theState }
        })
      }
    }
  }

  _renderElement = (element) => {
    switch (element.type) {
      case 'datepicker': {
        return (
          <Fragment>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>{element.label}</InputGroupText>
            </InputGroupAddon>
            <DateTimeBoostrap
              value={this.state.inputState[element.name] || ''}
              onChange={(date) => this._onDateChange(date, element.name)}
              utc={true}
              dateFormat={'DD/MM/YYYY'}
              initialViewMode='years'
              closeOnSelect
              onBlur={(date) => this._onDateBlur(date, element.name)}
              timeFormat={false}
              inputProps={{
                className: 'form-control',
                name: element.name,
                autoComplete: 'off'
              }}
            />
          </Fragment>
        )
      }
      case 'timepicker': {
        return (
          <Fragment>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>{element.label}</InputGroupText>
            </InputGroupAddon>
            <DateTimeBoostrap
              value={this.state.inputState[element.name] || ''}
              onChange={(time) => this._onTimeChange(time, element.name)}
              utc={true}
              onBlur={(time) => this._onTimeBlur(time, element.name)}
              timeFormat={true}
              dateFormat={false}
              inputProps={{
                className: 'form-control',
                name: element.name,
                autoComplete: 'off'
              }}
            />
          </Fragment>
        )
      }
      case 'monthpicker': {
        return (
          <Fragment>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>{element.label}</InputGroupText>
            </InputGroupAddon>
            <Datetime
              value={this.state.inputState[element.name] || ''}
              dateFormat='MM-YYYY'
              initialViewMode='years'
              closeOnSelect
              timeFormat={false}
              onChange={(date) => this._onDateChange(date, element.name)}
              onBlur={(date) => this._onDateBlur(date, element.name)}
              inputProps={{
                className: 'form-control',
                name: element.name,
                autoComplete: 'off'
              }}
            />
          </Fragment>
        )
      }
      case 'array': {
        return (
          <Fragment>
            <Col md={12}>
              {this.state.inputState[element.name] &&
                this.state.inputState[element.name].map((elem, index) => {
                  return (
                    <div key={index} style={{ padding: 5 }}>
                      <Button
                        onClick={() => {
                          this._handleArrayRemove(element.name, index)
                        }}
                        size='xs'
                        color='danger'
                        className='btn-labeled float-right'
                      >
                        <span className='btn-label'>
                          <i className='fa fa-trash-alt' />
                        </span>{' '}
                        Fshij
                      </Button>
                      <em className='fa-fw fas fa-exclamation-triangle mr-2' />
                      {this.state.inputState[element.name][index].description}
                    </div>
                  )
                })}
            </Col>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>{element.label}</InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder='Shëno të dhënat dhe shtyp enter në fund'
              type='text'
              name={element.name}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  this._handleArrayFill(element.name, e.target.value)
                  e.target.value = ''
                }
              }}
            />
          </Fragment>
        )
      }
      case 'file': {
        return (
          <Fragment>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>{element.label}</InputGroupText>
            </InputGroupAddon>
            <Input
              style={{ position: 'absolute' }}
              className='custom-file-input'
              type={element.type}
              name={element.name}
              onChange={this.fileInputChange}
            />
            <label className='custom-file-label' htmlFor={element.name}>
              {this.state.inputState[element.name] ? this.state.inputState[element.name][0].name : 'Zgjedh fajllin per import'}
            </label>
          </Fragment>
        )
      }
      case 'select': {
        return (
          <Fragment>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>{element.label}</InputGroupText>
            </InputGroupAddon>
            <Input type={element.type} name={element.name} onChange={this.inputChange}>
              <option value=''>Ju lutem zgjedhni</option>
              {element.options.map((o) => {
                return (
                  <option key={o.value} value={o.value}>
                    {o.name}
                  </option>
                )
              })}
            </Input>
          </Fragment>
        )
      }
      default: {
        return (
          <Fragment>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>{element.label}</InputGroupText>
            </InputGroupAddon>
            <Input
              type={element.type}
              name={element.name}
              defaultValue={element.default}
              value={this.state.inputState[element.name]}
              onChange={this.inputChange}
            />
          </Fragment>
        )
      }
    }
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.isOpen} toggle={this._onCancel} className={this.props.className}>
          <ModalHeader toggle={this._onCancel}>{this.props.modalData.title}</ModalHeader>
          <ModalBody>
            {this.props.modalData.description && <p>{this.props.modalData.description}</p>}
            {this.props.modalData.fields &&
              this.props.modalData.fields.map((element, index) => {
                return (
                  <InputGroup key={index} style={{ marginBottom: 10 }}>
                    {this._renderElement(element)}
                  </InputGroup>
                )
              })}
            {this.props.modalData.Selected && <>{this.props.modalData.Selected}</>}
          </ModalBody>
          <ModalFooter>
            <span className='invalid-feedback' style={{ display: this.state.missingDetails ? 'block' : 'none' }}>
              Mungojnë të gjitha të dhënat
            </span>
            <Button color='primary' onClick={this._onComplete}>
              Gjenero
            </Button>{' '}
            <Button color='secondary' onClick={this._onCancel}>
              Anulo
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default DocumentInputModal
