import React from 'react'
import { CircularProgress } from '@material-ui/core'

const Loader = () => {
  return (
    <div className='d-flex justify-content-center align-items-center' style={{ height: '92vh' }}>
      <CircularProgress style={{ color: '#F58634' }} />
    </div>
  )
}

export default Loader
