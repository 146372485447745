import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import React from 'react'
import axios from 'axios'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup
  .object({
    description: yup.string().required(),
    days: yup.number().required()
  })
  .required()

function VacancyForm({ getUser, reloadData, userId }) {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      description: '',
      days: 1
    }
  })

  const onSubmit = (data) => {
    try {
      data.user_id = userId
      axios.post('leaves/addExtra', data).then((data) => {
        try {
          getUser(userId)
          reloadData()
        } catch (e) {
          console.log('error', e)
        }
      })
    } catch (e) {
      console.log('error', e)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} name='vacancyForm'>
      <FormGroup>
        <Label for='description'>Arsyeja e pushimit</Label>
        <Controller
          control={control}
          rules={{
            required: true
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input invalid={errors.description} onBlur={onBlur} onChange={onChange} value={value} />
          )}
          name='description'
        />
        {errors.description && <FormFeedback>This is required.</FormFeedback>}
      </FormGroup>

      <FormGroup>
        <Label for='days'>Dite</Label>
        <Controller
          control={control}
          rules={{
            required: true
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input invalid={errors.days} onBlur={onBlur} onChange={onChange} value={value} />
          )}
          name='days'
        />
        {errors.days && <FormFeedback>This is required.</FormFeedback>}
      </FormGroup>

      <div className='pt-2'>
        <Button color='danger' type='submit'>
          Dërgo
        </Button>
      </div>
    </form>
  )
}

export default VacancyForm
