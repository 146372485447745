import axios from 'axios'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'
import ContentWrapper from '../../Layout/ContentWrapper'
class Departments extends React.Component {
  constructor() {
    super()
    this.state = {
      data: []
    }
  }

  componentDidMount() {
    this.axiosCancelSource = axios.CancelToken.source()
    axios
      .get(`departments`, { cancelToken: this.axiosCancelSource.token })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            data: response.data.data
          })
        }
      })
      .catch((error) => {})
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel('Component unmounted')
  }

  render() {
    const columns = [
      {
        name: 'Emri',
        label: 'Emri',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'Projekti',
        label: 'Projekti',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'Krijuar nga',
        label: 'Krijuar nga',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'Data e krijimit',
        label: 'Data e Krijimit',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'Modifikuar nga',
        label: 'Modifikuar nga',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'Data e modifikimit',
        label: 'Data e Modifikimit',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'Pamje',
        label: 'Pamje',
        options: {
          filter: false,
          sort: false
        }
      }
    ]
    const options = {
      responsive: 'scroll',
      filterType: 'dropdown',
      selectableRows: 'none',
      textLabels: {
        body: {
          noMatch: 'Nuk ka të dhëna'
        }
      }
    }

    const data = this.state.data.map((element, index) => {
      var date = moment(element.created_date).format('DD/MM/YYYY')

      return [
        element.name,
        element.project && element.project.name,
        element.created_by.email,
        date,
        element.edited_by && element.edited_by.email,
        element.last_edit_date && moment(element.last_edit_date).format('DD/MM/YYYY'),
        <Link to={`/editDepartments/${element._id}`}>
          <Button key={index} outline className='mb-1' color='warning' type='button'>
            <span>Modifiko</span>
          </Button>
        </Link>
      ]
    })
    return (
      <ContentWrapper>
        <div className='content-heading'>
          <div>Departamentet</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-end'
            }}
            className='ml-auto'
          >
            <Link to='/editDepartments/'>
              <Button color='success' className='btn-oval'>
                Shto Departament
              </Button>
            </Link>
          </div>
        </div>
        <Row>
          <Col>
            <MUIDataTable title={'Departamentet'} columns={columns} options={options} data={data} />
          </Col>
        </Row>
      </ContentWrapper>
    )
  }
}

export default Departments
