import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@material-ui/icons'
import { Button, Grid, Tooltip, Typography, Chip, Avatar, Box } from '@mui/material'
import moment from 'moment'
import { Link } from 'react-router-dom'

export const enum_task_type = {
  single: 'single',
  repetitive: 'repetitive'
}

export const enum_assignment_type = {
  all_users: 'all_users',
  per_user: 'per_user'
}

export const week_days = [
  { name: 'Hënë', value: 1 },
  { name: 'Martë', value: 2 },
  { name: 'Mërkurë', value: 3 },
  { name: 'Enjete', value: 4 },
  { name: 'Premte', value: 5 },
  { name: 'Shtunë', value: 6 },
  { name: 'Diel', value: 7 }
]

export const tasks_header = [
  { label: 'Name', width: '100px' },
  { label: 'Description', width: '150px' },
  { label: 'Repetition', width: '100px' },
  { label: 'Due Time', width: '100px' },
  { label: 'Due Date', width: '100px' },
  { label: 'Action', width: '100px' }
]
export const floors = [
  { name: 'Floor 1', value: 1 },
  { name: 'Floor 2', value: 2 },
  { name: 'Floor 3', value: 3 },
  { name: 'Floor 4', value: 4 },
  { name: 'Floor 5', value: 5 },
  { name: 'Floor 6', value: 6 }
]

export const office_location = [
  { name: 'Home Office', value: 'Home Office' },
  { name: 'Prishtinë', value: 'Prishtinë' },
  { name: 'Prishtinë HQ', value: 'Prishtinë HQ' },
  { name: 'Vushtrri', value: 'Vushtrri' },
  { name: 'Ferizaj', value: 'Ferizaj' },
  { name: 'Prizren', value: 'Prizren' }
]

export const capitalizeFirstLetter = (string) => (string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()).replace(/_/g, ' ')

export const unCheckedIcon = <CheckBoxOutlineBlank fontSize='small' />
export const checkedIcon = <CheckBoxOutlined fontSize='small' />

export const RenderItems = ({ taskDetails }) =>
  taskDetails.map(({ title, value }, key) => (
    <Box key={key} display='flex'>
      <Typography variant='body1' fontWeight='bold' textTransform='capitalize' fontSize='18px' mb={2}>
        {title}:
      </Typography>
      <Typography variant='body1' fontWeight='regular' color='text' fontSize='18px'>
        &nbsp;{value}
      </Typography>
    </Box>
  ))

export const RenderProfiles = ({ assignedUsers }) =>
  assignedUsers?.map((user) => (
    <Box key={user.user_id._id} component='li' display='flex' alignItems='center' py={1} mb={1}>
      <Box mr={2}>
        <Avatar src={''} alt='something here' shadow='' />
      </Box>
      <Box display='flex' flexDirection='column' alignItems='flex-start' justifyContent='center'>
        <Typography variant='buton' fontWeight='medium'>
          {user.user_id.first_name} {user.user_id.last_name}
        </Typography>
        <Typography variant='caption' color='text'>
          <Link to={`/editUsers/${user.user_id._id}`}>Profili</Link>
        </Typography>
      </Box>
    </Box>
  ))

export const RenderDepartemnts = ({ assignedDepartments }) =>
  assignedDepartments.map(({ _id, name }) => (
    <Box key={_id} component='li' display='flex' alignItems='center' py={1} mb={1}>
      <Box mr={2}>
        <Avatar src={''} alt='something here' shadow=''>
          {name.charAt(0)}
        </Avatar>
      </Box>
      <Box display='flex' flexDirection='column' alignItems='flex-start' justifyContent='center'>
        <Typography variant='button' fontWeight='medium'>
          {name}
        </Typography>
      </Box>
    </Box>
  ))

export const RenderChips = ({ activeDay }) =>
  week_days.map((day, index) => {
    const isInArray = activeDay?.includes(day.value)
    const today = new Date()
    const dayOfWeek = today.getDay()
    return (
      <Grid item>
        <Chip
          key={day.value}
          label={`${index + 1}: ${day.value === dayOfWeek ? 'Sot' : day.name}`}
          size='small'
          variant={isInArray ? '' : 'outlined'}
          color={isInArray ? 'success' : 'default'}
        />
      </Grid>
    )
  })
export const CustomChip = ({ activeDay }) =>
  week_days.map((day, index) => {
    const isInArray = activeDay?.includes(day.value)
    const today = new Date()
    const dayOfWeek = today.getDay()
    return (
      <Box
        key={day.value}
        sx={{
          background: `${isInArray ? 'rgba(38, 194, 129, 1)' : 'rgba(0, 0, 0, .8)'}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: { xs: '100%', md: '120px', lg: '120px', xl: '170px' },
          padding: '5px',
          borderRadius: '12px',
          color: '#fff',
          gap: '5px'
        }}
      >
        <Typography>{index + 1}.</Typography>
        <Typography>{day.value === dayOfWeek ? 'Sot' : day.name}</Typography>
      </Box>
    )
  })

export const boxStyling = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: { xs: '100%', md: '48%', lg: '23%' },
  borderRadius: '4px',
  padding: '20px',
  backgroundColor: '#fff',
  minHeight: '115px'
  // boxShadow: '3px 3px 3px #f2f2f2'
}

export const titleBoxStyling = {
  fontWeight: 'bold',
  fontSize: '16px',
  mb: '10px'
}
export const titleChipStyling = {
  fontSize: '14px',
  border: '1px solid #ccc',
  mb: '10px',
  borderRadius: '12px',
  padding: '1px 7px',
  display: 'inline-block',
  mr: 1
}

export const flexTypography = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px',
  mb: 1
}

export const cardStyling = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  borderRadius: '4px',
  padding: '20px',
  backgroundColor: '#fff',
  boxShadow: '3px 3px 3px #f2f2f2',
  flexDirection: 'column',
  minHeight: '330px',
  '&.small': {
    minHeight: '157px',
    padding: { xs: '10px', lg: '10px', xl: '20px' }
  }
}
