import { Box, IconButton, makeStyles, TextField, Tooltip, useMediaQuery } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Card, CardBody, Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { BASE_API } from '../../utils/consts'
import ContentWrapper from '../Layout/ContentWrapper'
import { useDebounce } from '../../utils/searchDebouncer'

const useStyles = makeStyles((theme) => ({
  textfield: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.23)'
    }
  }
}))

const BlacklistsComponent = ({ user }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [blacklist, setBlacklist] = useState([])
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const max600 = useMediaQuery('(max-width:600px)')
  const max900 = useMediaQuery('(max-width:900px)')
  const [name, setName] = useState('')
  const [blacklistModal, setBlacklistModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [userToDelete, setUserToDelete] = useState({ name: '', id: '' })
  const initialInputsState = {
    first_name: '',
    last_name: '',
    idNumber: '',
    email: '',
    description: ''
  }
  const [formInputs, setFormInputs] = useState(initialInputsState)
  const [showError, setShowError] = useState(false)
  const searchTxt = useDebounce(name)

  useEffect(() => {
    getBlacklistByName()
  }, [searchTxt])

  useEffect(() => {
    getBlacklist()
  }, [])

  const getBlacklistByName = () => {
    const url = `${BASE_API}/blacklist/getByName?name=${name}`
    fetchBlacklist(url)
  }

  const toggleBlacklistModal = () => {
    setBlacklistModal(!blacklistModal)
    setFormInputs(initialInputsState)
  }

  const addToBlacklist = () => {
    if (!Object.values(formInputs).some((v) => v)) {
      return setShowError(true)
    } else {
      axios
        .post(`${BASE_API}/blacklist`, formInputs)
        .then((res) => {
          toast.success(res?.data?.message)
          toggleBlacklistModal()
          getBlacklist()
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message ?? 'Ndodhi nje gabim!')
        })
    }
  }
  const downloadCsv = () => {
    axios
      .get(`blacklist/downloadCSV?from=${dateFrom ? dateFrom.getTime() : ''}&to=${dateTo ? dateTo.getTime() : ''}`, {
        responseType: 'blob'
      })
      .then((response) => {
        if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          saveAs(response.data, 'Blacklist.csv')
          toast.success('Te dhenat u shkarkuan!')
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message ?? 'Ndodhi nje gabim!')
      })
  }

  const fetchBlacklist = async (url) => {
    setLoading(true)
    try {
      const { data } = await axios.get(url)
      if (data) {
        const _data = data.data.map((item) => {
          return [
            item.first_name,
            item.last_name,
            item.email,
            item.idNumber,
            item.insert_from,
            <Link to={`blacklists/${item._id}`}>
              <Button color='success'>Më shumë</Button>
            </Link>,
            user?.userLevel === 1 && (
              <Button
                onClick={() => {
                  setUserToDelete({
                    id: item._id,
                    name: `${item.first_name} ${item.last_name}`
                  })
                  setOpenDeleteModal(true)
                }}
                color='danger'
              >
                Largo nga lista e zezë
              </Button>
            )
          ]
        })
        setBlacklist(_data.reverse())
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? 'Ndodhi nje gabim!')
    } finally {
      setLoading(false)
    }
  }

  const getBlacklist = () => {
    const url = `${BASE_API}/blacklist?from=${dateFrom ? dateFrom.getTime() : ''}&to=${dateTo ? dateTo.getTime() : ''}`
    fetchBlacklist(url)
  }

  const deleteOneFromBlackList = async () => {
    setLoading(true)
    try {
      if (user?.userLevel !== 1) {
        toast.error(error?.response?.data?.message ?? 'Ndodhi nje gabim!')
      } else {
        await axios
          .delete(`${BASE_API}/blacklist/${userToDelete?.id}`)
          .then((data) => {
            toast.success('Personi u largua me sukses nga lista e zezë')
            getBlacklist()
            setOpenDeleteModal(false)
          })
          .catch((e) => console.log('error', e))
          .finally(() => {
            setLoading(false)
          })
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? 'Ndodhi nje gabim!')
    }
  }

  const columns = [
    {
      name: 'Emri',
      label: 'Emri',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Mbiemri',
      label: 'Mbiemri',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Email',
      label: 'Email',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Numri personal',
      label: 'Numri personal',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Shtuar nga',
      label: 'Shtuar nga',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Më shumë',
      label: 'Më shumë',
      options: {
        filter: false,
        sort: false,
        download: false
      }
    },
    user?.userLevel === 1 && {
      name: 'Largo nga lista e zezë',
      label: 'Largo nga lista e zezë',
      options: {
        filter: false,
        sort: false,
        download: false
      }
    }
  ]

  const options = {
    download: false,
    print: false,
    filter: false,
    search: false,
    filterType: 'dropdown',
    selectableRows: 'none',
    responsive: 'scroll',
    elevation: 0,
    maxHeight: 'none',
    textLabels: {
      body: {
        noMatch: loading ? 'Loading...' : 'Nuk ka të dhëna'
      }
    },
    customToolbar: () => {
      return (
        <Tooltip title={'Shkarko CSV'}>
          <IconButton
            aria-label={'Shkarko CSV'}
            onClick={() => {
              downloadCsv()
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      )
    }
  }

  const changeInputValue = (formName, formValue) => {
    setFormInputs((prev) => {
      return {
        ...prev,
        [formName]: formValue
      }
    })
  }

  const inputsData = {
    twoInRow: [
      { label: 'Emri', name: 'first_name' },
      { label: 'Mbiemri', name: 'last_name' }
    ],
    oneInRow: [
      { label: 'Numri Personal', name: 'idNumber' },
      { label: 'Email', name: 'email', type: 'email' },
      { label: 'Arsyeja', name: 'description', type: 'textarea' }
    ]
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ContentWrapper>
        <Modal isOpen={blacklistModal} toggle={toggleBlacklistModal} className='report_modal'>
          <ModalHeader toggle={toggleBlacklistModal}>Shto një person në listën e zezë</ModalHeader>
          <ModalBody>
            <div>
              <div className='d-flex justify-content-between align-items-center'>
                {inputsData.twoInRow.map((inputData, i) => (
                  <div style={{ width: '45%' }} key={i}>
                    <span>
                      {inputData.label} <span className='text-danger'>*</span>
                    </span>
                    <Input value={formInputs[inputData.name].value} onChange={(e) => changeInputValue(inputData.name, e.target.value)} />
                  </div>
                ))}
              </div>
              {inputsData.oneInRow.map((inputData, i) => (
                <div className='mt-2' key={i}>
                  <span>
                    {inputData.label} <span className='text-danger'>*</span>
                  </span>
                  <Input
                    type={inputData.type || 'text'}
                    value={formInputs[inputData.name].value}
                    onChange={(e) => changeInputValue(inputData.name, e.target.value)}
                  />
                </div>
              ))}
              <span className='mt-4 mb-3 d-flex text-danger'>{showError ? 'Duhet të plotësohen të gjitha!' : ''}</span>
              <Button onClick={() => addToBlacklist()} color='danger'>
                Dërgo në listën e zezë
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={openDeleteModal} toggle={() => setOpenDeleteModal(!openDeleteModal)} className='delete_modal'>
          <ModalHeader toggle={() => setOpenDeleteModal(!openDeleteModal)}>Largo nga lista e zezë</ModalHeader>
          <ModalBody>
            <p style={{ fontSize: '18px' }}>Dëshironi të largoni nga lista e zezë {userToDelete?.name} ?</p>
            <div className='d-flex justify-content-around mt-4'>
              <Button onClick={() => setOpenDeleteModal(false)} color='succes'>
                Anulo
              </Button>
              <Button
                onClick={() => {
                  deleteOneFromBlackList()
                }}
                color='danger'
              >
                Largo
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <div className='content-heading'>
          <div>Lista e zezë</div>{' '}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-end'
            }}
            className='ml-auto'
          >
            <Button onClick={toggleBlacklistModal} color='success' className='btn-oval'>
              Shto në listën e zezë
            </Button>
          </div>
        </div>
        <Box>
          <Card className={`shadow bg-white ${loading && 'whirl standard'}`}>
            <CardBody>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: max900 && 'column'
                }}
              >
                <Box
                  style={{
                    width: max900 ? '100%' : '40%',
                    marginBottom: max900 && '10px'
                  }}
                >
                  <TextField
                    variant='outlined'
                    label='Kërko personin...'
                    size='small'
                    fullWidth
                    className={classes.textfield}
                    value={name}
                    onChange={(e) => {
                      setDateFrom(null)
                      setDateTo(null)
                      setName(e.target.value)
                      // if (e.target.value !== '') {
                      //   getBlacklistByName(e.target.value)
                      // } else {
                      //   getBlacklist()
                      // }
                    }}
                  />
                </Box>
                <Box
                  style={{
                    width: 'fit-content',
                    marginLeft: 'auto',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <DatePicker
                    clearable
                    openTo='year'
                    inputFormat='dd/MM/yyyy'
                    views={['year', 'month', 'day']}
                    componentsProps={{
                      actionBar: {
                        actions: ['clear']
                      }
                    }}
                    label='Prej datës'
                    value={dateFrom}
                    onChange={(newValue) => {
                      setDateFrom(newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        size='small'
                        className={classes.textfield}
                        style={{ width: max600 ? '120px' : '180px' }}
                      />
                    )}
                  />
                  <DatePicker
                    clearable
                    openTo='year'
                    inputFormat='dd/MM/yyyy'
                    views={['year', 'month', 'day']}
                    componentsProps={{
                      actionBar: {
                        actions: ['clear']
                      }
                    }}
                    label='Deri më'
                    value={dateTo}
                    onChange={(newValue) => {
                      setDateTo(newValue)
                    }}
                    minDate={dateFrom}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        size='small'
                        className={classes.textfield}
                        style={{
                          width: max600 ? '120px' : '180px',
                          marginRight: '20px',
                          marginLeft: '20px'
                        }}
                      />
                    )}
                  />
                  <Button
                    color='info'
                    onClick={() => {
                      setName('')
                      getBlacklist(dateFrom, dateTo)
                    }}
                  >
                    Kërko
                  </Button>
                </Box>
              </Box>
              <MUIDataTable
                // align="right"
                columns={columns}
                options={options}
                data={blacklist}
              />
            </CardBody>
          </Card>
        </Box>
      </ContentWrapper>
    </LocalizationProvider>
  )
}

export default BlacklistsComponent
