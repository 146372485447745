import React, { useState } from 'react'
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import birthday_card from '../../assets/images/birthday_card.png'

const BirthdayWish = (props) => {
  // Check in session storage if user has read the birthday
  const getReadBirthday = sessionStorage.getItem(props.user_email)

  const readBirthday = getReadBirthday === null ? true : false
  const [showModal, setShowModal] = useState(readBirthday)
  const toggleModal = () => {
    setShowModal(!showModal)
    // if user has read the birthday, we set session Storage to false and opened modal will not be shown
    sessionStorage.setItem(props.user_email, false)
  }

  return (
    <Row>
      <Modal className='modal-lg modal-dialog-centered' isOpen={showModal} toggle={() => toggleModal()}>
        <ModalHeader>
          Gëzuar Ditëlindjen, {props.name} {props.surname}
        </ModalHeader>
        <ModalBody style={{ display: 'flex', paddingBottom: '1px' }}>
          <div className='list-group px-3'>
            <img
              src={birthday_card}
              className='img-fluid shadow-2-strong rounded'
              style={{ display: 'block', maxWidth: '100%', height: 'auto' }}
              alt=''
            />
          </div>
        </ModalBody>
        <ModalFooter
          style={{
            justifyContent: 'center',
            borderTop: 'none',
            paddingBottom: '24px',
            paddingTop: '24px'
          }}
        >
          <div className='col-lg-6'>
            <Row className='justify-content-center align-self-center'>
              <Col xl={12} style={{ paddingLeft: '22px', paddingRight: '15px' }}>
                <Button color='info' className='btn-md btn-block btn-info' onClick={toggleModal}>
                  <span
                    style={{
                      display: 'inline',
                      verticalAlign: 'middle'
                    }}
                  >
                    <i className='fa fa-check' />
                  </span>
                </Button>
              </Col>
            </Row>
          </div>
        </ModalFooter>
      </Modal>
      <Col xl={{ size: 12, offset: 0 }} offset='true'>
        <div className='card b px-3 pt-3'>
          <div className='d-flex justify-content-between mb-2'>
            <h4 style={{ color: 'black' }}>
              Gëzuar Ditëlindjen, {props.name} {props.surname}
            </h4>
          </div>
          <Row>
            <Col>
              <div className='list-group '>
                <img src={birthday_card} className='img-fluid shadow-2-strong rounded' alt='' />
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  )
}

export default BirthdayWish
