import React, { PureComponent } from 'react'
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts'

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 20
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'start'
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={'#f58b54'}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <text x={ex + (cos >= 0 ? -30 : -20)} y={cos >= 0 ? ey : ey - 5} textAnchor={textAnchor} fill='#333'>{`Punëtorë: ${value}`}</text>
      <text x={ex + (cos >= 0 ? -30 : -20)} y={cos >= 0 ? ey : ey - 5} dy={18} textAnchor={textAnchor} fill='#b16142'>
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  )
}

export default class PieCustomized extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/hqnrgxpj/'

  state = {
    activeIndex: 0
  }

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index
    })
  }

  render() {
    return (
      <ResponsiveContainer height={300}>
        <PieChart>
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape}
            data={this.props.projectData}
            innerRadius={60}
            outerRadius={80}
            fill='#a34a28'
            dataKey='value'
            onMouseEnter={this.onPieEnter}
          />
        </PieChart>
      </ResponsiveContainer>
    )
  }
}
