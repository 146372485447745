import axios from 'axios'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import DatePickers from './DatePickers'
import OrderForm from './OrderForm'
import OrdersStats from './OrdersStats'
import OrdersTable from './OrdersTable'
import { qualityDepartment } from './EnumOrder'
import jwtDecode from 'jwt-decode'

const Orders = ({ type }) => {
  const token = localStorage.getItem('token')
  const decodedToken = token ? jwtDecode(token) : null
  const loggedUserDepartment = decodedToken?.department[0] || ''
  const isQualityUser = loggedUserDepartment.includes(qualityDepartment)
  const user_level = decodedToken?.user_level || ''
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(async () => {
    try {
      const { data } = await axios.get(type == 'user' ? 'orders/getUserOrders' : 'orders')
      if (data.success) {
        const _data = await data.orders.map((d) => {
          return [
            `${d.user_id.first_name} ${d.user_id.last_name} ${d.user_id.department[0].includes(qualityDepartment) ? '(From Quality)' : ''}`,
            // `${d.user_id.first_name} ${d.user_id.last_name}`,
            d.order_type.name,
            d.order_type?.category?.name,
            d.order_id,
            d.user_id.department[0].includes(qualityDepartment) ? 'Yes' : 'No',
            moment(d.activation_date).format('DD/MM/YYYY'),

            (d.user_id.department[0].includes(qualityDepartment) || !isQualityUser) && (
              <Link to={`/editOrder/${d._id}`}>
                <Button outline className='mb-1' color='warning' type='button'>
                  <span>Modifiko</span>
                </Button>
              </Link>
            )
          ]
        })
        setData(_data)
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      console.log('error', e)
    }
  }, [])

  return (
    <div>
      <div className='content-heading d-flex justify-content-between'>
        <div>Orders</div>
      </div>
      {user_level === 4 && !isQualityUser ? '' : <DatePickers />}
      {type == 'user' && (
        <div>
          <OrderForm setOrders={setData} />
        </div>
      )}

      {type == 'all' && <OrdersStats />}

      {!loading && <OrdersTable orders={data} type={type} loading={loading} />}
    </div>
  )
}

export default Orders
