import axios from 'axios'
import { Button, Col, Row } from 'reactstrap'
import { useState, useEffect } from 'react'
import moment from 'moment'
import Swal from 'sweetalert2'

const ResignInterviewCard = ({ userID, firstName, lastName }) => {
  const [data, setData] = useState([])

  const downloadInterviewDoc = async (interviewId) => {
    Swal.fire({
      title: 'Ju lutem prisni...',
      text: 'Duke gjeneruar dokumentin',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    const filename = `IntervistaDalese${firstName}_${lastName}.docx`

    const response = await axios.get(`resignInterview/me/${interviewId}/wordDoc`, { responseType: 'blob' })
    if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      saveAs(response.data, filename)
      Swal.close()
      Swal.fire({
        title: 'Te dhenat u shkarkuan me sukses.',
        text: 'Dokumenti eshte gjeneruar me sukses!',
        type: 'success'
      })
    } else {
      if (!response.success) {
        console.log('Response failed with message:', response.message)
        Swal.fire({
          title: 'Gabim gjatë shkarkimit të dokumentit',
          text: `Gabim gjatë shkarkimit të dokumentit ${response.message}`,
          type: 'warning'
        })
      } else {
        console.log('Response failed:')
        Swal.fire({
          title: 'Gabim gjatë shkarkimit të dokumentit',
          text: 'Gabim gjatë shkarkimit të dokumentit',
          type: 'warning'
        })
      }
    }
  }

  useEffect(() => {
    const getMyResignInterview = async () => {
      await axios.get(`resignInterview/me/${userID}?limit=1&sort=-createdAt`).then((response) => {
        if (response.data.success === true) {
          setData(response.data.data)
        }
      }),
        (error) => {
          console.log('error', error)
        }
    }
    getMyResignInterview()
  }, [])

  return (
    <div className='card card-default'>
      <div className='card-header d-flex align-items-center'>
        <div className='d-flex justify-content-center col'>
          <div className='h4 m-0 text-center'>Intervista Dalëse</div>
        </div>
      </div>
      <div className='card-body'>
        {data.length < 0 ? (
          <div className='d-flex justify-content-center col'>
            <div className='h6 m-0 text-center'>Nuk ka file të ngarkuar</div>
          </div>
        ) : (
          <Col lg='12'>
            <Row className='d-flex justify-content-center'>
              {data?.map((file, index) => {
                return (
                  <Col lg='4' key={index}>
                    <div className='card' data-filter-group='audio'>
                      <div className='card-body text-center'>
                        <div className='ml-auto'>
                          <Button onClick={() => downloadInterviewDoc(file._id)} className='px-4'>
                            <em className={`fa-3x far text-info`} />
                            Shkarko
                          </Button>
                          <div className='m-0 text-muted'>
                            <small>{moment(file.createdAt).fromNow()}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </Col>
        )}
      </div>
    </div>
  )
}
export default ResignInterviewCard
