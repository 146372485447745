import React, { PureComponent } from 'react'
import { PieChart, Pie, Sector, ResponsiveContainer, Text } from 'recharts'

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, name } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 10) * cos
  const my = cy + (outerRadius + 10) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'start'

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={'#c19191'}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />

      <Text
        angle={-30}
        x={ex + (cos >= 0 ? -15 : -35)}
        y={cos >= 0 ? ey + 5 : ey}
        dy={10}
        textAnchor={textAnchor}
        fill='#333'
      >{`Punëtorë: ${value}`}</Text>
      <Text angle={-30} x={ex + (cos >= 0 ? -7 : -30)} y={cos >= 0 ? ey - 2 : ey} dy={25} textAnchor={textAnchor} fill='#b16142'>
        {name}
      </Text>
    </g>
  )
}

export default class PieCustomizedFill extends PureComponent {
  state = {
    activeIndex: 0
  }

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index
    })
  }

  render() {
    return (
      <ResponsiveContainer height={300}>
        <PieChart>
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape}
            data={this.props.projectData}
            innerRadius={0}
            outerRadius={80}
            fill='#8b5d5d'
            dataKey='value'
            onMouseEnter={this.onPieEnter}
          />
        </PieChart>
      </ResponsiveContainer>
    )
  }
}
