export const usersWorkHoursPercentage = [
  {
    percentage: 100,
    value: '7.5h'
  },
  {
    percentage: 96.7,
    value: '7.25h'
  },
  {
    percentage: 93.3,
    value: '7h'
  },
  {
    percentage: 90,
    value: '6.75h'
  },
  {
    percentage: 88.8,
    value: '6.66h'
  },
  {
    percentage: 86.7,
    value: '6.5h'
  },
  {
    percentage: 83.3,
    value: '6.25h'
  },
  {
    percentage: 80,
    value: '6h'
  },
  {
    percentage: 76.7,
    value: '5.75h'
  },
  {
    percentage: 73.3,
    value: '5.5h'
  },
  {
    percentage: 70,
    value: '5.25h'
  },
  {
    percentage: 66.7,
    value: '5h'
  },
  {
    percentage: 63.3,
    value: '4.75h'
  },
  {
    percentage: 60,
    value: '4.5h'
  },
  {
    percentage: 56.7,
    value: '4.25h'
  },
  {
    percentage: 53.3,
    value: '4h'
  },
  {
    percentage: 50,
    value: '3.75h'
  },
  {
    percentage: 46.7,
    value: '3.5h'
  },
  {
    percentage: 43.3,
    value: '3.25h'
  },
  {
    percentage: 40,
    value: '3h'
  }
]
