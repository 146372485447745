import axios from 'axios'
import moment from 'moment'
import pubsub from 'pubsub-js'
import React from 'react'
import 'react-datetime/css/react-datetime.css'
import { Button, Card, CardBody, CardFooter, Col, Input, Row } from 'reactstrap'
import { DeleteDialogBox } from '../../Common/'
import FormValidator from '../../Forms/FormValidator.js'
import ContentWrapper from '../../Layout/ContentWrapper'
import DateTimeBoostrap from '../../Forms/DateTimeBootstrap'

class EditHolidays extends React.Component {
  constructor() {
    super()
    this.state = {
      isEdit: false,
      formHoliday: {
        nameHoliday: '',
        descHoliday: ''
      },
      selectedDate: null,
      dateTo: null
    }
  }
  _onDateChange = (date) => {
    if (typeof date == 'object') {
      this.setState({ selectedDate: date })
    }
  }

  _onDateBlur = (date) => {
    this.setState({ selectedDate: moment(date, 'DD/MM/YYYY') })
  }

  deleteHoliday(id) {
    const goBack = () => this.props.history.goBack()
    const path = `holidays/${id}`
    const name = 'Pushimin'
    DeleteDialogBox({ path, name, goBack })
  }

  async componentDidMount() {
    const idHoliday = this.props.match.params.idHoliday
    if (idHoliday) {
      try {
        const res = await axios.get('holidays/' + idHoliday, {})
        this.setState({
          isEdit: true,
          formHoliday: {
            nameHoliday: res.data.data.name,
            descHoliday: res.data.data.description
          },
          selectedDate: moment(res.data.data.date)
        })
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  validateOnChange = (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value
    const result = FormValidator.validate(input)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
  }

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  onSubmit = (e) => {
    e.preventDefault()
    const idHoliday = this.props.match.params.idHoliday
    const form = e.target
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })
    if (!hasError) {
      const postData = {
        name: this.state.formHoliday.nameHoliday,
        description: this.state.formHoliday.descHoliday,
        date: moment(this.state.selectedDate).format('YYYY-MM-DD')
      }
      if (idHoliday) {
        axios
          .put(`holidays/${idHoliday}`, postData)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push('/holidays')
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ndryshuar!',
                type: 'success'
              })
            } else {
              pubsub.publish('showNotification', {
                message: 'Gabim gjatë ndryshimit të të dhënave',
                type: 'error'
              })
            }
          })

          .catch((err) => {
            pubsub.publish('showNotification', {
              message: 'Gabim gjatë ruajtjes së të dhënave',
              type: 'error'
            })
          })
      } else {
        axios
          .post('holidays', postData)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push('/holidays')
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ruajtur!',
                type: 'success'
              })
            } else {
              pubsub.publish('showNotification', {
                message: res.data.message,
                type: 'error'
              })
            }
          })
          .catch((e) => {
            console.log('error', e)
          })
      }
    }
  }

  render() {
    const id = this.props.match.params.idHoliday
    return (
      <ContentWrapper>
        {this.state.isEdit ? (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Modifiko Pushimin</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Shto Pushim</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        )}
        <Row>
          <div className='col-md-12'>
            <form onSubmit={this.onSubmit} name='formHoliday'>
              {/* START card */}
              <Card className='card-default'>
                <CardBody>
                  <legend className='mb-4' />
                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Emri i pushimit :</label>
                      <Col md={6}>
                        <Input
                          key='nameHoliday'
                          type='text'
                          name='nameHoliday'
                          invalid={this.hasError('formHoliday', 'nameHoliday', 'required')}
                          onChange={this.validateOnChange}
                          data-validate='["required"]'
                          value={this.state.formHoliday.nameHoliday}
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                      <Col md={4} />
                    </div>
                  </fieldset>

                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Përshkrimi i pushimit :</label>
                      <Col md={6}>
                        <Input
                          key='descHoliday'
                          type='text'
                          name='descHoliday'
                          onChange={this.validateOnChange}
                          value={this.state.formHoliday.descHoliday}
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                      <Col md={4} />
                    </div>
                  </fieldset>

                  <fieldset>
                    <div className='form-group row mb'>
                      <label className='col-md-2 col-form-label mb'>Data</label>
                      <Col md={6}>
                        <DateTimeBoostrap
                          key='selectDate'
                          value={this.state.selectedDate}
                          onChange={this._onDateChange}
                          locale='de'
                          utc={true}
                          dateFormat='DD/MM/YYYY'
                          onBlur={this._onDateBlur}
                          data-validate='["validDate"]'
                          timeFormat={false}
                          inputProps={{
                            'data-validate': '["validDate"]',
                            className: 'form-control',
                            name: 'data',
                            autoComplete: 'off',
                            invalid: this.hasError('formHoliday', 'data', 'validDate')
                          }}
                        />
                      </Col>
                    </div>
                  </fieldset>
                </CardBody>
                {this.state.isEdit ? (
                  <CardFooter className='text-right'>
                    <button style={{ marginRight: 10 }} type='submit' className='btn btn-info'>
                      Ruaj / Modifiko
                    </button>
                    <Button
                      color='danger'
                      onClick={() => {
                        this.deleteHoliday(id)
                      }}
                    >
                      Fshij
                    </Button>
                  </CardFooter>
                ) : (
                  <CardFooter className='text-right'>
                    <button type='submit' className='btn btn-info'>
                      Ruaj
                    </button>
                  </CardFooter>
                )}
              </Card>
              {/* END card */}
            </form>
          </div>
        </Row>
      </ContentWrapper>
    )
  }
}

export default EditHolidays
