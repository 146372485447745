import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextField } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  textfield: {
    width: '100%' // Adjust as needed
    // fieldset: {
    //   borderBottom: '1px solid rgba(0,0,0,0.23)'
    // }
  }
}))

export default function CustomDatePicker({ label, value, onChange, minDate, maxDate }) {
  const classes = useStyles()
  const max600 = useMediaQuery('(max-width:600px)')

  return (
    <DatePicker
      clearable
      openTo='year'
      inputFormat='dd/MM/yyyy'
      views={['year', 'month', 'day']}
      componentsProps={{
        actionBar: {
          actions: ['clear']
        }
      }}
      label={label}
      value={value}
      fullWidth
      onChange={onChange}
      maxDate={maxDate}
      minDate={minDate}
      renderInput={(params) => <TextField {...params} variant='outlined' size='small' className={classes.textfield} />}
    />
  )
}
