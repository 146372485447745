import Datetime from 'react-datetime'
import { Input } from 'reactstrap'
import React from 'react'

function DateTimeBoostrap(props) {
  return (
    <Datetime
      closeOnSelect={true}
      {...props}
      utc={false}
      renderInput={(props) => (
        <div>
          <Input {...props} autoComplete='off' />
          <span className='invalid-feedback'>Data e formatuar gabim</span>
        </div>
      )}
    />
  )
}

export default DateTimeBoostrap
