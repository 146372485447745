
import { Box, Grid, Card, Avatar, CardHeader, Typography, CardContent } from '@mui/material'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import EventIcon from '@material-ui/icons/Event';

const SalaryCard = ({ paidLeavesHours,
  paidLeavesMoney,
  worktimeMoney,
  worktimeHours,
  overtimeHourse,
  overtimeMoney,
  weekendMoney,
  weekendtimeHourse }) => {
  const salesData = [
    {
      stats: `€ ${parseFloat(worktimeMoney).toFixed(2) || 0}`,
      title: `Orë standarde (${parseFloat(worktimeHours).toFixed(2) || 0})`,
      color: 'primary',
      icon: <HourglassEmptyIcon sx={{ fontSize: '1.75rem' }} />
    },
    {
      stats: `€ ${parseFloat(weekendMoney).toFixed(2) || 0}`,
      title: `Orë Vikendi (${parseFloat(weekendtimeHourse).toFixed(2)})`,
      color: 'success',
      icon: <EventIcon sx={{ fontSize: '1.75rem' }} />
    },
    {
      stats: `€ ${parseFloat(overtimeMoney).toFixed(2) || 0}`,
      color: 'warning',
      title: `Orë shtesë (${parseFloat(overtimeHourse).toFixed(2)})`,
      icon: <AccessTimeIcon sx={{ fontSize: '1.75rem' }} />
    },
    {
      stats: `€ ${parseFloat(paidLeavesMoney).toFixed(2) || 0}`,
      color: 'info',
      title: `Pushime (${parseFloat(paidLeavesHours).toFixed(2) || 0})`,
      icon: <BeachAccessIcon sx={{ fontSize: '1.75rem' }} />
    }
  ]
  const salesInfo = salesData.map((item, index) => (
    <Grid item xs={12} sm={12} md={6} lg={3} key={index}>
      <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          variant='rounded'
          sx={{
            mr: 2,
            width: 44,
            height: 44,
            boxShadow: 3,
            color: 'common.white',
            backgroundColor: `${item.color}.main`
          }}
        >
          {item.icon}
        </Avatar>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='caption'>{item.title}</Typography>
          <Typography variant='h6'>{item.stats}</Typography>
        </Box>
      </Box>
    </Grid>))

  return (
    <Card sx={{ position: "relative", zIndex: "99" }}>
      <CardHeader
        sx={{ padding: "24px 10px !important" }}
        title='Ndërtimi i pagës'
        subheader={
          <Typography variant='body2'>
            <Box component='span' sx={{ fontWeight: 600, color: 'text.primary' }}>
              Përbërja e pagës bazë:
            </Box>{' '}
          </Typography>
        }
        titleTypographyProps={{
          sx: {
            marginBottom: "20px !important",
            lineHeight: '2rem !important',
            letterSpacing: '0.15px !important'
          }
        }}
      />
      <CardContent sx={{ pt: theme => `${theme.spacing(3)} !important` }}>
        <Grid container spacing={[5, 0]}>
          {salesInfo}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default SalaryCard