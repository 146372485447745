import { Card, TextField, CardContent, FormControl, Grid, InputLabel, Select, MenuItem } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Locale from 'date-fns/locale/sq'
import { expectedDepartments } from '../../utils/utils'

const Filters = ({ queryParams, updateQueryParam, filterDepartemnt }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
      <Card
        variant='outlined'
        sx={{
          width: '100%',
          backgroundColor: '#fff',
          borderColor: ' #fff !important',
          mt: 2
        }}
      >
        <Grid
          container
          sx={{
            fieldset: {
              borderBottom: '1px solid rgba(0,0,0,0.23)'
            }
          }}
        >
          <Grid xs={12} md={4} lg={4}>
            <CardContent>
              <FormControl size='small' sx={{ m: 0, width: '100%' }}>
                <TextField
                  size='small'
                  fullWidth
                  id='outlined-error-helper-text'
                  label='Search...'
                  sx={{
                    '.MuiInputBase-input': {
                      padding: '11px 14px  !important'
                    }
                  }}
                  value={queryParams.search}
                  onChange={(e) => updateQueryParam((prevState) => ({ ...prevState, search: e.target.value }))}
                />
              </FormControl>
            </CardContent>
          </Grid>
          {filterDepartemnt && (
            <Grid xs={12} md={5} lg={3}>
              <CardContent>
                <FormControl size='small' sx={{ m: 0, width: '100%' }}>
                  <InputLabel id='department'>Zgjidh Departamentin</InputLabel>
                  <Select
                    size='small'
                    label='Zgjidh Departamentin'
                    sx={{
                      '.MuiInputBase-input': {
                        padding: '9px 14px !important'
                      }
                    }}
                    value={queryParams.department}
                    onChange={(e) => updateQueryParam((prevState) => ({ ...prevState, department: e.target.value }))}
                  >
                    <MenuItem key={'Te gjitha'} value={'all'} sx={{ display: 'block !important', padding: '5px 15px !important' }}>
                      Te gjitha
                    </MenuItem>
                    {expectedDepartments.map((departemnt) => (
                      <MenuItem
                        key={departemnt._id}
                        value={departemnt._id}
                        sx={{ display: 'block !important', padding: '5px 15px !important' }}
                      >
                        {departemnt.department_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardContent>
            </Grid>
          )}
        </Grid>
      </Card>
    </LocalizationProvider>
  )
}
export default Filters
