import axios from 'axios'

const MAIN_PATH = 'complianceRecords'

export const getComplianceData = async (page, limit, type, query) => {
  try {
    const res = await axios.get(`${MAIN_PATH}?page=${page}&limit=${limit}&type=${type}&${query}`)
    return { data: res.data }
  } catch (error) {
    console.error('Error in getComplianceData:', error)
    throw error
  }
}

export const complianceLoginsUpdate = async (params, body) => {
  try {
    const res = await axios.patch(`${MAIN_PATH}/${params}`, { ...body })
    return { data: res }
  } catch (error) {
    console.error('Error in complianceLoginsUpdate:', error)
    throw error
  }
}

export const downloadComplianceCsv = async (type, query) => {
  try {
    const res = axios.get(`${MAIN_PATH}/csv?type=${type}&${query}`, {
      responseType: 'blob'
    })
    return res
  } catch (error) {
    console.error('Error in downloadComplianceCsv:', error)
    throw error
  }
}
