import { Delete } from '@material-ui/icons'
import { Checkbox, IconButton, Tooltip, Box } from '@mui/material'
import { languages } from './OpenPosition'

const Position = ({ job, updatePosition, deletePosition, isActive }) => {
  return (
    <Tooltip
      placement='right'
      key={job._id}
      title={Object.keys(job.translations)?.map((item) => (
        <Box sx={{ display: 'flex' }}>
          <p>{languages[item]}: </p>
          <p style={{ marginLeft: '5px' }}>{job.translations[item]}</p>
        </Box>
      ))}
    >
      <div
        key={job._id}
        style={{
          border: '1px solid #eaeaea',
          width: 'fit-content',
          padding: '4px 10px',
          borderRadius: '8px',
          backgroundColor: '#fff',
          margin: '2px 10px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <p style={{ marginBottom: 0, fontSize: '20px' }}>{job?.job_title}</p>
        {(!isActive || (isActive && job.job_title !== 'Këshilltar për kujdes ndaj klientit')) && (
          <Checkbox defaultChecked={isActive} onChange={() => updatePosition(job._id, !isActive)} />
        )}
        {!isActive && (
          <IconButton onClick={() => deletePosition(job._id)}>
            <Delete />
          </IconButton>
        )}
      </div>
    </Tooltip>
  )
}

export default Position
