export const applicantTableColumns = [
  {
    name: 'Emri',
    label: 'Emri',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'Mbiemri',
    label: 'Mbiemri',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'Email',
    label: 'Email',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'Pozita',
    label: 'Pozita',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'Location',
    label: 'Vendi i Punës',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'Diaspore',
    label: 'Diasporë',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'Data',
    label: 'Data e aplikimit',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'Statusi',
    label: 'Statusi',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span
              className='badge bg-info'
              style={{
                padding: '10px',
                borderRadius: '3px',
                letterSpacing: '1.2px'
              }}
            >
              {value?.status}
            </span>
            <span
              className='badge bg-warning'
              style={{
                padding: '10px',
                borderRadius: '3px',
                letterSpacing: '1.2px'
              }}
            >
              {value?.ex && 'Ish punëtor'}
            </span>
          </div>
        )
      }
    }
  },
  {
    name: 'Më shumë',
    label: 'Më shumë',
    options: {
      filter: false,
      sort: false,
      download: false
    }
  }
]
