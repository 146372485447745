import axios from 'axios'
import { inject } from 'mobx-react'
import queryString from 'query-string'
import { Component } from 'react'
import { Link } from 'react-router-dom'
import { Input } from 'reactstrap'
import FormValidator from '../Forms/FormValidator.js'

@inject('userStore')
class PasswordReset extends Component {
  state = {
    formReset: {
      password1: '',
      password2: ''
    },
    passwordSent: false,
    email: '',
    token: '',
    errorMessage: '',
    passwordReset: false
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search)
    if (query.email && query.token) {
      this.setState({
        email: query.email,
        token: query.token
      })
    } else {
      this.props.history.push('/login')
    }
  }

  /**
   * Validate input using onChange event
   * @param  {String} formName The name of the form in the state object
   * @return {Function} a function used for the event
   */
  validateOnChange = (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value
    const result = FormValidator.validate(input)

    this.setState({
      errorMessage: '',
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      },
      errorMessage: ''
    })
    if (!hasError) {
      if (this.state[form.name].password1 == this.state[form.name].password2) {
        const postData = {
          password1: this.state[form.name].password1,
          password2: this.state[form.name].password2
        }
        axios
          .post(
            `https://liyakq8s5d.execute-api.eu-central-1.amazonaws.com/prod/api/resetPassword?email=${this.state.email}&reset_token=${this.state.token}`,
            postData
          )
          .then((response) => {
            if (response.data.success) {
              this.setState({ passwordReset: true })
            } else {
              this.setState({ errorMessage: response.data.message })
            }
          })
          .catch((e) => {
            this.setState({ errorMessage: 'Server error' })
          })
      } else {
        this.setState({
          errorMessage: 'Ju lutem shenoni fjalekalimin e njejte ne dy fushat'
        })
      }
    }
  }

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  render() {
    return (
      <div className='row h-100 main-container'>
        <div className='text-center col-lg-7 left-container'>
          <Link to='/login' className='text-muted'>
            <img className='block-center rounded' src='img/logo.png' alt='Logo' />
          </Link>
        </div>
        <span className='divider' />
        <div className='col-lg-3 right-container'>
          <p className='text-center py-2'>Resetimi i passwordit</p>
          {this.state.passwordReset ? (
            <p className='text-center'>Fjalekalimi i ri u vendos me sukses.</p>
          ) : (
            <form name='formReset' onSubmit={this.onSubmit}>
              <p className='text-center'>Ju lutem vendosni fjalekalimin e ri.</p>
              {this.state.errorMessage != '' && <p className='text-center text-danger'>{this.state.errorMessage}</p>}
              <div className='form-group'>
                <label className='text-muted' htmlFor='password1'>
                  Fjalekalimi i ri:
                </label>
                <div className='input-group with-focus'>
                  <Input
                    type='password'
                    name='password1'
                    className='border-right-0'
                    placeholder='Shenoni fjalekalimin e ri'
                    invalid={this.hasError('formReset', 'password1', 'required')}
                    onChange={this.validateOnChange}
                    data-validate='["required"]'
                    value={this.state.formReset.pass}
                  />
                  <div className='input-group-append'>
                    <span className='input-group-text text-muted bg-transparent border-left-0'>
                      <em className='fa fa-key'></em>
                    </span>
                  </div>
                  {this.hasError('formReset', 'password1', 'required') && (
                    <span className='invalid-feedback'>Ju lutem plotesoni fushen</span>
                  )}
                </div>
              </div>
              <div className='form-group'>
                <label className='text-muted' htmlFor='password2'>
                  Perseritni fjalekalimin i ri:
                </label>
                <div className='input-group with-focus'>
                  <Input
                    type='password'
                    name='password2'
                    className='border-right-0'
                    placeholder='Perserisni fjalekalimin e ri'
                    invalid={this.hasError('formReset', 'password2', 'required')}
                    onChange={this.validateOnChange}
                    data-validate='["required"]'
                    value={this.state.formReset.pass}
                  />
                  <div className='input-group-append'>
                    <span className='input-group-text text-muted bg-transparent border-left-0'>
                      <em className='fa fa-key'></em>
                    </span>
                  </div>
                  {this.hasError('formReset', 'password2', 'required') && (
                    <span className='invalid-feedback'>Ju lutem plotesoni fushen</span>
                  )}
                </div>
              </div>
              <button className='btn btn-success btn-block' type='submit'>
                Rivendos fjalekalimin e ri
              </button>
            </form>
          )}
        </div>
      </div>
    )
  }
}

export default PasswordReset
