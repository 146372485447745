import axios from 'axios'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import { useEffect, useState, useRef, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, Col, Row, TabPane } from 'reactstrap'
import ContentWrapper from '../../../components/Layout/ContentWrapper'
import UsersDataTable from '../components/UsersDataTable'
import UserHistoryModal from '../components/UsersHistoryModal'
import { getColumns } from '../utils/columns'
import { _exportData, data } from '../utils/data'
import { getOptions } from '../utils/options'

const Users = () => {
  const [users, setUsers] = useState([])
  const [count, setCount] = useState(20)
  const [total, setTotal] = useState(0)
  const [projects, setProjects] = useState([])
  const [roles, setRoles] = useState([])
  const [departments, setDepartments] = useState([])
  const [activities, setActivities] = useState([])
  const [workingStatus, setWorkingStatus] = useState([])
  const [languages, setLanguages] = useState([])
  const [selectedFilters, setSelectedFilters] = useState({
    page: 0,
    searchText: '',
    fromDateValue: null,
    toDateValue: null,
    language: undefined,
    project: undefined,
    department: undefined,
    function: undefined,
    activity: undefined,
    workingStatus: undefined,
    office: undefined
  })
  const [renderFilters, setRenderFilters] = useState({
    activity: '',
    workingStatus: '',
    project: '',
    department: '',
    function: '',
    language: '',
    offices: ''
  })
  const [showFilters, setShowFilters] = useState({
    department: true,
    activity: false,
    workingStatus: false,
    identityID: false,
    createdDate: false,
    city: false,
    manager: false,
    teamLeader: false,
    language: true,
    offices: false
  })

  const [isLoading, setIsLoading] = useState(true)
  const token = localStorage.getItem('token')
  const decodedToken = token ? jwtDecode(token) : null
  const userLevel = decodedToken ? decodedToken.user_level : null
  const today = moment().format('DD-MM-YYYY, HH:mm')
  const axiosCancelSource = axios.CancelToken.source()
  const offices = ['Home Office', 'Prishtinë', 'Prishtinë HQ', 'Ferizaj', 'Vushtrri', 'Prizren']

  // Ref to track initial mount
  let isInitialMount = useRef(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const [projectsRes, rolesRes, departmentsRes, activitiesRes, workingStatusRes, languagesRes, usersRes] = await Promise.all([
          axios.get('projects', { cancelToken: axiosCancelSource.token }),
          axios.get('roles', { cancelToken: axiosCancelSource.token }),
          axios.get('departments', { cancelToken: axiosCancelSource.token }),
          axios.get('activities', { cancelToken: axiosCancelSource.token }),
          axios.get('works', { cancelToken: axiosCancelSource.token }),
          axios.get('languages', { cancelToken: axiosCancelSource.token }),
          axios.get(`users/${count}/${selectedFilters.page + 1}`, { params })
        ])
        setProjects(projectsRes.data.success && projectsRes.data.data)
        setRoles(rolesRes.data.success && rolesRes.data.data)
        setDepartments(departmentsRes.data.success && departmentsRes.data.data)
        setActivities(activitiesRes.data.success && activitiesRes.data.data)
        setWorkingStatus(workingStatusRes.data.success && workingStatusRes.data.data)
        setLanguages(languagesRes.data.success && languagesRes.data.data)
        setTotal(usersRes.data.data.totalDocs)
        setUsers(usersRes.data.data.docs)
      } catch (e) {
        console.log('error', e)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
    return () => {
      axiosCancelSource.cancel('Component unmounted')
    }
  }, [])

  const params = {
    function: selectedFilters.function || undefined,
    project: selectedFilters.project || undefined,
    activity: selectedFilters.activity || undefined,
    work: selectedFilters.workingStatus || undefined,
    'department[]': selectedFilters.department || undefined,
    'languages[]': selectedFilters.language || undefined,
    office_location: selectedFilters.office || undefined,
    search: selectedFilters.searchText || undefined,
    fromDate: selectedFilters.fromDateValue || undefined,
    toDate: selectedFilters.toDateValue || undefined
  }

  const exportUsers = async () => {
    _exportData(`users/export`, `Users (${today}).csv`, 'Duke shkarkuar të dhënat për përdoruesit.', params)
  }
  const exportWarnings = () => {
    _exportData('/userwarnings/csv', `Warnings (${today}).csv`, 'Duke shkarkuar të dhënat për vërejtjet.')
  }
  const exportRejoiners = () => {
    _exportData('/users/rejoiners', `Të rikthyerit (${today}).csv`, 'Duke shkarkuar të dhënat për të rikthyerit.')
  }
  const exportTransfers = () => {
    _exportData('/users/transfers', `Transfers (${today}).csv`, 'Duke shkarkuar të dhënat për transferet.')
  }
  const exportActiveAndInactiveUsers = () => {
    _exportData('/users/activeandinactivecsv', `Përdoruesit AKTIV-JOAKTIV (${today}).csv`, 'Duke shkarkuar të dhënat për përdoruesit.')
  }
  const exportAuditReportActiveAndInactiveUsers = () => {
    _exportData('/users/auditreport-activeandinactive', `Raporti për auditor (${today}).csv`, 'Duke shkarkuar të dhënat për përdoruesit.')
  }

  const _getUsers = async () => {
    try {
      setIsLoading(true)
      const usersRes = await axios.get(`users/${count}/${selectedFilters.page + 1}`, { params })
      if (usersRes.data.success) {
        setTotal(usersRes.data.data.totalDocs)
        setUsers(usersRes.data.data.docs)
      }
    } catch (error) {
      console.error('Failed to fetch users:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      _getUsers()
    }
  }, [
    count,
    selectedFilters.page,
    selectedFilters.function,
    selectedFilters.project,
    selectedFilters.activity,
    selectedFilters.workingStatus,
    selectedFilters.department,
    selectedFilters.language,
    selectedFilters.office,
    selectedFilters.searchText,
    selectedFilters.fromDateValue,
    selectedFilters.toDateValue
  ])

  const columns = getColumns(languages, roles, projects, departments, activities, workingStatus, offices, showFilters, renderFilters)
  const options = getOptions(
    total,
    count,
    setCount,
    languages,
    roles,
    projects,
    offices,
    departments,
    activities,
    workingStatus,
    exportUsers,
    renderFilters,
    setRenderFilters,
    selectedFilters,
    setSelectedFilters,
    showFilters,
    setShowFilters
  )

  return (
    <ContentWrapper>
      <div className='content-heading'>
        <div>Perdoruesit</div>
      </div>
      {[1, 5, 6].includes(userLevel) && (
        <div className='d-flex justify-content-between align-items-center mb-3 ml-auto'>
          <div>
            <Button color='info' className='btn-oval' onClick={exportWarnings}>
              Shkarko të gjitha vërejtet
            </Button>
            <Button color='info' className='btn-oval mx-2' onClick={exportTransfers}>
              Shkarko të gjitha transferet
            </Button>
            <Button color='info' className='btn-oval mx-2' onClick={exportActiveAndInactiveUsers}>
              Shkarko të gjithë përdoruesit AKTIV & JO-AKTIV
            </Button>
            <Button color='info' className='btn-oval mx-2' onClick={exportRejoiners}>
              Shkarko të rikthyerit
            </Button>
            {userLevel === 1 && (
              <Button color='info' className='btn-oval mx-2' onClick={exportAuditReportActiveAndInactiveUsers}>
                Shkarko raportin për auditor
              </Button>
            )}
            <UserHistoryModal projects={projects} functions={roles} departments={departments} languages={languages} />
          </div>
          <Link to='/editUsers/'>
            <Button color='success' className='btn-oval'>
              Shto një Përdorues
            </Button>
          </Link>
        </div>
      )}
      <Row>
        <Col>
          <Card className='shadow bg-white'>
            <TabPane tabId='1'>
              <UsersDataTable
                columns={columns}
                options={options}
                data={data(users)}
                setSelectedFilters={setSelectedFilters}
                selectedFilters={selectedFilters}
                isLoading={isLoading}
              />
            </TabPane>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default Users
