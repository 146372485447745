import axios from 'axios'
import pubsub from 'pubsub-js'
import React from 'react'
import { Button, Card, CardBody, CardFooter, Col, Input, Row } from 'reactstrap'
import { DeleteDialogBox } from '../../Common/'
import FormValidator from '../../Forms/FormValidator.js'
import ContentWrapper from '../../Layout/ContentWrapper'

class EditBanks extends React.Component {
  constructor() {
    super()
    this.state = {
      isEdit: false,
      formBank: {
        nameBank: '',
        descriptionBank: '',
        addressBank: ''
      }
    }
  }

  validateOnChange = (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value
    const result = FormValidator.validate(input)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
  }

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  componentDidMount() {
    const idBank = this.props.match.params.idBank
    if (idBank) {
      this.setState({ isEdit: true })
    }
  }

  UNSAFE_componentWillMount() {
    const ID = this.props.match.params.idBank
    if (ID) {
      axios
        .get(`banks/${ID}`)
        .then((response) => {
          this.setState({
            formBank: {
              nameBank: response.data.data.name,
              descriptionBank: response.data.data.description,
              addressBank: response.data.data.address
            }
          })
        })
        .catch((error) => {})
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    const ID = this.props.match.params.idBank
    const form = e.target
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })

    if (!hasError) {
      const postData = {
        name: this.state.formBank.nameBank,
        description: this.state.formBank.descriptionBank,
        address: this.state.formBank.addressBank
      }
      if (ID) {
        axios
          .put(`banks/${ID}`, postData)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push('/banks')
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ruajtur!',
                type: 'success'
              })
            } else {
              pubsub.publish('showNotification', {
                message: res.data.message,
                type: 'error'
              })
            }
          })

          .catch((err) => {
            pubsub.publish('showNotification', {
              message: 'Gabim gjatë ruajtjes së të dhënave',
              type: 'error'
            })
          })
      } else {
        axios
          .post('banks', postData)
          .then((res) => {
            if (res.data.success) {
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ruajtur!',
                type: 'success'
              })
              this.props.history.push('/banks')
            } else {
              pubsub.publish('showNotification', {
                message: res.data.message,
                type: 'error'
              })
            }
          })
          .catch((err) => {
            pubsub.publish('showNotification', {
              message: 'Gabim gjatë ruajtjes të të dhënave',
              type: 'error'
            })
          })
      }
    }
  }

  deleteBank() {
    const ID = this.props.match.params.idBank
    const goBack = () => this.props.history.goBack()
    const path = `banks/${ID}`
    const name = 'Banken'
    DeleteDialogBox({ path, name, goBack })
  }

  render() {
    return (
      <ContentWrapper>
        {this.state.isEdit ? (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Modifiko Bankën</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Shto Bankën</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        )}
        <Row>
          <div className='col-md-12'>
            <form onSubmit={this.onSubmit} name='formBank'>
              {/* START card */}
              <Card className='card-default'>
                <CardBody>
                  <legend className='mb-4' />
                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Emri i Bankës :</label>
                      <Col md={6}>
                        <Input
                          type='text'
                          name='nameBank'
                          key='nameBank'
                          invalid={this.hasError('formBank', 'nameBank', 'required')}
                          onChange={this.validateOnChange}
                          value={this.state.formBank.nameBank}
                          data-validate='["required"]'
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                    </div>
                  </fieldset>

                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Përshkrimi i Bankes :</label>
                      <Col md={6}>
                        <Input
                          key='descriptionBank'
                          type='text'
                          name='descriptionBank'
                          onChange={this.validateOnChange}
                          value={this.state.formBank.descriptionBank}
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                    </div>
                  </fieldset>

                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Adressa e Bankës :</label>
                      <Col md={6}>
                        <Input
                          key='addressBank'
                          type='text'
                          name='addressBank'
                          onChange={this.validateOnChange}
                          value={this.state.formBank.addressBank}
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                    </div>
                  </fieldset>
                </CardBody>
                {this.state.isEdit ? (
                  <CardFooter className='text-right'>
                    <button style={{ marginRight: 10 }} type='submit' className='btn btn-info'>
                      Ruaj / Modifiko
                    </button>
                    <Button color='danger' onClick={this.deleteBank.bind(this)}>
                      Fshij
                    </Button>
                  </CardFooter>
                ) : (
                  <CardFooter className='text-right'>
                    <button type='submit' className='btn btn-info'>
                      Ruaj
                    </button>
                  </CardFooter>
                )}
              </Card>
              {/* END card */}
            </form>
          </div>
        </Row>
      </ContentWrapper>
    )
  }
}

export default EditBanks
