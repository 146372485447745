import { IconButton, Tooltip } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownload'

export const applicantTableOptions = (records, loading, setQuery, downloadCsv) => {
  return {
    download: false,
    print: false,
    filter: false,
    filterType: 'dropdown',
    selectableRows: 'none',
    responsive: 'scroll',
    elevation: 0,
    maxHeight: 'none',
    search: false,
    pagination: true,
    serverSide: true,
    count: records,
    rowsPerPageOptions: [10, 50, 100, 200],
    textLabels: {
      body: {
        noMatch: loading ? 'Loading...' : 'Nuk ka të dhëna'
      }
    },
    onTableChange: (action, a) => {
      switch (action) {
        case 'changeRowsPerPage':
          a.page = 0
          break
        default:
      }
    },
    onChangeRowsPerPage: (e) => {
      setQuery((prev) => ({ ...prev, limit: e, page: 0 }))
    },
    onChangePage: (e) => {
      setQuery((prev) => ({ ...prev, page: e }))
    },
    customToolbar: () => {
      return (
        <Tooltip title={'Shkarko CSV'}>
          <IconButton
            aria-label={'Shkarko CSV'}
            onClick={() => {
              downloadCsv()
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      )
    }
  }
}
