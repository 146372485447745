import { statusEnumSQ } from '../enums/statusEnumSQ'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

const formatDataForTable = (item) => [
  item.firstName,
  item.lastName,
  item.email ? item.email : item.personal_email,
  item.position,
  item.office_location,
  item.is_diaspore ? 'PO' : 'JO',
  `${moment(item?.created_at).format('LLL')}`,
  { status: statusEnumSQ[item?.status], ex: item?.ex_employee },
  <Link to={`applicants/${item._id}`}>
    <Button color='success'>Më shumë</Button>
  </Link>
]

export default formatDataForTable
