import { useState } from 'react'
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import PdfViewer from '../PdfViewer/PdfViewer'
import { pinnedDocs } from './pinnedDocsMock'

const PinnedDocs = () => {
  const [showModal, setShowModal] = useState(false)
  const [activeDoc, setActiveDoc] = useState({})

  const handleClick = (doc) => {
    setActiveDoc(doc)
    toggleModal()
  }

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  return (
    <Row>
      <Modal size='lg' style={{ maxHeight: '80vh' }} isOpen={showModal} toggle={() => toggleModal()}>
        <ModalHeader toggle={toggleModal}>{activeDoc.label}</ModalHeader>
        <ModalBody>
          <PdfViewer fileKey={activeDoc.fileKey} />
        </ModalBody>
      </Modal>
      <Col xl={{ size: 12, offset: 0 }} offset='true'>
        <div className='card b p-4'>
          <div className='d-flex justify-content-between mb-4'>
            <h4>Dokumente të rëndësishme:</h4>
            <span>
              <i>*Pinned</i>
            </span>
          </div>
          <Row>
            {pinnedDocs.map((doc, i) => (
              <Col md={6} style={{ marginBottom: 10 }} key={i}>
                <div className='list-group mb0'>
                  <div className='list-group-item bt0'>
                    <Button onClick={() => handleClick(doc)} size='xs' color='info' className='btn-labeled float-right ml-2'>
                      <span className='btn-label'>
                        <i className='fa fa-book' />
                      </span>{' '}
                      Lexo
                    </Button>
                    <em className='fa-fw fas fa-user-lock mr-2' />
                    {doc.label}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Col>
    </Row>
  )
}

export default PinnedDocs
