import * as React from 'react'
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, Checkbox, ListItemText } from '@mui/material'
import { useMediaQuery } from '@material-ui/core'

const names = ['Home Office', 'Prishtinë', 'Prishtinë HQ', 'Ferizaj', 'Vushtrri', 'Prizren']

export default function LocationFilter({ query, setQuery }) {
  const [locationName, setLocationName] = React.useState([])

  const handleChange = (event) => {
    const {
      target: { value }
    } = event
    setQuery((prevQuery) => ({
      ...prevQuery,
      location: typeof value === 'string' ? value.split(',') : value
    }))
    setLocationName(typeof value === 'string' ? value.split(',') : value)
  }

  const max1200 = useMediaQuery('(max-width:1200px)')

  return (
    <div>
      <FormControl sx={{ width: `${max1200 ? '100%' : '96%'}` }}>
        <InputLabel size='small' id='dlocation-filter'>
          Lokacioni
        </InputLabel>
        <Select
          labelId='location-filter'
          id='demo-multiple-checkbox'
          multiple
          value={query.location}
          onChange={handleChange}
          input={<OutlinedInput label='Lokacioni' size='small' />}
          renderValue={(selected) => selected.join(', ')}
          sx={{
            fieldset: {
              borderBottom: '1px solid rgba(0,0,0,0.23)'
            }
          }}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={locationName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
