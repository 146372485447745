const columns = [
  { name: 'name', label: 'Name' },
  { name: 'department', label: 'Department' },
  { name: 'order_id', label: 'Order Id' },
  { name: 'product_name', label: 'Product Name' },
  { name: 'additional_product', label: 'Additional Product' },
  { name: 'created_date', label: 'Created Date' },
  { name: 'activation_date', label: 'Activation Date' },
  { name: 'modify', label: 'Modify' }
]
export default columns
