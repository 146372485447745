import { Col, Input } from 'reactstrap'
import moment from 'moment'
import DateTimeBoostrap from '../../Forms/DateTimeBootstrap'

const DatePicker = ({ date, setDate, title }) => {
  const onDateChange = async (date) => {
    if (typeof date == 'object') {
      setDate((prevSate) => ({ ...prevSate, value: moment(date).format('YYYY-MM-DD') }))
    }
  }
  return (
    <div className='form-group row mb float-left pl-1'>
      <div className='checkbox c-checkbox pt-2'>
        <label>
          <Input
            onChange={() => {
              setDate((prevSate) => ({ ...prevSate, isChecked: !date.isChecked }))
            }}
            id='fromdate'
            name='fromdate'
            type='checkbox'
          />
          <span className='fa fa-check'></span>
        </label>
      </div>
      <label className='col-form-label mb'>{title}</label>
      <Col md={title === 'Deri' ? 7 : 6} className='from-date'>
        <DateTimeBoostrap
          value={moment(date.value).format('DD/MM/YYYY')}
          onChange={onDateChange}
          locale='en'
          dateFormat={'DD/MM/YYYY'}
          timeFormat={false}
          name='fromdate'
          type='checkbox'
          closeOnSelect
          inputProps={{
            disabled: !date.isChecked && 'disabled',
            id: 'dateFrom',
            'data-validate': '["validDate"]',
            className: 'form-control input',
            name: 'dateFrom'
          }}
        />
      </Col>
    </div>
  )
}

export default DatePicker
