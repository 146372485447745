import axios from 'axios'
import { saveAs } from 'file-saver'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import pubsub from 'pubsub-js'
import React from 'react'
import 'react-datetime/css/react-datetime.css'
import { Link, withRouter } from 'react-router-dom'
import { Button, Col, Input, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import DateTimeBoostrap from '../../components/Forms/DateTimeBootstrap'
import DocumentInputModal from '../Users/DocumentInputModal'
import { albanianMonths } from './enums/AlbanianMonths'

const IMPORT_MODAL_TYPE_ENUMS = {
  WAGES: 'wages',
  ORDERS: 'orderSell',
  MONTHLY_WORKING_HOURS: 'monthlyWorkingHours'
}
class ImportTab extends React.Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      isModalOpen: false,
      modalData: {},
      modalLoading: false,
      count: 10,
      total: 0,
      page: 1,
      getData: [],
      selectedDate: new Date(),
      selectedTime: new Date(),
      nameType: 1,
      createdDateType: 1,
      targetMonthType: 1,
      targetYearType: 1,
      createdByFirstNameType: 1,
      createdByEmailType: 1,
      showEmail: false,
      sortParam: '',
      sortType: '',
      departments: [],
      departmentId: ''
    }
    this._onDateChange = this._onDateChange.bind(this)
    this._onTimeChange = this._onTimeChange.bind(this)
    this._getDataForTable = this._getDataForTable.bind(this)
    this._deleteWages = this._deleteWages.bind(this)
    this._getExport = this._getExport.bind(this)
    this.exportByDepartment = this.exportByDepartment.bind(this)
    this.exportAllDepartments = this.exportAllDepartments.bind(this)
  }

  _getDataForTable() {
    const { page, count, sortParam, sortType } = this.state
    const year = parseInt(moment(this.state.selectedDate).format('YYYY'))
    var params = {}
    params.year = year
    params.sortParam = sortParam ? sortParam : undefined
    params.sortType = sortType ? sortType : undefined
    axios
      .get(`salaryimport/${count}/${page}`, { params })
      .then((res) => {
        if (res.data.success) {
          if (this._isMounted) {
            this.setState({
              getData: res.data.data.docs,
              total: res.data.data.totalDocs
            })
          }
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  async componentDidMount() {
    this._isMounted = true
    await this._getDataForTable()

    try {
      const depts = await axios.get('/departments')
      if (depts.data.success) {
        this.setState({
          departments: depts.data.data.map((d) => {
            return {
              name: d.name,
              value: d._id
            }
          })
        })
      }
    } catch (e) {}
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  async _onDateChange(date) {
    if (typeof date == 'object') {
      await this.setState({ selectedDate: date })
      await this._getDataForTable()
    }
  }

  async _onTimeChange(date) {
    this.setState({
      selectedTime: date
    })
  }

  _onDateBlur = (date) => {
    this.setState({ selectedDate: moment(date, 'YYYY-MM') })
  }

  _getExport(e, id, department, month, year) {
    e.preventDefault()
    Swal.fire({
      title: 'Ju lutem prisni...',
      html: 'Duke gjeneruar dokumentin',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
    axios
      .get(`salaryimport/${id}`, { responseType: 'blob' })
      .then((response) => {
        if (response.headers['content-type'] == 'text/csv; charset=UTF-8') {
          saveAs(response.data, `Pagat_${department}_${month}_${year}.csv`)
          Swal.close()
          Swal.fire({
            title: 'Te dhenat u shkarkuan me sukses.',
            html: 'Dokumenti eshte gjeneruar me sukses'
          })
        } else {
          Swal.fire({
            title: 'Gabim gjatë shkarkimit të dokumentit',
            html: 'Gabim gjatë shkarkimit të dokumentit'
          })
        }
      })
      .catch((error) => {
        console.log('error', error)
        Swal.fire({
          title: 'Gabim gjatë shkarkimit të dokumentit',
          html: 'Gabim gjatë shkarkimit të dokumentit'
        })
      })
  }

  _deleteWages(id) {
    Swal.fire({
      title: 'A jeni të sigurt?',
      text: 'Fajlli me paga do të fshihet ende pa u procesuar!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Po, fshij.',
      cancelButtonText: 'Jo.'
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`salaryimport/${id}`)
          .then((response) => {
            if (response.data.success) {
              Swal.fire('Fshihet!', 'Të dhënat janë fshirë me sukses.', 'success').then(() => {
                this.props.history.push('/manageWages')
                this._getDataForTable()
              })
            } else {
              Swal.fire('Anuluar', 'Të dhënat nuk janë fshirë', 'error')
            }
          })
          .catch((e) => {
            Swal.fire('Anuluar', 'Të dhënat nuk janë fshirë', 'error')
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Anuluar', 'Të dhënat nuk janë fshirë', 'error')
      }
    })
  }

  _importOrders = (modalType, modalTitle) => {
    const wages_fields =
      modalType == IMPORT_MODAL_TYPE_ENUMS.WAGES
        ? [
            {
              type: 'text',
              name: 'import_name',
              label: 'Emri (psh. Pagat Janar):'
            },
            {
              type: 'select',
              name: 'department',
              label: 'Departamenti',
              options: this.state.departments
            }
          ]
        : []

    this.setState({
      isModalOpen: true,
      modalData: {
        modalType: modalType,
        title: `Importo ${modalTitle}`,
        description: `Ngarkoni fajllin per importim te te dhenave. Fajlli duhet te jete ne fomart CSV. Ju lutem zgjedhni edhe daten per muajin qe vlejne ${modalTitle}.`,
        fields: [
          {
            type: 'monthpicker',
            name: 'month',
            label: 'Muaji dhe viti:'
          },
          {
            type: 'file',
            name: 'file_import',
            label: 'Fajlli per import:'
          },
          ...wages_fields
        ]
      }
    })
  }

  _toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen
    }))
  }

  _newModalComplete = async (data) => {
    const file = data.state.file_import[0]
    const filename = file.name.split('.').pop()

    if (filename !== 'csv') {
      return pubsub.publish('showNotification', {
        message: 'Fajlli i zgjedhur duhet te jete ne formatin CSV',
        type: 'error'
      })
    }

    this.setState({ isModalOpen: false, modalLoading: true })

    const formData = new FormData()
    formData.append('file', file)
    formData.append('target_month', data.state.month.format('M'))
    formData.append('target_year', data.state.month.format('YYYY'))
    Swal.fire({
      title: 'Ju lutem prisni...',
      html: 'Duke ngarkuar të dhënat!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })

    try {
      if (this.state.modalData.modalType === IMPORT_MODAL_TYPE_ENUMS.WAGES) {
        formData.append('name', data.state.import_name)
        formData.append('department', data.state.department)

        const { data: checkData } = await axios.post('salaryimport/check', {
          target_month: data.state.month.format('M'),
          target_year: data.state.month.format('YYYY'),
          department: data.state.department
        })
        Swal.close()

        if (!checkData.success) {
          return pubsub.publish('showNotification', {
            message: 'Gaim gjatë procesimit të fajllit',
            type: 'error'
          })
        }

        if (checkData.data.exists) {
          const result = await Swal.fire({
            title: 'Të dhënat ekzistojnë',
            text: 'Dëshironi ti importoni përsëri?',
            icon: 'warning',
            showCancelButton: true
          })

          if (!result.isConfirmed) {
            this.setState({ modalLoading: false })
            return pubsub.publish('showNotification', {
              message: 'Procesimi u anulua',
              type: 'error'
            })
          }
        }

        this._processData(formData)
      } else {
        const { data: importResponse } = await axios.post(this.state.modalData.modalType, formData)

        Swal.close()
        if (!importResponse.success) {
          return pubsub.publish('showNotification', {
            message: importResponse.message,
            type: 'error'
          })
        }

        pubsub.publish('showNotification', {
          message: 'Të dhënat u ngarkuan me sukses!',
          type: 'success'
        })
      }
    } catch (error) {
      pubsub.publish('showNotification', {
        message: 'Gabim gjatë procesimit të fajllit',
        type: 'error'
      })
    } finally {
      this.setState({ modalLoading: false })
    }
  }

  _processData(formData) {
    axios.post(`salaryimport`, formData).then((importResponse) => {
      if (importResponse.data.success) {
        //Redirect to process with data preloaded
        this.props.history.push({
          pathname: '/ProcessWages/' + importResponse.data.import_id,
          params: { importData: importResponse.data }
        })
      } else {
        this.setState({ modalLoading: false })
        pubsub.publish('showNotification', {
          message: importResponse.data.message,
          type: 'error'
        })
      }
    })
  }

  onSortChange(param, type, fn) {
    this.setState({ sortParam: param, sortType: type }, fn)
  }

  exportByDepartment() {
    const month = moment(this.state.selectedTime).format('MM')
    const year = moment(this.state.selectedTime).format('YYYY')
    let departamentName = this.state.departments.find((test) => test.value === this.state.departmentId)?.name

    Swal.fire({
      title: 'Ju lutem prisni...',
      html: 'Duke shkarkuar të dhënat për departamentin e zgjedhur!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })

    axios
      .post(`/users/export/department/${this.state.departmentId}`, { target_month: month, target_year: year }, { responseType: 'blob' })
      .then((response) => {
        if (response.data) {
          if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            Swal.close()
            Swal.fire('', 'Te dhenat u shkarkuan!', 'success')
            saveAs(response.data, `${departamentName.replace(/ /g, '_')}_Pagat_${month}/${year}.csv`)
          }
        } else {
          Swal.fire({
            title: 'Gabim gjatë shkarkimit të dokumentit',
            html: 'Gabim gjatë shkarkimit të dokumentit'
          })
        }
      })
      .catch((error) =>
        Swal.fire({
          title: 'Gabim gjatë shkarkimit të dokumentit',
          html: 'Departamenti i zgjedhur nuk ka përdorues ose metodë të kalkulimit të pagës!'
        })
      )
  }

  exportAllDepartments() {
    const month = moment(this.state.selectedTime).format('MM')
    const year = moment(this.state.selectedTime).format('YYYY')
    const departamentIds = this.state.departments.map((item) => item.value)

    Swal.fire({
      title: 'Ju lutem prisni...',
      html: 'Duke shkarkuar të dhënat për të gjithë departamentet!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })

    axios
      .post(
        `/users/export/allDepartments`,
        {
          departmentIds: departamentIds,
          target_month: month,
          target_year: year
        },
        { responseType: 'blob' }
      )
      .then((response) => {
        if (response.data) {
          if (response.headers['content-type'] == 'application/zip') {
            Swal.close()
            Swal.fire('', 'Te dhenat u shkarkuan!', 'success')
            saveAs(response.data, `Departments_Salaries_${month}_${year}.zip`)
          }
        } else {
          Swal.fire({
            title: 'Gabim gjatë shkarkimit të dokumentit',
            html: 'Gabim gjatë shkarkimit të dokumentit'
          })
        }
      })
      .catch((error) =>
        Swal.fire({
          title: 'Gabim gjatë shkarkimit të dokumentit',
          html: 'Gabim gjatë shkarkimit të dokumentit'
        })
      )
  }

  render() {
    const { count, total } = this.state
    const columns = [
      {
        name: 'Muaji',
        label: 'Muaji',
        options: {
          sort: true
        }
      },
      {
        name: 'Viti',
        label: 'Viti',
        options: {
          sort: true
        }
      },
      {
        name: 'Pershkrimi',
        label: 'Pershkrimi',
        options: {
          sort: true
        }
      },
      {
        name: 'Departamenti',
        label: 'Departamenti',
        options: {
          sort: false
        }
      },
      {
        name: 'Data e krijimit',
        label: 'Data e krijimit',
        options: {
          sort: true
        }
      },
      {
        name: 'Krijuar nga',
        label: 'Krijuar nga',
        options: {
          sort: true
        }
      },
      {
        name: 'Shkarko',
        label: 'Shkarko',
        options: {
          sort: false
        }
      },
      {
        name: 'Proceso',
        label: 'Proceso',
        options: {
          sort: false
        }
      },
      {
        name: 'Fshij',
        label: 'Fshij',
        options: {
          sort: false
        }
      }
    ]
    const options = {
      download: false,
      filter: false,
      responsive: 'scroll',
      filterType: 'dropdown',
      selectableRows: 'none',
      pagination: true,
      search: false,
      viewColumns: true,
      serverSide: true,
      rowsPerPage: count,
      page: this.state.page - 1,
      count: total,
      rowsPerPageOptions: [10, 50, 100, 200],
      textLabels: {
        body: {
          noMatch: 'Nuk ka të dhëna'
        },
        pagination: {
          next: 'Faqja tjeter',
          previous: 'Faqja e meparshme',
          rowsPerPage: 'Rreshta për faqe',
          displayRows: 'të'
        },
        toolbar: {
          search: 'Kërko',
          downloadCsv: 'Shkarko në CSV',
          print: 'Printo',
          viewColumns: 'Shiko kolonat',
          filterTable: 'Tabela e filtrimit'
        },
        filter: {
          all: 'TE GJITHA',
          title: 'FILTRIMET',
          reset: 'RIVENDOS'
        },
        viewColumns: {
          title: 'Shfaq kolonat',
          titleAria: 'Trego/fsheh kolonat e tabelave'
        }
      },
      onColumnSortChange: (changedColumn) => {
        const { nameType, createdByEmailType, createdByFirstNameType, targetYearType, createdDateType, targetMonthType } = this.state

        switch (changedColumn) {
          case 'Muaji':
            this.setState(
              { targetMonthType: targetMonthType == -1 ? 1 : -1 },
              this.onSortChange('target_month', targetMonthType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
          case 'Email':
            this.setState(
              { createdByEmailType: createdByEmailType == -1 ? 1 : -1 },
              this.onSortChange('created_by.email', createdByEmailType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
          case 'Viti':
            this.setState(
              { targetYearType: targetYearType == -1 ? 1 : -1 },
              this.onSortChange('target_year', targetYearType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
          case 'Pershkrimi':
            this.setState({ nameType: nameType == -1 ? 1 : -1 }, this.onSortChange('name', nameType == -1 ? 1 : -1, this._getDataForTable))
            break
          case 'Data e krijimit':
            this.setState(
              { createdDateType: createdDateType == -1 ? 1 : -1 },
              this.onSortChange('created_date', createdDateType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
          case 'Krijuar nga':
            this.setState(
              { createdByFirstNameType: createdByFirstNameType == -1 ? 1 : -1 },
              this.onSortChange('created_by.first_name', createdByFirstNameType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
        }
      },
      onTableChange: async (action, tableState) => {
        switch (action) {
          case 'changePage':
            await this.setState({ page: tableState.page + 1 })
            this._getDataForTable()
            break
          case 'columnViewChange':
            this.setState({
              showEmail: tableState.columns[1].display == 'true' ? true : false
            })
            break
          case 'changeRowsPerPage':
            await this.setState({ count: tableState.rowsPerPage })
            this._getDataForTable()
            break
        }
      },

      elevation: 0
    }

    const data = this.state.getData.map((elem) => {
      return [
        albanianMonths[elem.target_month - 1],
        elem.target_year,
        elem.name,
        elem.department.name,
        moment(elem.created_date).format('DD/MM/YYYY'),
        elem.created_by.email,
        <Button
          onClick={(e) => {
            this._getExport(e, elem._id, elem.department.name, albanianMonths[elem.target_month - 1], elem.target_year)
          }}
          outline
          className='mb-1'
          color='success'
          type='button'
        >
          <span>Shkarko</span>
        </Button>,
        <Link style={{ pointerEvents: elem.processed ? 'none' : 'auto' }} to={`/ProcessWages/${elem._id}`}>
          <Button
            outline
            className='mb-1'
            disabled={elem.processed ? true : false}
            color={elem.processed ? 'text-muted' : 'warning'}
            type='button'
          >
            <span>Proceso</span>
          </Button>
        </Link>,
        <Button
          disabled={elem.processed ? true : false}
          onClick={() => {
            this._deleteWages(elem._id)
          }}
          outline
          className='mb-1'
          color={elem.processed ? 'text-muted' : 'danger'}
          type='button'
        >
          <span>Fshij</span>
        </Button>
      ]
    })

    return (
      <Row style={{ padding: 20 }}>
        <DocumentInputModal
          className={this.state.modalLoading ? 'whirl standard' : ''}
          onComplete={this._newModalComplete}
          isOpen={this.state.isModalOpen}
          modalData={this.state.modalData}
          toggleModal={() => this._toggleModal()}
        />
        <Col>
          <fieldset style={{ borderTop: '1px dashed #eee', paddingTop: '20px' }}>
            <Button className='float-right' color='info' onClick={this.exportAllDepartments}>
              Shkarko per te gjithe departamentet
            </Button>
            <Button
              className='float-right'
              color='info'
              style={{ marginRight: '10px' }}
              disabled={!this.state.departmentId}
              onClick={this.exportByDepartment}
            >
              Shkarko per departamentin e zgjedhur
            </Button>
            <div className='form-group row align-items-center'>
              <label className='col-form-label mb'>Shkarko mostrën për departamentin:</label>
              <div className='col-md-4'>
                <Input
                  type='select'
                  name='departament'
                  value={this.state.departmentId}
                  onChange={(e) => {
                    this.setState({ departmentId: e.target.value })
                  }}
                >
                  <option key={0}>Zgjedhni departamentin tuaj...</option>
                  {this.state.departments.map((item, index) => {
                    return (
                      <option value={item.value} key={item.value}>
                        {item.name}
                      </option>
                    )
                  })}
                </Input>
              </div>
            </div>
            <div className='form-group row mb float-left pl-3 pt-3'>
              <label className='col-form-label mb'>Zgjedh vitin dhe muajin:</label>
              <Col md={5}>
                <DateTimeBoostrap
                  key='selectTime'
                  value={this.state.selectedTime}
                  onChange={this._onTimeChange}
                  locale='de'
                  utc={true}
                  closeOnSelect
                  dateFormat='MM-YYYY'
                  initialViewMode='years'
                  // onBlur={this._onDateBlur}
                  data-validate='["validDate"]'
                  timeFormat={false}
                  inputProps={{
                    'data-validate': '["validDate"]',
                    className: 'form-control',
                    name: 'data',
                    autoComplete: 'off'
                  }}
                />
              </Col>
            </div>
          </fieldset>

          <fieldset>
            <Button
              className='float-right'
              color='info'
              onClick={() => this._importOrders(IMPORT_MODAL_TYPE_ENUMS.MONTHLY_WORKING_HOURS, 'oret')}
            >
              Importo Orët
            </Button>
            <Button
              className='float-right'
              color='info'
              style={{ marginRight: '10px' }}
              onClick={() => this._importOrders(IMPORT_MODAL_TYPE_ENUMS.ORDERS, 'orderat')}
            >
              Importo Orders
            </Button>
            <Button
              className='float-right'
              color='info'
              style={{ marginRight: '10px' }}
              onClick={() => this._importOrders(IMPORT_MODAL_TYPE_ENUMS.WAGES, 'pagat')}
            >
              Importo paga
            </Button>
            <div className='form-group row mb float-left pl-3'>
              <label className='col-form-label mb'>Zgjedh vitin:</label>
              <div className='col-md-5'>
                <DateTimeBoostrap
                  key='selectDate'
                  value={this.state.selectedDate}
                  onChange={this._onDateChange}
                  locale='de'
                  utc={true}
                  dateFormat='YYYY'
                  closeOnSelect
                  onBlur={this._onDateBlur}
                  data-validate='["validDate"]'
                  timeFormat={false}
                  inputProps={{
                    'data-validate': '["validDate"]',
                    className: 'form-control',
                    name: 'data',
                    autoComplete: 'off'
                  }}
                />
              </div>
            </div>
          </fieldset>

          <Row>
            <Col>
              <MUIDataTable columns={columns} options={options} data={data} />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default withRouter(ImportTab)
