const Search = ({ tableParams, setTableParams }) => {
  return (
    <div className='input-group '>
      <input
        value={tableParams.searchText}
        onChange={(e) => setTableParams((prevState) => ({ ...prevState, searchText: e.target.value }))}
        className='form-control'
        type='text'
        placeholder='Kërko përdoruesit...'
      />
      <span className='input-group-btn'>
        <button disabled className='btn btn-secondary' type='button'>
          <em className='fa fa-search'></em>
        </button>
      </span>
    </div>
  )
}

export default Search
