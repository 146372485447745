import moment from 'moment'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { saveAs } from 'file-saver'
import Swal from 'sweetalert2'
import axios from 'axios'

export const data = (getData) =>
  getData.map((element) => {
    const date = moment(element.created_at).format('DD/MM/YYYY')
    return [
      element.email,
      element.first_name + ' ' + element.last_name,
      element.languages && element.languages?.map((lang) => lang?._id?.code).join(','),
      element.function && element.function.name,
      element.project && element.project.name,
      element.activity && element.activity.name,
      element.work && element.work.name,
      element.identity_card,
      element.created_at && date,
      element.city && element.city.name,
      element.manager && `${element.manager.first_name} ${element.manager.last_name}`,
      element.teamleader ? `${element.teamleader.first_name} ${element.teamleader.last_name}` : 'Nuk ka',
      element.office_location,
      element.department &&
        element.department.map((object, index) => (element.department.length - 1 === index ? object.name : object.name + ', ')),
      <Link to={`/editUsers/${element._id}`}>
        <Button outline className='mb-1' color='warning' type='button'>
          <span>Shfaq</span>
        </Button>
      </Link>
    ]
  })

export const _exportData = (url, fileName, message, params = {}) => {
  Swal.fire({
    title: 'Ju lutem prisni...',
    html: message,
    allowEscapeKey: false,
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      Swal.showLoading()
    }
  })
  axios
    .get(url, { params, responseType: 'blob' })
    .then((response) => {
      if (response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        saveAs(response.data, fileName)
      }
      Swal.close()
    })
    .catch((error) => {
      Swal.close()
      Swal.fire('', 'Ndodhi nje gabim!', 'error')
    })
}
