import jwt_decode from 'jwt-decode'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import Base from './components/Layout/Base'
import Login from './components/Pages/Login'
import PasswordReset from './components/Pages/PasswordReset'
import RecoverPassword from './components/Pages/RecoverPassword'
import TaxesSurvey from './components/TaxesSurvey/TaxesSurvey'
import { getRoutesData } from './components/Layout/getRoutesData'

const Routes = ({ location }) => {
  const token = localStorage.getItem('token')
  if (!token) {
    return (
      <div className='wrapper'>
        <Switch location={location}>
          <Route path='/login' component={Login} />
          <Route path='/recoverPassword' component={RecoverPassword} />
          <Route path='/passwordReset' component={PasswordReset} />
        </Switch>
      </div>
    )
  }

  const decodedToken = jwt_decode(token)
  const { hasCompletedProfile, user_level, user_id } = decodedToken

  if (!hasCompletedProfile) {
    return (
      <Switch location={location}>
        <Route path='/taxesSurvey'>
          <TaxesSurvey userID={user_id} />
        </Route>
        <Redirect to='/taxesSurvey' />
      </Switch>
    )
  }

  const routes = getRoutesData()

  return (
    <Base>
      <Switch location={location}>
        {routes.map((item, i) =>
          item?.allowed_user_levels.includes(user_level) || item.allowed_condition ? <Route key={i} {...item} /> : null
        )}
      </Switch>
    </Base>
  )
}

export default withRouter(Routes)
