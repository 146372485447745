import React, { Fragment } from 'react'
import ContentWrapper from '../../Layout/ContentWrapper'
import { Row, Col, Button, Card, CardBody, Input, CardFooter } from 'reactstrap'
import axios from 'axios'
import FormValidator from '../../Forms/FormValidator.js'
import pubsub from 'pubsub-js'
import { DeleteDialogBox } from '../../Common/'

class EditBanks extends React.Component {
  constructor() {
    super()
    this.state = {
      isEdit: false,
      formSalaryMethods: {
        method_type: 'kpis',
        kpi_value: 0
      },
      method_quintile: [
        { quintile: 1, value: 0 },
        { quintile: 2, value: 0 },
        { quintile: 3, value: 0 },
        { quintile: 4, value: 0 },
        { quintile: 5, value: 0 }
      ],
      method_kpis: [],
      method_products: []
    }
  }

  validateOnChange = (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value
    const result = FormValidator.validate(input)
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
  }

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  componentDidMount() {
    const methodId = this.props.match.params.methodId
    if (methodId) {
      this.setState({ isEdit: true })
    }
  }

  componentWillMount() {
    const ID = this.props.match.params.methodId
    if (ID) {
      axios
        .get(`salarycalculations/${ID}`)
        .then((response) => {
          this.setState({
            formSalaryMethods: {
              method_name: response.data.data.name,
              description: response.data.data.description,
              method_type: response.data.data.method_type,
              kpi_value: response.data.data.kpi_value
            },
            [`method_${response.data.data.method_type}`]: response.data.data.method_data
          })
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    const ID = this.props.match.params.methodId
    const form = e.target
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })

    if (!hasError) {
      const postData = {
        method_type: this.state.formSalaryMethods.method_type,
        name: this.state.formSalaryMethods.method_name,
        description: this.state.formSalaryMethods.description,
        kpi_value: this.state.formSalaryMethods.kpi_value,
        method_data: this.state[`method_${this.state.formSalaryMethods.method_type}`]
      }
      if (ID) {
        axios
          .put(`salarycalculations/${ID}`, postData)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push('/salaryMethods')
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ruajtur!',
                type: 'success'
              })
            } else {
              pubsub.publish('showNotification', {
                message: res.data.message,
                type: 'error'
              })
            }
          })

          .catch((err) => {
            pubsub.publish('showNotification', {
              message: 'Gabim gjatë ruajtjes së të dhënave',
              type: 'error'
            })
          })
      } else {
        axios
          .post('salarycalculations', postData)
          .then((res) => {
            if (res.data.success) {
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ruajtur!',
                type: 'success'
              })
              this.props.history.push('/salaryMethods')
            } else {
              pubsub.publish('showNotification', {
                message: res.data.message,
                type: 'error'
              })
            }
          })
          .catch((err) => {
            pubsub.publish('showNotification', {
              message: 'Gabim gjatë ruajtjes të të dhënave',
              type: 'error'
            })
          })
      }
    }
  }

  deleteSalaryMethod() {
    const ID = this.props.match.params.methodId
    const goBack = () => this.props.history.goBack()
    const path = `salarycalculations/${ID}`
    const name = 'Metodën'
    DeleteDialogBox({ path, name, goBack })
  }

  _renderMethod() {
    if (this.state.formSalaryMethods.method_type === 'kpis') {
      return (
        <Fragment>
          <fieldset>
            {this.state.method_kpis.map((q, i) => {
              return (
                <div key={i} className='form-group row align-items-center'>
                  <label className='col-md-1 col-form-label'>Kodi i KPI</label>
                  <Col md={2}>
                    <Input
                      name='kpi_code'
                      value={q.kpi_code}
                      onChange={(e) => {
                        var currState = [...this.state.method_kpis]
                        currState[i].kpi_code = e.target.value
                        this.setState({ method_kpis: currState })
                      }}
                      type='text'
                    />
                  </Col>
                  <label className='col-md-1 col-form-label'>Vlera Prej </label>
                  <Col md={1}>
                    <Input
                      name='kpi_from'
                      value={q.kpi_from}
                      onChange={(e) => {
                        var currState = [...this.state.method_kpis]
                        currState[i].kpi_from = e.target.value
                        this.setState({ method_kpis: currState })
                      }}
                      type='number'
                    />
                  </Col>
                  <label className='col-md-1col-form-label'>deri</label>
                  <Col md={1}>
                    <Input
                      name='kpi_to'
                      value={q.kpi_to}
                      onChange={(e) => {
                        var currState = [...this.state.method_kpis]
                        currState[i].kpi_to = e.target.value
                        this.setState({ method_kpis: currState })
                      }}
                      type='number'
                    />
                  </Col>
                  <label className='col-md-1col-form-label'>Piket: </label>
                  <Col md={1}>
                    <Input
                      name='kpi_points'
                      value={q.kpi_points}
                      onChange={(e) => {
                        var currState = [...this.state.method_kpis]
                        currState[i].kpi_points = e.target.value
                        this.setState({ method_kpis: currState })
                      }}
                      type='number'
                    />
                  </Col>
                  <Button
                    color='danger'
                    onClick={(e) => {
                      e.preventDefault()
                      this.setState({ method_kpis: this.state.method_kpis.filter((k, index) => i !== index) })
                    }}
                  >
                    Fshij
                  </Button>
                </div>
              )
            })}
            <CardBody className='text-center'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  this.setState({
                    method_kpis: [
                      ...this.state.method_kpis,
                      {
                        kpi_code: '',
                        kpi_from: 0,
                        kpi_to: 1,
                        kpi_points: 0
                      }
                    ]
                  })
                }}
                className='btn btn-info'
              >
                Shto KPI
              </button>
            </CardBody>
          </fieldset>
          <fieldset>
            <label className='col-md-2 col-form-label'>Shuma për pike: </label>
            <Col md={6}>
              <Input
                name='kpi_points'
                value={this.state.formSalaryMethods.kpi_value}
                onChange={(e) => {
                  this.setState({
                    formSalaryMethods: {
                      ...this.state.formSalaryMethods,
                      kpi_value: e.target.value
                    }
                  })
                }}
                type='number'
              />
            </Col>
          </fieldset>
        </Fragment>
      )
    } else if (this.state.formSalaryMethods.method_type === 'quintile') {
      return (
        <fieldset>
          {this.state.method_quintile.map((q, i) => {
            return (
              <div key={i} className='form-group row align-items-center'>
                <label className='col-md-3 col-form-label'>Quintile {q.quintile}</label>
                <Col md={3}>
                  <Input
                    name={`quintile${i}`}
                    value={q.value}
                    onChange={(e) => {
                      var currState = [...this.state.method_quintile]
                      currState[i].value = e.target.value
                      this.setState({ method_quintile: currState })
                    }}
                    type='text'
                  />
                </Col>
              </div>
            )
          })}
        </fieldset>
      )
    } else if (this.state.formSalaryMethods.method_type === 'products') {
      return (
        <fieldset>
          {this.state.method_products.map((q, i) => {
            return (
              <div key={i} className='form-group row align-items-center'>
                <label className='col-md-2 col-form-label'>Kodi i produktit</label>
                <Col md={3}>
                  <Input
                    name={`product_code${i}`}
                    value={q.product_code}
                    onChange={(e) => {
                      var currState = [...this.state.method_products]
                      currState[i].product_code = e.target.value
                      this.setState({ method_products: currState })
                    }}
                    type='text'
                  />
                </Col>
                <label className='col-md-2 col-form-label'>Vlera</label>
                <Col md={2}>
                  <Input
                    name={`product_value${i}`}
                    value={q.product_value}
                    onChange={(e) => {
                      var currState = [...this.state.method_products]
                      currState[i].product_value = e.target.value
                      this.setState({ method_products: currState })
                    }}
                    type='number'
                  />
                </Col>
                <Button
                  color='danger'
                  onClick={(e) => {
                    e.preventDefault()
                    this.setState({ method_products: this.state.method_products.filter((k, index) => i !== index) })
                  }}
                >
                  Fshij
                </Button>
              </div>
            )
          })}
          <CardBody className='text-center'>
            <button
              onClick={(e) => {
                e.preventDefault()
                this.setState({
                  method_products: [
                    ...this.state.method_products,
                    {
                      product_code: '',
                      product_value: 0
                    }
                  ]
                })
              }}
              className='btn btn-info'
            >
              Shto Produkt
            </button>
          </CardBody>
        </fieldset>
      )
    }
  }

  render() {
    return (
      <ContentWrapper>
        <div className='d-flex justify-content-between'>
          <p style={{ fontSize: 23, color: 'gray' }}> {this.state.isEdit ? 'Modifiko Metodën' : 'Shto Metodën'}</p>
          <div>
            <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
              <span className='btn-label'>
                <i className='fa fa-arrow-left' />
              </span>{' '}
              Prapa
            </Button>
          </div>
        </div>

        <Row>
          <div className='col-md-12'>
            <form onSubmit={this.onSubmit} name='formSalaryMethods'>
              {/* START card */}
              <Card className='card-default'>
                <CardBody>
                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Emri i Metodës</label>
                      <Col md={6}>
                        <Input
                          type='text'
                          name='method_name'
                          onChange={this.validateOnChange}
                          value={this.state.formSalaryMethods.method_name}
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                    </div>
                  </fieldset>
                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Përshkrimi</label>
                      <Col md={6}>
                        <Input
                          type='text'
                          name='description'
                          onChange={this.validateOnChange}
                          value={this.state.formSalaryMethods.description}
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                    </div>
                  </fieldset>
                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Lloji i Metodës:</label>
                      <Col md={6}>
                        <Input
                          type='select'
                          name='method_type'
                          onChange={(e) =>
                            this.setState({ formSalaryMethods: { ...this.state.formSalaryMethods, method_type: e.target.value } })
                          }
                          value={this.state.formSalaryMethods.method_type}
                        >
                          <option key={0} value='kpis'>
                            Metodë e bazuar në KPI
                          </option>
                          <option key={1} value='quintile'>
                            Metodë e bazuar në Quintile
                          </option>
                          <option key={2} value='products'>
                            Metodë e bazuar në Shitje të Produkteve
                          </option>
                        </Input>
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                    </div>
                  </fieldset>
                  {this._renderMethod()}
                </CardBody>
                {this.state.isEdit ? (
                  <CardFooter className='text-right'>
                    <button style={{ marginRight: 10 }} type='submit' className='btn btn-info'>
                      Ruaj / Modifiko
                    </button>
                    <Button color='danger' onClick={this.deleteSalaryMethod.bind(this)}>
                      Fshij
                    </Button>
                  </CardFooter>
                ) : (
                  <CardFooter className='text-right'>
                    <button type='submit' className='btn btn-info'>
                      Ruaj
                    </button>
                  </CardFooter>
                )}
              </Card>
              {/* END card */}
            </form>
          </div>
        </Row>
      </ContentWrapper>
    )
  }
}

export default EditBanks
