import { Box, Button as MUIButton, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core'
import { FormControl, InputLabel, Select } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import axios from 'axios'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Badge, Button } from 'reactstrap'
import pubsub from 'pubsub-js'

const useStyles = makeStyles((theme) => ({
  textfield: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.23)'
    },
    width: '200px'
  }
}))

const PersonalBusinessTrip = ({ userID, loggedInUser }) => {
  const classes = useStyles()
  const [dateFrom, setDateFrom] = useState(null)
  const [dateFromInput, setDateFromInput] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const [dateToInput, setDateToInput] = useState(null)
  const [status, setStatus] = useState('all')
  const [records, setRecords] = useState()
  const [pagination, setPagination] = useState({ limit: 10, page: 0 })
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [openModal, setOpenModal] = useState(false)

  const [applyDateFrom, setApplyDateFrom] = useState(null)
  const [applyDateFromInput, setApplyDateFromInput] = useState(null)
  const [applyDateTo, setApplyDateTo] = useState(null)
  const [applyDateToInput, setApplyDateToInput] = useState(null)
  const [description, setDescription] = useState(null)

  const [detailsModal, setDetailsModal] = useState(false)
  const [detailsDateFrom, setDetailsDateFrom] = useState(null)
  const [detailsDateTo, setDetailsDateTo] = useState(null)
  const [detailsDescription, setDetailsDescription] = useState('')
  const [detailsStatus, setDetailsStatus] = useState()
  const [detailsHRDescription, setDetailsHRDescription] = useState('')

  const statuses = [
    { text: 'Refuzuar', color: 'danger' },
    { text: 'Në pritje', color: 'info' },
    { text: 'Aprovuar', color: 'success' }
  ]

  const getAllBusinessTrip = async () => {
    let query = `page=${pagination.page}&limit=${pagination.limit}`
    if (dateFrom) {
      query = query + `&date_from=${dateFrom.getTime()}`
    }
    if (dateTo) {
      query = query + `&date_to=${dateTo.getTime()}`
    }
    if (status !== 'all') {
      query = query + `&status=${status}`
    }
    if (loggedInUser) {
      query = query + `&user_id=${loggedInUser.user_id}`
    }

    axios
      .get(`businesstrip?${query}`)
      .then((data) => {
        if (data.data.status) {
          setRecords(data.data.records)
          const _data = data.data.data.map((item) => {
            return [
              //   `${item.user.first_name} ${item.user.last_name}`,
              moment(item.date_from).format('LL'),
              moment(item.date_to).format('LL'),
              <Badge style={{ padding: '10px', fontSize: '14px' }} color={statuses[item.status].color}>
                {statuses[item.status].text}
              </Badge>,
              <Button
                variant='contained'
                color='success'
                onClick={() => {
                  setDetailsModal(true)
                  axios
                    .get(`businesstrip/one`, {
                      params: { id: item._id }
                    })
                    .then((data) => {
                      if (data.data.status) {
                        setDetailsStatus(parseInt(data.data.data.status))
                        setDetailsDateFrom(data.data.data.date_from)
                        setDetailsDateTo(data.data.data.date_to)
                        setDetailsDescription(data.data.data.user_description)
                        setDetailsHRDescription(data.data.data.hr_description)
                      }
                    })
                }}
              >
                Më shumë
              </Button>
            ]
          })
          setData(_data)
        }
      })
      .catch((e) => console.log('error', e))
      .finally(() => {
        setLoading(false)
      })
  }

  const applyForBusinessTrip = async () => {
    if (!applyDateFrom || !applyDateTo || !description) {
      return pubsub.publish('showNotification', {
        message: 'Të gjitha fushat duhet të plotësohen!',
        type: 'error'
      })
    }
    const data = await axios.post(`businesstrip`, {
      date_from: applyDateFrom,
      date_to: applyDateTo,
      user_description: description
    })
    if (data.data.status) {
      pubsub.publish('showNotification', {
        message: 'Kërkesa për Business Trip është realizuar me sukses!',
        type: 'success'
      })
      setOpenModal(false)
      setApplyDateFrom(null)
      setApplyDateFromInput(null)
      setApplyDateTo(null)
      setApplyDateToInput(null)
      setDescription()
      getAllBusinessTrip()
    } else {
      pubsub.publish('showNotification', {
        message: data.data.message || `Something went wrong!`,
        type: 'error'
      })
    }
  }

  useEffect(() => {
    getAllBusinessTrip()
  }, [status, dateFrom, dateTo, pagination.limit, pagination.page])

  const columns = [
    {
      name: 'Prej datës',
      label: 'Prej datës',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Deri më datën',
      label: 'Deri më datën',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Statusi',
      label: 'Statusi',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Më shumë',
      label: 'Më shumë',
      options: {
        filter: false,
        sort: false,
        download: false
      }
    }
  ]
  const options = {
    download: false,
    print: false,
    filter: false,
    filterType: 'dropdown',
    selectableRows: 'none',
    responsive: 'scroll',
    elevation: 0,
    maxHeight: 'none',
    search: false,
    pagination: true,
    serverSide: true,
    count: records,
    rowsPerPageOptions: [10, 50, 100, 200],
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: loading ? 'Loading...' : 'Nuk ka të dhëna'
      }
    },
    onTableChange: (action, a) => {
      switch (action) {
        case 'changeRowsPerPage':
          a.page = 0
          break
        default:
      }
    },
    onChangeRowsPerPage: (e) => {
      setPagination({ limit: e, page: 0 })
    },
    onChangePage: (e) => {
      setPagination((prev) => {
        return { ...prev, page: e }
      })
    }
  }
  return (
    <Box>
      <Typography variant='h6'>Kërkesat e tuaja për Business Trip</Typography>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          my: 1
        }}
      >
        <DatePicker
          clearable
          openTo='year'
          views={['year', 'month', 'day']}
          componentsProps={{
            actionBar: {
              actions: ['clear']
            }
          }}
          label='Prej datës'
          inputFormat='dd/MM/yyyy'
          value={dateFromInput}
          onChange={(newValue) => {
            setDateFromInput(newValue)
          }}
          onAccept={(newValue) => {
            setDateFrom(newValue)
          }}
          renderInput={(params) => <TextField {...params} variant='outlined' size='small' className={classes.textfield} />}
        />
        <DatePicker
          clearable
          openTo='year'
          views={['year', 'month', 'day']}
          componentsProps={{
            actionBar: {
              actions: ['clear']
            }
          }}
          label='Deri më datën'
          inputFormat='dd/MM/yyyy'
          value={dateToInput}
          onChange={(newValue) => {
            setDateToInput(newValue)
          }}
          onAccept={(newValue) => {
            setDateTo(newValue)
          }}
          renderInput={(params) => (
            <TextField {...params} variant='outlined' size='small' className={classes.textfield} style={{ margin: '0 20px' }} />
          )}
        />
        <FormControl size='small' sx={{ maxHeight: '40px' }}>
          <InputLabel>Statusi</InputLabel>
          <Select value={status} label='Statusi' onChange={(e) => setStatus(e.target.value)} className={classes.textfield}>
            <MenuItem value='all'>
              <em>Të gjitha</em>
            </MenuItem>
            <MenuItem value='0'>Refuzuar</MenuItem>
            <MenuItem value='1'>Në pritje</MenuItem>
            <MenuItem value='2'>Aprovuar</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ ml: 'auto' }}>
          <MUIButton variant='contained' onClick={() => setOpenModal(true)}>
            Apliko
          </MUIButton>
          {openModal && (
            <Modal
              show={openModal}
              onHide={() => {
                setOpenModal(false)
                setApplyDateFrom(null)
                setApplyDateFromInput(null)
                setApplyDateTo(null)
                setApplyDateToInput(null)
                setDescription()
              }}
              className='modal-1'
            >
              <Modal.Header closeButton>
                <Modal.Title>Aplikim për Business Trip</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap'
                }}
              >
                <DatePicker
                  clearable
                  openTo='year'
                  views={['year', 'month', 'day']}
                  componentsProps={{
                    actionBar: {
                      actions: ['clear']
                    }
                  }}
                  label='Prej datës'
                  inputFormat='dd/MM/yyyy'
                  value={applyDateFromInput}
                  onChange={(newValue) => {
                    setApplyDateFromInput(newValue)
                  }}
                  onAccept={(newValue) => {
                    setApplyDateFrom(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} variant='outlined' size='small' className={classes.textfield} />}
                />
                <DatePicker
                  clearable
                  openTo='year'
                  views={['year', 'month', 'day']}
                  componentsProps={{
                    actionBar: {
                      actions: ['clear']
                    }
                  }}
                  label='Deri më datën'
                  inputFormat='dd/MM/yyyy'
                  value={applyDateToInput}
                  onChange={(newValue) => {
                    setApplyDateToInput(newValue)
                  }}
                  onAccept={(newValue) => {
                    setApplyDateTo(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} variant='outlined' size='small' className={classes.textfield} />}
                />
                <TextField
                  id='description-basic'
                  label='Përshkrimi'
                  variant='outlined'
                  // size="small"
                  fullWidth
                  multiline
                  minRows={3}
                  className={classes.textfield}
                  value={description}
                  style={{ width: '100%', margin: '15px 0' }}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <MUIButton variant='contained' onClick={() => applyForBusinessTrip()}>
                  Apliko
                </MUIButton>
              </Modal.Body>
            </Modal>
          )}
          {detailsModal && (
            <Modal
              show={detailsModal}
              onHide={() => {
                setDetailsModal(false)
              }}
              className='modal-1'
            >
              <Modal.Header closeButton>
                <Modal.Title>Aplikimi juaj për Business Trip</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap'
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '20px'
                  }}
                >
                  <Typography>Statusi Aktual</Typography>
                  <Badge
                    style={{
                      padding: '10px',
                      fontSize: '18px',
                      fontWeight: '400',
                      borderRadius: '10px',
                      marginLeft: '10px',
                      padding: '8px 15px'
                    }}
                    color={statuses[detailsStatus !== undefined ? detailsStatus : 1].color}
                  >
                    {statuses[detailsStatus !== undefined ? detailsStatus : 1].text}
                  </Badge>
                </Box>
                <DatePicker
                  clearable
                  openTo='year'
                  views={['year', 'month', 'day']}
                  componentsProps={{
                    actionBar: {
                      actions: ['clear']
                    }
                  }}
                  label='Prej datës'
                  inputFormat='dd/MM/yyyy'
                  disabled
                  value={detailsDateFrom}
                  renderInput={(params) => <TextField {...params} variant='outlined' size='small' className={classes.textfield} />}
                />
                <DatePicker
                  clearable
                  openTo='year'
                  views={['year', 'month', 'day']}
                  componentsProps={{
                    actionBar: {
                      actions: ['clear']
                    }
                  }}
                  label='Deri më datën'
                  inputFormat='dd/MM/yyyy'
                  disabled
                  value={detailsDateTo}
                  renderInput={(params) => <TextField {...params} variant='outlined' size='small' className={classes.textfield} />}
                />
                <TextField
                  id='description-basic'
                  label='Përshkrimi'
                  variant='outlined'
                  // size="small"
                  fullWidth
                  multiline
                  minRows={3}
                  className={classes.textfield}
                  disabled
                  value={detailsDescription}
                  style={{ width: '100%', margin: '20px 0' }}
                />
                <TextField
                  id='description-basic'
                  label='Përshkrimi i HR'
                  variant='outlined'
                  // size="small"
                  fullWidth
                  multiline
                  minRows={3}
                  className={classes.textfield}
                  disabled
                  value={detailsHRDescription}
                  style={{ width: '100%' }}
                />
              </Modal.Body>
            </Modal>
          )}
        </Box>
      </Box>
      <MUIDataTable columns={columns} options={options} data={data} />
    </Box>
  )
}

export default PersonalBusinessTrip
