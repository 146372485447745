import { TextField } from '@mui/material'
import Stack from '@mui/material/Stack'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'

export default function monthPicker({ value, setValue }) {
  const today = new Date()
  let maxDate = moment(today)
    .add(today.getDate() > 25 ? 1 : 0, 'months')
    .format('YYYY-MM-DD')

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DatePicker
          views={['month', 'year']}
          label='Zgjedh Muajin dhe Vitin'
          minDate={'2024-1'}
          maxDate={maxDate}
          value={value}
          onChange={(newValue) => {
            setValue(newValue)
          }}
          renderInput={(params) => (
            <TextField
              sx={{
                '.MuiInputBase-input': {
                  padding: '16.5px 14px !important'
                }
              }}
              {...params}
              helperText={null}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  )
}
