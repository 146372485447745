import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import DownloadIcon from '@material-ui/icons/CloudDownload'

const options = (params, setParams, downloadCsv) => {
  return {
    rowsPerPageOptions: [10, 50, 100, 200],
    download: false,
    filter: false,
    sort: false,
    search: false,
    responsive: 'scroll',
    count: params.total,
    page: params.page,
    elevation: 0,
    filterType: 'dropdown',
    selectableRows: 'none',
    viewColumns: false,
    rowsPerPage: params.limit,
    pagination: true,
    serverSide: true,
    print: false,
    textLabels: {
      body: {
        noMatch: 'Nuk ka të dhëna'
      }
    },
    onTableChange: (action, a) => (action === 'changeRowsPerPage' ? (a.page = 0) : null),
    onChangeRowsPerPage: (e) => {
      setParams((prevState) => ({
        ...prevState,
        limit: e,
        page: 0
      }))
    },
    onChangePage: (e) => {
      setParams((prevState) => ({
        ...prevState,
        page: e
      }))
    },
    customToolbar: () => {
      return (
        <Tooltip title={'Shkarko CSV'}>
          <IconButton
            aria-label={'Shkarko CSV'}
            onClick={() => {
              downloadCsv(false)
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      )
    }
  }
}

export default options
