import { Viewer, Worker } from '@react-pdf-viewer/core'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import * as React from 'react'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/page-navigation/lib/styles/index.css'

import { getDocTemplate } from '../../utils/awsPaths'
import { version } from 'pdfjs-dist'

const PdfViewer = ({ fileKey }) => {
  const pageNavigationPluginInstance = pageNavigationPlugin()
  const [doc, setDoc] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const getFile = async () => {
    setLoading(true)
    const dataString = await getDocTemplate(fileKey)
    if (dataString && dataString.data && dataString.data.length) {
      setLoading(false)
      return dataString.data
    }

    setLoading(false)
    return null
  }

  React.useEffect(async () => {
    setDoc(await getFile())
  }, [])

  return (
    <div
      className={`rpv-core__viewer ${loading ? 'whirl standard' : null}}}`}
      style={{
        height: '100vh',
        position: 'relative'
      }}
    >
      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${version || '2.13.216'}/build/pdf.worker.min.js`}>
        {doc ? <Viewer fileUrl={doc} plugins={[pageNavigationPluginInstance]} /> : <>{!loading && 'Gabim gjate leximit'}</>}
      </Worker>
    </div>
  )
}

export default PdfViewer
