import { inject } from 'mobx-react'
import React from 'react'
import BlacklistsComponent from './BlacklistsComponent'

@inject('userStore')
class Blacklists extends React.Component {
  constructor(props) {
    super(props)
    if (props.userStore.user_id) {
      this.state = {
        user: {
          user_id: props?.userStore.user_id,
          userLevel: props?.userStore.userLevel
        }
      }
    }
  }

  render() {
    return <>{this.state.user && <BlacklistsComponent user={this.state.user} />}</>
  }
}

export default Blacklists
