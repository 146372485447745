import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import FileDownloadIcon from '@material-ui/icons/CloudDownload';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid, Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ImportHoursModal from '../Import_Hours_Modal/Import_Hours_Modal'
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2'
import DayTable from './DayTable';


export default function ViewsDatePicker() {
  const [value, setValue] = useState(new Date());
  const [uploadedDays, setUploadedDays] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false)

  const downloadDailyCVS = async () => {
    Swal.fire({
      title: 'Ju lutem prisni...',
      html: 'Duke shkarkuar...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })

    let query = `targetYear=${moment(value).format('YYYY')}&targetMonth=${moment(value).format('MM')}&targetDay=${moment(value).format('DD')}`

    axios
      .get(`dailyWorkingHours/exportHours?${query}`, {
        responseType: 'blob'
      })
      .then((response) => {
        if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          saveAs(response.data, `${moment(value).format('DD/MM/YYYY')}_DailyWorkingHours.csv`)
        }
        Swal.close()
      })
      .catch((error) => {
        console.log('error', error)
        Swal.close()
        Swal.fire('', 'Ndodhi nje gabim!', 'error')
      })

  }
  const downloadMonthlyCVS = async () => {
    Swal.fire({
      title: 'Ju lutem prisni...',
      html: 'Duke shkarkuar...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })

    let query = `targetYear=${moment(value).format('YYYY')}&targetMonth=${moment(value).format('MM')}&targetDay=${moment(value).format('DD')}`
    axios
      .get(`dailyWorkingHours/exportHoursMonthly?${query}`, {
        responseType: 'blob'
      })
      .then((response) => {
        if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          saveAs(response.data, `${moment(value).format('MM/YYYY')}_DailyWorkingHours.csv`)
        }
        Swal.close()
      })
      .catch((error) => {
        console.log('error', error)
        Swal.close()
        Swal.fire('', 'Ndodhi nje gabim!', 'error')
      })

  }

  useEffect(() => {
    let query = `year=${moment(value).format('YYYY')}&month=${moment(value).format('MM')}}`

    axios
      .get(`dailyWorkingHours/uploadedDaysStats?${query}`)
      .then((res) => {
        if (res.data.success) {
          setUploadedDays(res.data.data)

        } else {
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        console.log('error', err)
      })
  }, [value, refreshTable])

  return (
    <Grid container sx={{ padding: '20px' }}>
      <Grid item xs={3} >
        <Box sx={{ fieldset: { border: '1px solid' }, paddingX: '10px', }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DatePicker
                views={['year', 'day', 'month']}
                label="Zgjidh Datën"
                minDate={('2023-11-01')}
                inputFormat='dd/MM/yyyy'
                maxDate={moment(new Date()).format('YYYY-MM-DD')}
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => <TextField sx={{
                  '.MuiInputBase-input': {
                    padding: "16.5px 14px !important"
                  }
                }} InputProps={{
                  style: { fontSize: 14, padding: '8px' }, // Adjust the fontSize and padding as needed
                }} {...params} helperText={null} />}
              />
            </Stack>
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={9} >
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button onClick={() => downloadDailyCVS()} sx={{ backgroundColor: '#3A3F51 !important', padding: "6px 16px !important", minWidth: '150px !important', color: "#fff !important" }} variant="contained">Shkarko&nbsp;< FileDownloadIcon /></Button>
          <ImportHoursModal refreshTable={refreshTable} setRefreshTable={setRefreshTable} />
        </Box>
      </Grid>
      <Grid item xs={3} >
      </Grid>
      <Grid item xs={9} >
        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end', paddingTop: '20px !important' }}>
          <Button onClick={() => downloadMonthlyCVS()} sx={{ backgroundColor: '#3A3F51 !important', padding: "6px 16px !important", minWidth: '150px !important', color: "#fff !important" }} variant="contained">Te gjitha&nbsp;< FileDownloadIcon /></Button>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '20px !important' }}>
        < DayTable uploadedDays={uploadedDays} />
      </Grid>
    </Grid>
  );
}