import axios from 'axios'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import Scheduler, { SchedulerData, ViewTypes } from 'react-big-scheduler'
import { getColor } from '../../../../../components/Calendar/Calendar'

const schedulerData = new SchedulerData(new Date(), ViewTypes.Month, false, false)
schedulerData.config.creatable = false
schedulerData.config.dnd = false
schedulerData.config.views = []
schedulerData.config.showAgenda = false
schedulerData.config.showMonth = true
schedulerData.config.showWeek = false

schedulerData.config.schedulerWidth = window.innerWidth - 350

const LeavesCalendar = ({ userId }) => {
  const divElementRef = useRef(null)
  const [viewModel, setViewModel] = useState(new SchedulerData())
  const [resources, setResources] = useState([])
  const [events, setEvents] = useState([])

  const fetchLeavesCategories = async () => {
    try {
      const leaveCategoriesRes = await axios.get('leavecategories')
      const leaveTypes = leaveCategoriesRes.data.data

      const newResources = leaveTypes.map((t) => ({ id: t._id, name: t.name }))
      setResources(newResources)
    } catch (error) {
      console.error('Error fetching leave categories:', error)
    }
  }

  const fetchLeavesData = async (selectedYear) => {
    try {
      const leaveRes = await axios.get('leaves/me', {
        params: { year: selectedYear ? selectedYear : moment().year() }
      })

      if (leaveRes.data.success) {
        const leavesEvents = leaveRes.data.data.map((h) => ({
          id: h._id,
          showPopover: false,
          start: h.date_from,
          end: h.date_to,
          resourceId: h.category._id,
          title: ` ${h.category && h.category.name}`,
          bgColor: getColor(h),
          status: h.status,
          movable: false,
          resizable: false,
          other_info: h.other_info,
          messageChanged: h.message,
          days: h.days,
          type: h.category._id,
          name: h.category.name,
          user: h.user_id
        }))
        setEvents(leavesEvents)
      }
    } catch (error) {
      console.error('Error fetching leaves data:', error)
    }
  }

  const handleResize = () => {
    const contentWrapper = document.getElementById('wages-container').offsetWidth
    const clientWidth = divElementRef.current.offsetWidth

    schedulerData.config.schedulerWidth = clientWidth ? clientWidth - 30 : contentWrapper - 140
  }

  useEffect(() => {
    fetchLeavesCategories()
    fetchLeavesData()
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const updateCalendar = () => {
    schedulerData.setEvents(events)
    schedulerData.setResources(resources)
    setViewModel(schedulerData)
    handleResize()
  }

  const scrollCalendar = (left = false) => {
    const view = document.getElementsByClassName('scheduler-view')
    const scrollView = view[0].querySelectorAll(':scope > div')
    if (scrollView[1]) scrollView[1].scrollLeft = left ? 1 : 0
    setTimeout(() => {
      if (scrollView[1]) scrollView[1].scrollLeft = left ? 0 : 5
    }, 300)
  }

  const updateYear = (next = false) => {
    const month = schedulerData.startDate.split('-')[1] === next ? '01' : '12'
    month && fetchLeavesData(moment(schedulerData.startDate).year())
    updateCalendar()
    scrollCalendar(next)
  }

  const prevClick = (schedulerData) => {
    schedulerData.prev()
    updateYear()
  }

  const nextClick = (schedulerData) => {
    schedulerData.next()
    updateYear(true)
  }

  useEffect(() => {
    updateCalendar()
  }, [resources, events])

  return userId ? (
    ''
  ) : (
    <div ref={divElementRef}>
      <Scheduler schedulerData={viewModel} prevClick={prevClick} nextClick={nextClick} />
    </div>
  )
}

export default LeavesCalendar
