import { Box, Card, CardContent, Grid, Typography, CircularProgress } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { fetchMozoOrders, fetchMozoOrdersByDepartment } from '../API/endpoints'
import { Badge } from 'reactstrap'
import ContentWrapper from '../../../../components/Layout/ContentWrapper'
import Filters from '../components/filters/filters'
import MozoOrder_Table from '../components/table/table'
import Mozo_CSV from '../components/csv/downloadCSV'
import { expectedDepartments } from '../utils/utils'
import { useState } from 'react'

const Mozo_Orders = () => {
  const [queryParams, setQueryParams] = useState({ page: 0, limit: 10, department: '', search: '' })

  const ordersEndpoint = `mobilezoneOrders?department=${queryParams.department}&page=${queryParams.page}&limit=${queryParams.limit}&search=${queryParams.search}`

  const mozoOrders = useQuery({
    queryKey: ['mozoOrder', queryParams.department, queryParams.page, queryParams.limit, queryParams.search],
    queryFn: () => fetchMozoOrders(ordersEndpoint),
    staleTime: 50000
  })
  const mozoOrdersStats = useQuery({
    queryKey: ['mozoOrderstats'],
    queryFn: fetchMozoOrdersByDepartment,
    staleTime: 50000
  })

  if (mozoOrdersStats.isLoading) {
    return <CircularProgress />
  }

  if (mozoOrdersStats.error || mozoOrders.error) {
    return <Typography color='error'>Error loading data</Typography>
  }
  const dataMap = mozoOrdersStats?.data?.reduce((acc, group) => {
    acc[group._id] = { count: group.count, department_name: group.department_name }
    return acc
  }, {})

  // Combine expected departments with any additional departments from the response
  const combinedDepartments = [...expectedDepartments]

  Object.keys(dataMap).forEach((id) => {
    if (!expectedDepartments.find((dep) => dep._id === id)) {
      combinedDepartments.push({ _id: id, department_name: dataMap[id].department_name })
    }
  })

  const tableHeader = (
    <Grid container alignItems='center'>
      <Grid item xs={12} md={2} lg={1}>
        <Typography sx={{ fontSize: '25px' }}>Stats</Typography>
      </Grid>
      <Grid item xs={12} md={10} lg={11} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {combinedDepartments.map((department) => (
          <Box className='mr-3 ml-3' key={department._id}>
            {department.department_name}:{' '}
            <Badge pill className='statsGroupOrder' variant='secondary'>
              {dataMap[department._id]?.count || 0}
            </Badge>
          </Box>
        ))}
      </Grid>
    </Grid>
  )

  return (
    <ContentWrapper>
      <div className='content-heading'>
        <p>Mobilezone Orders</p>
      </div>
      <Mozo_CSV />
      <Card>
        <CardContent>{tableHeader}</CardContent>
      </Card>
      <Filters queryParams={queryParams} updateQueryParam={setQueryParams} filterDepartemnt={true} />
      {mozoOrders.isLoading ? (
        <CircularProgress />
      ) : (
        <MozoOrder_Table
          data={mozoOrders.data.data}
          total={mozoOrders.data.total}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
        />
      )}
    </ContentWrapper>
  )
}

export default Mozo_Orders
