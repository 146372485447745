import axios from 'axios'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Button, Col, Row } from 'reactstrap'
import ContentWrapper from '../../Layout/ContentWrapper'
import './warnings.scss'

const Warnings = () => {
  const [warnings, setWarnings] = useState([])
  const [show, setShow] = useState(false)
  const [newDescription, setNewDescription] = useState('')
  const [modifyModal, setModifyModal] = useState()

  const getAllWarnings = async () => {
    const res = await axios.get('/warnings')
    if (res.data.status) {
      setWarnings(res.data.data)
    } else {
      toast.error('Ndodhi një gabim')
    }
  }

  const handleClose = () => {
    setShow(false)
    setModifyModal()
    setNewDescription('')
  }
  const handleShow = () => setShow(true)

  const addNewWarnings = async () => {
    try {
      if (!newDescription) {
        toast.error('Përshkrimi nuk mund të jetë i zbrazët!')
      } else {
        const res = await axios.post('/warnings', {
          description: newDescription
        })
        if (res.data.status) {
          toast.success('Vërejtja është shtuar me sukses')
          getAllWarnings()
          handleClose()
        } else {
          toast.error(res.data.message ? res.data.message : 'Ndodhi një gabim')
        }
      }
    } catch (error) {
      console.log('error', error)
      toast.error('Ndodhi një gabim')
      handleClose()
    }
  }

  const updateWarning = async () => {
    try {
      if (!newDescription) {
        toast.error('Përshkrimi nuk mund të jetë i zbrazët!')
      } else {
        const res = await axios.put(`/warnings/${modifyModal.id}`, {
          description: newDescription
        })
        if (res.data.status) {
          toast.success('Vërejtja është modifikuar me sukses')
          getAllWarnings()
          handleClose()
        } else {
          toast.error(res.data.message ? res.data.message : 'Ndodhi një gabim')
        }
      }
    } catch (error) {
      console.log('error', error)
      toast.error('Ndodhi një gabim')
      handleClose()
    }
  }

  const deleteWarning = async (id) => {
    try {
      if (!id) {
        toast.error('Ndodhi një gabim')
      } else {
        const res = await axios.delete(`/warnings/${id}`)
        if (res.data.status) {
          toast.success('Vërejtja është fshirë me sukses')
          getAllWarnings()
        } else {
          toast.error(res.data.message ? res.data.message : 'Ndodhi një gabim')
        }
      }
    } catch (error) {
      console.log('error', error)
      toast.error('Ndodhi një gabim')
    }
  }

  useEffect(() => {
    getAllWarnings()
  }, [])

  const columns = [
    {
      name: 'Përshkrimi',
      label: 'Përshkrimi',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Krijuar më',
      label: 'Krijuar më'
    },
    {
      name: 'Krijuar nga',
      label: 'Krijuar nga'
    },
    {
      name: 'Modifikuar më',
      label: 'Modifikuar më'
    },
    {
      name: 'Modifikuar nga',
      label: 'Modifikuar nga'
    },
    {
      name: 'Modifiko',
      label: 'Modifiko',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Fshij',
      label: 'Fshij',
      options: {
        filter: false,
        sort: false
      }
    }
  ]
  const options = {
    search: false,
    print: false,
    responsive: 'scroll',
    filterType: 'dropdown',
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: 'Nuk ka të dhëna'
      }
    }
  }
  const data = warnings.map((oneWarning) => {
    return [
      oneWarning.description,
      moment(oneWarning.created_at).format('DD/MM/YYYY'),
      oneWarning.created_by.first_name + ' ' + oneWarning.created_by.last_name,
      oneWarning.last_edit_date ? moment(oneWarning.last_edit_date).format('DD/MM/YYYY') : '',
      oneWarning.edited_by ? oneWarning.edited_by.first_name + ' ' + oneWarning.edited_by.last_name : '',
      <Button
        outline
        className='mb-1'
        color='warning'
        type='button'
        onClick={() =>
          setModifyModal({
            description: oneWarning.description,
            id: oneWarning._id
          })
        }
      >
        <span>Modifiko</span>
      </Button>,
      <Button outline className='mb-1' color='danger' type='button' onClick={() => deleteWarning(oneWarning._id)}>
        <span>Fshij</span>
      </Button>
    ]
  })

  return (
    <>
      <Modal show={show} onHide={handleClose} centered size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Shto vërejtjen e re</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
              <Modal.Title>Përshkrimi</Modal.Title>
              <Form.Control as='textarea' rows={3} onChange={(e) => setNewDescription(e.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button color='danger' onClick={handleClose}>
            Mbyll
          </Button>
          <Button color='success' onClick={addNewWarnings}>
            Shto
          </Button>
        </Modal.Footer>
      </Modal>
      {modifyModal && (
        <Modal show={modifyModal} onHide={handleClose} centered size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>Modifiko vërejtjen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                <Modal.Title>Përshkrimi</Modal.Title>
                <Form.Control
                  as='textarea'
                  rows={3}
                  defaultValue={modifyModal.description}
                  onChange={(e) => setNewDescription(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button color='danger' onClick={handleClose}>
              Mbyll
            </Button>
            <Button color='success' onClick={updateWarning}>
              Modifiko
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <ContentWrapper>
        <div className='content-heading'>
          <div>Vërejtjet</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-end'
            }}
            className='ml-auto'
          >
            <Button color='success' className='btn-oval' onClick={() => handleShow()}>
              Shto Vërejtje
            </Button>
          </div>
        </div>
        <Row className='warningsTable'>
          <Col>
            <MUIDataTable title={'Vërejtjet'} columns={columns} options={options} data={data} />
          </Col>
        </Row>
      </ContentWrapper>
    </>
  )
}

export default Warnings
