import React from 'react'
import ContentWrapper from '../Layout/ContentWrapper'
import { SchedulerData, ViewTypes, DATE_FORMAT } from 'react-big-scheduler'
import moment from 'moment'
import 'moment/locale/sq' // without this line it didn't work
import { Row, Col, Button, Nav, NavItem, NavLink, TabContent, TabPane, Collapse, CardBody, CardHeader } from 'reactstrap'
import classnames from 'classnames'
import axios from 'axios'
import FormValidator from '../Forms/FormValidator.js'
import { inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import Calendar, { getColor } from '../Calendar/Calendar'
import MyLeaves from './MyLeavesList'
import LeaveForm from './LeaveForm'
import LeavesHeader from '../LeavesHeader/LeavesHeader'
import pubsub from 'pubsub-js'
import { DeleteDialogBox } from '../Common/'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

moment.locale('sq')
@inject('userStore')
class Leaves extends React.Component {
  _isMounted = false

  scrollToSpecialMoment = true

  state = {
    schedulerData: new SchedulerData(),
    dropdownOpen: false,
    width: 100,
    activeTab: '1',
    leaveTypes: [],
    myLeaves: [],
    currentLeave: null,
    dateFrom: null,
    dateTo: null,
    type: '',
    name: '',
    otherInfo: '',
    messageChanged: '',
    days: '',
    holidays: [],
    leaves: [],
    categoriesLoading: true,
    dataLoading: false,
    formLoading: false,
    myLeavesLoading: false,
    editLeave: null,
    backCurrentLeave: null,
    statsLoading: false,
    stats: null,
    status: '',
    collapse: false,
    selectedDate: new Date(),
    userInfo: [],
    availableForLeave: true,
    work: '',
    token: ''
  }

  constructor(props) {
    super(props)
    this.toggleLegend = this.toggleLegend.bind(this)
    this.updateDimensions = this.updateDimensions.bind(this)
    this.nextClick = this.nextClick.bind(this)
    this.prevClick = this.prevClick.bind(this)
    this.onViewChange = this.onViewChange.bind(this)
    this.onSelectDate = this.onSelectDate.bind(this)
    this.eventClicked = this.eventClicked.bind(this)
    this.ops1 = this.ops1.bind(this)
    this.ops2 = this.ops2.bind(this)
    this.newEvent = this.newEvent.bind(this)
    this.updateEventStart = this.updateEventStart.bind(this)
    this.updateEventEnd = this.updateEventEnd.bind(this)
    this.moveEvent = this.moveEvent.bind(this)
    this.toggleExpandFunc = this.toggleExpandFunc.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSelectChange = this.onSelectChange.bind(this)
    this.updateCreateEventFromInput = this.updateCreateEventFromInput.bind(this)
    this.loadData = this.loadData.bind(this)
    this.loadStats = this.loadStats.bind(this)
    this.clearFromAndRefresh = this.clearFromAndRefresh.bind(this)
    this.editLeave = this.editLeave.bind(this)
    this.clearEditLeave = this.clearEditLeave.bind(this)
    this.delete = this.delete.bind(this)
    this.getYear = this.getYear.bind(this)

    let schedulerData = new SchedulerData(new moment().format(DATE_FORMAT), ViewTypes.Month, false, false, undefined, {
      getScrollSpecialMomentFunc: (schedulerData, start) => {
        if (this.state.currentLeave) {
          const view = document.getElementsByClassName('scheduler-view')
          const scrollView = view[0].querySelectorAll(':scope > div')
          const categoryElementOfcurrentEvent = document.querySelectorAll(`[data-resource-id='${this.state.type}']`)

          if (categoryElementOfcurrentEvent[0]) scrollView[1].scrollTop = categoryElementOfcurrentEvent[0].offsetTop

          setTimeout(() => {
            if (categoryElementOfcurrentEvent[0]) scrollView[1].scrollTop = categoryElementOfcurrentEvent[0].offsetTop
          }, 100)

          return this.state.dateFrom
        }

        return moment()
      }
    })

    // schedulerData.config.schedulerMaxHeight = 350;
    schedulerData.setLocaleMoment(moment)
    schedulerData.config.views = schedulerData.config.views
      .filter((v) => {
        return v.viewType === ViewTypes.Week || v.viewType === ViewTypes.Month
      })
      .map((v) => {
        if (v.viewType === ViewTypes.Week) return { ...v, viewName: 'Java' }

        if (v.viewType === ViewTypes.Month) return { ...v, viewName: 'Muaji' }

        return v
      })

    this.state.viewModel = schedulerData
  }

  toggleLegend() {
    this.setState((state) => ({ collapse: !state.collapse }))
  }

  getYear(year) {
    this.setState({ selectedDate: year })
    this.loadMyLeaves()
    this.loadStats()
  }

  async loadMyLeaves() {
    return new Promise(async (resolve, reject) => {
      if (this._isMounted) {
        this.setState({
          myLeavesLoading: true
        })
      }

      try {
        const year = moment(this.state.selectedDate).format('YYYY')
        var params = {}
        params.year = year
        const myLeaveRes = await axios.get('leaves/me', { params })
        if (myLeaveRes.data.success && this._isMounted) {
          let myLeaves = myLeaveRes.data.data
          this.setState({
            myLeaves: myLeaves,
            myLeavesLoading: false
          })

          return resolve(myLeaves)
        }

        reject()
      } catch (e) {
        reject()
      }
    })
  }

  eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth) => {
    let borderWidth = isStart ? '4' : '0'
    let borderColor = 'rgba(0,139,236,1)',
      backgroundColor = '#80C5F6'
    let titleText = schedulerData.behaviors.getEventTextFunc(schedulerData, event)
    if (!!event.type) {
      borderColor = event.type === 1 ? 'rgba(0,139,236,1)' : event.type === 3 ? 'rgba(245,60,43,1)' : '#999'
      backgroundColor = event.type === 1 ? '#80C5F6' : event.type === 3 ? '#FA9E95' : '#D9D9D9'
    }
    let divStyle = {
      borderLeft: borderWidth + 'px solid ' + borderColor,
      backgroundColor: backgroundColor,
      height: mustBeHeight
    }
    if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth }

    return (
      <div key={event.id} className={mustAddCssClass} style={divStyle}>
        <span style={{ marginLeft: '4px', lineHeight: `${mustBeHeight}px` }}>{titleText}</span>
      </div>
    )
  }

  async loadStats() {
    return new Promise(async (resolve, reject) => {
      this.setState({
        statsLoading: true
      })

      try {
        const statsRes = await axios.get('leaves/userstats', {
          params: {
            year: moment(this.state.selectedDate).format('YYYY'),
            userId: this.props.userStore.user_id
          }
        })
        if (statsRes.data.success && this._isMounted) {
          this.setState(
            {
              stats: statsRes.data.data,
              statsLoading: false
            },
            resolve
          )
        } else {
          reject()
        }
      } catch (e) {
        reject()
        console.log('error', e)
      }
    })
  }

  async loadData() {
    return new Promise(async (resolve, reject) => {
      const schedulerData = this.state.viewModel

      var events = [...this.state.holidays]

      this.state.holidays.map((holiday) => {
        holiday.end._d = holiday.start._d
      })

      this.state.holidays.map((holiday) => {
        holiday.end._d = holiday.start._d
      })

      if (!this.state.editLeave && this.state.currentLeave) events.push(this.state.currentLeave)

      if (this._isMounted) {
        this.setState({
          viewModel: schedulerData,
          dataLoading: true
        })
      }

      try {
        const leaveRes = await axios.get('leaves/colleagues', {
          params: {
            date_from: moment(schedulerData.startDate).toISOString(true),
            date_to: moment(schedulerData.endDate).toISOString(true)
          }
        })

        if (leaveRes.data.success) {
          let leaves = leaveRes.data.data.map((h) => ({
            id: h._id,
            showPopover: h.category && this.props.userStore.user_id === h.user_id._id && h.status === 'Pending',
            start: h.date_from,
            end: h.date_to,
            resourceId: h.category && this.props.userStore.user_id === h.user_id._id ? h.category._id : 'r1',
            title: `${h.user_id.first_name} ${h.user_id.last_name} ${h.category && h.category.name}`,
            bgColor: this.state.currentLeave && this.state.currentLeave.id === h._id ? 'purple' : getColor(h),
            status: h.status,
            movable: false,
            resizable: false,
            other_info: h.other_info,
            messageChanged: h.message,
            days: h.days,
            type: h.category._id,
            name: h.category.name,
            user: h.user_id
          }))

          events.push(...leaves)
          schedulerData.setEvents(events)

          if (this._isMounted) {
            this.setState(
              {
                viewModel: schedulerData,
                leaves: leaves,
                dataLoading: false
              },
              resolve
            )
          }
        } else {
          reject()
        }
      } catch (e) {
        reject()
        console.log('error', e)
      }
    })
  }

  prevClick(schedulerData) {
    const view = document.getElementsByClassName('scheduler-view')
    const scrollView = view[0].querySelectorAll(':scope > div')
    const categoryElementOfcurrentEvent = document.querySelectorAll(`[data-resource-id='${this.state.type}']`)

    if (scrollView[1]) scrollView[1].scrollLeft = 1000000
    setTimeout(() => {
      if (scrollView[1]) scrollView[1].scrollLeft = 1000000
    }, 300)

    schedulerData.prev()

    this.setState(
      {
        viewModel: schedulerData
      },
      this.loadData
    )
  }

  nextClick(schedulerData) {
    const view = document.getElementsByClassName('scheduler-view')
    const scrollView = view[0].querySelectorAll(':scope > div')
    const categoryElementOfcurrentEvent = document.querySelectorAll(`[data-resource-id='${this.state.type}']`)

    if (scrollView[1]) scrollView[1].scrollLeft = 0
    setTimeout(() => {
      if (scrollView[1]) scrollView[1].scrollLeft = 0
    }, 300)

    schedulerData.next()

    this.setState(
      {
        viewModel: schedulerData
      },
      this.loadData
    )
  }

  onViewChange(schedulerData, view) {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective)

    this.setState(
      {
        viewModel: schedulerData
      },
      this.loadData
    )
  }

  onSelectDate(schedulerData, date) {
    schedulerData.setDate(date)

    this.setState(
      {
        viewModel: schedulerData
      },
      this.loadData
    )
  }

  eventClicked(schedulerData, event) {
    if (!this.editLeave && this.state.currentLeave && event.id === this.state.currentLeave.id) {
      this.setState({
        activeTab: '2'
      })
    }
  }

  ops1(schedulerData, event) {
    this.scrollToSpecialMoment = false
    this.props.history.push(`/leaves/${event.id}`)
  }

  ops2(schedulerData, event) {
    alert(`You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`)
  }

  newEvent(schedulerData, slotId, slotName, start, end, type, name, item) {
    if (this.state.editLeave) {
      return
    }

    if (this.state.currentLeave) {
      schedulerData.events.forEach((item, i) => {
        if (item.id === this.state.currentLeave.id) schedulerData.events.splice(i, 1)
      })
    }

    let newFreshId = 0
    schedulerData.events.forEach((item) => {
      if (item.id >= newFreshId) newFreshId = item.id + 1
    })

    let newEvent = {
      id: newFreshId,
      title: this.state.leaveTypes.filter((t) => t._id === slotId)[0].name,
      start: start,
      end: end,
      showPopover: false,
      resourceId: slotId,
      bgColor: 'purple'
    }

    schedulerData.addEvent(newEvent)

    this.setState({
      dateFrom: moment(start),
      dateTo: moment(end),
      currentLeave: newEvent,
      viewModel: schedulerData,
      type: slotId,
      name: newEvent.title,
      activeTab: '2'
    })
  }

  updateEventStart(schedulerData, event, newStart) {
    schedulerData.updateEventStart(event, newStart)

    this.setState({
      dateFrom: moment(newStart),
      viewModel: schedulerData,
      activeTab: '2'
    })
  }

  updateEventEnd(schedulerData, event, newEnd) {
    schedulerData.updateEventEnd(event, newEnd)

    this.setState({
      dateTo: moment(newEnd),
      viewModel: schedulerData,
      activeTab: '2'
    })
  }

  async editLeave(id, jumpToSpecialMoment = true) {
    var leaves = this.state.leaves

    const schedulerData = this.state.viewModel

    if (this.state.editLeave) {
      // return old
      leaves = this.state.leaves.map((l) => {
        if (l.id === this.state.backCurrentLeave.id) {
          Object.assign(l, this.state.backCurrentLeave)
          schedulerData.moveEvent(l, l.resourceId, '', l.start, l.end)
        }
        return l
      })
    } else if (this.state.currentLeave) {
      schedulerData.removeEvent(this.state.currentLeave)
    }

    this.setState({
      activeTab: '2',
      backCurrentLeave: { ...leaves.filter((l) => l.id === id)[0] },
      categoriesLoading: true
    })

    const setEditLeave = (event) => {
      event.bgColor = 'purple'
      event.resizable = true
      event.movable = true
      event.showPopover = false

      if (jumpToSpecialMoment) {
        schedulerData.setScrollToSpecialMoment(true)
      } else {
        // document.documentElement.scrollTop = 200
      }

      this.setState(
        {
          currentLeave: event,
          editLeave: id,
          dateFrom: moment(event.start),
          dateTo: moment(event.end),
          viewModel: schedulerData,
          otherInfo: event.other_info,
          days: event.days,
          status: event.status,
          messageChanged: event.messageChanged,
          lastYearLeave: event.lastYearLeave,
          extraLeaves: event.extraLeaves,
          accumulatedLeaves: event.accumulatedLeaves,
          // type: event.resourceId,
          name: event.categoryName,
          activeTab: '2',
          leaves,
          categoriesLoading: false,
          type: (event.type ? event.type : event.category._id) ? event.type : event.category
        },
        this.loadStats
      )
    }
    axios.get(`leaves/${id}`).then((res) => {
      if (res.data.success) {
        let data = new Object()
        data.id = res.data.data._id
        data.showPopover = false
        data.start = res.data.data.date_from
        data.end = res.data.data.date_to
        data.resourceId = 'r1'
        data.status = res.data.data.status
        data.other_info = res.data.data.other_info
        data.messageChanged = res.data.data.message
        data.days = res.data.data.days
        data.category = res.data.data.category
        data.categoryName = res.data.data.category.name
        data.lastYearLeave = res.data.data.days_removed_from ? res.data.data.days_removed_from.last_year_leaves : '0'
        data.extraLeaves = res.data.data.days_removed_from ? res.data.data.days_removed_from.extra_leaves : '0'
        data.accumulatedLeaves = res.data.data.days_removed_from ? res.data.data.days_removed_from.accumulated_leaves : '0'
        data.user = res.data.data.user_id
        setEditLeave(data)
      } else {
        let matchLeaves = leaves.filter((l) => l.id === id)

        if (matchLeaves[0]) {
          setEditLeave(matchLeaves[0])
        } else {
          let matchMyLeaves = this.state.myLeaves.filter((l) => l._id === id)
          if (matchMyLeaves[0]) {
            schedulerData.setDate(matchMyLeaves[0].date_from)
            this.setState(
              {
                viewModel: schedulerData,
                myLeavesLoading: true
              },
              async () => {
                await this.loadData
                await this.loadStats()

                this.setState({
                  myLeavesLoading: false
                })

                let matchLeaves = this.state.myLeaves.filter((l) => l._id === id)
                if (matchLeaves[0]) {
                  setEditLeave(matchLeaves[0])
                } else {
                  pubsub.publish('showNotification', {
                    message: 'Pushimi nuk u gjet ose është anuluar',
                    type: 'error'
                  })
                  this.setState(
                    {
                      categoriesLoading: false,
                      dataTableDataLoading: false
                    },
                    () => {
                      this.props.history.push('/leaves')
                    }
                  )
                }
              }
            )
          } else {
            pubsub.publish('showNotification', {
              message: 'Pushimi nuk u gjet ose është anuluar',
              type: 'error'
            })
            this.setState(
              {
                categoriesLoading: false,
                dataTableDataLoading: false
              },
              () => {
                this.props.history.push('/manageLeaves')
              }
            )
          }
        }
      }
    })
  }

  clearEditLeave() {
    this.setState(
      {
        currentLeave: null,
        editLeave: null,
        activeTab: '1'
      },
      this.clearFromAndRefresh
    )
  }

  moveEvent(schedulerData, event, slotId, slotName, name, start, end) {
    event.title = this.state.leaveTypes.filter((t) => t._id === slotId)[0].name

    schedulerData.moveEvent(event, slotId, slotName, name, start, end)

    this.setState({
      dateFrom: moment(start),
      dateTo: moment(end),
      viewModel: schedulerData,
      type: slotId,
      name: name,
      activeTab: '2'
    })
  }

  toggleExpandFunc(schedulerData, slotId) {
    schedulerData.toggleExpandStatus(slotId)
    this.setState({
      viewModel: schedulerData
    })
  }

  updateCreateEventFromInput() {
    const schedulerData = this.state.viewModel
    const { dateFrom, dateTo, type, currentLeave, leaveTypes } = this.state

    if (!moment(dateFrom).isValid() || !moment(dateTo).isValid() || !type) return

    if (currentLeave) {
      currentLeave.title = leaveTypes.filter((t) => t._id === type._id).name
      schedulerData.moveEvent(currentLeave, type, currentLeave.slotName, dateFrom.toISOString(true), dateTo.toISOString(true))
      schedulerData.setScrollToSpecialMoment(true)

      this.setState({
        viewModel: schedulerData,
        currentLeave: currentLeave
      })
    } else if (dateFrom && dateTo && type) {
      let newFreshId = 0
      schedulerData.events.forEach((item) => {
        if (item.id >= newFreshId) newFreshId = item.id + 1
      })

      let newEvent = {
        id: newFreshId,
        title: this.state.leaveTypes.filter((t) => t._id === type)[0].name,
        start: moment(dateFrom),
        end: moment(dateTo),
        resourceId: type,
        bgColor: 'purple'
      }

      schedulerData.setDate(dateFrom)
      schedulerData.setScrollToSpecialMoment(true)

      this.setState(
        {
          currentLeave: newEvent,
          viewModel: schedulerData
        },
        this.loadData
      )
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      const leaveId = this.props.match.params.id

      if (leaveId) {
        this.editLeave(leaveId, this.scrollToSpecialMoment)
        this.scrollToSpecialMoment = true
        return
      }

      this.clearEditLeave()
      await this.loadMyLeaves()
    }
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  async componentDidMount() {
    this._isMounted = true

    window.addEventListener('resize', this.updateDimensions)
    this.updateDimensions()

    const schedulerData = this.state.viewModel

    try {
      const leaveCategoriesRes = await axios.get('leavecategories')

      if (leaveCategoriesRes.data.success) {
        const leaveTypes = leaveCategoriesRes.data.data

        let resources = [
          {
            id: 'r0',
            name: 'Festat',
            groupOnly: true
          },
          {
            id: 'r1',
            name: 'Pushimet e ekipit',
            groupOnly: true
          },
          ...leaveTypes.map((t) => ({
            id: t._id,
            name: t.name
          }))
        ]

        schedulerData.setResources(resources)

        const holidaysRes = await axios.get('holidays')

        if (holidaysRes.data.success) {
          const holidays = holidaysRes.data.data.map((h) => {
            return {
              id: h._id,
              start: moment(h.date),
              end: moment(h.date).add(1, 'days'),
              resourceId: 'r0',
              title: h.name,
              showPopover: false,
              bgColor: 'gray'
            }
          })

          schedulerData.setEvents(holidays)

          if (this._isMounted) {
            this.setState({ categoriesLoading: false })

            this.setState({
              viewModel: schedulerData,
              holidays,
              leaveTypes
            })
          }

          await this.loadData
          await this.loadMyLeaves()
          await this.loadStats()

          const leaveId = this.props.match.params.id

          if (leaveId && this._isMounted) {
            this.editLeave(leaveId)
          }
        }
      }
    } catch (e) {
      console.log('error', e)
    }

    axios
      .get(`users/${this.props.userStore.user_id}`)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            work: res.data.data.work.name
          })
        }
      })
      .catch((err) => {})
  }

  componentWillUnmount() {
    this._isMounted = false

    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions() {
    const schedulerData = this.state.viewModel
    schedulerData.config.schedulerWidth = this.divElement.clientWidth - 80
    if (this._isMounted) {
      this.setState({ width: this.divElement.clientWidth, schedulerData })

      setTimeout(() => {
        const schedulerData = this.state.viewModel
        if (!this.divElement) return
        schedulerData.config.schedulerWidth = this.divElement.clientWidth - 80
        this.setState({ width: this.divElement.clientWidth })
      }, 300)
    }
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  clearFromAndRefresh() {
    this.setState(
      {
        currentLeave: null,
        dateFrom: null,
        dateTo: null,
        type: '',
        name: '',
        otherInfo: '',
        messageChanged: '',
        formLoading: false
      },
      this.loadData
    )
  }

  async delete() {
    const schedulerData = this.state.viewModel

    if (this.state.editLeave) {
      const goBack = () => this.props.history.goBack()
      const path = `leaves/${this.state.currentLeave.id}`
      const name = 'Pushimin'

      DeleteDialogBox({ path, name, goBack })
    } else {
      if (this.state.currentLeave) {
        schedulerData.removeEvent(this.state.currentLeave)
        this.clearFromAndRefresh()
      }
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = e.target
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })

    if (hasError) return

    try {
      this.setState({ formLoading: true })

      if (this.state.editLeave) {
        const body = {
          date_from: moment(this.state.dateFrom).format('YYYY-MM-DD'),
          date_to: moment(this.state.dateTo).format('YYYY-MM-DD'),
          category: this.state.type,
          categoryName: this.state.name,
          other_info: this.state.otherInfo,
          message: this.state.messageChanged,
          days: this.state.days
        }

        const dateFrom = moment(body.date_from)

        const lessThanSixMonthsDiffernce = dateFrom.diff(moment(this.props.userStore.first_call), 'months') < 6

        if (lessThanSixMonthsDiffernce && body.categoryName == 'Pushim Vjetor') {
          Swal.fire('Vërejtje', 'Nuk mund të aplikoni për pushim vjetor para 6 muajve punë!', 'error')
          pubsub.publish('showNotification', {
            message: 'Nuk mund të aplikoni për pushim vjetor!',
            type: 'error'
          })

          return this.props.history.push('/leaves')
        } else {
          // const moreThan30Day = dateTo.diff(dateFrom, 'months') >= 30

          // // if (moreThan30Day && body.categoryName == 'Pushim Vjetor') {
          // //   Swal.fire('Vërejtje', 'Ditet e pushimit vjetor nuk do te kalkulohen gjate kesaj periudhe', 'warning')
          // // }

          // // if (this.state.work == 'In Training' && body.categoryName == 'Pushim Pa Pagesë') {
          // //   Swal.fire(
          // //     'Vërejtje',
          // //     'Ju njoftojmë se për tu aprovuar pushimi ju duhet të bëni depozitimin e pagës së parë tek zyra e HR',
          // //     'warning'
          // //   )
          // // }

          await axios.put(`leaves/${this.state.currentLeave._id ? this.state.currentLeave._id : this.state.currentLeave.id}`, {
            date_from: moment(this.state.dateFrom).toISOString(true),
            date_to: moment(this.state.dateTo).toISOString(true),
            category: this.state.type,
            categoryName: this.state.name,
            other_info: this.state.otherInfo,
            message: this.state.messageChanged,
            days: this.state.days
          })

          this.props.history.push('/leaves')

          pubsub.publish('showNotification', {
            message: 'Të dhënat janë ruajtur!',
            type: 'success'
          })
        }
      } else {
        const body = {
          date_from: moment(this.state.dateFrom).format('YYYY-MM-DD'),
          date_to: moment(this.state.dateTo).format('YYYY-MM-DD'),
          category: this.state.type,
          categoryName: this.state.name,
          other_info: this.state.otherInfo,
          message: this.state.messageChanged,
          days: this.state.days
        }

        const dateFrom = moment(body.date_from)

        const lessThanSixMonthsDiffernce = dateFrom.diff(moment(this.props.userStore.first_call), 'months') < 6

        if (lessThanSixMonthsDiffernce && body.categoryName == 'Pushim Vjetor') {
          Swal.fire('Vërejtje', 'Nuk mund të aplikoni për pushim vjetor para 6 muajve punë!', 'error')
          pubsub.publish('showNotification', {
            message: 'Nuk mund të aplikoni për pushim vjetor!',
            type: 'error'
          })
        } else {
          // const moreThan10Days = dateTo.diff(dateFrom, 'months') >= 10

          // if (diff > 10 && body.categoryName == 'Pushim Vjetor') {
          //   Swal.fire('Vërejtje', 'Ditet e pushimit vjetor nuk do te kalkulohen gjate kesaj periudhe', 'warning')
          // }

          // if (this.state.work == 'In Training' && body.categoryName == 'Pushim Pa Pagesë') {
          //   Swal.fire(
          //     'Vërejtje',
          //     'Ju njoftojmë se për tu aprovuar pushimi ju duhet të bëni depozitimin e pagës së parë tek zyra e HR',
          //     'warning'
          //   )
          // }

          await axios.post('leaves', body)

          pubsub.publish('showNotification', {
            message: 'Këkesa për pushim është deguar!',
            type: 'success'
          })
        }
      }

      this.setState({
        formLoading: false,
        activeTab: '1'
      })

      this.clearFromAndRefresh()
      this.loadMyLeaves()
    } catch (e) {
      pubsub.publish('showNotification', {
        message: e.toString(),
        type: 'error'
      })
    }
  }

  onSelectChange(e) {
    this.setState(
      {
        type: e.target.value,
        name: e.target.options[e.target.selectedIndex].text
      },
      () => {
        this.updateCreateEventFromInput()
      }
    )
  }
  onChange(field) {
    return (data) => {
      if (field === 'dateFrom' && typeof data != 'string') {
        data.set({
          hours: 0,
          minutes: 0,
          seconds: 0
        })
      }

      if (field === 'dateTo' && typeof data != 'string') {
        data.set({
          hours: 23,
          minutes: 59,
          seconds: 59
        })
      }

      this.setState(
        {
          [field]: data
        },
        () => {
          if (field !== 'otherInfo') this.updateCreateEventFromInput()
        }
      )
    }
  }

  render() {
    return (
      <ContentWrapper>
        <div className='content-heading d-flex justify-content-between'>
          <p>
            <span>Pushimet:</span>
            {this.state.currentLeave &&
              this.state.currentLeave !== null &&
              this.state.currentLeave.user &&
              this.props.userStore.user_id !== this.state.currentLeave.user._id && (
                <span>
                  <Link to={`/editUsers/${this.state.currentLeave.user._id}`}>
                    {this.state.currentLeave && ' ' + this.state.currentLeave.user.first_name}{' '}
                    {this.state.currentLeave && this.state.currentLeave.user.last_name}
                  </Link>
                </span>
              )}
          </p>
          {this.state.editLeave && (
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          )}
        </div>
        <div className={this.state.categoriesLoading ? 'whirl standard' : null} ref={(ref) => (this.divElement = ref)}>
          <div role='tabpanel'>
            <LeavesHeader previous={false} onYear={this.getYear} statsLoading={this.state.statsLoading} stats={this.state.stats} />
            <Col>
              <Row>
                <div className='card b w-100'>
                  <div className='card-body text-center'>
                    {!this.state.editLeave ? (
                      <Nav tabs fill>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === '1'
                            })}
                            onClick={() => {
                              this.toggleTab('1')
                            }}
                          >
                            Pushimet e mia
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === '2'
                            })}
                            onClick={() => {
                              this.toggleTab('2')
                            }}
                          >
                            Kërko Pushim
                          </NavLink>
                        </NavItem>
                      </Nav>
                    ) : (
                      <Nav tabs fill />
                    )}
                    <TabContent activeTab={this.state.activeTab}>
                      <div className={this.state.dataLoading ? 'whirl standard' : null}>
                        <Calendar
                          key='calendar'
                          schedulerData={this.state.viewModel}
                          eventItemClick={this.eventClicked}
                          prevClick={this.prevClick}
                          nextClick={this.nextClick}
                          onSelectDate={this.onSelectDate}
                          onViewChange={this.onViewChange}
                          viewEventClick={this.ops1}
                          viewEventText='Modifiko'
                          viewEvent2Text=''
                          viewEvent2Click={this.ops2}
                          updateEventStart={this.updateEventStart}
                          updateEventEnd={this.updateEventEnd}
                          moveEvent={this.moveEvent}
                          newEvent={this.newEvent}
                          toggleExpandFunc={this.toggleExpandFunc}
                          holidays={this.state.holidays}
                          leaveTypes={this.state.leaveTypes}
                          leaves={this.state.leaves}
                          currentLeave={this.state.currentLeave}
                          dateFrom={this.state.dateFrom}
                          dateTo={this.state.dateTo}
                          type={this.state.type}
                          width={this.state.width}
                        />
                      </div>
                      <div className='card b mb-3'>
                        <CardHeader onClick={this.toggleLegend}>
                          <div className='d-flex justify-content-between'>
                            <span color='info' className='btn-labeled'>
                              <span>Legjenda</span>
                            </span>
                            <div>
                              <i className='fas fa-angle-down' />
                            </div>
                          </div>
                        </CardHeader>
                        <Collapse isOpen={this.state.collapse}>
                          <CardBody>
                            <Col>
                              <span style={{ marginRight: 8 }} className='badge badge-success'>
                                Pushimi i aprovuar
                              </span>
                              <span style={{ marginRight: 8 }} className='badge badge-danger'>
                                Pushimi i refuzuar
                              </span>
                              <span style={{ marginRight: 8 }} className='badge bg-yellow'>
                                Pushimi eshte ne procesim
                              </span>
                              <span style={{ marginRight: 8 }} className='badge badge-warning'>
                                Pushimi pjeserisht i aprovuar
                              </span>
                              <span
                                style={{
                                  marginRight: 8,
                                  backgroundColor: 'rgb(128,128,128)',
                                  color: 'white'
                                }}
                                className='badge'
                              >
                                Pushimet e festave
                              </span>
                              <span
                                style={{
                                  marginRight: 8,
                                  backgroundColor: 'rgb(123,2,134)',
                                  color: 'white'
                                }}
                                className='badge'
                              >
                                Pushimi qe jeni duke aplikuar
                              </span>
                            </Col>
                          </CardBody>
                        </Collapse>
                      </div>
                      <TabPane tabId='1'>
                        <MyLeaves
                          leaveTypes={this.state.leaveTypes}
                          data={this.state.myLeaves}
                          myLeavesLoading={this.state.myLeavesLoading}
                          editLeave={this.editLeave}
                        />
                      </TabPane>
                      <TabPane tabId='2'>
                        <div className={this.state.myLeavesLoading ? 'whirl standard' : null}>
                          <LeaveForm
                            days={this.state.days}
                            status={this.state.status}
                            hasDeleteButton={this.state.editLeave == null && this.state.activeTab === '2' ? false : true}
                            title={!this.state.editLeave ? 'Kërko Pushim' : 'Modifiko Pushimin'}
                            leaveTypes={this.state.leaveTypes}
                            type={this.state.type}
                            formLoading={this.state.formLoading}
                            dateFrom={this.state.dateFrom}
                            dateTo={this.state.dateTo}
                            otherInfo={this.state.otherInfo}
                            messageChanged={this.state.messageChanged}
                            lastYearLeave={this.state.lastYearLeave}
                            extraLeaves={this.state.extraLeaves}
                            accumulatedLeaves={this.state.accumulatedLeaves}
                            onChange={this.onChange}
                            onSelectChange={this.onSelectChange}
                            hasError={this.hasError}
                            delete={this.delete}
                            viewOnly={this.state.editLeave && this.state.currentLeave.status !== 'Pending'}
                            onSubmit={this.onSubmit}
                          />
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </Row>
            </Col>
          </div>
        </div>
      </ContentWrapper>
    )
  }
}

export default withRouter(Leaves)
