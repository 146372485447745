import { useEffect, useState } from "react"

export const useDebounce = (search, delay = 300) => {
  const [debouncedVal, setDebouncedVal] = useState(search);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedVal(search)
    }, delay);

    return () => clearTimeout(timeout)
  }, [search, delay]);

  return debouncedVal;
};