import React, { useState, useRef } from 'react'
import useTwoFaCodeInput from '../hooks/useTwoFaCodeInput'

const TwoFactorAuthForm = ({ onSubmit, isVerification = false, viaEmail = false, onBackToLogin }) => {
  const [twoFaToken, setTwoFaToken] = useState(['', '', '', '', '', ''])
  const twoFaInputs = Array.from({ length: 6 }, () => useRef(null))

  const { handleTwoFaChange, handleTwoFaKeyDown, handleTwoFaPaste, disabledBtn } = useTwoFaCodeInput(twoFaToken, setTwoFaToken, twoFaInputs)

  const handleSubmit = (e) => {
    e.preventDefault()
    const token = twoFaToken.join('')
    onSubmit(token)
  }

  return (
    <div>
      {isVerification && (
        <div className='d-flex justify-content-center align-item-center flex-column'>
          <h5 className='mb-3 text-center'>Verifikoni Autentifikimin me Dy Faktorë (2FA)</h5>
          <p className='text-center'>
            {viaEmail ? (
              <>
                Për të vazhduar me hyrjen në llogarinë tuaj, ju lutemi kontrolloni email-in tuaj dhe futni kodin <strong>6-shifror</strong>{' '}
                të dërguar.
              </>
            ) : (
              <>
                Për të vazhduar me hyrjen në llogarinë tuaj, ju lutemi futni kodin e gjeneruar nga aplikacioni juaj të autentifikimit. Ky
                kod përmban <strong>6 shifra</strong> dhe është i vlefshëm për një kohë të shkurtër.
              </>
            )}
          </p>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className='two-fa-container'>
          {twoFaToken.map((digit, index) => (
            <input
              key={index}
              type='text'
              maxLength='1'
              className='two-fa-input'
              name='twoFaToken'
              value={digit}
              onChange={(e) => handleTwoFaChange(e, index)}
              onKeyDown={(e) => handleTwoFaKeyDown(e, index)}
              onPaste={index === 0 ? handleTwoFaPaste : undefined}
              ref={twoFaInputs[index]}
              autoFocus={index === 0}
              aria-label={`Digit ${index + 1}`}
            />
          ))}
        </div>
        <button disabled={disabledBtn} className='btn btn-block btn-info mt-3 login-btn' type='submit'>
          Verifiko
        </button>
        <div className='mt-3 d-flex justify-content-center align-item-center'>
          <href onClick={onBackToLogin} className='btn btn-link text-muted' type='button'>
            Kthehu tek login
          </href>
        </div>
      </form>
    </div>
  )
}

export default TwoFactorAuthForm
