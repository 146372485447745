export const getColumns = (
    languages,
    roles,
    projects,
    departments,
    activities,
    workingStatus,
    offices,
    showFilters,
    renderFilters
  ) => [
    {
      name: 'Email',
      label: 'Email',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Përdoruesi',
      label: 'Përdoruesi',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Gjuhet',
      label: 'Gjuhet',
      options: {
        filter: true,
        sort: false,
        display: showFilters.language,
        filterList: renderFilters.language,
        filterOptions: {
          names: languages.map((element) => element.code)
        }
      }
    },
    {
      name: 'Pozita',
      label: 'Pozita',
      options: {
        filter: true,
        sort: true,
        filterList: renderFilters.function,
        filterOptions: {
          names: roles.map((element) => element.name)
        }
      }
    },
    {
      name: 'Projekti',
      label: 'Projekti',
      options: {
        filter: true,
        sort: true,
        filterList: renderFilters.project,
        filterOptions: {
          names: projects.map((element) => element.name)
        }
      }
    },

    {
      name: 'Aktiviteti',
      label: 'Aktiviteti',
      options: {
        filter: true,
        sort: true,
        display: showFilters.activity,
        filterList: renderFilters.activity,
        filterOptions: {
          names: activities.map((element) => element.name)
        }
      }
    },
    {
      name: 'Statusi i punës',
      label: 'Statusi i punës',
      options: {
        filter: true,
        sort: false,
        display: showFilters.workingStatus,
        filterList: renderFilters.workingStatus,
        filterOptions: {
          names: workingStatus.map((element) => element.name)
        }
      }
    },
    {
      name: 'Leternjoftimi',
      label: 'Leternjoftimi',
      options: {
        filter: false,
        sort: true,
        display: showFilters.identityID
      }
    },
    {
      name: 'Krijuar ne',
      label: 'Krijuar ne',
      options: {
        filter: false,
        sort: true,
        display: showFilters.createdDate
      }
    },
    {
      name: 'Qyteti',
      label: 'Qyteti',
      options: {
        filter: false,
        sort: true,
        display: showFilters.city
      }
    },
    {
      name: 'Menaxheri',
      label: 'Menaxheri',
      options: {
        filter: false,
        sort: true,
        display: showFilters.manager
      }
    },
    {
      name: 'Team Leaderi',
      label: 'Team Leaderi',
      options: {
        filter: false,
        sort: true,
        display: showFilters.teamLeader
      }
    },
    {
      name: 'Lokacioni i punës',
      label: 'Lokacioni i punës',
      options: {
        filter: true,
        sort: false,
        filterList: renderFilters.offices,
        filterOptions: {
          names: offices.map((office) => office)
        }
      }
    },
    {
      name: 'Departamenti',
      label: 'Departamenti',
      options: {
        filterType: 'checkbox',
        filter: true,
        sort: false,
        display: showFilters.department,
        filterList: renderFilters.department,
        filterOptions: {
          names: departments.map((element) => element.name)
        }
      }
    },
    {
      name: 'Pamje',
      label: 'Pamje',
      options: {
        filter: false,
        sort: false
      }
    }
  ];
  