import React from 'react'
import { Badge, Box, Typography } from '@material-ui/core'
import { Button } from 'reactstrap'
import DoneIcon from '@material-ui/icons/Done'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function Statuses({ setQuery, statusEnumSQ, query, btn, len }) {
  const max600 = useMediaQuery('(max-width:600px)')
  const max900 = useMediaQuery('(max-width:900px)')
  return (
    <Box
      style={{
        width: max600 ? `calc((100% / ${len} * 3) - 15px)` : max900 ? `calc((100% / ${len} * 3) - 10px)` : `calc((100% / ${len}) - 10px)`,
        marginTop: max900 && '5px',
        ':hover': {
          backgroundColor: '#000'
        },
        transform: query.status === btn._id ? 'scale(0.9)' : ''
      }}
      className='shadow'
    >
      <Button
        style={{
          padding: 0,
          width: '100%',
          height: '120px',
          position: 'relative',
          whiteSpace: 'normal',
          color: query.status === btn._id ? '#fff' : 'initial',
          backgroundColor: query.status === btn._id && '#3a3f51'
        }}
        onClick={() => {
          setQuery((prev) => ({ ...prev, status: btn._id }))
        }}
      >
        {query.status === btn._id ? (
          <Box
            style={{
              position: 'absolute',
              top: '-10px',
              right: '-10px',
              backgroundColor: '#3a3f51',
              borderRadius: '20px',
              border: '1px solid #fff',
              padding: '3px',
              color: '#fff'
            }}
          >
            <Badge color='primary'>
              <DoneIcon />
            </Badge>
          </Box>
        ) : (
          ''
        )}
        <Typography>{statusEnumSQ[btn._id]}</Typography>
        <Typography
          style={{
            marginTop: '10px',
            fontSize: '40px',
            fontWeight: '600',
            letterSpacing: '.2rem'
          }}
        >
          {btn.total}
        </Typography>
      </Button>
    </Box>
  )
}
