import * as React from 'react';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Button, TextField, Typography, IconButton } from '@mui/material'
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2'
import { useState } from 'react';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  alignItems: "flex-start",
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    width: "550px",
  },

  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function ImportHoursModal({ setRefreshTable, refreshTable }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(new Date());
  const [csvFile, setCsvFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCsvFile(file);
  };

  const submitFile = async () => {
    if (csvFile) {
      setOpen(false)
      Swal.fire({
        title: 'Ju lutem prisni...',
        html: 'Duke ngarkuar...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        }
      })
      let query = `targetYear=${moment(value).format('YYYY')}&targetMonth=${moment(value).format('MM')}&targetDay=${moment(value).format('DD')}`

      if (csvFile) {
        const formData = new FormData();
        formData.append('csvFile', csvFile);

        try {
          const response = await axios.post(`dailyWorkingHours/importHours?${query}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          if (response.status === 200) {
            setRefreshTable(!refreshTable)
            Swal.close()
            Swal.fire({
              title: 'Te dhenat u importuan me sukses.',
              html: 'Dukumenti eshte importuar me sukses'
            })
          }
          setCsvFile(null)
        } catch (error) {
          console.error('Error submitting file:', error);
          Swal.fire('', 'Ndodhi nje gabim!', 'error')

        }
      }
    } else {
      Swal.fire('', 'Ngarkon filen', 'error')
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'end', paddingLeft: '20px' }}>
        <Button onClick={handleClickOpen} sx={{ backgroundColor: '#3A3F51 !important', padding: "6px 16px !important", minWidth: '150px !important', color: "#fff !important" }} variant="contained" >Importo&nbsp;<CloudUploadIcon /></Button>
      </Box>
      <BootstrapDialog
        sx={{ alignItems: 'flex-start !important', }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className='parentClass'
      >

        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Importo oret
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
        </IconButton>
        <DialogContent dividers >
          <Box sx={{ fieldset: { border: '1px solid' }, paddingX: '10px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <DatePicker
                  views={['year', 'month', 'day']}
                  label="Selekto Muajin dhe Vitin"
                  inputFormat='dd/MM/yyyy'
                  // minDate={('2012-03-01')}
                  // maxDate={('2023-06-01')}
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => <TextField sx={{
                    '.MuiInputBase-input': {
                      padding: "16.5px 14px !important"
                    }
                  }} {...params} helperText={null} />}
                />
              </Stack>
            </LocalizationProvider>
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
              <Button component="label" sx={{ backgroundColor: '#3A3F51 !important', padding: "6px 16px !important", minWidth: '150px !important', color: "#fff !important" }} variant="contained" startIcon={<CloudUploadIcon />}>
                Upload file
                <input
                  type="file"
                  accept=".csv"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </Button>
            </Box>
            <Typography gutterBottom>
              {csvFile?.name}
            </Typography>
          </Box>
          <Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Anulo
          </Button>
          <Button autoFocus onClick={submitFile}>
            Importo
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

