import React from 'react'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import 'moment/locale/sq' // without this line it didn't work
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import './CustomTooltip.css'

function MyLeavesList({ data, myLeavesLoading, leaveTypes }) {
  const mapMethod = (element) => {
    let approval = []
    let hr = {}
    if (element) {
      Object.entries(element).forEach((elem) => {
        if (elem[0] === 'first_name') {
          hr['first_name'] = elem[1]
        }
        if (elem[0] === 'last_name') {
          hr['last_name'] = elem[1]
        }
      })
      approval.push(hr)
    }
    return approval
  }

  const columns = [
    'Tipi i pushimit',
    'Prej',
    'Deri',
    'Ditët e punes total',
    {
      name: 'Shqyrtuesi i kërkesës',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'Data e kërkesës',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'Statusi',
      label: 'Statusi',
      options: {
        filter: false
      }
    },
    {
      name: 'Modifiko',
      label: 'Modifiko',
      options: {
        filter: false,
        download: false
      }
    }
  ]

  const options = {
    downloadOptions: {
      filename: `Pushimet e mia.csv`
    },
    status: false,
    pagination: false,
    filter: false,
    filterType: 'dropdown',
    responsive: 'scroll',
    viewColumns: true,
    selectableRows: 'none',
    rowsPerPageOptions: [10, 50, 100, 200],
    page: 0,
    elevation: 0,
    textLabels: {
      body: {
        noMatch: 'Nuk ka të dhëna'
      }
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      data.map((d) => {
        let status = d.data[6].props.children
        d.data[6] = status
      })

      return '\uFEFF' + buildHead(columns) + buildBody(data)
    }
  }

  const leaveTypesKeys = {}
  leaveTypes &&
    leaveTypes.map((t) => {
      leaveTypesKeys[t._id] = 1
    })

  const _data = data.map((element) => {
    let string = ''

    if (element.hr_approval) {
      mapMethod(element.hr_approval)
      string = string + 'Aprovuar nga HR: \n'
      string = string + element.hr_approval.first_name + ' ' + element.hr_approval.last_name + '\n'
    }

    if (element.manager_approval) {
      mapMethod(element.manager_approval)
      string = string + 'Aprovuar nga Manager: \n'
      string = string + element.manager_approval.first_name + ' ' + element.manager_approval.last_name + '\n'
    }

    if (element.teamleader_approval) {
      mapMethod(element.teamleader_approval)
      string = string + 'Pjesërisht aprovuar nga TL: \n'
      string = string + element.teamleader_approval.first_name + ' ' + element.teamleader_approval.last_name + '\n'
    }

    let color
    switch (element.status) {
      case 'Pending':
        color = 'badge bg-yellow'
        break
      case 'Approved':
        color = 'badge badge-success'
        break
      case 'Rejected':
        color = 'badge badge-danger'
        break
      case 'Partially approved':
        color = 'badge badge-warning'
        break
    }

    return [
      element.category ? element.category.name : '',
      moment(element.date_from).format('DD/MM/YYYY'),
      moment(element.date_to).format('DD/MM/YYYY'),
      element.days,
      element.hr_approval
        ? element.hr_approval.first_name + ' ' + element.hr_approval.last_name
        : element.manager_approval
        ? element.manager_approval.first_name + ' ' + element.manager_approval.last_name
        : element.teamleader_approval
        ? element.teamleader_approval.first_name + ' ' + element.teamleader_approval.last_name
        : '',
      moment(element.created_date).format('DD/MM/YYYY'),
      <span
        className={color}
        data-tooltip={(string.length > 0 && element.status == 'Approved') || element.status == 'Partially approved' ? string : null}
        data-tooltip-position='top'
      >
        {element.status}
      </span>,
      leaveTypesKeys[element.category && element.category._id] && (
        <Link to={`/leaves/${element._id}`}>
          <Button outline className='mb-1' color='warning' type='button'>
            <span>{element.status == 'Pending' ? 'Modifiko' : 'Shfaq'}</span>
          </Button>
        </Link>
      )
    ]
  })

  return <MUIDataTable title={''} columns={columns} options={options} data={_data} />
}

export default MyLeavesList
