export const icomoonIcons = {
  'Web Applications': [
    57436, 57437, 57438, 57439, 57524, 57525, 57526, 57527, 57528, 57531, 57532, 57533, 57534, 57535, 57536, 57537, 57541, 57545, 57691,
    57692
  ],
  'Business Icons': [
    57347, 57348, 57375, 57376, 57377, 57379, 57403, 57406, 57432, 57433, 57434, 57435, 57450, 57453, 57456, 57458, 57460, 57461, 57463
  ],
  eCommerce: [57392, 57397, 57398, 57399, 57402],
  'Currency Icons': [],
  'Form Control Icons': [57383, 57384, 57385, 57386, 57387, 57388, 57484, 57594, 57595, 57600, 57603, 57604, 57659, 57660, 57693],
  'User Action & Text Editor': [
    57442, 57443, 57444, 57445, 57446, 57447, 57472, 57473, 57474, 57475, 57476, 57477, 57539, 57662, 57668, 57669, 57670, 57671, 57674,
    57675, 57688, 57689
  ],
  'Charts and Codes': [57493],
  Attentive: [57543, 57588, 57590, 57591, 57592, 57593, 57596],
  'Multimedia Icons': [
    57356, 57357, 57362, 57363, 57448, 57485, 57547, 57548, 57549, 57605, 57606, 57609, 57610, 57611, 57614, 57617, 57618, 57620, 57621,
    57622, 57623, 57624, 57625, 57626
  ],
  'Location and Contact': [57344, 57345, 57346, 57404, 57405, 57408, 57410, 57411, 57413, 57414, 57540],
  'Date and Time': [57415, 57416, 57417, 57421, 57422, 57423],
  Devices: [57359, 57361, 57364, 57425, 57426, 57430],
  Tools: [57349, 57350, 57352, 57355, 57365, 57478, 57479, 57480, 57481, 57482, 57483, 57486, 57487, 57488, 57663, 57664],
  'Social and Networking': [
    57694, 57700, 57701, 57702, 57703, 57704, 57705, 57706, 57707, 57709, 57710, 57711, 57717, 57718, 57719, 57736, 57737, 57738, 57739,
    57740, 57741, 57742, 57746, 57747, 57748, 57755, 57756, 57758, 57759, 57760, 57761, 57763, 57764, 57765, 57766, 57767, 57776
  ],
  Brands: [57743, 57750, 57751, 57752, 57753, 57754, 57757, 57773, 57774, 57775, 57789, 57790, 57792, 57793],
  'Files & Documents': [57378, 57380, 57381, 57382, 57390, 57391, 57778, 57779, 57780, 57781, 57782, 57783, 57784, 57785, 57786, 57787],
  'Like & Dislike Icons': [57542, 57544, 57550, 57551, 57552, 57553, 57554, 57555, 57556, 57557],
  Emoticons: [
    57558, 57559, 57560, 57561, 57562, 57563, 57564, 57565, 57566, 57567, 57568, 57569, 57570, 57571, 57572, 57573, 57574, 57575, 57576,
    57577, 57578, 57579, 57580, 57581, 57582, 57583
  ],
  'Directional Icons': [
    57584, 57585, 57586, 57587, 57631, 57632, 57633, 57634, 57635, 57636, 57637, 57638, 57639, 57640, 57641, 57642, 57643, 57644, 57645,
    57646, 57647, 57648, 57649, 57650, 57651, 57652, 57653, 57654
  ],
  'Other Icons': [
    57351, 57353, 57354, 57358, 57360, 57366, 57367, 57368, 57369, 57370, 57371, 57372, 57373, 57374, 57389, 57393, 57394, 57395, 57396,
    57400, 57401, 57407, 57409, 57412, 57418, 57419, 57420, 57424, 57427, 57428, 57429, 57431, 57440, 57441, 57449, 57451, 57452, 57454,
    57455, 57457, 57459, 57462, 57464, 57465, 57466, 57467, 57468, 57469, 57470, 57471, 57489, 57490, 57491, 57492, 57494, 57495, 57496,
    57497, 57498, 57499, 57500, 57501, 57502, 57503, 57504, 57505, 57506, 57507, 57508, 57509, 57510, 57511, 57512, 57513, 57514, 57515,
    57516, 57517, 57518, 57519, 57520, 57521, 57522, 57523, 57529, 57530, 57538, 57546, 57589, 57597, 57598, 57599, 57601, 57602, 57607,
    57608, 57612, 57613, 57615, 57616, 57619, 57627, 57628, 57629, 57630, 57655, 57656, 57657, 57658, 57661, 57665, 57666, 57667, 57672,
    57673, 57676, 57677, 57678, 57679, 57680, 57681, 57682, 57683, 57684, 57685, 57686, 57687, 57690, 57695, 57696, 57697, 57698, 57699,
    57708, 57712, 57713, 57714, 57715, 57716, 57720, 57721, 57722, 57723, 57724, 57725, 57726, 57727, 57728, 57729, 57730, 57731, 57732,
    57733, 57734, 57735, 57744, 57745, 57749, 57762, 57768, 57769, 57770, 57771, 57772, 57777, 57788, 57791, 57794
  ]
}

export const icomoonIconsSearch = {
  'Web Applications': [
    'Box add',
    'Box remove',
    'Download',
    'Upload',
    'List',
    'List 2',
    'Numbered list',
    'Menu',
    'Menu 2',
    'Cloud download',
    'Cloud upload',
    'Download 2',
    'Upload 2',
    'Download 3',
    'Upload 3',
    'Globe',
    'Attachment',
    'Bookmark',
    'Embed',
    'Code'
  ],
  'Business Icons': [
    'Office',
    'Newspaper',
    'Book',
    'Books',
    'Library',
    'Profile',
    'Support',
    'Address book',
    'Cabinet',
    'Drawer',
    'Drawer 2',
    'Drawer 3',
    'Bubble',
    'Bubble 2',
    'User',
    'User 2',
    'User 3',
    'User 4',
    'Busy'
  ],
  eCommerce: ['Tag', 'Cart', 'Cart 2', 'Cart 3', 'Calculate'],
  'Currency Icons': [],
  'Form Control Icons': [
    'Copy',
    'Copy 2',
    'Copy 3',
    'Paste',
    'Paste 2',
    'Paste 3',
    'Settings',
    'Cancel circle',
    'Checkmark circle',
    'Spell check',
    'Enter',
    'Exit',
    'Radio checked',
    'Radio unchecked',
    'Console'
  ],
  'User Action & Text Editor': [
    'Undo',
    'Redo',
    'Flip',
    'Flip 2',
    'Undo 2',
    'Redo 2',
    'Zoomin',
    'Zoomout',
    'Expand',
    'Contract',
    'Expand 2',
    'Contract 2',
    'Link',
    'Scissors',
    'Bold',
    'Underline',
    'Italic',
    'Strikethrough',
    'Table',
    'Table 2',
    'Indent increase',
    'Indent decrease'
  ],
  'Charts and Codes': ['Pie'],
  Attentive: ['Eye blocked', 'Warning', 'Question', 'Info', 'Info 2', 'Blocked', 'Spam'],
  'Multimedia Icons': [
    'Image',
    'Image 2',
    'Play',
    'Film',
    'Forward',
    'Equalizer',
    'Brightness medium',
    'Brightness contrast',
    'Contrast',
    'Play 2',
    'Pause',
    'Forward 2',
    'Play 3',
    'Pause 2',
    'Forward 3',
    'Previous',
    'Next',
    'Volume high',
    'Volume medium',
    'Volume low',
    'Volume mute',
    'Volume mute 2',
    'Volume increase',
    'Volume decrease'
  ],
  'Location and Contact': [
    'Home',
    'Home 2',
    'Home 3',
    'Phone',
    'Phone hang up',
    'Envelope',
    'Location',
    'Location 2',
    'Map',
    'Map 2',
    'Flag'
  ],
  'Date and Time': ['History', 'Clock', 'Clock 2', 'Stopwatch', 'Calendar', 'Calendar 2'],
  Devices: ['Camera', 'Headphones', 'Camera 2', 'Keyboard', 'Screen', 'Tablet'],
  Tools: [
    'Pencil',
    'Pencil 2',
    'Pen',
    'Paint format',
    'Dice',
    'Key',
    'Key 2',
    'Lock',
    'Lock 2',
    'Unlocked',
    'Wrench',
    'Cog',
    'Cogs',
    'Cog 2',
    'Filter',
    'Filter 2'
  ],
  'Social and Networking': [
    'Share',
    'Googleplus',
    'Googleplus 2',
    'Googleplus 3',
    'Googleplus 4',
    'Google drive',
    'Facebook',
    'Facebook 2',
    'Facebook 3',
    'Twitter',
    'Twitter 2',
    'Twitter 3',
    'Vimeo',
    'Vimeo 2',
    'Vimeo 3',
    'Github',
    'Github 2',
    'Github 3',
    'Github 4',
    'Github 5',
    'Wordpress',
    'Wordpress 2',
    'Tumblr',
    'Tumblr 2',
    'Yahoo',
    'Soundcloud',
    'Soundcloud 2',
    'Reddit',
    'Linkedin',
    'Lastfm',
    'Lastfm 2',
    'Stumbleupon',
    'Stumbleupon 2',
    'Stackoverflow',
    'Pinterest',
    'Pinterest 2',
    'Yelp'
  ],
  Brands: [
    'Joomla',
    'Apple',
    'Finder',
    'Android',
    'Windows',
    'Windows 8',
    'Skype',
    'Paypal',
    'Paypal 2',
    'Paypal 3',
    'Chrome',
    'Firefox',
    'Opera',
    'Safari'
  ],
  'Files & Documents': [
    'File',
    'File 2',
    'File 3',
    'File 4',
    'Folder',
    'Folder open',
    'File pdf',
    'File openoffice',
    'File word',
    'File excel',
    'File zip',
    'File powerpoint',
    'File xml',
    'File css',
    'Html 5',
    'Html 52'
  ],
  'Like & Dislike Icons': ['Eye', 'Eye 2', 'Star', 'Star 2', 'Star 3', 'Heart', 'Heart 2', 'Heart broken', 'Thumbs up', 'Thumbs up 2'],
  Emoticons: [
    'Happy',
    'Happy 2',
    'Smiley',
    'Smiley 2',
    'Tongue',
    'Tongue 2',
    'Sad',
    'Sad 2',
    'Wink',
    'Wink 2',
    'Grin',
    'Grin 2',
    'Cool',
    'Cool 2',
    'Angry',
    'Angry 2',
    'Evil',
    'Evil 2',
    'Shocked',
    'Shocked 2',
    'Confused',
    'Confused 2',
    'Neutral',
    'Neutral 2',
    'Wondering',
    'Wondering 2'
  ],
  'Directional Icons': [
    'Point up',
    'Point right',
    'Point down',
    'Point left',
    'Arrow up left',
    'Arrow up',
    'Arrow up right',
    'Arrow right',
    'Arrow down right',
    'Arrow down',
    'Arrow down left',
    'Arrow left',
    'Arrow up left 2',
    'Arrow up 2',
    'Arrow up right 2',
    'Arrow right 2',
    'Arrow down right 2',
    'Arrow down 2',
    'Arrow down left 2',
    'Arrow left 2',
    'Arrow up left 3',
    'Arrow up 3',
    'Arrow up right 3',
    'Arrow right 3',
    'Arrow down right 3',
    'Arrow down 3',
    'Arrow down left 3',
    'Arrow left 3'
  ],
  'Other Icons': [
    'Quill',
    'Blog',
    'Droplet',
    'Images',
    'Music',
    'Pacman',
    'Spades',
    'Clubs',
    'Diamonds',
    'Pawn',
    'Bullhorn',
    'Connection',
    'Podcast',
    'Feed',
    'Stack',
    'Tags',
    'Barcode',
    'Qrcode',
    'Ticket',
    'Coin',
    'Credit',
    'Notebook',
    'Pushpin',
    'Compass',
    'Alarm',
    'Alarm 2',
    'Bell',
    'Print',
    'Laptop',
    'Mobile',
    'Mobile 2',
    'Tv',
    'Disk',
    'Storage',
    'Reply',
    'Bubbles',
    'Bubbles 2',
    'Bubbles 3',
    'Bubbles 4',
    'Users',
    'Users 2',
    'Quotes left',
    'Spinner',
    'Spinner 2',
    'Spinner 3',
    'Spinner 4',
    'Spinner 5',
    'Spinner 6',
    'Binoculars',
    'Search',
    'Hammer',
    'Wand',
    'Aid',
    'Bug',
    'Stats',
    'Bars',
    'Bars 2',
    'Gift',
    'Trophy',
    'Glass',
    'Mug',
    'Food',
    'Leaf',
    'Rocket',
    'Meter',
    'Meter 2',
    'Dashboard',
    'Hammer 2',
    'Fire',
    'Lab',
    'Magnet',
    'Remove',
    'Remove 2',
    'Briefcase',
    'Airplane',
    'Truck',
    'Road',
    'Accessibility',
    'Target',
    'Shield',
    'Lightning',
    'Switch',
    'Powercord',
    'Signup',
    'Tree',
    'Cloud',
    'Earth',
    'Bookmarks',
    'Notification',
    'Close',
    'Checkmark',
    'Checkmark 2',
    'Minus',
    'Plus',
    'Stop',
    'Backward',
    'Stop 2',
    'Backward 2',
    'First',
    'Last',
    'Eject',
    'Loop',
    'Loop 2',
    'Loop 3',
    'Shuffle',
    'Tab',
    'Checkbox checked',
    'Checkbox unchecked',
    'Checkbox partial',
    'Crop',
    'Font',
    'Text height',
    'Text width',
    'Omega',
    'Sigma',
    'Insert template',
    'Pilcrow',
    'Lefttoright',
    'Righttoleft',
    'Paragraph left',
    'Paragraph center',
    'Paragraph right',
    'Paragraph justify',
    'Paragraph left 2',
    'Paragraph center 2',
    'Paragraph right 2',
    'Paragraph justify 2',
    'Newtab',
    'Mail',
    'Mail 2',
    'Mail 3',
    'Mail 4',
    'Google',
    'Instagram',
    'Feed 2',
    'Feed 3',
    'Feed 4',
    'Youtube',
    'Youtube 2',
    'Lanyrd',
    'Flickr',
    'Flickr 2',
    'Flickr 3',
    'Flickr 4',
    'Picassa',
    'Picassa 2',
    'Dribbble',
    'Dribbble 2',
    'Dribbble 3',
    'Forrst',
    'Forrst 2',
    'Deviantart',
    'Deviantart 2',
    'Steam',
    'Steam 2',
    'Blogger',
    'Blogger 2',
    'Tux',
    'Delicious',
    'Xing',
    'Xing 2',
    'Flattr',
    'Foursquare',
    'Foursquare 2',
    'Libreoffice',
    'Css 3',
    'IE',
    'IcoMoon'
  ]
}

export const fontelloIcons = {
  'Web Application Icons': [
    'icon-mail',
    'icon-mail-alt',
    'icon-th-large',
    'icon-th',
    'icon-th-list',
    'icon-help-circled',
    'icon-info-circled',
    'icon-info',
    'icon-home',
    'icon-link',
    'icon-unlink',
    'icon-link-ext',
    'icon-link-ext-alt',
    'icon-attach',
    'icon-tag',
    'icon-tags',
    'icon-bookmark',
    'icon-bookmark-empty',
    'icon-download',
    'icon-upload',
    'icon-download-cloud',
    'icon-upload-cloud',
    'icon-reply',
    'icon-reply-all'
  ],
  'Form Control Icons': [
    'icon-search',
    'icon-ok',
    'icon-ok-circled',
    'icon-ok-circled2',
    'icon-ok-squared',
    'icon-cancel',
    'icon-cancel-circled',
    'icon-cancel-circled2',
    'icon-plus',
    'icon-plus-circled',
    'icon-plus-squared',
    'icon-plus-squared-small',
    'icon-minus',
    'icon-minus-circled',
    'icon-minus-squared',
    'icon-minus-squared-alt',
    'icon-minus-squared-small',
    'icon-quote-right',
    'icon-code',
    'icon-comment-empty',
    'icon-chat-empty'
  ],
  'Media Icons': [
    'icon-video',
    'icon-videocam',
    'icon-picture',
    'icon-camera',
    'icon-camera-alt',
    'icon-export',
    'icon-export-alt',
    'icon-pencil',
    'icon-pencil-squared',
    'icon-edit',
    'icon-print'
  ],
  'Popular Icons': [
    'icon-heart',
    'icon-heart-empty',
    'icon-star',
    'icon-star-empty',
    'icon-star-half',
    'icon-star-half-alt',
    'icon-user',
    'icon-users',
    'icon-male',
    'icon-female',
    'icon-forward',
    'icon-quote-left',
    'icon-retweet',
    'icon-keyboard',
    'icon-gamepad',
    'icon-comment',
    'icon-chat'
  ],
  Others: [
    'icon-music',
    'icon-help',
    'icon-lock',
    'icon-lock-open',
    'icon-lock-open-alt',
    'icon-pin',
    'icon-eye',
    'icon-eye-off',
    'icon-flag',
    'icon-flag-empty',
    'icon-flag-checkered',
    'icon-thumbs-up',
    'icon-thumbs-down',
    'icon-thumbs-up-alt',
    'icon-thumbs-down-alt',
    'icon-bell',
    'icon-bell-alt'
  ]
}

export const superHeroIcons = [
  'superhero-batman',
  'superhero-robin',
  'superhero-spiderman',
  'superhero-green-lantern',
  'superhero-hawkeye',
  'superhero-captain-america',
  'superhero-thor',
  'superhero-loki',
  'superhero-green-arrow',
  'superhero-hulk',
  'superhero-superman',
  'superhero-daredevil',
  'superhero-wolverine',
  'superhero-wonder-woman',
  'superhero-catwoman',
  'superhero-flash',
  'superhero-punisher',
  'superhero-ironman',
  'superhero-bpdr',
  'superhero-joker',
  'superhero-two-face-coin',
  'superhero-two-face',
  'superhero-silver-surfer',
  'superhero-magneto',
  'superhero-xavier',
  'superhero-thing',
  'superhero-human-torch',
  'superhero-mr-fantastic',
  'superhero-infinity-gauntlet',
  'superhero-dr-manhattan',
  'superhero-rorschach',
  'superhero-venom',
  'superhero-the-riddler',
  'superhero-aquaman',
  'superhero-spawn',
  'superhero-ghost-rider',
  'superhero-rocketeer',
  'superhero-the-atom',
  'superhero-nick-fury',
  'superhero-cyclops',
  'superhero-judge-dredd',
  'superhero-deadpool',
  'superhero-dr-spectrum',
  'superhero-solar',
  'superhero-black-widow',
  'superhero-iron-fist',
  'superhero-poison-ivy',
  'superhero-dr-doom',
  'superhero-tmnt',
  'superhero-the-spirit',
  'superhero-dr-octopus',
  'superhero-red-skull',
  'superhero-gambit',
  'superhero-scarecrow',
  'superhero-deathstroke',
  'superhero-brainiac',
  'superhero-moon-knight',
  'superhero-karnak',
  'superhero-black-adam',
  'superhero-penguin',
  'superhero-shredder',
  'superhero-hawkeye2',
  'superhero-superman-hair',
  'superhero-bane',
  'superhero-adamantium-shield',
  'superhero-daredevil-glasses',
  'superhero-mutagen',
  'superhero-hellboy',
  'superhero-penguins-umbrella',
  'superhero-starlords-element-gun',
  'superhero-hemans-power-sword',
  'superhero-lukes-1st-lightsaber',
  'superhero-lukes-2nd-lightsaber',
  'superhero-daredevils-billy-cub',
  'superhero-green-goblins-pumpkin',
  'superhero-kryptonita',
  'superhero-elektras-sais',
  'superhero-bow-and-arrow',
  'superhero-jokers-gun',
  'superhero-jokers-buzzer',
  'superhero-leonardos-katana',
  'superhero-michelangelos-nunchakus',
  'superhero-donatellos-bo-staff',
  'superhero-raphaels-sais',
  'superhero-freeze-ray-gun',
  'superhero-new-freeze-ray-gun',
  'superhero-adamantium-claws',
  'superhero-mjolnir',
  'superhero-ghost-riders-hellfire-chain',
  'superhero-dr-stranges-eye-of-agamotto',
  'superhero-gl-power-ring',
  'superhero-the-phantoms-ring',
  'superhero-batarang',
  'superhero-batmans-utility-belt',
  'superhero-dr-fate',
  'superhero-x-men-logo',
  'superhero-fantastic-4',
  'superhero-avengers',
  'superhero-spiderman-spider',
  'superhero-spiderman-web'
]

export const superHeroSearch = superHeroIcons.map((val) => val.replace('superhero-', ''))

export const svgs = [
  '004-green_lantern',
  '003-spiderman',
  '002-robin',
  '005-hawkeye',
  '001-batman',
  '007-thor',
  '006-captain_america',
  '011-superman',
  '009-green_arrow',
  '008-loki',
  '010-hulk',
  '012-daredevil',
  '015-catwoman',
  '013-wolverine',
  '014-wonder_woman',
  '017-punisher',
  '016-flash',
  '018-ironman',
  '019-bpdr',
  '020-joker',
  '021-two-face_coin',
  '022-two-face',
  '025-xavier',
  '023-silver_surfer',
  '024-magneto',
  '026-thing',
  '029-infinity_gauntlet',
  '027-human_torch',
  '028-mr_fantastic',
  '030-dr_manhattan',
  '031-rorschach',
  '033-the_riddler',
  '032-venom',
  '035-spawn',
  '034-aquaman',
  '036-ghost_rider',
  '037-rocketeer',
  '041-judge_dredd',
  '038-the_atom',
  '039-nick_fury',
  '040-cyclops',
  '042-deadpool',
  '044-solar',
  '043-dr_spectrum',
  '045-black_widow',
  '046-iron_fist',
  '049-tmnt',
  '047-poison_ivy',
  '050-the_spirit',
  '048-dr_doom',
  '051-dr_octopus',
  '055-deathstroke',
  '053-gambit',
  '052-red_skull',
  '054-scarecrow',
  '057-moon_knight',
  '056-brainiac',
  '059-black_adam',
  '058-karnak',
  '060-penguin',
  '061-shredder',
  '063-superman-hair',
  '062-hawkeye',
  '064-bane',
  '065-adamantium_shield',
  '066-daredevil_glasses',
  '067-mutagen',
  '069-penguins_umbrella',
  '070-starlords_element_gun',
  '071-hemans_power_sword',
  '068-hellboys_good_samaritan',
  '072-lukes_1st_lightsaber',
  '073-lukes_2nd_lightsaber',
  '074-daredevils_billy_cub',
  '075-green_goblins_pumpkin',
  '076-kryptonita',
  '077-elektras_sais',
  '078-bow_and_arrow',
  '079-jokers_gun',
  '080-jokers_buzzer',
  '081-leonardos_katana',
  '082-michelangelos_nunchakus',
  '083-donatellos_bo_staff',
  '084-raphaels_sais',
  '085-freeze_ray_gun',
  '088-mjolnir',
  '087-adamantium_claws',
  '086-new_freeze_ray_gun',
  '089-ghost_riders_hellfire_chain',
  '090-dr_stranges_eye_of_agamotto',
  '091-gl_power_ring',
  '093-batarang',
  '092-the_phantoms_ring',
  '094-batmans_utility_belt',
  '095-dr_fate',
  '098-avengers',
  '096-x-men_logo',
  '097-fantastic_4',
  '099-spiderman-spider',
  '100-spiderman-web'
]

export const fontAwesome = {
  Accessibility: [
    'fab fa-accessible-icon',
    'fas fa-american-sign-language-interpreting',
    'fas fa-assistive-listening-systems',
    'fas fa-audio-description',
    'fas fa-blind',
    'fas fa-braille',
    'fas fa-closed-captioning',
    'far fa-closed-captioning',
    'fas fa-deaf',
    'fas fa-low-vision',
    'fas fa-phone-volume',
    'fas fa-question-circle',
    'far fa-question-circle',
    'fas fa-sign-language',
    'fas fa-tty',
    'fas fa-universal-access',
    'fas fa-wheelchair'
  ],
  Arrows: [
    'fas fa-angle-double-down',
    'fas fa-angle-double-left',
    'fas fa-angle-double-right',
    'fas fa-angle-double-up',
    'fas fa-angle-down',
    'fas fa-angle-left',
    'fas fa-angle-right',
    'fas fa-angle-up',
    'fas fa-arrow-alt-circle-down',
    'far fa-arrow-alt-circle-down',
    'fas fa-arrow-alt-circle-left',
    'far fa-arrow-alt-circle-left',
    'fas fa-arrow-alt-circle-right',
    'far fa-arrow-alt-circle-right',
    'fas fa-arrow-alt-circle-up',
    'far fa-arrow-alt-circle-up',
    'fas fa-arrow-circle-down',
    'fas fa-arrow-circle-left',
    'fas fa-arrow-circle-right',
    'fas fa-arrow-circle-up',
    'fas fa-arrow-down',
    'fas fa-arrow-left',
    'fas fa-arrow-right',
    'fas fa-arrow-up',
    'fas fa-arrows-alt',
    'fas fa-arrows-alt-h',
    'fas fa-arrows-alt-v',
    'fas fa-caret-down',
    'fas fa-caret-left',
    'fas fa-caret-right',
    'fas fa-caret-square-down',
    'far fa-caret-square-down',
    'fas fa-caret-square-left',
    'far fa-caret-square-left',
    'fas fa-caret-square-right',
    'far fa-caret-square-right',
    'fas fa-caret-square-up',
    'far fa-caret-square-up',
    'fas fa-caret-up',
    'fas fa-cart-arrow-down',
    'fas fa-chart-line',
    'fas fa-chevron-circle-down',
    'fas fa-chevron-circle-left',
    'fas fa-chevron-circle-right',
    'fas fa-chevron-circle-up',
    'fas fa-chevron-down',
    'fas fa-chevron-left',
    'fas fa-chevron-right',
    'fas fa-chevron-up',
    'fas fa-cloud-download-alt',
    'fas fa-cloud-upload-alt',
    'fas fa-download',
    'fas fa-exchange-alt',
    'fas fa-expand-arrows-alt',
    'fas fa-external-link-alt',
    'fas fa-external-link-square-alt',
    'fas fa-hand-point-down',
    'far fa-hand-point-down',
    'fas fa-hand-point-left',
    'far fa-hand-point-left',
    'fas fa-hand-point-right',
    'far fa-hand-point-right',
    'fas fa-hand-point-up',
    'far fa-hand-point-up',
    'fas fa-hand-pointer',
    'far fa-hand-pointer',
    'fas fa-history',
    'fas fa-level-down-alt',
    'fas fa-level-up-alt',
    'fas fa-location-arrow',
    'fas fa-long-arrow-alt-down',
    'fas fa-long-arrow-alt-left',
    'fas fa-long-arrow-alt-right',
    'fas fa-long-arrow-alt-up',
    'fas fa-mouse-pointer',
    'fas fa-play',
    'fas fa-random',
    'fas fa-recycle',
    'fas fa-redo',
    'fas fa-redo-alt',
    'fas fa-reply',
    'fas fa-reply-all',
    'fas fa-retweet',
    'fas fa-share',
    'fas fa-share-square',
    'far fa-share-square',
    'fas fa-sign-in-alt',
    'fas fa-sign-out-alt',
    'fas fa-sort',
    'fas fa-sort-alpha-down',
    'fas fa-sort-alpha-up',
    'fas fa-sort-amount-down',
    'fas fa-sort-amount-up',
    'fas fa-sort-down',
    'fas fa-sort-numeric-down',
    'fas fa-sort-numeric-up',
    'fas fa-sort-up',
    'fas fa-sync',
    'fas fa-sync-alt',
    'fas fa-text-height',
    'fas fa-text-width',
    'fas fa-undo',
    'fas fa-undo-alt',
    'fas fa-upload'
  ],
  'Audio & Video': [
    'fas fa-audio-description',
    'fas fa-backward',
    'fas fa-circle',
    'far fa-circle',
    'fas fa-closed-captioning',
    'far fa-closed-captioning',
    'fas fa-compress',
    'fas fa-eject',
    'fas fa-expand',
    'fas fa-expand-arrows-alt',
    'fas fa-fast-backward',
    'fas fa-fast-forward',
    'fas fa-file-audio',
    'far fa-file-audio',
    'fas fa-file-video',
    'far fa-file-video',
    'fas fa-film',
    'fas fa-forward',
    'fas fa-headphones',
    'fas fa-microphone',
    'fas fa-microphone-slash',
    'fas fa-music',
    'fas fa-pause',
    'fas fa-pause-circle',
    'far fa-pause-circle',
    'fas fa-phone-volume',
    'fas fa-play',
    'fas fa-play-circle',
    'far fa-play-circle',
    'fas fa-podcast',
    'fas fa-random',
    'fas fa-redo',
    'fas fa-redo-alt',
    'fas fa-rss',
    'fas fa-rss-square',
    'fas fa-step-backward',
    'fas fa-step-forward',
    'fas fa-stop',
    'fas fa-stop-circle',
    'far fa-stop-circle',
    'fas fa-sync',
    'fas fa-sync-alt',
    'fas fa-undo',
    'fas fa-undo-alt',
    'fas fa-video',
    'fas fa-volume-down',
    'fas fa-volume-off',
    'fas fa-volume-up',
    'fab fa-youtube'
  ],
  Business: [
    'fas fa-address-book',
    'far fa-address-book',
    'fas fa-address-card',
    'far fa-address-card',
    'fas fa-archive',
    'fas fa-balance-scale',
    'fas fa-birthday-cake',
    'fas fa-book',
    'fas fa-briefcase',
    'fas fa-building',
    'far fa-building',
    'fas fa-bullhorn',
    'fas fa-bullseye',
    'fas fa-calculator',
    'fas fa-calendar',
    'far fa-calendar',
    'fas fa-calendar-alt',
    'far fa-calendar-alt',
    'fas fa-certificate',
    'fas fa-chart-area',
    'fas fa-chart-bar',
    'far fa-chart-bar',
    'fas fa-chart-line',
    'fas fa-chart-pie',
    'fas fa-clipboard',
    'far fa-clipboard',
    'fas fa-coffee',
    'fas fa-columns',
    'fas fa-compass',
    'far fa-compass',
    'fas fa-copy',
    'far fa-copy',
    'fas fa-copyright',
    'far fa-copyright',
    'fas fa-cut',
    'fas fa-edit',
    'far fa-edit',
    'fas fa-envelope',
    'far fa-envelope',
    'fas fa-envelope-open',
    'far fa-envelope-open',
    'fas fa-envelope-square',
    'fas fa-eraser',
    'fas fa-fax',
    'fas fa-file',
    'far fa-file',
    'fas fa-file-alt',
    'far fa-file-alt',
    'fas fa-folder',
    'far fa-folder',
    'fas fa-folder-open',
    'far fa-folder-open',
    'fas fa-globe',
    'fas fa-industry',
    'fas fa-paperclip',
    'fas fa-paste',
    'fas fa-pen-square',
    'fas fa-pencil-alt',
    'fas fa-percent',
    'fas fa-phone',
    'fas fa-phone-square',
    'fas fa-phone-volume',
    'fas fa-registered',
    'far fa-registered',
    'fas fa-save',
    'far fa-save',
    'fas fa-sitemap',
    'fas fa-sticky-note',
    'far fa-sticky-note',
    'fas fa-suitcase',
    'fas fa-table',
    'fas fa-tag',
    'fas fa-tags',
    'fas fa-tasks',
    'fas fa-thumbtack',
    'fas fa-trademark'
  ],
  Chess: [
    'fas fa-chess',
    'fas fa-chess-bishop',
    'fas fa-chess-board',
    'fas fa-chess-king',
    'fas fa-chess-knight',
    'fas fa-chess-pawn',
    'fas fa-chess-queen',
    'fas fa-chess-rook',
    'fas fa-square-full'
  ],
  Code: [
    'fas fa-archive',
    'fas fa-barcode',
    'fas fa-bath',
    'fas fa-bug',
    'fas fa-code',
    'fas fa-code-branch',
    'fas fa-coffee',
    'fas fa-file',
    'far fa-file',
    'fas fa-file-alt',
    'far fa-file-alt',
    'fas fa-file-code',
    'far fa-file-code',
    'fas fa-filter',
    'fas fa-fire-extinguisher',
    'fas fa-folder',
    'far fa-folder',
    'fas fa-folder-open',
    'far fa-folder-open',
    'fas fa-keyboard',
    'far fa-keyboard',
    'fas fa-microchip',
    'fas fa-qrcode',
    'fas fa-shield-alt',
    'fas fa-sitemap',
    'fas fa-terminal',
    'fas fa-user-secret',
    'fas fa-window-close',
    'far fa-window-close',
    'fas fa-window-maximize',
    'far fa-window-maximize',
    'fas fa-window-minimize',
    'far fa-window-minimize',
    'fas fa-window-restore',
    'far fa-window-restore'
  ],
  Communication: [
    'fas fa-address-book',
    'far fa-address-book',
    'fas fa-address-card',
    'far fa-address-card',
    'fas fa-american-sign-language-interpreting',
    'fas fa-assistive-listening-systems',
    'fas fa-at',
    'fas fa-bell',
    'far fa-bell',
    'fas fa-bell-slash',
    'far fa-bell-slash',
    'fab fa-bluetooth',
    'fab fa-bluetooth-b',
    'fas fa-bullhorn',
    'fas fa-comment',
    'far fa-comment',
    'fas fa-comment-alt',
    'far fa-comment-alt',
    'fas fa-comments',
    'far fa-comments',
    'fas fa-envelope',
    'far fa-envelope',
    'fas fa-envelope-open',
    'far fa-envelope-open',
    'fas fa-envelope-square',
    'fas fa-fax',
    'fas fa-inbox',
    'fas fa-language',
    'fas fa-microphone',
    'fas fa-microphone-slash',
    'fas fa-mobile',
    'fas fa-mobile-alt',
    'fas fa-paper-plane',
    'far fa-paper-plane',
    'fas fa-phone',
    'fas fa-phone-square',
    'fas fa-phone-volume',
    'fas fa-rss',
    'fas fa-rss-square',
    'fas fa-tty',
    'fas fa-wifi'
  ],
  Computers: [
    'fas fa-desktop',
    'fas fa-download',
    'fas fa-hdd',
    'far fa-hdd',
    'fas fa-headphones',
    'fas fa-keyboard',
    'far fa-keyboard',
    'fas fa-laptop',
    'fas fa-microchip',
    'fas fa-mobile',
    'fas fa-mobile-alt',
    'fas fa-plug',
    'fas fa-power-off',
    'fas fa-print',
    'fas fa-save',
    'far fa-save',
    'fas fa-server',
    'fas fa-tablet',
    'fas fa-tablet-alt',
    'fas fa-tv',
    'fas fa-upload'
  ],
  Currency: [
    'fab fa-bitcoin',
    'fab fa-btc',
    'fas fa-dollar-sign',
    'fas fa-euro-sign',
    'fab fa-gg',
    'fab fa-gg-circle',
    'fas fa-lira-sign',
    'fas fa-money-bill-alt',
    'far fa-money-bill-alt',
    'fas fa-pound-sign',
    'fas fa-ruble-sign',
    'fas fa-rupee-sign',
    'fas fa-shekel-sign',
    'fas fa-won-sign',
    'fas fa-yen-sign'
  ],
  'Date & Time': [
    'fas fa-bell',
    'far fa-bell',
    'fas fa-bell-slash',
    'far fa-bell-slash',
    'fas fa-calendar',
    'far fa-calendar',
    'fas fa-calendar-alt',
    'far fa-calendar-alt',
    'fas fa-calendar-check',
    'far fa-calendar-check',
    'fas fa-calendar-minus',
    'far fa-calendar-minus',
    'fas fa-calendar-plus',
    'far fa-calendar-plus',
    'fas fa-calendar-times',
    'far fa-calendar-times',
    'fas fa-clock',
    'far fa-clock',
    'fas fa-hourglass',
    'far fa-hourglass',
    'fas fa-hourglass-end',
    'fas fa-hourglass-half',
    'fas fa-hourglass-start',
    'fas fa-stopwatch'
  ],
  Design: [
    'fas fa-adjust',
    'fas fa-clone',
    'far fa-clone',
    'fas fa-copy',
    'far fa-copy',
    'fas fa-crop',
    'fas fa-crosshairs',
    'fas fa-cut',
    'fas fa-edit',
    'far fa-edit',
    'fas fa-eraser',
    'fas fa-eye',
    'fas fa-eye-dropper',
    'fas fa-eye-slash',
    'far fa-eye-slash',
    'fas fa-object-group',
    'far fa-object-group',
    'fas fa-object-ungroup',
    'far fa-object-ungroup',
    'fas fa-paint-brush',
    'fas fa-paste',
    'fas fa-pencil-alt',
    'fas fa-save',
    'far fa-save',
    'fas fa-tint'
  ],
  Editors: [
    'fas fa-align-center',
    'fas fa-align-justify',
    'fas fa-align-left',
    'fas fa-align-right',
    'fas fa-bold',
    'fas fa-clipboard',
    'far fa-clipboard',
    'fas fa-clone',
    'far fa-clone',
    'fas fa-columns',
    'fas fa-copy',
    'far fa-copy',
    'fas fa-cut',
    'fas fa-edit',
    'far fa-edit',
    'fas fa-eraser',
    'fas fa-file',
    'far fa-file',
    'fas fa-file-alt',
    'far fa-file-alt',
    'fas fa-font',
    'fas fa-heading',
    'fas fa-i-cursor',
    'fas fa-indent',
    'fas fa-italic',
    'fas fa-link',
    'fas fa-list',
    'fas fa-list-alt',
    'far fa-list-alt',
    'fas fa-list-ol',
    'fas fa-list-ul',
    'fas fa-outdent',
    'fas fa-paper-plane',
    'far fa-paper-plane',
    'fas fa-paperclip',
    'fas fa-paragraph',
    'fas fa-paste',
    'fas fa-pencil-alt',
    'fas fa-print',
    'fas fa-quote-left',
    'fas fa-quote-right',
    'fas fa-redo',
    'fas fa-redo-alt',
    'fas fa-reply',
    'fas fa-reply-all',
    'fas fa-share',
    'fas fa-strikethrough',
    'fas fa-subscript',
    'fas fa-superscript',
    'fas fa-sync',
    'fas fa-sync-alt',
    'fas fa-table',
    'fas fa-tasks',
    'fas fa-text-height',
    'fas fa-text-width',
    'fas fa-th',
    'fas fa-th-large',
    'fas fa-th-list',
    'fas fa-trash',
    'fas fa-trash-alt',
    'far fa-trash-alt',
    'fas fa-underline',
    'fas fa-undo',
    'fas fa-undo-alt',
    'fas fa-unlink'
  ],
  Files: [
    'fas fa-archive',
    'fas fa-clone',
    'far fa-clone',
    'fas fa-copy',
    'far fa-copy',
    'fas fa-cut',
    'fas fa-file',
    'far fa-file',
    'fas fa-file-alt',
    'far fa-file-alt',
    'fas fa-file-archive',
    'far fa-file-archive',
    'fas fa-file-audio',
    'far fa-file-audio',
    'fas fa-file-code',
    'far fa-file-code',
    'fas fa-file-excel',
    'far fa-file-excel',
    'fas fa-file-image',
    'far fa-file-image',
    'fas fa-file-pdf',
    'far fa-file-pdf',
    'fas fa-file-powerpoint',
    'far fa-file-powerpoint',
    'fas fa-file-video',
    'far fa-file-video',
    'fas fa-file-word',
    'far fa-file-word',
    'fas fa-folder',
    'far fa-folder',
    'fas fa-folder-open',
    'far fa-folder-open',
    'fas fa-paste',
    'fas fa-save',
    'far fa-save',
    'fas fa-sticky-note',
    'far fa-sticky-note'
  ],
  Genders: [
    'fas fa-genderless',
    'fas fa-mars',
    'fas fa-mars-double',
    'fas fa-mars-stroke',
    'fas fa-mars-stroke-h',
    'fas fa-mars-stroke-v',
    'fas fa-mercury',
    'fas fa-neuter',
    'fas fa-transgender',
    'fas fa-transgender-alt',
    'fas fa-venus',
    'fas fa-venus-double',
    'fas fa-venus-mars'
  ],
  Hands: [
    'fas fa-hand-lizard',
    'far fa-hand-lizard',
    'fas fa-hand-paper',
    'far fa-hand-paper',
    'fas fa-hand-peace',
    'far fa-hand-peace',
    'fas fa-hand-point-down',
    'far fa-hand-point-down',
    'fas fa-hand-point-left',
    'far fa-hand-point-left',
    'fas fa-hand-point-right',
    'far fa-hand-point-right',
    'fas fa-hand-point-up',
    'far fa-hand-point-up',
    'fas fa-hand-pointer',
    'far fa-hand-pointer',
    'fas fa-hand-rock',
    'far fa-hand-rock',
    'fas fa-hand-scissors',
    'far fa-hand-scissors',
    'fas fa-hand-spock',
    'far fa-hand-spock',
    'fas fa-handshake',
    'far fa-handshake',
    'fas fa-thumbs-down',
    'far fa-thumbs-down',
    'fas fa-thumbs-up',
    'far fa-thumbs-up'
  ],
  Health: [
    'fab fa-accessible-icon',
    'fas fa-ambulance',
    'fas fa-h-square',
    'fas fa-heart',
    'far fa-heart',
    'fas fa-heartbeat',
    'fas fa-hospital',
    'far fa-hospital',
    'fas fa-medkit',
    'fas fa-plus-square',
    'far fa-plus-square',
    'fas fa-stethoscope',
    'fas fa-user-md',
    'fas fa-wheelchair'
  ],
  Images: [
    'fas fa-adjust',
    'fas fa-bolt',
    'fas fa-camera',
    'fas fa-camera-retro',
    'fas fa-clone',
    'far fa-clone',
    'fas fa-compress',
    'fas fa-expand',
    'fas fa-eye',
    'fas fa-eye-dropper',
    'fas fa-eye-slash',
    'far fa-eye-slash',
    'fas fa-file-image',
    'far fa-file-image',
    'fas fa-film',
    'fas fa-id-badge',
    'far fa-id-badge',
    'fas fa-id-card',
    'far fa-id-card',
    'fas fa-image',
    'far fa-image',
    'fas fa-images',
    'far fa-images',
    'fas fa-sliders-h',
    'fas fa-tint'
  ],
  Interfaces: [
    'fas fa-ban',
    'fas fa-barcode',
    'fas fa-bars',
    'fas fa-beer',
    'fas fa-bell',
    'far fa-bell',
    'fas fa-bell-slash',
    'far fa-bell-slash',
    'fas fa-bug',
    'fas fa-bullhorn',
    'fas fa-bullseye',
    'fas fa-calculator',
    'fas fa-calendar',
    'far fa-calendar',
    'fas fa-calendar-alt',
    'far fa-calendar-alt',
    'fas fa-calendar-check',
    'far fa-calendar-check',
    'fas fa-calendar-minus',
    'far fa-calendar-minus',
    'fas fa-calendar-plus',
    'far fa-calendar-plus',
    'fas fa-calendar-times',
    'far fa-calendar-times',
    'fas fa-certificate',
    'fas fa-check',
    'fas fa-check-circle',
    'far fa-check-circle',
    'fas fa-check-square',
    'far fa-check-square',
    'fas fa-circle',
    'far fa-circle',
    'fas fa-clipboard',
    'far fa-clipboard',
    'fas fa-clone',
    'far fa-clone',
    'fas fa-cloud',
    'fas fa-cloud-download-alt',
    'fas fa-cloud-upload-alt',
    'fas fa-coffee',
    'fas fa-cog',
    'fas fa-cogs',
    'fas fa-copy',
    'far fa-copy',
    'fas fa-cut',
    'fas fa-database',
    'fas fa-dot-circle',
    'far fa-dot-circle',
    'fas fa-download',
    'fas fa-edit',
    'far fa-edit',
    'fas fa-ellipsis-h',
    'fas fa-ellipsis-v',
    'fas fa-envelope',
    'far fa-envelope',
    'fas fa-envelope-open',
    'far fa-envelope-open',
    'fas fa-eraser',
    'fas fa-exclamation',
    'fas fa-exclamation-circle',
    'fas fa-exclamation-triangle',
    'fas fa-external-link-alt',
    'fas fa-external-link-square-alt',
    'fas fa-eye',
    'fas fa-eye-slash',
    'far fa-eye-slash',
    'fas fa-file',
    'far fa-file',
    'fas fa-file-alt',
    'far fa-file-alt',
    'fas fa-filter',
    'fas fa-flag',
    'far fa-flag',
    'fas fa-flag-checkered',
    'fas fa-folder',
    'far fa-folder',
    'fas fa-folder-open',
    'far fa-folder-open',
    'fas fa-frown',
    'far fa-frown',
    'fas fa-hashtag',
    'fas fa-heart',
    'far fa-heart',
    'fas fa-history',
    'fas fa-home',
    'fas fa-i-cursor',
    'fas fa-info',
    'fas fa-info-circle',
    'fas fa-language',
    'fas fa-magic',
    'fas fa-meh',
    'far fa-meh',
    'fas fa-microphone',
    'fas fa-microphone-slash',
    'fas fa-minus',
    'fas fa-minus-circle',
    'fas fa-minus-square',
    'far fa-minus-square',
    'fas fa-paste',
    'fas fa-pencil-alt',
    'fas fa-plus',
    'fas fa-plus-circle',
    'fas fa-plus-square',
    'far fa-plus-square',
    'fas fa-qrcode',
    'fas fa-question',
    'fas fa-question-circle',
    'far fa-question-circle'
  ],
  Maps: [
    'fas fa-ambulance',
    'fas fa-anchor',
    'fas fa-balance-scale',
    'fas fa-bath',
    'fas fa-bed',
    'fas fa-beer',
    'fas fa-bell',
    'far fa-bell',
    'fas fa-bell-slash',
    'far fa-bell-slash',
    'fas fa-bicycle',
    'fas fa-binoculars',
    'fas fa-birthday-cake',
    'fas fa-blind',
    'fas fa-bomb',
    'fas fa-book',
    'fas fa-bookmark',
    'far fa-bookmark',
    'fas fa-briefcase',
    'fas fa-building',
    'far fa-building',
    'fas fa-car',
    'fas fa-coffee',
    'fas fa-crosshairs',
    'fas fa-dollar-sign',
    'fas fa-eye',
    'fas fa-eye-slash',
    'far fa-eye-slash',
    'fas fa-fighter-jet',
    'fas fa-fire',
    'fas fa-fire-extinguisher',
    'fas fa-flag',
    'far fa-flag',
    'fas fa-flag-checkered',
    'fas fa-flask',
    'fas fa-gamepad',
    'fas fa-gavel',
    'fas fa-gift',
    'fas fa-glass-martini',
    'fas fa-globe',
    'fas fa-graduation-cap',
    'fas fa-h-square',
    'fas fa-heart',
    'far fa-heart',
    'fas fa-heartbeat',
    'fas fa-home',
    'fas fa-hospital',
    'far fa-hospital',
    'fas fa-image',
    'far fa-image',
    'fas fa-images',
    'far fa-images',
    'fas fa-industry',
    'fas fa-info',
    'fas fa-info-circle',
    'fas fa-key',
    'fas fa-leaf',
    'fas fa-lemon',
    'far fa-lemon',
    'fas fa-life-ring',
    'far fa-life-ring',
    'fas fa-lightbulb',
    'far fa-lightbulb',
    'fas fa-location-arrow',
    'fas fa-low-vision',
    'fas fa-magnet',
    'fas fa-male',
    'fas fa-map',
    'far fa-map',
    'fas fa-map-marker',
    'fas fa-map-marker-alt',
    'fas fa-map-pin',
    'fas fa-map-signs',
    'fas fa-medkit',
    'fas fa-money-bill-alt',
    'far fa-money-bill-alt',
    'fas fa-motorcycle',
    'fas fa-music',
    'fas fa-newspaper',
    'far fa-newspaper',
    'fas fa-paw',
    'fas fa-phone',
    'fas fa-phone-square',
    'fas fa-phone-volume',
    'fas fa-plane',
    'fas fa-plug',
    'fas fa-plus',
    'fas fa-plus-square',
    'far fa-plus-square',
    'fas fa-print',
    'fas fa-recycle',
    'fas fa-road',
    'fas fa-rocket',
    'fas fa-search',
    'fas fa-search-minus',
    'fas fa-search-plus',
    'fas fa-ship',
    'fas fa-shopping-bag',
    'fas fa-shopping-basket',
    'fas fa-shopping-cart',
    'fas fa-shower',
    'fas fa-street-view',
    'fas fa-subway',
    'fas fa-suitcase',
    'fas fa-tag',
    'fas fa-tags',
    'fas fa-taxi',
    'fas fa-thumbtack'
  ],
  Objects: [
    'fas fa-ambulance',
    'fas fa-anchor',
    'fas fa-archive',
    'fas fa-balance-scale',
    'fas fa-bath',
    'fas fa-bed',
    'fas fa-beer',
    'fas fa-bell',
    'far fa-bell',
    'fas fa-bicycle',
    'fas fa-binoculars',
    'fas fa-birthday-cake',
    'fas fa-bomb',
    'fas fa-book',
    'fas fa-bookmark',
    'far fa-bookmark',
    'fas fa-briefcase',
    'fas fa-bug',
    'fas fa-building',
    'far fa-building',
    'fas fa-bullhorn',
    'fas fa-bullseye',
    'fas fa-bus',
    'fas fa-calculator',
    'fas fa-calendar',
    'far fa-calendar',
    'fas fa-calendar-alt',
    'far fa-calendar-alt',
    'fas fa-camera',
    'fas fa-camera-retro',
    'fas fa-car',
    'fas fa-clipboard',
    'far fa-clipboard',
    'fas fa-cloud',
    'fas fa-coffee',
    'fas fa-cog',
    'fas fa-cogs',
    'fas fa-compass',
    'far fa-compass',
    'fas fa-copy',
    'far fa-copy',
    'fas fa-cube',
    'fas fa-cubes',
    'fas fa-cut',
    'fas fa-envelope',
    'far fa-envelope',
    'fas fa-envelope-open',
    'far fa-envelope-open',
    'fas fa-eraser',
    'fas fa-eye',
    'fas fa-eye-dropper',
    'fas fa-fax',
    'fas fa-fighter-jet',
    'fas fa-file',
    'far fa-file',
    'fas fa-file-alt',
    'far fa-file-alt',
    'fas fa-film',
    'fas fa-fire',
    'fas fa-fire-extinguisher',
    'fas fa-flag',
    'far fa-flag',
    'fas fa-flag-checkered',
    'fas fa-flask',
    'fas fa-futbol',
    'far fa-futbol',
    'fas fa-gamepad',
    'fas fa-gavel',
    'fas fa-gem',
    'far fa-gem',
    'fas fa-gift',
    'fas fa-glass-martini',
    'fas fa-globe',
    'fas fa-graduation-cap',
    'fas fa-hdd',
    'far fa-hdd',
    'fas fa-headphones',
    'fas fa-heart',
    'far fa-heart',
    'fas fa-home',
    'fas fa-hospital',
    'far fa-hospital',
    'fas fa-hourglass',
    'far fa-hourglass',
    'fas fa-image',
    'far fa-image',
    'fas fa-images',
    'far fa-images',
    'fas fa-industry',
    'fas fa-key',
    'fas fa-keyboard',
    'far fa-keyboard',
    'fas fa-laptop',
    'fas fa-leaf',
    'fas fa-lemon',
    'far fa-lemon',
    'fas fa-life-ring',
    'far fa-life-ring',
    'fas fa-lightbulb',
    'far fa-lightbulb',
    'fas fa-lock',
    'fas fa-lock-open',
    'fas fa-magic',
    'fas fa-magnet',
    'fas fa-map',
    'far fa-map',
    'fas fa-map-marker',
    'fas fa-map-marker-alt'
  ],
  'Payments & Shopping': [
    'fab fa-amazon-pay',
    'fab fa-apple-pay',
    'fas fa-bell',
    'far fa-bell',
    'fas fa-bookmark',
    'far fa-bookmark',
    'fas fa-bullhorn',
    'fas fa-camera',
    'fas fa-camera-retro',
    'fas fa-cart-arrow-down',
    'fas fa-cart-plus',
    'fab fa-cc-amazon-pay',
    'fab fa-cc-amex',
    'fab fa-cc-apple-pay',
    'fab fa-cc-diners-club',
    'fab fa-cc-discover',
    'fab fa-cc-jcb',
    'fab fa-cc-mastercard',
    'fab fa-cc-paypal',
    'fab fa-cc-stripe',
    'fab fa-cc-visa',
    'fas fa-certificate',
    'fas fa-credit-card',
    'far fa-credit-card',
    'fab fa-ethereum',
    'fas fa-gem',
    'far fa-gem',
    'fas fa-gift',
    'fab fa-google-wallet',
    'fas fa-handshake',
    'far fa-handshake',
    'fas fa-heart',
    'far fa-heart',
    'fas fa-key',
    'fab fa-paypal',
    'fas fa-shopping-bag',
    'fas fa-shopping-basket',
    'fas fa-shopping-cart',
    'fas fa-star',
    'far fa-star',
    'fab fa-stripe',
    'fab fa-stripe-s',
    'fas fa-tag',
    'fas fa-tags',
    'fas fa-thumbs-down',
    'far fa-thumbs-down',
    'fas fa-thumbs-up',
    'far fa-thumbs-up',
    'fas fa-trophy'
  ],
  Shapes: [
    'fas fa-bookmark',
    'far fa-bookmark',
    'fas fa-calendar',
    'far fa-calendar',
    'fas fa-certificate',
    'fas fa-circle',
    'far fa-circle',
    'fas fa-cloud',
    'fas fa-comment',
    'far fa-comment',
    'fas fa-file',
    'far fa-file',
    'fas fa-folder',
    'far fa-folder',
    'fas fa-heart',
    'far fa-heart',
    'fas fa-map-marker',
    'fas fa-play',
    'fas fa-square',
    'far fa-square',
    'fas fa-star',
    'far fa-star'
  ],
  Spinners: [
    'fas fa-asterisk',
    'fas fa-certificate',
    'fas fa-circle-notch',
    'fas fa-cog',
    'fas fa-compass',
    'far fa-compass',
    'fas fa-crosshairs',
    'fas fa-life-ring',
    'far fa-life-ring',
    'fas fa-snowflake',
    'far fa-snowflake',
    'fas fa-spinner',
    'fas fa-sun',
    'far fa-sun',
    'fas fa-sync'
  ],
  Sports: [
    'fas fa-baseball-ball',
    'fas fa-basketball-ball',
    'fas fa-bowling-ball',
    'fas fa-football-ball',
    'fas fa-futbol',
    'far fa-futbol',
    'fas fa-golf-ball',
    'fas fa-hockey-puck',
    'fas fa-quidditch',
    'fas fa-table-tennis',
    'fas fa-volleyball-ball'
  ],
  Status: [
    'fas fa-ban',
    'fas fa-battery-empty',
    'fas fa-battery-full',
    'fas fa-battery-half',
    'fas fa-battery-quarter',
    'fas fa-battery-three-quarters',
    'fas fa-bell',
    'far fa-bell',
    'fas fa-bell-slash',
    'far fa-bell-slash',
    'fas fa-calendar',
    'far fa-calendar',
    'fas fa-calendar-alt',
    'far fa-calendar-alt',
    'fas fa-calendar-check',
    'far fa-calendar-check',
    'fas fa-calendar-minus',
    'far fa-calendar-minus',
    'fas fa-calendar-plus',
    'far fa-calendar-plus',
    'fas fa-calendar-times',
    'far fa-calendar-times',
    'fas fa-cart-arrow-down',
    'fas fa-cart-plus',
    'fas fa-exclamation',
    'fas fa-exclamation-circle',
    'fas fa-exclamation-triangle',
    'fas fa-eye',
    'fas fa-eye-slash',
    'far fa-eye-slash',
    'fas fa-file',
    'far fa-file',
    'fas fa-file-alt',
    'far fa-file-alt',
    'fas fa-folder',
    'far fa-folder',
    'fas fa-folder-open',
    'far fa-folder-open',
    'fas fa-info',
    'fas fa-info-circle',
    'fas fa-lock',
    'fas fa-lock-open',
    'fas fa-minus',
    'fas fa-minus-circle',
    'fas fa-minus-square',
    'far fa-minus-square',
    'fas fa-plus',
    'fas fa-plus-circle',
    'fas fa-plus-square',
    'far fa-plus-square',
    'fas fa-question',
    'fas fa-question-circle',
    'far fa-question-circle',
    'fas fa-shield-alt',
    'fas fa-shopping-cart',
    'fas fa-sign-in-alt',
    'fas fa-sign-out-alt',
    'fas fa-thermometer-empty',
    'fas fa-thermometer-full',
    'fas fa-thermometer-half',
    'fas fa-thermometer-quarter',
    'fas fa-thermometer-three-quarters',
    'fas fa-thumbs-down',
    'far fa-thumbs-down',
    'fas fa-thumbs-up',
    'far fa-thumbs-up',
    'fas fa-toggle-off',
    'fas fa-toggle-on',
    'fas fa-unlock',
    'fas fa-unlock-alt'
  ],
  'Users & People': [
    'fab fa-accessible-icon',
    'fas fa-address-book',
    'far fa-address-book',
    'fas fa-address-card',
    'far fa-address-card',
    'fas fa-bed',
    'fas fa-blind',
    'fas fa-child',
    'fas fa-female',
    'fas fa-frown',
    'far fa-frown',
    'fas fa-id-badge',
    'far fa-id-badge',
    'fas fa-id-card',
    'far fa-id-card',
    'fas fa-male',
    'fas fa-meh',
    'far fa-meh',
    'fas fa-power-off',
    'fas fa-smile',
    'far fa-smile',
    'fas fa-street-view',
    'fas fa-user',
    'far fa-user',
    'fas fa-user-circle',
    'far fa-user-circle',
    'fas fa-user-md',
    'fas fa-user-plus',
    'fas fa-user-secret',
    'fas fa-user-times',
    'fas fa-users',
    'fas fa-wheelchair'
  ],
  Vehicles: [
    'fab fa-accessible-icon',
    'fas fa-ambulance',
    'fas fa-bicycle',
    'fas fa-bus',
    'fas fa-car',
    'fas fa-fighter-jet',
    'fas fa-motorcycle',
    'fas fa-paper-plane',
    'far fa-paper-plane',
    'fas fa-plane',
    'fas fa-rocket',
    'fas fa-ship',
    'fas fa-shopping-cart',
    'fas fa-space-shuttle',
    'fas fa-subway',
    'fas fa-taxi',
    'fas fa-train',
    'fas fa-truck',
    'fas fa-wheelchair'
  ],
  Writing: [
    'fas fa-archive',
    'fas fa-book',
    'fas fa-bookmark',
    'far fa-bookmark',
    'fas fa-edit',
    'far fa-edit',
    'fas fa-envelope',
    'far fa-envelope',
    'fas fa-envelope-open',
    'far fa-envelope-open',
    'fas fa-eraser',
    'fas fa-file',
    'far fa-file',
    'fas fa-file-alt',
    'far fa-file-alt',
    'fas fa-folder',
    'far fa-folder',
    'fas fa-folder-open',
    'far fa-folder-open',
    'fas fa-keyboard',
    'far fa-keyboard',
    'fas fa-newspaper',
    'far fa-newspaper',
    'fas fa-paper-plane',
    'far fa-paper-plane',
    'fas fa-paperclip',
    'fas fa-paragraph',
    'fas fa-pen-square',
    'fas fa-pencil-alt',
    'fas fa-quote-left',
    'fas fa-quote-right',
    'fas fa-sticky-note',
    'far fa-sticky-note',
    'fas fa-thumbtack'
  ]
}
