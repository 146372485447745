import { Col, Input } from 'reactstrap'
import Datetime from 'react-datetime'
import moment from 'moment'

var DateTimeBoostrap = (props) => {
  const isValidDate = (current) => {
    return current.isAfter(moment('2022-12-31'), 'day')
  }
  return (
    <Datetime
      {...props}
      utc={false}
      dateFormat='DD/MM/YYYY'
      isValidDate={isValidDate}
      renderInput={(props) => (
        <div>
          <Input {...props} autoComplete='off' />
          <span className='invalid-feedback'>Data e formatuar gabim</span>
        </div>
      )}
    />
  )
}

const ComplianceFilters = ({ from, setFrom, to, setTo, search, setSearch }) => {
  const onFromDateChange = async (date) => {
    const minDate = moment('2023-01-01')
    if (typeof date === 'object' && moment(date).isSameOrAfter(minDate)) {
      setFrom((prevState) => ({
        ...prevState,
        value: moment(date).format('YYYY-MM-DD')
      }))
    }
  }

  const onToDateChange = async (date) => {
    if (typeof date == 'object') {
      setTo((prevState) => ({
        ...prevState,
        value: moment(date).format('YYYY-MM-DD')
      }))
    }
  }

  return (
    <div style={{}}>
      <div className='input-group'>
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className='form-control'
          type='text'
          placeholder='Kërko përdoruesit...'
        />
        <span className='input-group-btn'>
          <button disabled className='btn btn-secondary' type='button'>
            <em className='fa fa-search'></em>
          </button>
        </span>
      </div>

      <div className='input-group mt-3'>
        <Col style={{ display: 'flex', justifyContent: 'flex-' }}>
          <div className='form-group row mb-0 float-left pl-1'>
            <div className='checkbox c-checkbox pt-2'>
              <label>
                <Input
                  onChange={() => {
                    setFrom((prevState) => ({
                      ...prevState,
                      isChecked: !from.isChecked
                    }))
                  }}
                  id='fromdate'
                  name='fromdate'
                  type='checkbox'
                />
                <span className='fa fa-check'></span>
              </label>
            </div>
            <label className='col-form-label mb-0'>Prej datës:</label>
            <Col md={7} className='from-date'>
              <DateTimeBoostrap
                value={from.value}
                onChange={onFromDateChange}
                locale='de'
                dateFormat={'DD/MM/YYYY'}
                timeFormat={false}
                name='fromdate'
                type='checkbox'
                closeOnSelect
                inputProps={{
                  disabled: !from.isChecked && 'disabled',
                  id: 'dateFrom',
                  'data-validate': '["validDate"]',
                  className: 'form-control input',
                  name: 'dateFrom'
                }}
              />
            </Col>
          </div>

          <div className='form-group row mb float-left pl-1'>
            <div className='checkbox c-checkbox pt-2'>
              <label>
                <Input
                  onChange={() => {
                    setTo((prevState) => ({
                      ...prevState,
                      isChecked: !to.isChecked
                    }))
                  }}
                  name='todate'
                  id='todate'
                  type='checkbox'
                />
                <span className='fa fa-check'></span>
              </label>
            </div>
            <label className='col-form-label mb'>Deri:</label>
            <Col md={7} className='to-date'>
              <DateTimeBoostrap
                value={to.value}
                type='date'
                closeOnSelect
                dateFormat={'DD/MM/YYYY'}
                timeFormat={false}
                onChange={onToDateChange}
                placeholder='yy/mm/dd'
                inputProps={{
                  disabled: !to.isChecked && 'disabled',
                  id: 'dateTo',
                  'data-validate': '["validDate"]',
                  className: 'form-control',
                  name: 'dateTo'
                }}
              />
            </Col>
          </div>
        </Col>
      </div>
    </div>
  )
}
export default ComplianceFilters
