import React, { Component, Fragment } from 'react'
import { BrowserRouter } from 'react-router-dom'
import $ from 'jquery'
import { inject } from 'mobx-react'
import { ToastContainer, toast } from 'react-toastify'
import Routes from './Routes'
import { BASE_API } from '../src/utils/consts'
import './Vendor'
import './assets/css/berryrotundafont.css'
import './styles/bootstrap.scss'
import './styles/app.scss'
import 'sweetalert2/src/sweetalert2.scss'
import axios from 'axios'
import pubsub from 'pubsub-js'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import jwt_decode from 'jwt-decode'

// Disable warning "Synchronous XMLHttpRequest on the main thread is deprecated.."
$.ajaxPrefilter((o) => (o.async = true))

@inject('userStore')
class App extends Component {
  render() {
    const token = localStorage.getItem('token')

    axios.defaults.baseURL = BASE_API
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear()
          window.location.pathname = '/login'
        } else {
          throw error
        }
      }
    )
    if (token) {
      const decodedToken = jwt_decode(token)
      this.props.userStore.setUserLevel(decodedToken.user_level)
      this.props.userStore.setUserID(decodedToken.user_id)
      this.props.userStore.setAuthenticated(true)
      this.props.userStore.setUserEmail(decodedToken.email)
      this.props.userStore.setStartDate(decodedToken.start_date)
      this.props.userStore.setUserHasBirthday(decodedToken.userHasBirthday)
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` }
    }

    //Redirect to login
    if (!this.props.userStore.isAuthenticated && window.location.pathname !== '/login' && window.location.pathname !== '/passwordReset') {
      window.location.pathname = '/login'
    }

    if (this.props.userStore.isAuthenticated && window.location.pathname === '/') {
      window.location.pathname = '/dashboard'
    }

    pubsub.subscribe('showNotification', (_, data) => {
      let link = ''
      switch (data.type_notif) {
        case 'SALERY_PROCESSED_NOTIFICATION':
          link = '/wages'
          break
        case 'LEAVE_APPROVED_NOTIFICATION':
        case 'LEAVE_REJECTED_NOTIFICATION':
          link = '/leaves/' + data.ref_id
          break
        case 'NEW_LEAVE_NOTIFICATION':
          link = '/manageLeaves/' + this.props.userStore.user_id + '/' + data.ref_id
          break
        case 'CAR_USAGE_APPROVED_NOTIFICATION':
        case 'CAR_USAGE_REJECTED_NOTIFICATION':
          link = '/bookings/' + data.ref_id
          break
        case 'NEW_CAR_USAGE_NOTIFICATION':
          link = '/manageBookings/' + this.props.userStore.user_id + '/' + data.ref_id
          break
        case 'FEED_NOTIFICATION':
          link = '/dashboard'
          break
        default:
          link = null // No link for default case
          break
      }

      const content = link ? (
        <a style={{ textDecoration: 'none', color: 'white' }} href={link}>
          {data.message}{' '}
        </a>
      ) : (
        data.message
      )

      toast(content, {
        type: data.type,
        position: 'top-right'
      })
    })

    return (
      <Fragment>
        <ToastContainer />
        <BrowserRouter basename='/'>
          <Routes />
        </BrowserRouter>
      </Fragment>
    )
  }
}

export default DragDropContext(HTML5Backend)(App)
