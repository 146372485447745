import { Paper, Table, TableRow, TableHead, TableCell, TableContainer } from '@mui/material'
import React from 'react'

const DayTable = ({ uploadedDays }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell>Ditët e Plotësuara</TableCell>
            {uploadedDays.map(({ day }) => (
              <TableCell key={day} align='right'>
                {day}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>

  )
}

export default DayTable
